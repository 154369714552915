import { Button, FormGroup, FormHelperText, Typography } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import useHelperText from "client/hooks/useHelperText";
import Logging from "client/utils/logging";
import notifications from "client/utils/notifications";
import { FieldProps } from "formik";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import TrackInput, { TrackInputProps } from "../TrackInput";

export interface IdVerificationFieldProps extends FieldProps<{ completed: null | boolean }>, TrackInputProps {
  verificationURL?: string;
  onComplete: () => any;
  onSkip?: () => any;
  skipDisabled?: boolean;
  helperText?: string;
  hideDoItLater?: boolean;
}

const useStyles = makeStyles({ name: "IdVerificationField" })((theme) => ({
  yotiContainer: {
    height: "84vh",
    maxHeight: 786,
    width: "100%",
    maxWidth: 629,
    alignSelf: "center",
    overflow: "auto",
    display: "flex",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  yoti: {
    border: "none",
    width: "100%",
  },
}));

const IdVerificationField: FC<IdVerificationFieldProps> = ({
  field,
  form: { setFieldError, setFieldValue, setSubmitting },
  onComplete,
  onSkip,
  skipDisabled,
  hideDoItLater,
  verificationURL: amlChecksURL,
  category,
  ...props
}) => {
  const { classes } = useStyles();
  const { hasError, helperText } = useHelperText(field.name, props.helperText);

  const completedName = useMemo(() => [...(field.name ? [field.name] : []), "completed"].join("."), [field.name]);

  const handleEvent = useCallback(
    (event) => {
      if (event.data.eventType === "SUCCESS") {
        if (onComplete) onComplete();
        return setFieldValue(completedName, true);
      }
      if (event.data.eventType === "ERROR") {
        Logging.error(
          event,
          "Sorry there was an error during the process. Either press DO IT LATER to continue with the flow, or refresh the page to start again.",
          { duration: 10 },
        );
        setFieldError(
          completedName,
          "Sorry there was an error during the process. Either press DO IT LATER to continue with the flow, or refresh the page to start again.",
        );
        return setFieldValue(completedName, false);
      }
    },
    [completedName, onComplete, setFieldError, setFieldValue],
  );

  const handleCompleteLater = useCallback(() => {
    setFieldValue(completedName, false);
    setSubmitting(true);
    if (onSkip) onSkip();
  }, [completedName, onSkip, setFieldValue, setSubmitting]);

  useEffect(() => {
    window.addEventListener("message", handleEvent);
    return () => window.removeEventListener("message", handleEvent);
  }, [handleEvent]);

  useEffect(() => {
    (async () => {
      try {
        const coerce = await import("semver/functions/coerce");
        const semverLessThan = await import("semver/functions/lt");
        const { UAParser } = await import("ua-parser-js");
        const osInfo = new UAParser().getOS();
        const parsedVersion = coerce.default(osInfo.version, { loose: true });
        if (
          osInfo.name?.toLowerCase() === "ios" &&
          osInfo.version &&
          parsedVersion &&
          semverLessThan.default(parsedVersion, "14.0.0", { loose: true })
        ) {
          notifications.warning(
            <>
              There may be issues with the identity verification process on this version of iOS. We recommend you do one
              of the following: update your device to the latest version of iOS, change devices or press &ldquo;DO IT
              LATER&rdquo; to skip the process for now.
            </>,
            { duration: 30 },
          );
        }
      } catch (error) {
        Logging.error(error, { quiet: true });
      }
    })();
  }, [completedName]);

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      <ErrorBoundary onError={onSkip}>
        <FormGroup>
          <div className={classes.yotiContainer}>
            {amlChecksURL ? (
              <iframe
                title="ID Verification"
                src={amlChecksURL}
                className={classes.yoti}
                allow="camera"
                onError={onSkip}
              />
            ) : null}
          </div>
        </FormGroup>
        <FormGroup>
          <Typography variant="caption">
            <strong>
              Once you have completed the steps, please wait while we attempt to prefill some answers for you. When
              completed, we will move you onto the next stage.
            </strong>
          </Typography>
          {hideDoItLater ? null : (
            <FormGroup>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleCompleteLater}
                disabled={Boolean(field.value.completed) || skipDisabled}
                data-testid="skip-id-verification"
              >
                Do it later
              </Button>
            </FormGroup>
          )}
        </FormGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </ErrorBoundary>
    </TrackInput>
  );
};

export default IdVerificationField;
