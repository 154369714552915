import { useEffect, useRef } from "react";

const useDynamicRef = <T>(value: T) => {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref;
};

export default useDynamicRef;
