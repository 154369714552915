import { useEffect } from "react";

let currentDescription = "";

const getDescMetaTag = (): HTMLMetaElement | undefined => {
  if (typeof document === "undefined") return;
  const existingDescTag = document.querySelector<HTMLMetaElement>("meta[name='description']");
  if (existingDescTag) return existingDescTag;

  const newDescTag = document.createElement("meta");
  newDescTag.name = "description";
  document.head.append(newDescTag);
  return newDescTag;
};

const descMetaTag: HTMLMetaElement | undefined = getDescMetaTag();

const useDescription = (desc: string) => {
  currentDescription = desc;
  useEffect(() => {
    if (!descMetaTag) return;
    const prevDesc = descMetaTag?.content;
    descMetaTag.content = desc;
    return () => {
      descMetaTag.content = prevDesc;
    };
  }, [desc]);
};

export const getDescription = () => currentDescription;

export default useDescription;
