import useSCEffect from "@wearenova/use-sce";
import { Axios } from "axios";
import { getAdminApplication, patchAdminApplicationData } from "client/api/admin/applications/application";
import { getClientApplicationById, getLatestClientApplication } from "client/api/advisor/clients/client/applications";
import {
  patchAdvisedApplicationData,
  getAdvisedAssessment,
} from "client/api/advisor/clients/client/applications/application";
import { getLatestApplication } from "client/api/applications";
import { getApplicationById, patchApplicationData } from "client/api/applications/application";
import useAppContext, { AppActions } from "client/context/app";
import useAuthContext from "client/context/auth";
import useFundsContext, { FundsActions } from "client/context/funds";
import useQueryParams from "client/hooks/useQueryParams";
import { ObjectIdLike } from "mongoose";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApplicationData, LeanApplication } from "server/services/application";
import { AssessmentStatus } from "server/services/assessment/consts";
import { Forms } from "../utils";

const useApplication = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    fundId,
    applicationId: paramAppId,
    clientId: clientIdParam,
  } = useParams<{ fundId: string; applicationId?: string; clientId?: string }>();
  const [{ applicationId: queryAppId }] = useQueryParams<{ applicationId?: string }>();
  const { userApp, clientApp, dispatch } = useAppContext();
  const { user } = useAuthContext();
  const { funds, selected, dispatch: fundsDispatch } = useFundsContext();

  const applicationId = useMemo(() => paramAppId || queryAppId, [paramAppId, queryAppId]);
  const clientId = useMemo(() => (user?.isAdvisor && clientIdParam) || null, [clientIdParam, user?.isAdvisor]);
  const isAdvisor = useMemo(() => Boolean(clientId), [clientId]);
  const isAdmin = useMemo(
    () => Boolean(applicationId && user?.isAdmin && location.pathname.includes("/admin")),
    [applicationId, location.pathname, user?.isAdmin],
  );
  const isAdvised = useMemo(() => user?.isAdvised, [user?.isAdvised]);
  const application = useMemo(() => (isAdvisor ? clientApp : userApp), [clientApp, isAdvisor, userApp]);
  const assessmentId = useMemo(() => (isAdvisor ? null : user?.assessmentId), [isAdvisor, user?.assessmentId]);

  const getApplication = useCallback(
    async (axios?: Axios) => {
      if (isAdmin) return getAdminApplication.call(axios, applicationId!);
      if (applicationId && isAdvisor) return getClientApplicationById.call(axios, clientId!, applicationId);
      if (applicationId) return getApplicationById.call(axios, applicationId);
      if (isAdvisor) return getLatestClientApplication.call(axios, clientId!, fundId!);
      return getLatestApplication.call(axios, fundId!);
    },
    [applicationId, clientId, fundId, isAdmin, isAdvisor],
  );

  const handleAppDataPatch = useCallback(
    async (values: Partial<ApplicationData>) => {
      if (!application) return null;
      let res: LeanApplication | null = null;
      if (isAdvisor) {
        res = await patchAdvisedApplicationData(clientId!, application._id, values);
        if (res) dispatch({ type: AppActions.SetClientApp, payload: res });
        return res;
      }
      if (isAdmin) {
        res = await patchAdminApplicationData(application._id, values);
      } else {
        res = await patchApplicationData(application._id, values);
      }
      if (res) dispatch({ type: AppActions.SetUserApp, payload: res });
      return res;
    },
    [application, clientId, dispatch, isAdmin, isAdvisor],
  );

  useSCEffect(
    async (axios?: Axios) => {
      if (userApp?.funds.includes(selected?._id)) return;
      if (
        isAdvisor &&
        clientApp?.funds.includes(selected?._id) &&
        (clientApp.investor.user as ObjectIdLike) === clientId
      )
        return;
      const res = await getApplication(axios);
      if (!res) return null;
      if (!("redirectTo" in res)) {
        dispatch({ type: isAdvisor ? AppActions.SetClientApp : AppActions.SetUserApp, payload: res });
        return res;
      }
      if (typeof window !== "undefined") navigate(res.redirectTo, { replace: true });
      return null;
    },
    [clientApp, clientId, dispatch, funds, getApplication, isAdvisor, selected, userApp],
    isAdvisor ? "clientApp" : "userApp",
  );

  useSCEffect(async () => {
    const isPath =
      location.pathname.includes(`/${Forms.InvestorDetails}`) ||
      location.pathname.includes(`/${Forms.Objectives}`) ||
      location.pathname.includes(`/${Forms.Finances}`) ||
      location.pathname.includes(`/${Forms.Experience}`) ||
      location.pathname.includes(`/${Forms.Investment}`)
        ? true
        : false;
    if (!isAdvisor && user?.assessmentStatus != AssessmentStatus.CONTINUE_TO_THE_APPLICATION && isPath) {
      navigate(`../${Forms.Assessment}`, { replace: true });
    }
    if (isAdvisor && isPath && clientId != null) {
      const advisedAssessmentRes = await getAdvisedAssessment(clientId);
      if (advisedAssessmentRes) {
        if (advisedAssessmentRes.status != AssessmentStatus.CONTINUE_TO_THE_APPLICATION)
          navigate(`../${Forms.Assessment}`, { replace: true });
      }
    }
  }, [user?.assessmentStatus, isAdvisor]);

  useSCEffect(() => {
    if (fundId || selected || !application) return;
    // TODO: handle multiple funds
    fundsDispatch({ type: FundsActions.SetFund, payload: funds.find((f) => f._id === application.funds[0])! });
  }, [application, fundId, funds, selected]);

  return { application, handleAppDataPatch, isAdvised, isAdvisor, isAdmin, clientId, assessmentId };
};

export default useApplication;
