import { Container, FormGroup, Typography } from "@mui/material";
import Divider from "client/components/Divider";
import FormButtons from "client/components/FormButtons";
import useAuthContext from "client/context/auth";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { ErrorsList, FormGenConfig, RadioConfig, TextConfig } from "form-gen";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Experience as ExperienceType } from "server/services/application";
import {
  Amounts,
  FinancialEducationLevels,
  NetWorthPercentages,
  RiskLevels,
  Times,
  CashEquivalents,
} from "server/services/application/consts";
import Yup from "shared/utils/Yup";
import useApplication from "./hooks/useApplication";
import { Forms } from "./utils";
const TimesOptions = Object.values(Times);
const AmountOptions = Object.values(Amounts);

const getTimesAmountFields = (
  name: keyof ExperienceType,
  changes?: {
    present?: Partial<RadioConfig<any>>;
    noTimes?: Partial<RadioConfig<any>>;
    amount?: Partial<RadioConfig<any>>;
    prevInvested?: Partial<RadioConfig<any>>;
  },
): FormGenConfig<any> => [
  {
    type: "radio",
    name: "present",
    label: "Yes or No?",
    validation: Yup.boolean().nullable().required("Please select either Yes or No"),
    ...changes?.present,
  },
  {
    type: "radio",
    name: "noTimes",
    label: "Number of times invested",
    options: TimesOptions,
    condition: (values) => Boolean(values[name]?.present),
    get validation() {
      return Yup.string()
        .nullable()
        .when("present", {
          is: true,
          then: (schema) =>
            schema
              .oneOf(this.options as string[], "Please enter no. of times invested")
              .required("Please enter no. of times invested"),
        });
    },
    ...changes?.noTimes,
  },
  {
    type: "radio",
    name: "amount",
    label: "Please confirm how much, in total, you have invested in each asset class",
    options: AmountOptions,
    condition: (values) => Boolean(values[name]?.present),
    get validation() {
      return Yup.string()
        .nullable()
        .when("present", {
          is: true,
          then: (schema) =>
            schema
              .oneOf(this.options as string[], "Please enter the amount invested or lent")
              .required("Please enter the amount invested or lent"),
        });
    },
    ...changes?.amount,
  },
  {
    type: "radio",
    name: "prevInvested",
    label: (
      <>
        Have you invested in this type of asset in the last <strong>TWO</strong> years?
      </>
    ),
    stringLabel: "Have you invested in this type of asset in the last TWO years?",
    condition: (values) => Boolean(values[name]?.present),
    validation: Yup.boolean()
      .nullable()
      .when("present", {
        is: true,
        then: (schema) => schema.required("Please select either Yes or No"),
      }),
    ...changes?.prevInvested,
  },
];

const getTimesYearsFields = (
  name: keyof ExperienceType,
  changes?: {
    present?: Partial<RadioConfig<any>>;
    noTimes?: Partial<TextConfig<any>>;
    years?: Partial<TextConfig<any>>;
  },
): FormGenConfig<any> => [
  {
    type: "radio",
    name: "present",
    label: "Yes or No?",
    validation: Yup.boolean().nullable().required("Please select either Yes or No"),
    ...changes?.present,
  },
  {
    type: "section",
    condition: (values) => Boolean(values[name]?.present),
    disableFormGroups: true,
    props: { row: true },
    fields: [
      {
        type: "text",
        name: "noTimes",
        label: "Number of times",
        placeholder: "Number of times, e.g. 2+",
        validation: Yup.string()
          .nullable()
          .when("present", {
            is: true,
            then: (schema) => schema.required("Please enter no. of times invested"),
          }),
        ...changes?.noTimes,
      },
      {
        type: "text",
        name: "years",
        label: "Number of years",
        placeholder: "Number of years, e.g. 5+",
        validation: Yup.string()
          .nullable()
          .when("present", {
            is: true,
            then: (schema) => schema.required("Please enter no. of years"),
          }),
        ...changes?.years,
      },
    ],
  },
];

function simpleRadioValidation(this: RadioConfig<any>) {
  return Yup.string()
    .nullable()
    .oneOf(this.options as string[], "Please select an option")
    .required("Please select an option");
}

export const getExperienceConfig = (isAdvisor: boolean, isSophisticated: boolean): FormGenConfig<ExperienceType> => [
  {
    type: "section",
    name: "taxProducts",
    fields: getTimesAmountFields("taxProducts", {
      present: {
        label: "Have you invested in tax products (e.g. VCTs, SEIS/EIS funds)?",
      },
    }),
  },
  {
    type: "section",
    name: "largeCompanies",
    fields: getTimesAmountFields("largeCompanies", {
      present: {
        label: "Have you invested in large quoted companies (including authorised unit trusts, OEICs, ISAs, PEPs)?",
      },
    }),
  },
  {
    type: "section",
    name: "smallCompanies",
    fields: getTimesAmountFields("smallCompanies", {
      present: {
        label: "Have you invested in smaller quoted companies (not listed on AIM)?",
      },
    }),
  },
  {
    type: "section",
    name: "aimCompanies",
    fields: getTimesAmountFields("aimCompanies", {
      present: {
        label: "Have you invested in AIM-listed companies or unquoted companies?",
      },
    }),
  },
  {
    type: "section",
    name: "realEstate",
    fields: getTimesAmountFields("realEstate", {
      present: {
        label: "Have you invested in Real Estate properties (excluding your home)?",
      },
      amount: { label: "Estimated amount held (£)" },
    }),
  },
  {
    type: "section",
    name: "otherSophisticatedInvestments",
    fields: getTimesAmountFields("otherSophisticatedInvestments", {
      present: {
        label:
          "Have you invested in other sophisticated investments such as unregulated collective investment schemes, foreign currencies, commodities or futures?",
      },
    }),
  },
  {
    type: "text",
    name: "companies",
    label: "If possible, please provide the name(s) of these investment companies.",
    placeholder: "Company",
    condition: (values) => Boolean(values["otherSophisticatedInvestments"]?.present),
    validation: Yup.string(),
  },

  {
    type: "section",
    name: "companyDirector",
    fields: getTimesYearsFields("companyDirector", {
      present: { label: "Have you been a director of a company with £1m+ turnover?" },
    }),
  },
  {
    type: "section",
    name: "managementTeam",
    fields: getTimesYearsFields("managementTeam", {
      present: { label: "Have you ever been part of a management team of an early stage, unquoted company?" },
    }),
  },
  {
    type: "section",
    name: "businessAngels",
    fields: [
      {
        type: "radio",
        name: "present",
        label: "Are you a member of an investor network or business angels?",
        validation: Yup.boolean().nullable().required("Please select either Yes or No"),
      },
      {
        type: "text",
        name: "details",
        label: "Please provide the name of the organisation and company registration number if available",
        placeholder: "Name of organisation and company registration number",
        condition: (values) => values.businessAngels?.present,
        validation: Yup.string().when("present", {
          is: true,
          then: (schema) =>
            schema.required("Please provide the name of the organisation and company registration number"),
        }),
      },
    ],
  },
  {
    type: "section",
    name: "financialEducation",
    fields: [
      {
        type: "radio",
        name: "present",
        label: "Please indicate if you have an education history or qualification in finance/financial services",
        validation: Yup.boolean().nullable().required("Please select either Yes or No"),
      },
      {
        type: "select",
        name: "qualifications",
        label: "Please indicate the nature of your finance education or qualification",
        placeholder: "Finance education or qualification details",
        options: Object.values(FinancialEducationLevels),
        props: { multiple: true },
        initialValue: [],
        condition: (values) => Boolean(values.financialEducation?.present),
        validation: Yup.array(Yup.string()).when("present", {
          is: true,
          then: Yup.array(Yup.string())
            .min(1, "Please give details about your finance education or qualification")
            .required("Please give details about your finance education or qualification"),
        }),
      },
      {
        type: "text",
        name: "otherQualifications",
        label: "Other qualifications",
        placeholder: "Please detail your education history or qualification in finance",
        condition: (values) =>
          Boolean(
            values.financialEducation?.present &&
              values.financialEducation?.qualifications?.includes(FinancialEducationLevels.Other),
          ),
        validation: Yup.string().when({
          is: (values: ExperienceType["financialEducation"]) =>
            values?.qualifications?.includes(FinancialEducationLevels.Other),
          then: (schema) => schema.required("Please detail your education history or qualification in finance"),
        }),
      },
    ],
  },
  {
    type: "text",
    name: "vocationalEducation",
    label:
      "Please describe the highest level of qualification/education you have obtained and the area of study and/or qualification",
    placeholder: "Highest level of qualification/education",
    condition: (values) =>
      typeof values.financialEducation?.present === "boolean" && !values.financialEducation?.present,
    validation: Yup.string().when("financialEducation.present", {
      is: false,
      then: Yup.string().required("Please describe the highest level of qualification/education you have obtained"),
    }),
  },

  {
    type: "text",
    name: "occupation",
    label:
      "What is your occupation or profession and your level (e.g., director, manager, etc). If retired, please indicate your previous profession.",
    placeholder: "Occupation or profession and your level",
    validation: Yup.string().required("Please enter your occupation or profession and your level"),
  },
  {
    type: "section",
    name: "relevantProfession",
    fields: [
      {
        type: "radio",
        name: "present",
        label:
          "Please also indicate if you have previously held a position in the financial services sector or if you are a relevant professional (e.g. accountant, stockbroker, solicitor, investment manager, IFA, etc.)",
        validation: Yup.boolean().nullable().required("Please select either Yes or No"),
      },
      {
        type: "text",
        name: "details",
        label: "Please indicate the number of years you held this position",
        placeholder: "Number of years, e.g. 10+",
        condition: (values) => Boolean(values.relevantProfession?.present),
        validation: Yup.string().when("present", {
          is: true,
          then: Yup.string().required("Please indicate the number of years you held this position"),
        }),
      },
    ],
  },
  {
    type: "text",
    name: "selfCertifiedCompany",
    label: "Please provide the company name you were a director of for this period.",
    placeholder: "Company name",
    condition: (values) => typeof values.occupation === "string" && isSophisticated === true,
    validation: Yup.string().when({
      is: (values: ExperienceType["financialEducation"]) =>
        (values?.qualifications?.includes(FinancialEducationLevels.Other) ||
          !values?.qualifications?.includes(FinancialEducationLevels.Other)) &&
        isSophisticated,
      then: (schema) => schema.required("Please provide company name"),
    }),
  },
  {
    type: "section",
    fields: [
      {
        type: "radio",
        name: "levelOfRisk",
        label:
          "What level of risk are you prepared to accept with your investments in early stage, unquoted companies? Please note, this fund would be regarded as 'aggressive risk'.",
        props: {
          optionLabelProps: { variant: "body2" },
        },
        options: [
          {
            value: RiskLevels.Conservative,
            label:
              "Conservative - Wishes to preserve capital or receive a moderate return while avoiding exposure to significant risk.",
          },
          {
            value: RiskLevels.ModeratelyConservative,
            label:
              "Moderately Conservative - Tolerant of a little risk to the capital in order to gain some returns but overall adverse to any significant loss of capital.",
          },
          {
            value: RiskLevels.Moderate,
            label:
              "Moderate - Wishes to receive good returns over the long term and willing to take some risk to capital to achieve this.",
          },
          {
            value: RiskLevels.ModeratelyAggressive,
            label:
              "Moderately Aggressive - Wishes to receive above average returns with aim of accumulating a significant amount of wealth and does not mind putting capital at risk or waiting for a significant amount of time to receive return on investment.",
          },
          {
            value: RiskLevels.Aggressive,
            label:
              "Aggressive - Seeks returns that are substantially higher than average and willing to expose capital to significant risk in order to achieve this objective.",
          },
        ],
        validation: Yup.string()
          .nullable()
          .oneOf(Object.values(RiskLevels), "Please select a level of risk")
          .required("Please select a level of risk"),
      },
      {
        type: "section",
        title: (values) =>
          `Note: By selecting a ${values.levelOfRisk} level of risk, your application is likely to be denied.`,
        titleProps: { variant: "body2", color: "error" },
        condition: (values) =>
          values.levelOfRisk !== RiskLevels.Aggressive && values.levelOfRisk !== RiskLevels.ModeratelyAggressive,
      },
    ],
  },
  {
    type: "number",
    name: "holdInEarlyStageCompanies",
    label:
      "How much do you propose to hold in total in early-stage unquoted companies including your subscription to this fund?",
    placeholder: "How much do you propose to hold in early-stage unquoted companies",
    validation: Yup.number()
      .typeError("Please specify how much you hold in early-stage unquoted companies")
      .required("Please specify how much you hold in early-stage unquoted companies"),
  },
  {
    type: "section",
    title: (
      <>
        We believe that investment in early stage, unquoted companies should only be considered by people who, assuming
        they suffer a total loss of all such investments, are financially secure having an appropriate pension, liquid
        savings or reasonably assured income and a sufficiently unencumbered personal residence.{" "}
        <strong>
          Furthermore, we expect that no more than 5% of an investor&apos;s net worth should be committed to early
          stage, unquoted investments (which this fund intends to invest in).
        </strong>
      </>
    ),
    stringTitle:
      "We believe that investment in early stage, unquoted companies should only be considered by people who, assuming they suffer a total loss of all such investments, are financially secure having an appropriate pension, liquid savings or reasonably assured income and a sufficiently unencumbered personal residence. Furthermore, we expect that no more than 5% of an investor's net worth should be committed to early stage, unquoted investments (which this fund intends to invest in).",
    fields: [
      {
        type: "radio",
        name: "percentageNetWorth",
        label: "What percentage does the amount in the prior question account for your net worth?",
        options: Object.values(NetWorthPercentages),
        get validation() {
          return simpleRadioValidation.call(this);
        },
      },
      {
        type: "section",
        title: `You could lose all your money in this investment. We do not recommend that you invest more than 5% of your net worth in this fund. Please reconsider the amount you are investing.${
          isAdvisor ? "" : " Please contact your IFA."
        }`,
        titleProps: { variant: "body2" },
        condition: (values) =>
          values.percentageNetWorth && values.percentageNetWorth !== NetWorthPercentages.ZeroToFive,
      },
    ],
  },
  {
    type: "section",
    fields: [
      {
        type: "radio",
        name: "cashEquivalents",
        label: "How much do you approximately hold in cash or cash equivalents",
        options: Object.values(CashEquivalents),
        get validation() {
          return simpleRadioValidation.call(this);
        },
      },
    ],
  },
  ...(isAdvisor ? [] : ([] as FormGenConfig<ExperienceType>)),
];

const Experience: React.FC = () => {
  useAppRedirect();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const { application, handleAppDataPatch, isAdvisor } = useApplication();
  let isSophisticated = false;
  if (user?.certificationType == "self-sophisticated") {
    isSophisticated = true;
  }
  const config = useMemo(() => getExperienceConfig(isAdvisor, isSophisticated), [isAdvisor, isSophisticated]);

  const goBack = useCallback(() => navigate(`../${Forms.Finances}`), [navigate]);

  const handleSubmit = useCallback(
    async (values: ExperienceType) => {
      const res = await handleAppDataPatch({ experience: values });
      if (res) navigate(`../${Forms.Investment}`);
    },
    [handleAppDataPatch, navigate],
  );

  return (
    <>
      <Container maxWidth="md">
        <FormGroup>
          <Typography variant="h4" gutterBottom>
            <strong>Section 4 of 5:</strong>
          </Typography>
          <Typography variant="h4" paragraph>
            Knowledge & Experience
          </Typography>
          <Typography variant="h4" paragraph>
            Understanding the risks involved in making early stage, unquoted company investments requires a certain
            level of financial sophisticated on the part of the investor. We must therefore assess that you have the
            necessary experience and knowledge in order to understand the risks involved in the management of your
            portfolio. To enable us to do this, please answer the following questions:
          </Typography>
        </FormGroup>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <FormGen
          config={config}
          initialValues={application?.data.experience}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ errors, submitCount }) => (
            <>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} />
                </FormGroup>
              )}
              <FormGroup>
                <FormButtons primaryProps={{ label: "Next" }} secondaryProps={{ label: "Back", onClick: goBack }} />
              </FormGroup>
            </>
          )}
        </FormGen>
      </Container>
    </>
  );
};

export default Experience;
