import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AdminCompany, LeanCompany } from "server/services/company";

export const patchCompany = async (id: string, changes: Partial<LeanCompany>) => {
  try {
    const res = await ipAxios.patch<AdminCompany>(`/admin/companies/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const updateCompany = async (id: string, changes: LeanCompany) => {
  try {
    const res = await ipAxios.put<AdminCompany>(`/admin/companies/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const deleteCompany = async (id: string) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/companies/${id}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export async function getCompany(this: ThisAxios, id: string) {
  try {
    const res = await ipAxios.get<AdminCompany>(`/admin/companies/${id}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
