import { Form, Formik, FormikProps } from "formik";
import _ from "lodash";
import React, { PropsWithChildren, useMemo } from "react";
import * as Yup from "yup";
import BuildFields from "./components/BuildFields";
import { FormGenContextProvider } from "./context";
import { buildSchema } from "./schema";
import { AnyField, BaseValues, FormGenProps, TypeOfSchema } from "./types";

export const resolveConfig = <T extends BaseValues>(
  config: AnyField<T>[],
  castRequiredSchema?: boolean,
): AnyField<T>[] =>
  config.map((c) => {
    const _isNested = Boolean(c.name) && c.name!.indexOf(".") !== -1;
    return {
      ...c,
      key: c.key || c.name || _.uniqueId("FormGen"),
      _isNested,
      ...(!castRequiredSchema ||
      c.type === "validation" ||
      !Yup.isSchema(c.validation) ||
      c.validation.spec.presence !== "required"
        ? {}
        : { props: { required: true, ...(c.props ?? {}) } }),
      ...(!_isNested ? {} : { _splitName: c.name!.split(".").reverse() }),
      ...(!c.fields ? {} : { fields: resolveConfig(c.fields, castRequiredSchema) }),
    } as AnyField<T>;
  });

const FormGen = <T extends BaseValues>(
  {
    config,
    classes,
    initialValues: initialValuesProp,
    castRequiredSchema,
    ...props
  }: PropsWithChildren<FormGenProps<T>>,
  ref: React.ForwardedRef<FormikProps<TypeOfSchema<T>>>,
) => {
  const resolvedConfig = useMemo(() => resolveConfig(config, castRequiredSchema), [config, castRequiredSchema]);

  const schema = useMemo(() => Yup.lazy((values: T) => buildSchema(resolvedConfig, values)), [resolvedConfig]);

  const initialValues = useMemo(() => {
    const resSchema = schema.resolve({});
    return _.merge({}, resSchema.getDefault(), initialValuesProp) as T | { dob: any };
  }, [initialValuesProp, schema]);

  if (initialValues?.dob) initialValues.dob = new Date(initialValues?.dob);

  const formGenContextValue = useMemo(
    () => ({ config: resolvedConfig, schema, classes }),
    [classes, resolvedConfig, schema],
  );

  return (
    <FormGenContextProvider value={formGenContextValue}>
      <Formik {...(props as any)} initialValues={initialValues} validationSchema={schema} innerRef={ref}>
        {(formikProps) => (
          <Form>
            <BuildFields />
            {typeof props.children === "function" ? props.children(formikProps) : props.children}
          </Form>
        )}
      </Formik>
    </FormGenContextProvider>
  );
};
FormGen.whyDidYouRender = false;

export default React.forwardRef(FormGen);
