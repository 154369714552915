import { Collapse, Container, LinearProgress, Typography } from "@mui/material";
import useAdvisorContext from "client/context/advisor";
import useFundsContext from "client/context/funds";
import React, { useMemo } from "react";
import { PaymentMethod } from "server/services/application/consts";
import { makeStyles } from "tss-react/mui";
import useApplication from "./hooks/useApplication";

const useStyles = makeStyles({ name: "Complete" })((theme) => ({
  titleContainer: {
    margin: "20px auto",
  },
  sectionContainer: {
    backgroundColor: theme.palette.common.white,
    borderTop: "solid 1px",
    borderBottom: "solid 1px",
    margin: "20px auto 60px",
  },
  paymentContainer: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}));

const Complete: React.FC = () => {
  const { classes } = useStyles();
  const { selected: fund } = useFundsContext();
  const { client } = useAdvisorContext();
  const { application, isAdvisor, isAdvised } = useApplication();

  const amlPassed = useMemo(
    () => isAdvised || isAdvisor || Boolean(application?.aml.passed),
    [application?.aml.passed, isAdvised, isAdvisor],
  );

  return (
    <>
      <Container maxWidth="md" className={classes.titleContainer}>
        {typeof amlPassed !== "boolean" && (
          <>
            <Typography variant="body1" align="center" paragraph>
              Fetching Data....
            </Typography>
            <LinearProgress />
          </>
        )}
        <Collapse in={typeof amlPassed === "boolean"}>
          <>
            <Typography variant="h1" align="center" paragraph>
              Application {amlPassed ? "complete" : "submitted"}!
            </Typography>
            {isAdvisor ? (
              <Typography variant="body1" align="center">
                You have completed the {fund?.name} application for {client?.details.forenames}{" "}
                {client?.details.surname}. We will now send the signed document to your client to electronically
                execute.
                <br />
                <br />
                Many thanks.
              </Typography>
            ) : (
              <Typography variant="body1" align="center">
                Thank you for your application to invest in the {fund?.name}! You will receive an email shortly
                containing a PDF document of your signed application. We will review your application and if we have any
                questions or concerns regarding your suitability to invest
                {!amlPassed && " or your AML documents"}, we will come back to you.
              </Typography>
            )}
          </>
        </Collapse>
      </Container>
      {!isAdvisor && (
        <Container maxWidth={false} className={classes.sectionContainer}>
          <Container maxWidth="md" className={classes.paymentContainer}>
            <div>
              {application?.data.investment?.paymentMethod === PaymentMethod.Bank && (
                <>
                  <Typography variant="body1" paragraph>
                    You can now transfer funds using the details below:
                  </Typography>
                  <Typography variant="body1">
                    <strong>Sort code:</strong> {fund?.paymentDetails.bank.sortCode}
                    <br />
                    <strong>Account number:</strong> {fund?.paymentDetails.bank.accountNumber}
                    <br />
                    <strong>Account name:</strong> {fund?.paymentDetails.bank.accountName}
                    <br />
                    <strong>Reference:</strong> {fund?.paymentDetails.bank.reference}
                    <br />
                    {fund?.paymentDetails.bank.iban && (
                      <>
                        <strong>IBAN:</strong> {fund?.paymentDetails.bank.iban}
                        <br />
                      </>
                    )}
                  </Typography>
                </>
              )}
              {application?.data.investment?.paymentMethod === PaymentMethod.Cheque && (
                <>
                  <Typography variant="body1" paragraph>
                    Please send a cheque from your personal account, make payable to &ldquo;
                    {fund?.paymentDetails.cheque.payable}&rdquo;. We do not accept cheques from business accounts.
                    Bankers&apos; drafts and building society cheques must specifically mention the investor&apos;s
                    name.
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <b>Address</b>: {fund?.paymentDetails.cheque.address}
                  </Typography>
                </>
              )}
            </div>
          </Container>
        </Container>
      )}
    </>
  );
};

export default Complete;
