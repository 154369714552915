import { findLast } from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationData } from "server/services/application";
import { Forms } from "../utils";
import useApplication from "./useApplication";
import useFormId from "./useFormId";

const AppDataPaths: ReadonlyArray<{ key: keyof ApplicationData; path: Forms }> = [
  { key: "investorDetails", path: Forms.InvestorDetails },
  { key: "objectives", path: Forms.Objectives },
  { key: "finances", path: Forms.Finances },
  { key: "experience", path: Forms.Experience },
  { key: "investment", path: Forms.Investment },
];

const FormOrder = [
  Forms.Funds,
  Forms.KeyInformation,
  Forms.Begin,
  Forms.IdVerification,
  Forms.Assessment,
  Forms.InvestorDetails,
  Forms.Objectives,
  Forms.Finances,
  Forms.Experience,
  Forms.Investment,
  Forms.Fee,
  Forms.Agreement,
  Forms.Complete,
].reduce((prev, curr, index) => ({ ...prev, [curr]: index }), {} as { [key in Forms]: number });

const useAppRedirect = (skip?: boolean) => {
  const navigate = useNavigate();
  const { application } = useApplication();
  const formId = useFormId();

  useEffect(() => {
    if (skip || !application) return;
    const { path: latestFilledOutPath } =
      findLast(AppDataPaths, ({ key }) => Boolean(application.data[key])) || AppDataPaths[0];
    if (FormOrder[formId] <= FormOrder[latestFilledOutPath] + 1 || latestFilledOutPath === Forms.Investment) return;
    navigate(`../${latestFilledOutPath}`, { replace: true });
  }, [application, formId, navigate, skip]);
};

export default useAppRedirect;
