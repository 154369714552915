import { FormHelperText, FormLabel, Slider, SliderProps } from "@mui/material";
import useHelperText from "client/hooks/useHelperText";
import clsx from "clsx";
import { FieldProps } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TrackInput, { TrackInputProps } from "../TrackInput";

export interface SliderFieldProps
  extends FieldProps<number | number[]>,
    SliderProps,
    Omit<TrackInputProps<number | number[]>, "color"> {
  label: string;
  helperText?: string;
}

const SliderField: React.FC<SliderFieldProps> = ({ field, form: { setFieldValue }, label, onChange, ...props }) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const [value, setValue] = useState(field.value);

  const debouncedHandler = useMemo(
    () =>
      _.debounce<NonNullable<SliderProps["onChange"]>>((e, v, t) => {
        if (onChange) return onChange(e, v, t);
        setFieldValue(field.name, v);
      }),
    [field.name, onChange, setFieldValue],
  );

  const handleChange = useCallback<NonNullable<SliderProps["onChange"]>>(
    (_e, newValue, activeThumb) => {
      setValue(newValue);
      debouncedHandler(_e, newValue, activeThumb);
    },
    [debouncedHandler],
  );

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TrackInput {...props} error={hasError} name={field.name} value={field.value}>
      {label ? <FormLabel>{label}</FormLabel> : <FormLabel sx={{ visibility: "hidden" }}>&nbsp;</FormLabel>}
      <Slider
        min={0}
        max={100}
        {...field}
        {...props}
        value={value}
        className={clsx(props.className, "fs-exclude")}
        onChange={handleChange}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default SliderField;
