import ImageIcon from "@mui/icons-material/ImageSharp";
import { Button, ButtonGroup } from "@mui/material";
import { ColumnDefinition, numberFormatter, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getCompanies } from "client/api/admin/companies";
import { patchCompany } from "client/api/admin/companies/company";
import Image from "client/components/Image";
import RouterLink from "client/components/RouterLink";
import SentryRoutes from "client/components/SentryRoutes";
import useAdminContext, { AdminActions } from "client/context/admin";
import useAuthContext from "client/context/auth";
import React, { useCallback, useMemo, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { AdminCompany } from "server/services/company";
import { ExportType } from "server/services/export/consts";
import AdminTable from "../components/AdminTable";
import ExportLinkForm from "../components/ExportLinkForm";
import MainPanelContainer from "../components/MainPanelContainer";
import createTableDefinition from "../utils/createTableDefinition";
import CompanyForm from "./Form";
import useSectors from "./useSectors.hook";

const CompaniesTable: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { companies, dispatch } = useAdminContext();
  const sectors = useSectors();
  const [count, setCount] = useState(-1);

  const compsArr = useMemo(() => Object.values(companies), [companies]);

  const setCompanies = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getCompanies(onChangeObject);
      if (!res) return [];
      if (!isExport) {
        dispatch({ type: AdminActions.SetCompanies, payload: res.data });
        setCount(res.count);
      }
      return res.data;
    },
    [dispatch],
  );

  const companyTable = useMemo(
    () =>
      createTableDefinition<ColumnDefinition<AdminCompany>>([
        {
          key: "companyLogo",
          title: "Logo",
          render: (company) =>
            company.logos?.primary ? (
              <Image
                src={company.logos.primary}
                alt={`${company.companyName} logo`}
                sx={{ maxWidth: 100, width: "auto" }}
              />
            ) : (
              <ImageIcon fontSize="large" />
            ),
        },
        {
          key: "companyName",
          title: "Company Name",
          dataIndex: "companyName",
          editable: true,
        },
        {
          key: "companyNumber",
          title: "Company Number",
          dataIndex: "number",
        },
        {
          key: "brandName",
          title: "Brand Name",
          dataIndex: "info.brandName",
          editable: true,
        },
        {
          key: "funds",
          title: "Funds",
          dataType: "string",
          dataIndex: "funds",
          render: (company) => company.funds.map((f) => f.name).join("\n"),
        },
        {
          key: "sector",
          title: "Sector",
          dataType: "string",
          dataIndex: "info.sector",
          editable: { path: true, type: "select", selectOptions: sectors },
        },
        {
          key: "preview",
          title: "Preview",
          render: (record) => <RouterLink to={`./${record._id}/preview`}>Preview</RouterLink>,
        },
        {
          key: "companyValuation",
          title: "Valuation",
          numerical: "meta.valuation",
        },
        {
          key: "shareClasses",
          title: "Share Classes",
          colGroup: [
            {
              key: "shareClassName",
              title: "Name",
              sorter: undefined,
              filterColumn: undefined,
              render: (company) => company.shareClasses.map(({ name }) => name).join("\n"),
            },
            {
              key: "shareClassNoShares",
              title: "No. Shares",
              dataType: "number",
              sorter: undefined,
              filterColumn: undefined,
              render: (company) =>
                company.shareClasses
                  .map(({ deployed }) => numberFormatter(deployed.total.shares, { currency: false, decimalPlaces: 0 }))
                  .join("\n"),
            },
            {
              key: "shareClassPrice",
              title: "Price",
              dataType: "number",
              sorter: undefined,
              filterColumn: undefined,
              render: (company) =>
                company.shareClasses
                  .map(({ sharePrice }) =>
                    typeof sharePrice !== "number"
                      ? "not set"
                      : numberFormatter(sharePrice, { minimumFractionDigits: 2, maximumFractionDigits: 10 }),
                  )
                  .join("\n"),
            },
          ],
        },
      ]),
    [sectors],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminCompany>>(
    async ({ path, value }, record) => {
      const res = await patchCompany(record._id, { [path]: value === "null" ? null : value });
      if (res) dispatch({ type: AdminActions.UpdateCompany, payload: res });
    },
    [dispatch],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);

  return (
    <>
      <MainPanelContainer>
        <AdminTable
          tableData={compsArr}
          onChange={setCompanies}
          count={count}
          tableStructure={companyTable}
          rowClick={(record) => navigate(`./${record._id}/edit`)}
          tableProps={{ size: "small" }}
          exportToCSVOption
          csvFilename="Companies"
          defaultSort={{ key: "companyName", direction: "asc" }}
          onEdit={handleTableEdit}
        />
        <ButtonGroup variant="text">
          <Button disabled={!user || user.isReadOnly} onClick={() => navigate("./create")}>
            Add company
          </Button>
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
      </MainPanelContainer>
      <SentryRoutes>
        <Route path="create" element={<CompanyForm open onClose={handleDialogClose} />} />
        <Route path=":companyId/edit" element={<CompanyForm open onClose={handleDialogClose} />} />
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Comps}
              data={compsArr}
              structure={companyTable}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default CompaniesTable;
