import { FormGroup, Link } from "@mui/material";
import { submitRegistration } from "client/api/public";
import ButtonSelector from "client/components/Fields/ButtonSelector";
import CheckboxField from "client/components/Fields/CheckboxField";
import TextField from "client/components/Fields/TextField";
import RiskWarning from "client/components/RiskWarning";
import EVENTS from "client/utils/events.consts";
import FormButton from "components/FormButton";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LeanUser } from "server/services/user";
import { UserType } from "server/services/user/consts";
import Yup from "shared/utils/Yup";
import { makeStyles } from "tss-react/mui";
import type { RequiredStringSchema } from "yup/lib/string";

export interface RegisterFormValues extends Partial<Pick<LeanUser, "forenames" | "surname" | "email">> {
  personalDetails: { contactNumber?: string };
  type: string;
  understandRisk: boolean;
}

const { PAGE_ACTIONS } = EVENTS;

const useStyles = makeStyles({ name: "RegisterForm" })((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    "& > form > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const PasswordSchema = Yup.object({
  password: Yup.string()
    .default("")
    .min(8, "Must be at least ${min} characters long")
    .max(30, "Must be a maximum of ${max} characters long")
    .required("Please choose a password"),
  confirmPassword: Yup.string()
    .default("")
    .oneOf([Yup.ref("password"), null], "Your passwords don't match")
    .required("Your passwords don't match"),
});

const Register: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const openRiskWarning = useRef<() => void>(() => null);

  const onRegistration = useCallback(
    (dataURL: string) => {
      window.dataLayer.push({ event: PAGE_ACTIONS.REGISTER_FORM_SUBMITTED_SUCCESSFULLY });
      navigate("/authenticate", { state: { twoFAEnabled: false, dataURL } })
    },
    [navigate],
  );

  const handleFormSubmit = useCallback(
    async ({
      confirmPassword: _confirmPassword,
      understandRisk: _understandRisk,
      ...vals
    }: Yup.Asserts<typeof schema>) => {
      if (!vals.personalDetails.contactNumber) vals.personalDetails.contactNumber = null;
      const res = await submitRegistration(vals);
      if (res) onRegistration(res.dataURL);
    },
    [onRegistration],
  );

  const userTypeOptions = useMemo(
    () => [
      { label: <>Direct&nbsp;Investor</>, value: UserType.Direct },
      { label: <>Financial&nbsp;Adviser</>, value: UserType.Advisor },
    ],
    [],
  );

  const schema = useMemo(
    () =>
      PasswordSchema.clone().concat(
        Yup.object({
          forenames: Yup.string().default("").max(50).required("Your name is required"),
          surname: Yup.string().default("").max(50).required("Your surname is required"),
          email: Yup.string()
            .default("")
            .max(100)
            .email("Please enter a valid email")
            .required("Please enter a valid email"),
          personalDetails: Yup.object({ contactNumber: Yup.string().default("").nullable() }).default({}),
          type: Yup.string()
            .default("")
            .oneOf(
              userTypeOptions.map((o) => o.value),
              "Please select an option",
            )
            .required("Please select an option") as RequiredStringSchema<UserType>,
          understandRisk: Yup.boolean()
            .default(false)
            .oneOf([true], "Please agree that you have read the risk warning")
            .required("Please agree that you have read the risk warning"),
        }),
      ),
    [userTypeOptions],
  );

  const initialValues = useMemo(() => schema.getDefault(), [schema]);

  return (
    <div className={classes.formContainer}>
      <Formik validationSchema={schema} onSubmit={handleFormSubmit} initialValues={initialValues}>
        {() => (
          <Form id="registration-form">
            <FormGroup row>
              <Field component={TextField} name="forenames" placeholder="Forename(s)*" size="small" required />
              <Field component={TextField} name="surname" placeholder="Surname*" size="small" required />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextField}
                name="email"
                placeholder="Email*"
                autoComplete="email"
                size="small"
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextField}
                name="personalDetails.contactNumber"
                placeholder="Contact Number"
                size="small"
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextField}
                name="password"
                placeholder="Password*"
                type="password"
                autoComplete="password"
                size="small"
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={TextField}
                name="confirmPassword"
                placeholder="Confirm your password*"
                type="password"
                autoComplete="confirm-password"
                size="small"
                required
              />
            </FormGroup>
            <FormGroup>
              <Field
                name="type"
                component={ButtonSelector}
                label="I am a..."
                options={userTypeOptions}
                size="small"
                row
              />
            </FormGroup>
            <FormGroup>
              <Field
                component={CheckboxField}
                name="understandRisk"
                data-testid="understandRisk"
                label={
                  <>
                    I have read and understood the&nbsp;
                    <RiskWarning ref={openRiskWarning}>
                      <Link onClick={openRiskWarning.current}>risk warning</Link>
                    </RiskWarning>
                  </>
                }
              />
            </FormGroup>
            <FormGroup>
              <FormButton size="small" type="submit">
                Register
              </FormButton>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
