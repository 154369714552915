import { Container, FormGroup, Theme, Typography, useMediaQuery } from "@mui/material";
import useSCEffect from "@wearenova/use-sce";
import Divider from "client/components/Divider";
import useAuthContext from "client/context/auth";
import useFundsContext, { FundsActions } from "client/context/funds";
import useScrollToTop from "client/hooks/useScrollToTop";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useFormId from "../hooks/useFormId";
import { Forms } from "../utils";
import LogoBanner from "./LogoBanner";
import ProgressBar from "./ProgressBar";

const StageMap: { [key in Forms]?: number } = {
  [Forms.IdVerification]: 1,
  [Forms.Assessment]: 1,
  [Forms.InvestorDetails]: 1,
  [Forms.Objectives]: 2,
  [Forms.Finances]: 3,
  [Forms.Experience]: 4,
  [Forms.Investment]: 5,
};

const DisableAppFormLayout: { [key in Forms]?: boolean } = {
  [Forms.Begin]: true,
  [Forms.KeyInformation]: true,
  [Forms.Agreement]: true,
  [Forms.Complete]: true,
  [Forms.Fee]: true,
};

const AppLayout: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { fundId } = useParams<{ fundId?: string }>();
  const formId = useFormId();
  const { funds, dispatch } = useFundsContext();
  const { user } = useAuthContext();
  let isAssesment = false;
  if (location.pathname.includes("/assessment") || location.pathname.includes("/id-verification")) {
    isAssesment = true;
  }
  useScrollToTop(formId);

  useSCEffect(() => {
    if (!fundId) return;
    const fund = funds.find((f) => f.urlId === fundId);
    if (!fund) return;
    dispatch({ type: FundsActions.SetFund, payload: fund });
  }, [fundId, funds]);

  useEffect(() => {
    if (user?.isAdvised && !location.pathname.includes("/applications")) {
      navigate("/apply/funds", { replace: true });
    }
  }, [location.pathname, navigate, user?.isAdvised]);

  useEffect(() => {
    if (!user?.isReferred && !user?.isAdvised && !user?.isCertified && !user?.isAdvisor) {
      navigate("/certify", { replace: true });
    }
  }, [navigate, user?.isAdvised, user?.isAdvisor, user?.isCertified, user?.isReferred]);

  if (DisableAppFormLayout[formId]) return <Outlet />;
  return (
    <>
      <LogoBanner />
      {!isMobile && (
        <Container sx={{ pb: 0 }}>
          <ProgressBar activeStage={StageMap[formId]} />
        </Container>
      )}
      {!isAssesment && (
        <Container>
          <FormGroup>
            <Typography variant="h2" align="center">
              Appropriateness questionnaire
            </Typography>
          </FormGroup>
          <FormGroup>
            <Typography align="center">
              Please note that we cannot accept an application if this information is not complete.
            </Typography>
          </FormGroup>
        </Container>
      )}
      {!isAssesment && <Divider />}
      <Outlet />
    </>
  );
};

export default AppLayout;
