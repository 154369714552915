import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { Box, styled, SxProps } from "@mui/material";
import useToggle from "client/hooks/useToggle";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { RequireAtLeastOne } from "type-fest";

interface HTMLImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

type SourceOpts = RequireAtLeastOne<{ src?: string; defaultImage?: string }, "src" | "defaultImage">;

export type ImageProps = Omit<HTMLImageProps, keyof SourceOpts> &
  SourceOpts & {
    alt: string;
    sx?: SxProps;
  };

const useStyles = makeStyles({ name: "Image" })((theme) => ({
  root: {
    opacity: 0,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  errorIcon: {
    width: "unset",
    height: "unset",
  },
  loaded: {
    opacity: 1,
  },
}));

const StyledImage = styled("img", { name: "Image" })({});

const Image: React.FC<ImageProps> = ({ src, defaultImage, onError, ...props }) => {
  const { classes } = useStyles();
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, toggleLoaded] = useToggle(false);
  const [errored, toggleErrored] = useToggle(false);
  const [source, setSource] = useState((src || defaultImage) as string);

  const handleError = useCallback<NonNullable<ImageProps["onError"]>>(
    (...args) => {
      onError?.(...args);
      if (defaultImage) setSource(defaultImage);
      if (!defaultImage || defaultImage === source) toggleErrored.on();
    },
    [defaultImage, onError, source, toggleErrored],
  );

  useEffect(() => {
    if (imageRef.current?.complete) toggleLoaded.on();
  }, [toggleLoaded]);

  useEffect(() => {
    setSource((src || defaultImage) as string);
    return () => {
      toggleLoaded.off();
      toggleErrored.off();
    };
  }, [defaultImage, src, toggleErrored, toggleLoaded]);

  return (
    <Box className={clsx(classes.root, { [classes.loaded]: loaded || errored })}>
      {errored && (
        <BrokenImageIcon color="disabled" className={clsx(classes.errorIcon, props.className)} sx={props.sx} />
      )}
      {!errored && (
        <StyledImage
          loading="lazy"
          {...props}
          ref={imageRef}
          src={source}
          onLoad={toggleLoaded.on}
          onError={handleError}
        />
      )}
    </Box>
  );
};

export default Image;
