import AddressField from "client/components/Fields/AddressField";
import CheckboxField from "client/components/Fields/CheckboxField";
import DateField from "client/components/Fields/DateField";
import IdVerificationField from "client/components/Fields/IdVerificationField";
import InstalmentsField from "client/components/Fields/InstalmentsField";
import NumberField from "client/components/Fields/NumberField";
import RadioField from "client/components/Fields/RadioField";
import SEISSplitField from "client/components/Fields/SEISSplitField";
import SelectField from "client/components/Fields/SelectField";
import TextField from "client/components/Fields/TextField";
import UploadField from "client/components/Fields/UploadField";
import { Field } from "formik";
import React from "react";
import { AnyComplexField, AnyField, BaseValues } from "./types";

export const DefaultValueMap: {
  [Key in Exclude<AnyComplexField<BaseValues>["type"], "array">]: any;
} = {
  text: "",
  number: "",
  date: null,
  select: null,
  checkbox: false,
  radio: null,
  file: null,
  instalments: [],
  seisSplit: { seis: "", eis: "" },
  address: {
    line1: "",
    line2: "",
    city: "",
    country: "",
    postcode: "",
    lengthYears: "",
    lengthMonths: "",
  },
  idVerification: {},
};

export const FieldMap: {
  [Key in Exclude<AnyComplexField<BaseValues>["type"], "array">]: React.FC<{ type: Key } & AnyField<any>>;
} = {
  text: ({ props, ...config }) => <Field {...props} {...config} component={TextField} />,
  number: ({ props, ...config }) => <Field {...props} {...config} component={NumberField} />,
  date: ({ props, ...config }) => <Field {...props} {...config} component={DateField} />,
  select: ({ props, ...config }) => <Field {...props} {...config} component={SelectField} />,
  checkbox: ({ props, ...config }) => <Field {...props} {...config} component={CheckboxField} />,
  radio: ({ props, ...config }) => <Field {...props} {...config} component={RadioField} />,
  file: ({ props, ...config }) => <Field {...props} {...config} component={UploadField} />,
  address: ({ props, ...config }) => <Field {...props} {...config} component={AddressField} />,
  instalments: ({ props, ...config }) => <Field {...props} {...config} component={InstalmentsField} />,
  seisSplit: ({ props, ...config }) => <Field {...props} {...config} component={SEISSplitField} />,
  idVerification: ({ props, ...config }) => <Field {...props} {...config} component={IdVerificationField} />,
};
