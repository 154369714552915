import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, IconButton, Theme, useMediaQuery } from "@mui/material";
import { useConfigContext } from "client/context/config";
import EVENTS from "client/utils/events.consts";
import useDescription from "hooks/useDescription";
import useTitle from "hooks/useTitle";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import FAQs from "./components/FAQs";
import Forms from "./Forms";
import Info from "./Info";

const { PAGE_VIEWS } = EVENTS;

const useStyles = makeStyles({ name: "LoginRegister" })((theme) => ({
  mainContent: {
    display: "flex",
    "& > div:first-of-type": {
      flex: 2,
    },
    "& > div:not(:first-of-type)": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      overflow: "visible",
      height: "120vh",
      position: "sticky",
      top: 0,
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
      paddingTop: "110px",
    },
  },
  mobileButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "none",
    position: "fixed",
    bottom: 10,
    left: 10,
    right: 10,
    border: `solid 2px ${theme.palette.background.default}`,
    [theme.breakpoints.down("lg")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      left: "25%",
      right: "25%",
    },
  },
  dialogBar: {
    margin: "20px",
  },
}));

const Home: React.FC = () => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();
  const location = useLocation();
  const isNotLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const [dialogOpen, setDialogOpen] = useState(false);

  useTitle(portal.meta.titles.home || portal.meta.titles.default);
  useDescription(portal.meta.descriptions.home || portal.meta.descriptions.default);

  useEffect(() => {
    window.dataLayer.push({ event: location.pathname === "/register" ? PAGE_VIEWS.REGISTER : PAGE_VIEWS.LOGIN });
  }, [location.pathname]);

  return (
    <>
      {/* left/right panes */}
      <div className={classes.mainContent}>
        <div>
          <Info />
        </div>
        {!isNotLarge && (
          <div>
            <Forms />
          </div>
        )}
      </div>

      {/* accordion */}
      {!portal.home?.faqs?.length || <FAQs items={portal.home.faqs} />}

      {/* floating register button (mobile) */}
      {isNotLarge && (
        <div className={classes.mobileButtonContainer}>
          <Button color="secondary" size="large" fullWidth onClick={() => setDialogOpen(true)}>
            {location.pathname === "/register" ? "Register" : "Login"}
          </Button>
        </div>
      )}

      {/* mobile reg/login dialog form */}
      {isNotLarge && (
        <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <div className={classes.dialogBar}>
            <IconButton edge="start" color="inherit" onClick={() => setDialogOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <br />
            <br />
            <Forms />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default Home;
