import { ColGroupDefinition, ColumnDefinition } from "@wearenova/mui-data-table";

const assignDefaults = <T extends ColumnDefinition<any, any> | ColGroupDefinition<any, any>>(
  columns: T[],
  defaults: Partial<ColumnDefinition<any, any>>,
): T[] =>
  columns.map<T>((column) => ({
    ...(!column.dataIndex ? {} : defaults),
    ...column,
    ...(!column.colGroup ? {} : { colGroup: assignDefaults(column.colGroup, defaults) }),
  }));

const createTableDefinition = <T extends ColumnDefinition<any, any>>(
  columns: T[],
  {
    defaults = { filterColumn: true, pinnable: true, sorter: true },
    disableIdColumn = false,
  }: { defaults?: Partial<ColumnDefinition<any, any>>; disableIdColumn?: boolean } = {},
): T[] => [
  ...(disableIdColumn
    ? []
    : [
        {
          key: "_id",
          title: "ID",
          dataIndex: "_id",
          dataType: "string",
          filterColumn: true,
          hidden: true,
        } as T,
      ]),
  ...assignDefaults(columns, defaults),
];

export default createTableDefinition;
