import CircleIcon from "@mui/icons-material/Circle";
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useConfigContext } from "client/context/config";
import clsx from "clsx";
import { intToExcelCol } from "excel-column-name";
import _ from "lodash";
import React, { Fragment, useCallback, useMemo } from "react";
import ReactMarkdown, { ReactMarkdownOptions } from "react-markdown";
import { ReactMarkdownProps, TableCellComponent } from "react-markdown/src/ast-to-react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { makeStyles } from "tss-react/mui";
import Image, { ImageProps } from "./Image";

interface Props extends ReactMarkdownOptions {
  enableHTML?: boolean | null;
}

const REPLACER_REGEX = /\$\{([\w.]+)\}|<%=\s*fund\.([\w.]+)\s*%>/g;
const REACT_MARKDOWN_PLUGINS = [remarkGfm];

const useStyles = makeStyles({ name: "Markdown" })((theme) => ({
  image: {
    maxWidth: "100%",
    maxHeight: 450,
  },
  listItemIcon: {
    alignSelf: "flex-start",
  },
  noMargin: {
    margin: 0,
    "& *": {
      margin: 0,
    },
  },
  orderedListItemIcon: {
    lineHeight: 1,
  },
  preLineWrap: {
    whiteSpace: "pre-line",
  },
  table: {
    width: "100%",
    margin: theme.spacing(2, 0),
  },
  tableCell: {
    "&:not(:last-child)": {
      paddingRight: theme.spacing(4),
    },
  },
}));

const getRelevantProps = <T extends ReactMarkdownProps & { [key: string | number | symbol]: any }>({
  node: _node,
  siblingCount: _siblingCount,
  sourcePosition: _sourcePosition,
  index: _index,
  ref: _ref,
  ordered: _ordered,
  ...props
}: T) => props;

const Markdown: React.FC<Props> = ({ enableHTML, ...props }) => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();

  const text = useMemo(() => {
    return props.children.replace(REPLACER_REGEX, (_fullMatch, match) => _.get(portal, match, ""));
  }, [portal, props.children]);

  const tableCell = useCallback<TableCellComponent>(
    ({ isHeader, ...ps }) => {
      const relevantProps = getRelevantProps(ps);
      const Component = (tableCellProps: typeof relevantProps) =>
        isHeader ? (
          <th {...(tableCellProps as JSX.IntrinsicElements["th"])} />
        ) : (
          <td {...(tableCellProps as JSX.IntrinsicElements["td"])} />
        );
      return (
        <Component {...relevantProps} className={clsx(classes.preLineWrap, classes.tableCell)}>
          {ps.children?.map((n, idx) => (
            <Fragment key={idx}>
              {typeof n === "string"
                ? n.split(/<br[\s]?\/?>/).map(
                    (str, nestedIndex) =>
                      str && (
                        <Fragment key={nestedIndex}>
                          <br />
                          {str}
                        </Fragment>
                      ),
                  )
                : n}
            </Fragment>
          ))}
        </Component>
      );
    },
    [classes],
  );

  const components = useMemo<ReactMarkdownOptions["components"]>(
    () => ({
      h1: (ps) => <Typography {...getRelevantProps(ps)} variant="h1" component="h1" color="inherit" paragraph />,
      h2: (ps) => <Typography {...getRelevantProps(ps)} variant="h2" component="h2" color="inherit" paragraph />,
      h3: (ps) => <Typography {...getRelevantProps(ps)} variant="h3" component="h3" color="inherit" paragraph />,
      h4: (ps) => <Typography {...getRelevantProps(ps)} variant="h4" component="h4" color="inherit" paragraph />,
      h5: (ps) => <Typography {...getRelevantProps(ps)} variant="h5" component="h5" color="inherit" paragraph />,
      h6: (ps) => <Typography {...getRelevantProps(ps)} variant="h6" component="h6" color="inherit" paragraph />,
      a: ({ color: _color, ...ps }) => <Link {...getRelevantProps(ps)} component="a" rel="noopener noreferrer" />,
      p: (ps) => (
        <Typography
          {...getRelevantProps(ps)}
          variant="body2"
          color="inherit"
          paragraph
          className={classes.preLineWrap}
        />
      ),
      ol: (ps) => <List {...getRelevantProps(ps)} component="ol" />,
      ul: (ps) => <List {...getRelevantProps(ps)} component="ul" />,
      li: ({ index, ordered, ...ps }) => (
        <ListItem {...getRelevantProps(ps)}>
          <ListItemIcon className={classes.listItemIcon}>
            {ordered ? (
              <Typography variant="body1" color="inherit" className={classes.orderedListItemIcon}>
                <strong>{intToExcelCol(index + 1).toLowerCase()}</strong>
              </Typography>
            ) : (
              <CircleIcon color="primary" />
            )}
          </ListItemIcon>
          <ListItemText disableTypography className={classes.noMargin}>
            {ps.children}
          </ListItemText>
        </ListItem>
      ),
      img: (ps) => <Image {...(getRelevantProps(ps) as ImageProps)} alt={ps.alt ?? ""} className={classes.image} />,
      table: (ps) => <table {...getRelevantProps(ps)} className={classes.table} />,
      th: tableCell,
      td: tableCell,
    }),
    [classes, tableCell],
  );

  return (
    <ReactMarkdown
      includeElementIndex
      linkTarget="_blank"
      remarkPlugins={REACT_MARKDOWN_PLUGINS}
      components={components}
      rehypePlugins={enableHTML ? [rehypeRaw] : []}
    >
      {text}
    </ReactMarkdown>
  );
};

export default Markdown;
