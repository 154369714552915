import { Container, Typography } from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import { acceptAdvisorFeeData } from "client/api/applications/application";
import FormButtons from "client/components/FormButtons";
import useAppContext, { AppActions } from "client/context/app";
import useAuthContext from "client/context/auth";
import useFundsContext from "client/context/funds";
import FormGen, { FormGenConfig } from "form-gen";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { InvestorAppUser } from "server/services/application";
import { FeeRecipient } from "server/services/fund/consts";
import { FeeType } from "server/services/user/consts";
import Yup from "shared/utils/Yup";
import useApplication from "./hooks/useApplication";
import { Forms } from "./utils";

const Fee: FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { application, isAdvised } = useApplication();
  const { selected: fund } = useFundsContext();
  const { user } = useAuthContext();

  const config = useMemo<FormGenConfig<Pick<InvestorAppUser, "acceptsAdvisorFee" | "acceptsFeeRecipient">>>(() => {
    const totalAmount = application?.data.investment?.totalAmount;
    const feeValue = application?.advisor?.fee;
    const feeAvailable = typeof feeValue === "number";
    const feePercentage =
      !feeAvailable || !totalAmount ? "TBD" : numberFormatter(feeValue / totalAmount, { style: "percent" });
    const feeAmount = !feeAvailable ? "TBD" : numberFormatter(feeValue);
    const [primaryFee, secondaryFee] =
      user?.advisor.fee.type === FeeType.Percentage ? [feePercentage, feeAmount] : [feeAmount, feePercentage];
    return [
      {
        type: "checkbox",
        name: "acceptsAdvisorFee",
        label: `Please tick this to confirm that you agree with the ${primaryFee} (${secondaryFee}) facilitation charge as set by your adviser.`,
        validation: Yup.boolean().oneOf([true], "Please accept the charge").required("Please accept the charge"),
      },
      {
        type: "checkbox",
        name: "acceptsFeeRecipient",
        label:
          fund?.applications.ifaFees.recipient === FeeRecipient.Fund
            ? `Please tick this box to confirm that you want ${fund?.companyDetails.name} to pay this fee on your behalf directly to your adviser.`
            : "Please tick this box to confirm that you are OK with paying this fee to your adviser.",
        validation: Yup.boolean()
          .oneOf([true], "Please accept the payment statement")
          .required("Please accept the payment statement"),
      },
    ];
  }, [
    application?.advisor?.fee,
    application?.data.investment?.totalAmount,
    fund?.applications.ifaFees.recipient,
    fund?.companyDetails.name,
    user?.advisor.fee.type,
  ]);

  const goBack = useCallback(
    () => navigate(isAdvised ? `../${Forms.KeyInformation}` : `../${Forms.Investment}`),
    [isAdvised, navigate],
  );

  useEffect(() => {
    if (application && typeof application.advisor?.fee !== "number") goBack();
  }, [application, goBack]);

  const handleSubmit = useCallback(async () => {
    const res = await acceptAdvisorFeeData(application!._id);
    if (!res) return;
    dispatch({ type: AppActions.SetUserApp, payload: res });
    navigate(`../${Forms.Agreement}`);
  }, [application, dispatch, navigate]);

  if (!isAdvised && !user?.isReferred) return <Navigate to="/apply/funds" replace />;
  return (
    <Container maxWidth="md">
      <Typography variant="h1">Financial Adviser / Intermediary Facilitation Charge</Typography>
      <FormGen config={config} onSubmit={handleSubmit}>
        {() => <FormButtons secondaryProps={{ label: "Back", onClick: goBack }} />}
      </FormGen>
    </Container>
  );
};

export default Fee;
