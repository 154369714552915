import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Slide,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ReactElement } from "react";

type ActionButton = {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  tooltip?: string;
  // all other props, like data attributes etc
  attributes?: { [key: string]: string };
};
export interface ResponsiveDialogProps extends DialogProps {
  title?: string;
  actionButtons?: ActionButton[];
  closeButton?: ActionButton;
}

const MobileTransition = React.forwardRef<unknown, TransitionProps & { children: ReactElement }>(
  function _MobileTransition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({ title, actionButtons, closeButton, ...props }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Dialog {...props} fullScreen={isMobile} TransitionComponent={!isMobile ? undefined : MobileTransition}>
      {title && (
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={closeButton?.onClick} color="secondary" edge="start">
              <Close />
            </IconButton>
            <Typography component="span" variant="h4">
              {title}
            </Typography>
          </Box>
        </DialogTitle>
      )}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {actionButtons?.map((actionButton, i) => (
          <Tooltip key={i} title={actionButton.tooltip || ""} placement="top">
            <span style={{ width: "100%" }}>
              <Button
                onClick={actionButton.onClick}
                color="secondary"
                variant="text"
                size="large"
                fullWidth
                disabled={actionButton.disabled}
                {...actionButton.attributes}
              >
                {actionButton.label}
              </Button>
            </span>
          </Tooltip>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ResponsiveDialog;
