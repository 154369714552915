import { FormGroup } from "@mui/material";
import Divider from "client/components/Divider";
import { FieldArray, useFormikContext } from "formik";
import React, { useMemo } from "react";
import { FieldMap } from "../config";
import useFormGenContext from "../context";
import { AnyField, BaseValues } from "../types";
import ArrayField from "./ArrayField";
import SectionField from "./SectionField";

const FormGenField = <T extends BaseValues>({
  config,
  disableFormGroups,
}: {
  config: AnyField<T>;
  disableFormGroups?: boolean;
}) => {
  const { classes } = useFormGenContext<T>();
  const formik = useFormikContext<T>();

  const fieldProps = useMemo(() => {
    const { type, initialValue, condition, validation, stringLabel, onChange, _isNested, _splitName, ...props } =
      config;
    return props;
  }, [config]);

  if (config.type === "validation" || (config.condition && !config.condition(formik.values, config.name))) {
    return null;
  }

  if (config.type === "section") {
    return <SectionField config={config} />;
  }

  if (config.type === "array") {
    return (
      <FormGroup className={classes?.formGroup}>
        <FieldArray name={config.name}>{(helpers) => <ArrayField field={config} helpers={helpers} />}</FieldArray>
      </FormGroup>
    );
  }

  if (config.type === "divider") {
    return (
      <FormGroup className={classes?.formGroup}>
        <Divider {...config.props} />
      </FormGroup>
    );
  }

  const FieldComponent = FieldMap[config.type];
  const onChangeProp = typeof config.onChange !== "function" ? {} : { onChange: config.onChange.bind(formik) };
  if (disableFormGroups) {
    // @ts-expect-error - union type is too complex
    return <FieldComponent {...fieldProps} {...onChangeProp} />;
  }
  return (
    <FormGroup className={classes?.formGroup}>
      <FieldComponent {...fieldProps} {...onChangeProp} />
    </FormGroup>
  );
};
FormGenField.whyDidYouRender = false;

export default FormGenField;
