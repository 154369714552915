import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  Container,
  FormGroup,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import Divider from "client/components/Divider";
import useApplication from "./hooks/useApplication";
import { useNavigate } from "react-router-dom";
import { Forms } from "./utils";
import { getAssessmentById, setUserAssessmentAnswer, submitUserAssessment } from "client/api/applications/application";
import { getAdvisedAssessment } from "client/api/advisor/clients/client/applications/application";
import { LeanAssessment } from "server/services/assessment";
import { AssessmentStatus } from "server/services/assessment/consts";
import CoolDown from "./components/CoolDown";

const Assessment: React.FC = () => {
  const navigate = useNavigate();
  const { application, assessmentId: assessId, isAdvisor, clientId } = useApplication();
  const [assessment, setAssessment] = useState<LeanAssessment>();
  const topReference = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (!assessId && !isAdvisor) return;
    (async () => {
      if (isAdvisor && clientId != null) {
        const res = await getAdvisedAssessment(clientId);
        if (res) setAssessment(res);
      } else {
        const res = await getAssessmentById(assessId as string);
        if (res) setAssessment(res);
      }

      topReference.current?.focus();
    })();
  }, [assessId, clientId, isAdvisor]);

  const goDetails = useCallback(() => navigate(`../${Forms.InvestorDetails}`), [navigate]);

  const getQuestionsSubject = useMemo(() => {
    const investorName =
      application?.data.investorDetails?.forenames && application?.data.investorDetails?.surname
        ? `${application?.data.investorDetails?.forenames} ${application?.data.investorDetails?.surname}`
        : `${application?.data.investorDetails?.forenames}`;
    const questions = assessment?.currentAttempt.questions;
    const noOfAttempt = assessment?.totalAttempts ? assessment?.totalAttempts + 1 : 0;
    let message = `${investorName}, please answer the following questions to assess your investment knowledge:`;
    if (noOfAttempt == 2)
      message = `It looks like you have failed your first assessment. Please answer the following newly generated  assessment carefully. If you fail again, you will be required to wait for 24h.`;

    return (
      <>
        {questions && (
          <>
            <Container maxWidth="md">
              <FormGroup>
                <Typography>{message}</Typography>
              </FormGroup>
            </Container>
            <Divider />
          </>
        )}
      </>
    );
  }, [
    application?.data.investorDetails?.forenames,
    application?.data.investorDetails?.surname,
    assessment?.currentAttempt.questions,
    assessment?.totalAttempts,
  ]);

  const handleQuestionSelection = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
      const res = await setUserAssessmentAnswer(assessment?.id, questionIndex, event.target.value);
      if (res) {
        if (res.currentAttempt.questions.every((q) => q.answer.selectedOption != null)) {
          const submitResponse = await submitUserAssessment(assessment?.id);
          if (submitResponse) {
            setAssessment(submitResponse);
            topReference.current?.focus();
          }
        } else setAssessment(res);
      }
    },
    [assessment?.id],
  );

  const getQuestions = useMemo(() => {
    const questions = assessment?.currentAttempt.questions;
    return (
      questions && (
        <form>
          {questions?.map((question, index) => {
            const isAnswered = question.answer?.selectedOption != null ? true : false;
            const optionsKeys = Object.keys(question.options);
            return (
              <>
                <FormControl>
                  <FormLabel id={index.toString()} disabled={isAnswered} focused={false} color="primary">
                    {index + 1}. {question.question}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby={index.toString()}
                    name={question.question}
                    value={isAnswered ? question.answer?.selectedOption : null}
                    onChange={(event) => handleQuestionSelection(event, index)}
                    color="default"
                  >
                    <FormControlLabel
                      value={optionsKeys[0]}
                      control={<Radio />}
                      label={question.options?.a}
                      disabled={isAnswered}
                    />
                    <FormControlLabel
                      value={optionsKeys[1]}
                      control={<Radio />}
                      label={question.options?.b}
                      disabled={isAnswered}
                    />
                    <FormControlLabel
                      value={optionsKeys[2]}
                      control={<Radio />}
                      label={question.options?.c}
                      disabled={isAnswered}
                    />
                    <FormControlLabel
                      value={optionsKeys[3]}
                      control={<Radio />}
                      label={question.options?.d}
                      disabled={isAnswered}
                    />
                  </RadioGroup>
                  {question.answer.selectedOption && (
                    <p>
                      {question.answer.selectedOption == question.answer.correctOption ? (
                        <span style={{ color: "green" }}>You are correct!</span>
                      ) : (
                        <span style={{ color: "red" }}>Incorrect</span>
                      )}
                    </p>
                  )}
                </FormControl>
                <br />
                <br />
              </>
            );
          })}
        </form>
      )
    );
  }, [assessment?.currentAttempt.questions, handleQuestionSelection]);

  const config = useMemo(() => {
    if (
      assessment?.status == AssessmentStatus.COOLDOWN_SUCCESS ||
      assessment?.status == AssessmentStatus.COOLDOWN_FAILED ||
      assessment?.status == AssessmentStatus.COMPLETED_SUCCESSFULLY
    )
      return (
        <CoolDown
          assessmentId={assessment.id}
          investorDetail={application?.data.investorDetails}
          assessmentStatus={assessment.status}
          coolDownTime={assessment.cooldown}
        />
      );
    else if (assessment?.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) return goDetails();
    else if (assessment?.status == AssessmentStatus.PENDING)
      return (
        <>
          <div ref={topReference} tabIndex={-1} />
          {getQuestionsSubject}
          <Container maxWidth="md">{getQuestions}</Container>
        </>
      );
    else return null;
  }, [
    application?.data.investorDetails,
    assessment?.cooldown,
    assessment?.id,
    assessment?.status,
    getQuestions,
    getQuestionsSubject,
    goDetails,
  ]);

  return <>{config}</>;
};

export default Assessment;
