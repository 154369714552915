import {
  InputAdornment,
  InputBaseComponentProps,
  StandardTextFieldProps,
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  TypographyProps,
} from "@mui/material";
import useDynamicFieldText from "client/hooks/useDynamicFieldText";
import useHelperText from "client/hooks/useHelperText";
import useToggle from "client/hooks/useToggle";
import clsx from "clsx";
import { FieldProps } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import EyeIcon from "../Icons/EyeIcon";
import TrackInput, { TrackInputProps } from "../TrackInput";

export interface TextFieldProps extends FieldProps<string>, StandardTextFieldProps, Omit<TrackInputProps, "variant"> {
  accept?: string | string[];
  errorMessageProps?: TypographyProps;
}

const TextField: React.FC<TextFieldProps> = ({
  field,
  form: _form,
  label: labelProp,
  placeholder: placeholderProp,
  className,
  accept,
  category,
  inputProps: inputPropsProp = {},
  onChange,
  ...props
}) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const label = useDynamicFieldText(labelProp);
  const placeholder = useDynamicFieldText(placeholderProp);
  const [value, setValue] = useState(props.value ?? field.value);
  const isPasswordField = useMemo(() => props.type === "password", [props.type]);
  const [hidden, toggleHidden] = useToggle(isPasswordField);

  const debouncedHandler = useMemo(
    () =>
      _.debounce<NonNullable<TextFieldProps["onChange"]>>((e) => {
        if (onChange) return onChange(e);
        field.onChange(e);
      }),
    [field, onChange],
  );

  const handleChange = useCallback<NonNullable<TextFieldProps["onChange"]>>(
    (e) => {
      setValue(e.target.value);
      debouncedHandler(e);
    },
    [debouncedHandler],
  );

  useEffect(() => setValue(props.value ?? field.value), [field.value, props.value]);

  const passwordFieldProps = useMemo<MUITextFieldProps>(() => {
    if (!isPasswordField) return {};
    return {
      type: hidden ? "password" : "text",
      InputProps: {
        ...props.InputProps,
        endAdornment: (
          <>
            {props.InputProps?.endAdornment}
            <InputAdornment position="end">
              <EyeIcon onClick={toggleHidden} />
            </InputAdornment>
          </>
        ),
      },
    };
  }, [hidden, isPasswordField, props.InputProps, toggleHidden]);

  const inputProps = useMemo<InputBaseComponentProps>(() => {
    const acceptArray = Array.isArray(accept) ? accept : [accept];
    return { ...inputPropsProp, accept: props.type === "file" ? acceptArray.join(",") : undefined };
  }, [accept, inputPropsProp, props.type]);

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      <MUITextField
        error={hasError}
        label={label}
        placeholder={placeholder}
        className={clsx(className, "fs-exclude")}
        {...field}
        {...props}
        {...passwordFieldProps}
        value={value}
        onChange={handleChange}
        helperText={helperText}
        inputProps={inputProps}
      />
    </TrackInput>
  );
};

export default TextField;
