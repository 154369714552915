import React from "react";
import { makeStyles } from "tss-react/mui";

interface Props {
  onClick?: React.ChangeEventHandler<HTMLInputElement>;
}

const useStyles = makeStyles<unknown, string>({ name: "EyeIcon" })((theme, _params, classes) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.25rem",
  },
  eye: {
    "& input": {
      display: "none",
      [`&:checked + .${classes.eyeOuter}`]: {
        borderColor: theme.palette.error.main,
        [`& .${classes.eyeInner}`]: {
          borderColor: theme.palette.error.main,
          background: theme.palette.error.main,
          width: "0.5em",
          height: "0.5em",
        },
      },
    },
  },
  eyeOuter: {
    border: `0.1em solid ${theme.palette.alternate1.main}`,
    width: "1em",
    height: "1em",
    borderRadius: "50% 10% 50% 10%",
    transform: "rotate(45deg)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("border-color", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  eyeInner: {
    position: "relative",
    width: "0.3em",
    height: "0.3em",
    border: `0.05em solid ${theme.palette.alternate1.main}`,
    borderRadius: "50%",
    transition: theme.transitions.create(["width", "height", "background-color"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
}));

const EyeIcon: React.FC<Props> = ({ onClick }) => {
  const { classes } = useStyles({});
  return (
    <div className={classes.container}>
      <label className={classes.eye}>
        <input type="checkbox" onChange={onClick} />
        <div className={classes.eyeOuter}>
          <div className={classes.eyeInner}></div>
        </div>
      </label>
    </div>
  );
};

export default EyeIcon;
