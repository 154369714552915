export enum UserType {
  Direct = "direct",
  Advisor = "advisor",
  Referred = "referred",
  Advised = "advised",
}

export enum UserStatus {
  LostOrNoInterest = "lost-or-no-interest",
  NotStarted = "not-started",
  AppStarted = "application-started",
  AppSigned = "application-signed",
  FundsReceived = "funds-received",
  FundsDeployed = "funds-deployed",
}

export enum USCitizen {
  Yes = "Yes",
  No = "No",
  GreenCardHolder = "I am a green card holder",
}

export enum CertificationType {
  Advised = "advised",
  SelfSophisticated = "self-sophisticated",
  HighNetWorth = "high-net-worth",
  Restricted = "restricted",
}

export enum FeeType {
  Fixed = "fixed",
  Percentage = "percentage",
}
