import { Container, FormGroup, Typography } from "@mui/material";
import { onboardAdvisor } from "client/api/advisor";
import FormButtons from "client/components/FormButtons";
import useAuthContext, { AuthActions } from "client/context/auth";
import useUserContext from "client/context/user";
import EVENTS from "client/utils/events.consts";
import FormGen, { FormGenConfig } from "form-gen";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Advisor, LeanUser } from "server/services/user";
import Yup from "shared/utils/Yup";

const CATEGORY = EVENTS.ADVISOR.INPUT.CATEGORIES.ONBOARDING;

export interface OnboardingValues
  extends Pick<LeanUser, "title" | "forenames" | "surname">,
    Omit<Advisor, "isVerified" | "hasOnboarded"> {}

export const OnboardingConfig: FormGenConfig<
  Pick<LeanUser, "title" | "forenames" | "surname"> & Omit<Advisor, "isVerified" | "hasOnboarded">
> = [
  {
    type: "text",
    name: "title",
    label: "Title",
    placeholder: "Title (Mr/Mrs/Miss/Ms/Other)",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your title"),
  },
  {
    type: "text",
    name: "forenames",
    label: "Forenames",
    placeholder: "Forenames",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your forenames"),
  },
  {
    type: "text",
    name: "surname",
    label: "Surnames",
    placeholder: "Surname",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your surname"),
  },
  {
    type: "text",
    name: "contactNumber",
    label: "Contact Number",
    placeholder: "Contact Number",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your contact number"),
  },
  {
    type: "text",
    name: "company",
    label: "What is the name of the company you work for?",
    placeholder: "Company Name",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your company name"),
  },
  {
    type: "text",
    name: "fcaNumber",
    label: "FCA Number",
    placeholder: "FCA Number",
    props: { category: CATEGORY },
    validation: Yup.string().required("Please enter your FCA Number"),
  },
  { type: "divider" },
  {
    type: "section",
    title: (
      <>
        <Typography variant="h3" gutterBottom>
          Bank Details
        </Typography>
        <Typography variant="body2">
          Please note that bank details are only required if the adviser charge is being charged via the Fund.
        </Typography>
      </>
    ),
    titleProps: { variant: "h3" },
    name: "bankDetails",
    fields: [
      {
        type: "text",
        name: "name",
        label: "Bank Name",
        placeholder: "Bank Name",
        props: { category: CATEGORY },
        validation: Yup.string().nullable(),
      },
      {
        type: "text",
        name: "accountName",
        label: "Account Name",
        placeholder: "Account Name",
        props: { category: CATEGORY },
        validation: Yup.string().nullable(),
      },
      {
        type: "number",
        name: "accountNumber",
        label: "Account Number",
        placeholder: "Account Number",
        props: {
          category: CATEGORY,
          disableMonetaryFormat: true,
          formatProps: {
            maxLength: 8,
            decimalScale: 0,
            allowLeadingZeros: true,
            thousandSeparator: false,
          },
        },
        validation: Yup.string()
          .matches(/^$|\d{6,8}/, "Invalid account number (must be at least 6 digits and at most 8)")
          .nullable(),
        onChange(e) {
          this.setFieldValue(e.target.name, e.target.valueAsFormatted);
        },
      },
      {
        type: "number",
        name: "sortCode",
        label: "Sort Code",
        placeholder: "Sort Code",
        props: {
          category: CATEGORY,
          disableMonetaryFormat: true,
          formatProps: {
            format: "##-##-##",
            mask: "X",
            type: "tel",
            isNumericString: false,
          },
        },
        validation: Yup.string()
          .matches(/^$|\d{2}-\d{2}-\d{2}/, "Invalid sort code")
          .nullable(),
        onChange(e) {
          this.setFieldValue(e.target.name, e.target.valueAsFormatted);
        },
      },
    ],
  },
];

const Onboarding = () => {
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const { details } = useUserContext();

  const initialValues = useMemo(
    () => ({
      title: details.title ?? "",
      forenames: details.forenames ?? "",
      surname: details.surname ?? "",
      contactNumber: details.contactNumber ?? "",
      ...details.advisorDetails,
    }),
    [details.advisorDetails, details.contactNumber, details.forenames, details.surname, details.title],
  );

  const handleSubmit = useCallback(
    async (values: OnboardingValues) => {
      const res = await onboardAdvisor(values);
      if (!res) {
        window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.ADVISOR_VERIFICATION_FORM_SUBMITTED_UNSUCCESSFULLY });
        return;
      }
      window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.ADVISOR_VERIFICATION_FORM_SUBMITTED_SUCCESSFULLY });
      dispatch({ type: AuthActions.SetUser, payload: res });
      navigate("/advisor/dashboard");
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    window.dataLayer.push({ event: EVENTS.PAGE_VIEWS.ADVISOR_VERIFICATION });
  }, []);

  return (
    <Container maxWidth="md" style={{ paddingTop: 150 }}>
      <Typography variant="h1" align="center" paragraph>
        Please answer the questions below to verify your IFA status
      </Typography>
      <FormGen config={OnboardingConfig} onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize>
        {() => (
          <FormGroup>
            <FormButtons secondaryProps={{ label: "Back", onClick: () => navigate("/advisor/dashboard") }} />
          </FormGroup>
        )}
      </FormGen>
    </Container>
  );
};

export default Onboarding;
