import { useMemo } from "react";
import { Location, useLocation } from "react-router-dom";

const useReferrer = (): null | { pathname: string | null; search: string | undefined } => {
  const location = useLocation() as Location & { state: { from?: Location } };
  return useMemo(() => {
    if (!location.state?.from?.pathname) return null;
    const hasSearch = Boolean(location.search || location.state.from.search);
    const referrerSearch = location.state.from.search || "?";
    return {
      pathname: location.state.from.pathname || null,
      search: !hasSearch ? undefined : `${referrerSearch}${location.search.replace("?", "")}`,
    };
  }, [location.search, location.state?.from?.pathname, location.state?.from?.search]);
};

export default useReferrer;
