import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Tooltip, Typography, TypographyProps } from "@mui/material";
import useToggle from "client/hooks/useToggle";
import Logging from "client/utils/logging";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo } from "react";
import { makeStyles } from "tss-react/mui";

export interface ClipboardTypographyProps extends TypographyProps {
  text: string;
}

const useStyles = makeStyles({ name: "ClipboardTypography" })(() => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
}));

const ClipboardTypography: React.FC<ClipboardTypographyProps> = ({ text, ...props }) => {
  const { classes } = useStyles();
  const clipboardEnabled = useMemo(() => typeof window !== "undefined" && window.navigator.clipboard, []);
  const [copied, toggleCopied] = useToggle(false);

  const handleClick = useCallback<React.MouseEventHandler<HTMLSpanElement>>(async () => {
    try {
      if (!clipboardEnabled || !text) return;
      await window.navigator.clipboard.writeText(text);
      toggleCopied.on();
    } catch (error) {
      Logging.error(error, { quiet: true });
      toggleCopied.off();
    }
  }, [clipboardEnabled, text, toggleCopied]);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(toggleCopied.off, 5000);
    return () => clearTimeout(timeout);
  }, [copied, toggleCopied.off]);

  return (
    <Box className={clsx(classes.root, { [classes.clickable]: clipboardEnabled })} onClick={handleClick}>
      <ContentCopyIcon fontSize="small" />
      &nbsp;
      <Tooltip title={!copied ? "" : "Copied!"} open placement="top">
        <Typography {...props}>{props.children || text}</Typography>
      </Tooltip>
    </Box>
  );
};

export default ClipboardTypography;
