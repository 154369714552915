import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useQueryParams = <T extends Record<string, string | string[]>>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const params = {} as T;
    for (const [key, value] of searchParams.entries() as IterableIterator<[key: keyof T, value: T[keyof T]]>) {
      const currValue = params[key];
      if (!currValue) {
        params[key] = value;
        continue;
      }
      params[key] = [...(Array.isArray(currValue) ? currValue : [currValue]), value] as T[keyof T];
    }
    const setQueryParams = (ps: T | ((prevParams: T) => T)) =>
      setSearchParams(typeof ps === "function" ? ps(params) : ps);
    return [params, setQueryParams] as [params: T, setParams: typeof setQueryParams];
  }, [searchParams, setSearchParams]);
};

export default useQueryParams;
