import React, { useCallback, useState } from "react";
import { Button, ButtonProps, Typography } from "@mui/material";
import { patchUser } from "client/api/admin/users/user";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import useAdminContext, { AdminActions } from "client/context/admin";
import { useFormikContext } from "formik";

interface ResetTwoFAProps extends ButtonProps {
  userId: string;
  onReset: (val: any) => void;
}

const useStyles = makeStyles({ name: "ResetTwoFA" })((theme) => ({
  buttonStyles: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  fontStyles: {
    marginTop: theme.spacing(1),
    color: theme.palette.success.light,
  },
}));

const ResetTwoFA: React.FC<ResetTwoFAProps> = ({ userId, onReset }) => {
  const { classes } = useStyles();
  const [isResetSuccessful, setIsResetSuccessful] = useState<boolean>(false);
  const { dispatch: adminDispatch } = useAdminContext();
  const { setFieldValue } = useFormikContext();

  const handleResetTwoFA = useCallback(async () => {
    if (userId) {
      const res = await patchUser(userId, {
        meta: { twoFASecret: null, backupCodes: [], twoFAEnabled: false },
      });
      if (res) {
        adminDispatch({ type: AdminActions.UpdateUser, payload: res });
        onReset(setFieldValue);
        setIsResetSuccessful(true);
      }
    }
  }, [adminDispatch, onReset, userId, setFieldValue]);

  return (
    <>
      <Button className={clsx(classes.buttonStyles)} onClick={() => handleResetTwoFA()}>
        Reset 2FA
      </Button>
      {isResetSuccessful && (
        <Typography variant="body2" component="p" className={clsx(classes.fontStyles)}>
          Two factor authentication has been reset.
        </Typography>
      )}
    </>
  );
};

export default ResetTwoFA;
