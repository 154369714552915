import DataTable, { BaseData, TableProps } from "@wearenova/mui-data-table";
import useAuthContext from "client/context/auth";
import clsx from "clsx";
import React, { useMemo } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "AdminTable" })((theme) => ({
  table: {
    "& *.MuiTableCell-root": {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.caption.fontWeight,
      whiteSpace: "pre",
      "& *:not(svg)": {
        fontSize: theme.typography.caption.fontSize,
      },
    },
    "& .MuiTableFooter-root .MuiTableCell-root": {
      fontWeight: theme.typography.fontWeightBold,
    },
    [theme.breakpoints.up("lg")]: {
      tableLayout: "auto",
    },
  },
}));

const AdminTable = <RowType extends BaseData, DataType extends RowType[]>({
  tableProps: tablePropsProp,
  onChange,
  ...props
}: TableProps<RowType, DataType>) => {
  const { user } = useAuthContext();
  const { classes } = useStyles();

  const tableProps = useMemo(
    () => ({
      size: "small",
      stickyHeader: true,
      ...tablePropsProp,
      className: clsx(classes.table, tablePropsProp?.className),
    }),
    [classes.table, tablePropsProp],
  );

  const rowsPerPageOptions = useMemo(() => [25, 50, 100, 500, { label: "All", value: props.count! }], [props.count]);

  return (
    <DataTable
      tableProps={tableProps}
      onChange={onChange}
      rowsPerPageOptions={rowsPerPageOptions}
      enableHiddenColumns
      {...props}
      editable={!user?.isReadOnly}
    />
  );
};

export default AdminTable;
