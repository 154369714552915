import { FileDetails } from "client/components/Fields/UploadField";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";

export const uploadFile = async ({
  name,
  body,
  onProgress,
  publicFile,
  owner,
}: {
  name: string;
  body: Blob;
  onProgress?: (event: ProgressEvent<XMLHttpRequestUpload>) => void;
  publicFile?: boolean;
  owner?: string | null;
}) => {
  try {
    const formData = new FormData();
    formData.append("file", body, name);
    const res = await ipAxios.post<{ key: string }>("/uploads", formData, {
      onUploadProgress: onProgress,
      headers: { "Content-Length": body.size },
      params: { publicFile: publicFile ?? owner === null, owner },
    });
    return res.data;
  } catch (error) {
    Logging.error(error, { quiet: true });
    return null;
  }
};

export const getFileDetails = async (key: string): Promise<FileDetails | null> => {
  try {
    const res = await ipAxios.head<undefined>(`/uploads/${encodeURIComponent(key)}`);
    return {
      name: res.headers["x-file-name"],
      size: parseInt(res.headers["content-length"] as string),
      type: res.headers["content-type"],
    };
  } catch (error) {
    Logging.error(error, { quiet: true });
    return null;
  }
};

export const createMultipartUpload = async (name: string, size: number) => {
  try {
    const res = await ipAxios.post<{ key: string; uploadId: string }>("/uploads/multipart", { name, size });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getPresignedUploadURLs = async (parts: { key: string; uploadId: string; partNumbers: number[] }) => {
  try {
    const res = await ipAxios.get<{ urls: Record<string, string> }>("/uploads/multipart", {
      params: parts,
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const uploadPart = async ({
  key,
  uploadId,
  partNumber,
  body,
}: {
  key: string;
  uploadId: string;
  partNumber: number;
  body: Blob;
}) => {
  try {
    const formData = new FormData();
    formData.append("key", key);
    formData.append("body", body);
    const res = await ipAxios.put<{ etag: string; partNumber: number }>(`/uploads/multipart/${uploadId}`, formData, {
      params: { partNumber },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const abortMultipartUpload = async (uploadId: string, key: string) => {
  try {
    await ipAxios.delete(`/uploads/multipart/${uploadId}`, { params: { key } });
    return true;
  } catch (error) {
    Logging.error(error);
    return false;
  }
};

export const completeMultipartUpload = async ({
  uploadId,
  key,
  parts,
}: {
  uploadId: string;
  key: string;
  parts: Array<{ ETag: string; PartNumber: number }>;
}) => {
  try {
    await ipAxios.put(`/uploads/multipart/${uploadId}/complete`, { key, parts });
    return true;
  } catch (error) {
    Logging.error(error);
    return false;
  }
};
