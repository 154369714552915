import { Typography } from "@mui/material";
import React from "react";

interface CommonStatementProps {
  uniqueText: string;
}

const CommonStatement: React.FC<CommonStatementProps> = ({ uniqueText }) => {
  return (
    <>
      <Typography variant="body1">
          {uniqueText}
          </Typography>
          <br />
          <Typography variant="body1">
            I understand that this means:
            <ol type="a">
              <li>
                I can receive financial promotions where the contents may not comply with rules made by the Financial
                Conduct Authority (FCA).
              </li>
              <li>
                I can expect <strong>no protection</strong> from the FCA, the Financial Ombudsman Service, or the
                Financial Services Compensation Scheme.
              </li>
            </ol>
          </Typography>
          <Typography variant="body1">
            I am aware that it is open to me to seek advice from someone who specialises in advising on investments.
          </Typography>
          <br />
          <Typography variant="body1">
            <strong>I accept that I could lose all the money I invest.</strong>
          </Typography>
    </>
  );
};

export default CommonStatement;
