import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormGroup,
  FormHelperText,
  Grid,
  GridProps,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { BaseData, ColumnDefinition } from "@wearenova/mui-data-table";
import { createExport } from "client/api/admin/exports";
import ClipboardTypography from "client/components/ClipboardTypography";
import Divider from "client/components/Divider";
import useToggle from "client/hooks/useToggle";
import React, { useCallback, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { ExportType } from "server/services/export/consts";
import { toCSVStructure } from "shared/utils/dataTableExport";
import DateTime from "shared/utils/DateTime";
import { makeStyles } from "tss-react/mui";

export interface ExportLinkFormProps<T extends BaseData> extends DialogProps {
  type: ExportType;
  structure: ColumnDefinition<T>[];
  data: T[];
  onClose(): void;
}

const GridContainer = React.forwardRef(function _GridContainer(props, ref) {
  return <Grid {...props} ref={ref} container rowSpacing={1} columnSpacing={1} minHeight={32} />;
}) as React.ForwardRefExoticComponent<GridProps>;

const useStyles = makeStyles({ name: "ExportLinkForm" })(() => ({
  chip: {
    cursor: "grab",
    width: "100%",
  },
  chipLabel: {
    flex: 1,
  },
}));

const ExportLinkForm = <T extends BaseData>({ type, structure, data, ...props }: ExportLinkFormProps<T>) => {
  const { classes } = useStyles();
  const fullStruct = useMemo(
    () => toCSVStructure(structure, data).map((col) => ({ ...col, id: col.key })),
    [data, structure],
  );
  const [expiresAt, setExpiresAt] = useState<DateTime | null>(null);
  const [included, setIncluded] = useState(fullStruct);
  const [excluded, setExcluded] = useState<typeof fullStruct>([]);
  const [loading, toggleLoading] = useToggle(false);
  const [link, setLink] = useState<string>();

  const handleSubmit = useCallback(async () => {
    toggleLoading.on();
    const res = await createExport(type, { expiresAt: expiresAt?.toISODate(), structure: included });
    if (res) setLink(res.url);
    toggleLoading.off();
  }, [expiresAt, included, toggleLoading, type]);

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle>Drag & Drop the columns you want to be included in the CSV and their order.</DialogTitle>
      <DialogContent>
        <FormGroup>
          <DatePicker
            value={expiresAt}
            onChange={setExpiresAt}
            renderInput={(params) => (
              <TextField {...params} size="small" helperText="Leave blank to not have the link expire." />
            )}
            label="Link Expires On...."
            disablePast
          />
        </FormGroup>
        <FormGroup>
          <Typography gutterBottom>
            Excluded
            <FormHelperText>Drag columns here to exclude them</FormHelperText>
          </Typography>
          <ReactSortable group="struct" list={excluded} setList={setExcluded} tag={GridContainer} animation={150}>
            {excluded.map((col) => (
              <Grid key={col.key} item xs={12} md={4}>
                <Chip
                  classes={{ root: classes.chip, label: classes.chipLabel }}
                  avatar={<Avatar>&nbsp;</Avatar>}
                  label={col.title}
                  deleteIcon={<AddCircleIcon />}
                  disabled={loading}
                  clickable
                  onDelete={() => {
                    setIncluded([...included, col]);
                    setExcluded(excluded.filter((exc) => exc.key !== col.key));
                  }}
                />
              </Grid>
            ))}
          </ReactSortable>
          <Divider sx={{ my: 1 }} />
          <Typography gutterBottom>
            Included
            <FormHelperText>The order of the columns is shown by the number on the left</FormHelperText>
          </Typography>
          <ReactSortable group="struct" list={included} setList={setIncluded} tag={GridContainer} animation={150}>
            {included.map((col, idx) => (
              <Grid key={col.key} item xs={12} md={4}>
                <Chip
                  classes={{ root: classes.chip, label: classes.chipLabel }}
                  avatar={<Avatar>{idx + 1}</Avatar>}
                  label={col.title}
                  disabled={loading}
                  clickable
                  onDelete={() => {
                    setIncluded(included.filter((inc) => inc.key !== col.key));
                    setExcluded([...excluded, col]);
                  }}
                />
              </Grid>
            ))}
          </ReactSortable>
        </FormGroup>
        <FormGroup>
          {link && (
            <div>
              <ClipboardTypography text={link} paragraph={loading} overflow="hidden" textOverflow="ellipsis">
                Copy Link
              </ClipboardTypography>
            </div>
          )}
          {loading && <LinearProgress variant="indeterminate" />}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} disabled={loading} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={loading} fullWidth>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportLinkForm;
