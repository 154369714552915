import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { Container, Typography } from "@mui/material";
import ResendEmailConfirmation from "client/components/CTAs/ResendEmailConfirmation";
import RouterButton from "client/components/RouterButton";
import useAuthContext from "client/context/auth";
import useQueryParams from "client/hooks/useQueryParams";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { TokenAuthStatus } from "shared/consts";

type ReasonsMap<T> = {
  [key in TokenAuthStatus]: T;
};

const ConfirmEmail: React.FC = () => {
  const { user } = useAuthContext();
  const [{ status }] = useQueryParams<{ status: TokenAuthStatus }>();

  const isSuccessful = useMemo(
    () => status === TokenAuthStatus.Success || status === TokenAuthStatus.Conflict,
    [status],
  );

  const Icons = useMemo<ReasonsMap<ReactElement>>(
    () => ({
      [TokenAuthStatus.Success]: <MarkEmailReadIcon fontSize="inherit" color="success" />,
      [TokenAuthStatus.Conflict]: <MarkEmailReadIcon fontSize="inherit" color="success" />,
      [TokenAuthStatus.Expired]: <CancelScheduleSendIcon fontSize="inherit" color="error" />,
      [TokenAuthStatus.Error]: <CancelScheduleSendIcon fontSize="inherit" color="error" />,
    }),
    [],
  );

  const Titles = useMemo<ReasonsMap<ReactNode>>(
    () => ({
      [TokenAuthStatus.Success]: "Email Confirmed",
      [TokenAuthStatus.Conflict]: "Email Previously Confirmed",
      [TokenAuthStatus.Expired]: "Email Confirmation Link Expired",
      [TokenAuthStatus.Error]: "Error Confirming Email",
    }),
    [],
  );

  const Descriptions = useMemo<ReasonsMap<ReactNode>>(
    () => ({
      [TokenAuthStatus.Success]: "Your email has been confirmed.",
      [TokenAuthStatus.Conflict]: "Your email has previously been confirmed.",
      [TokenAuthStatus.Expired]: (
        <>
          Your email confirmation link has expired.
          <br />
          Click the button below to send a new one.
          <br />
          <br />
          <ResendEmailConfirmation />
        </>
      ),
      [TokenAuthStatus.Error]: (
        <>
          An error occurred while confirming your email.
          <br />
          Please try again later.
        </>
      ),
    }),
    [],
  );

  return (
    <Container maxWidth="md" style={{ paddingTop: 150 }}>
      <Typography component="div" align="center">
        <Typography variant="h1" paragraph>
          {Icons[status]}
        </Typography>
        <Typography variant="h2" paragraph>
          {Titles[status]}
        </Typography>
        <Typography variant="body1" paragraph>
          {Descriptions[status]}
        </Typography>
        {isSuccessful && (
          <>
            <Typography variant="body1" paragraph>
              You may now close this tab or click the button below to {user ? "go to your dashboard." : "login."}
            </Typography>
            <RouterButton to="/">{user ? "Go to your Dashboard" : "Go to Login"}</RouterButton>
          </>
        )}
      </Typography>
    </Container>
  );
};

export default ConfirmEmail;
