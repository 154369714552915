import { UserType } from "server/services/user/consts";

const splitUsers = <T extends { type: UserType }>(users: T[]) => {
  const usersByType: { [key in "investors" | "advisors"]: T[] } = {
    investors: [],
    advisors: [],
  };
  users.forEach((user) => usersByType[user.type === UserType.Advisor ? "advisors" : "investors"].push(user));
  return usersByType;
};

export default splitUsers;
