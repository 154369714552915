import { Checkbox, CheckboxProps, FormControlLabel, FormHelperText, TypographyVariant } from "@mui/material";
import useHelperText from "client/hooks/useHelperText";
import clsx from "clsx";
import { FieldProps } from "formik";
import React, { ReactNode } from "react";
import TrackInput, { TrackInputProps } from "../TrackInput";

export interface CheckboxFieldProps
  extends FieldProps<boolean>,
    Omit<CheckboxProps, "form" | "color">,
    TrackInputProps {
  label: string;
  labelVariant: TypographyVariant;
  helperText?: ReactNode;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  form: _form,
  field: { value, ...field },
  label,
  category,
  labelVariant = "body2",
  ...props
}) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={value}>
      <FormControlLabel
        sx={(theme) => ({ "& .MuiFormControlLabel-label": { ...theme.typography[labelVariant] } })}
        label={label}
        control={<Checkbox {...field} {...props} checked={value} className={clsx(props.className, "fs-exclude")} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default CheckboxField;
