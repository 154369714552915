import { makeStyles } from "tss-react/mui";

const useAppStyles = makeStyles({ name: "Application" })((theme) => ({
  container: {
    borderTop: "solid 1px",
    borderBottom: "solid 1px",
  },
  formGroup: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(3),
    },
  },
  paraGraph: {
    fontSize: "16px !important",
    lineHeight: "2em",
  },
  countDownDigits: {
    fontSize: "80px",
  },
  countDownSeparator: {
    fontSize: "80px",
  },
}));

export default useAppStyles;
