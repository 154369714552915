import { Button, ButtonGroup } from "@mui/material";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getPortfolios } from "client/api/admin/portfolios";
import { patchPortfolio } from "client/api/admin/portfolios/portfolio";
import useAdminContext, { AdminActions } from "client/context/admin";
import React, { FC, useCallback, useMemo, useState } from "react";
import { LeanPortfolio } from "server/services/portfolio";
import AdminTable from "../components/AdminTable";
import MainPanelContainer from "../components/MainPanelContainer";
import createTableDefinition from "../utils/createTableDefinition";
import PortfolioForm from "./Form";
import { Route, useNavigate } from "react-router-dom";
import SentryRoutes from "client/components/SentryRoutes";
import ExportLinkForm from "../components/ExportLinkForm";
import { ExportType } from "server/services/export/consts";

const Portfolios: FC = () => {
  const navigate = useNavigate();
  const { portfolios, dispatch } = useAdminContext();
  const [count, setCount] = useState(-1);

  const portfoliosArr = useMemo(() => Object.values(portfolios), [portfolios]);

  const handleChange = useCallback(
    async (changeObject: OnChangeObject) => {
      const res = await getPortfolios(changeObject);
      if (!res) return [];
      setCount(res.count);
      dispatch({ type: AdminActions.SetPortfolios, payload: res.data || [] });
      return res.data;
    },
    [dispatch],
  );

  const tableStructure = useMemo<ColumnDefinition<LeanPortfolio>[]>(
    () =>
      createTableDefinition([
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
          dataType: "string",
          editable: true,
        },
        {
          key: "nominee",
          title: "Nominee",
          dataIndex: "nominee",
          dataType: "string",
          editable: true,
        },
      ]),
    [],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<LeanPortfolio>>(
    async ({ path, value }, record) => {
      const res = await patchPortfolio(record._id, { [path]: value === "null" ? null : value });
      if (res) dispatch({ type: AdminActions.UpdatePortfolio, payload: res });
    },
    [dispatch],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);

  return (
    <>
      <MainPanelContainer>
        <AdminTable
          tableStructure={tableStructure}
          tableData={portfoliosArr}
          onChange={handleChange}
          rowClick={(record) => navigate(`./${record._id}/edit`)}
          exportToCSVOption
          csvFilename="Portfolios"
          onEdit={handleTableEdit}
          count={count}
        />
        <ButtonGroup variant="text">
          <Button onClick={() => navigate("./create")}>Create a Portfolio</Button>
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
      </MainPanelContainer>
      <SentryRoutes>
        <Route path="create" element={<PortfolioForm open onClose={handleDialogClose} />} />
        <Route path=":portfoliosId/edit" element={<PortfolioForm open onClose={handleDialogClose} />} />
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Ports}
              data={portfoliosArr}
              structure={tableStructure}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default Portfolios;
