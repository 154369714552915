import { Dispatch, SetStateAction, useMemo, useState } from "react";

interface ToggleFunction {
  (): void;
  on(): void;
  off(): void;
  set: Dispatch<SetStateAction<boolean>>;
}

const useToggle = (initialValue: boolean): [value: boolean, toggle: ToggleFunction] => {
  const [value, setValue] = useState(initialValue);

  const toggle = useMemo(() => {
    const _toggle: ToggleFunction = () => setValue((s) => !s);
    _toggle.on = () => setValue(true);
    _toggle.off = () => setValue(false);
    _toggle.set = setValue;
    return _toggle;
  }, []);

  return useMemo(() => [value, toggle], [toggle, value]);
};

export default useToggle;
