import { Container, FormGroup, Typography } from "@mui/material";
import Divider from "client/components/Divider";
import FormButtons from "client/components/FormButtons";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { ArrayConfig, ErrorsList, FormGenConfig } from "form-gen";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { InvestorDetails as InvestorDetailsType } from "server/services/application";
import Yup from "shared/utils/Yup";
import useApplication from "./hooks/useApplication";
import useAppStyles from "./hooks/useAppStyles";
import { Forms } from "./utils";
import { USCitizen } from "server/services/user/consts";

countries.registerLocale(en);

const NationalityOptions = Object.entries(countries.getNames("en", { select: "official" }))
  .map(([code, name]) => ({
    label: name,
    value: code,
  }))
  .sort((a, b) => {
    if (a.label === "United Kingdom") return -1;
    if (b.label === "United Kingdom") return 1;
    return a.label.localeCompare(b.label);
  });

const AddressConfig: ArrayConfig<InvestorDetailsType, NonNullable<InvestorDetailsType["addressHistory"]>[number]> = {
  type: "array",
  name: "addressHistory",
  label: "Address History",
  min: 1,
  max: 3,
  automatic: (values, { push, pop, name }) => {
    const addressHistory = _.get(values, name) as NonNullable<InvestorDetailsType["addressHistory"]>;
    const totalLengthMonths = _.sumBy(
      addressHistory,
      (address) => Number(address.lengthYears || 0) * 12 + Number(address.lengthMonths || 0),
    );
    const lastLengthMonths = !addressHistory?.length
      ? 0
      : Number(addressHistory[addressHistory.length - 1].lengthYears || 0) * 12 +
        Number(addressHistory[addressHistory.length - 1].lengthMonths || 0);

    if (
      !addressHistory.length ||
      (addressHistory.length < 3 &&
        totalLengthMonths < 60 &&
        (typeof addressHistory[addressHistory.length - 1].lengthYears === "number" ||
          typeof addressHistory[addressHistory.length - 1].lengthMonths === "number"))
    ) {
      return push();
    }
    if (addressHistory.length > 1 && totalLengthMonths - lastLengthMonths >= 60) pop();
  },
  validation: Yup.array()
    .min(1, "Please provide at least ${min} address")
    .max(3, "You can only provide a maximum of ${max} addresses"),
  field: {
    type: "address",
    initialValue: {
      line1: "",
      line2: "",
      city: "",
      country: "",
      postcode: "",
      lengthYears: "",
      lengthMonths: "",
    },
    validation: Yup.object({
      line1: Yup.string().label("Line One").required("Please enter line 1 of the address"),
      line2: Yup.string().label("Line Two"),
      city: Yup.string().label("City").required("Please enter the city"),
      country: Yup.string().label("Country").required("Please enter the country"),
      postcode: Yup.string().label("Postcode").required("Please enter the postcode"),
      lengthMonths: Yup.number()
        .label("Months")
        .typeError("")
        .required("Please enter the length of time at this address"),
      lengthYears: Yup.number()
        .label("Years")
        .typeError("")
        .required("Please enter the length of time at this address"),
    }),
  },
};

export const getInvestorDetailsConfig = (isAdvisor: boolean): FormGenConfig<InvestorDetailsType> => [
  {
    type: "text",
    name: "title",
    label: "Title",
    placeholder: "Title (Mr/Mrs/Miss/Ms/Other)",
    validation: Yup.string().required("Please enter a title"),
  },
  {
    type: "text",
    name: "forenames",
    label: "Forename(s)",
    placeholder: "Forename(s)",
    validation: Yup.string().required("Please specify forename(s)"),
  },
  {
    type: "text",
    name: "surname",
    label: "Surname",
    placeholder: "Surname",
    validation: Yup.string().required("Please enter a surname"),
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    placeholder: "Email",
    validation: Yup.string().email("Please enter a valid email address").required("Please enter an email address"),
  },
  {
    type: "date",
    name: "dob",
    label: "Date of birth",
    placeholder: "Date of birth",
    props: {
      maxDate: DateTime.now().minus({ years: 18 }).startOf("day"),
      disableFuture: true,
    },
    get validation() {
      const path = this.name;
      return Yup.date()
        .typeError("Please enter a valid date")
        .test(function (value) {
          return (
            !value ||
            DateTime.fromJSDate(value).diffNow("days").days <= 0 ||
            this.createError({ path, message: "Date of birth cannot be in the future" })
          );
        })
        .max(this.props!.maxDate!, "Applicants must be at least 18 years old to invest in this fund")
        .required("Please enter a date of birth");
    },
  },
  {
    type: "text",
    name: "contactNumber",
    label: "Contact number",
    placeholder: "Contact number",
    validation: Yup.string().required("Please enter a contact number"),
  },
  {
    type: "text",
    name: "placeOfBirth",
    label: "Place of birth",
    placeholder: "Place of birth",
    validation: Yup.string().required("Please enter a place of birth"),
  },
  {
    type: "select",
    name: "nationality",
    label: "Select nationality",
    placeholder: "Nationality",
    options: NationalityOptions,
    validation: Yup.string()
      .nullable()
      .oneOf(
        NationalityOptions.map((o) => o.value),
        "Please select a valid option",
      )
      .required("Please select a nationality"),
  },
  {
    type: "select",
    name: "taxResidency",
    label: "Select a tax residency",
    placeholder: "Tax residency",
    options: NationalityOptions,
    validation: Yup.string()
      .nullable()
      .oneOf(
        NationalityOptions.map((o) => o.value),
        "Please select a valid option",
      )
      .required("Please select a tax residency"),
  },
  {
    type: "text",
    name: "tiNumber",
    label: (values) =>
      values && values.taxResidency === "GB" ? "National Insurance Number" : "Tax Identification Number",
    placeholder: (values) =>
      values && values.taxResidency === "GB" ? "National Insurance Number" : "Tax Identification Number",
    validation: Yup.string().when("taxResidency", {
      is: "GB",
      then: (schema) => schema.required(),
    }),
  },
  { ...AddressConfig },
  {
    type: "checkbox",
    name: "dualTaxResidency.present",
    label: isAdvisor
      ? "Please tick here if your client has dual tax residency"
      : "Please tick here if you have dual tax residency",
    validation: Yup.boolean().required("Please tick here if you have dual tax residency"),
  },
  {
    type: "select",
    name: "dualTaxResidency.nationality",
    condition: (values) => values && values.dualTaxResidency?.present,
    label: "Select a tax residency",
    placeholder: "Tax residency",
    options: NationalityOptions,
    validation: Yup.string()
      .nullable()
      .when("present", {
        is: true,
        then: Yup.string()
          .oneOf(
            NationalityOptions.map((o) => o.value),
            "Please select a valid option",
          )
          .required("Please select a tax residency"),
      }),
  },
  {
    type: "text",
    name: "dualTaxResidency.tiNumber",
    condition: (values) => values && values.dualTaxResidency?.present,
    label: "Tax Identification Number",
    placeholder: "Tax Identification Number",
    validation: Yup.string().when("present", {
      is: true,
      then: Yup.string().required("Please enter a tax identification number"),
    }),
  },
  {
    ...AddressConfig,
    name: "dualTaxResidency.addressHistory",
    label: "Dual Tax Residency Address History",
    condition: (values) => values && values.dualTaxResidency?.present,
    validation: Yup.array().when("present", {
      is: true,
      then: AddressConfig.validation,
    }),
  },
  {
    key: "usCitizen",
    type: "section",
    title: isAdvisor
      ? "If your client is a U.S. citizen, or holds a U.S. passport or green card, your client will also be considered tax resident in the U.S., even if they live outside the U.S. If you have any concerns regarding your clients tax residency, please seek advice from a suitably qualified person."
      : "If you are a U.S. citizen, or hold a U.S. passport or green card, you will also be considered tax resident in the U.S., even if you live outside the U.S. If you have any concerns regarding your tax residency, please seek advice from a suitably qualified person.",
    fields: [
      {
        type: "radio",
        name: "isUSCitizen",
        label: isAdvisor ? "Is your client a U.S. citizen?" : "Are you a U.S. citizen?",
        options: [
          {
            value: USCitizen.Yes,
            label: "Yes",
          },
          {
            value: USCitizen.No,
            label: "No",
          },
          {
            value: USCitizen.GreenCardHolder,
            label: isAdvisor ? "My client is a green card holder" : "I am a green card holder",
          },
        ],
        validation: Yup.string()
          .nullable()
          .oneOf(Object.values(USCitizen), "Please select one of the available options")
          .required("Please select one of the available options"),
      },
      {
        type: "file",
        name: "w9Form",
        condition: (values) => values && values.isUSCitizen === "Yes",
        label: isAdvisor ? "Please upload your client's completed W-9 form" : "Please upload your completed W-9 form",
        validation: Yup.mixed().when("isUSCitizen", {
          is: "Yes",
          then: Yup.mixed().required(
            isAdvisor ? "Please upload your client's completed W-9 form" : "Please upload your completed W-9 form",
          ),
        }),
      },
    ],
  },
  {
    key: "pep",
    type: "section",
    fields: [
      {
        type: "radio",
        name: "politicallyExposed.present",
        label: isAdvisor
          ? "Is your client a Politically Exposed Person (“PEP”) or a close relative or associate of one?"
          : "Are you a Politically Exposed Person (“PEP”) or a close relative or associate of one?",
        validation: Yup.boolean().required("Please tick here if you are a PEP"),
      },
      {
        type: "text",
        name: "politicallyExposed.details",
        condition: (values) => values && values.politicallyExposed?.present,
        label: isAdvisor
          ? "Please provide details of why your client is considered a PEP? We may have to conduct enhanced diligence regarding your client's PEP status and may have to contact your client directly."
          : "Please provide details of why you are considered a PEP? We may have to conduct enhanced diligence regarding your PEP status and may have to contact you directly.",
        placeholder: "Details",
        validation: Yup.string().when("present", {
          is: true,
          then: Yup.string().required(
            isAdvisor
              ? "Please provide details of why your client is considered a PEP"
              : "Please provide details of why you are considered a PEP",
          ),
        }),
      },
    ],
  },
  ...(isAdvisor ? [] : ([] as FormGenConfig<InvestorDetailsType>)),
];

const InvestorDetails: React.FC = () => {
  useAppRedirect();
  const { classes } = useAppStyles();
  const navigate = useNavigate();
  const { application, isAdvisor, handleAppDataPatch } = useApplication();

  const config = useMemo(() => getInvestorDetailsConfig(isAdvisor), [isAdvisor]);

  const goBack = useCallback(
    () => navigate(isAdvisor ? "../../funds" : `../${Forms.IdVerification}`),
    [isAdvisor, navigate],
  );

  const handleSubmit = useCallback(
    async (values: InvestorDetailsType) => {
      const app = await handleAppDataPatch({ investorDetails: values });
      if (app) navigate(`../${Forms.Objectives}`);
    },
    [handleAppDataPatch, navigate],
  );

  return (
    <>
      <Container>
        <FormGroup>
          <Typography variant="body1" align="center">
            By filling out these questions, it enables us to generate an application form which you can sign and send
            back to us.
          </Typography>
        </FormGroup>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <FormGen
          config={config}
          onSubmit={handleSubmit}
          classes={{ formGroup: classes.formGroup }}
          initialValues={application?.data?.investorDetails}
          validateOnMount
          enableReinitialize
        >
          {({ errors, submitCount }) => (
            <>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList />
                </FormGroup>
              )}
              <FormGroup>
                <FormButtons primaryProps={{ label: "Next" }} secondaryProps={{ label: "Back", onClick: goBack }} />
              </FormGroup>
            </>
          )}
        </FormGen>
      </Container>
    </>
  );
};

export default InvestorDetails;
