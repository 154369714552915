import { CacheProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserSCE } from "@wearenova/use-sce";
import React, { useEffect } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import createEmotionCache from "shared/utils/createEmotionCache";
import App from "./App";
import "./wdyr";

window.dataLayer = [];

Sentry.init({
  ...window.__CONFIG__.SENTRY_CONFIG,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
});

const emotionCache = createEmotionCache();

const Main: React.FC = () => {
  return (
    <CacheProvider value={emotionCache}>
      <BrowserRouter>
        <BrowserSCE value={{ data: window.__PRELOADED_STATE__ }}>
          <App config={window.__CONFIG__} />
        </BrowserSCE>
      </BrowserRouter>
    </CacheProvider>
  );
};

ReactDOM.hydrate(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root"),
);

if (module.hot) {
  module.hot.accept();
}
