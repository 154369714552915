import { Container, FormGroup, Link, Typography } from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import KeyDocumentsComponent from "client/components/KeyDocuments";
import RouterButton from "client/components/RouterButton";
import useConfigContext from "client/context/config";
import useFundsContext from "client/context/funds";
import useAppFormRedirect from "client/views/Application/hooks/useAppRedirect";
import React, { useMemo } from "react";
import LogoBanner from "./components/LogoBanner";
import useApplication from "./hooks/useApplication";
import { Forms } from "./utils";

const KeyInformation: React.FC = () => {
  useAppFormRedirect();
  const { portal } = useConfigContext();
  const { isAdvisor, isAdvised } = useApplication();
  const { selected: selectedFund } = useFundsContext();

  const minInvestment = useMemo(() => {
    const specificMin = isAdvisor
      ? selectedFund?.applications.minInvestment.ifa
      : selectedFund?.applications.minInvestment.direct;
    if (typeof specificMin === "number") return specificMin;
    return selectedFund?.applications.minInvestment.default;
  }, [
    isAdvisor,
    selectedFund?.applications.minInvestment.default,
    selectedFund?.applications.minInvestment.direct,
    selectedFund?.applications.minInvestment.ifa,
  ]);

  const continueTo = useMemo(() => {
    if (isAdvisor) return `../${Forms.Assessment}`;
    if (isAdvised) return `../${Forms.Fee}`;
    if (!portal.aml?.enabled) return `../${Forms.Assessment}`;
    return `../${Forms.IdVerification}`;
  }, [isAdvised, isAdvisor, portal.aml?.enabled]);

  return (
    <>
      <LogoBanner />
      <Container maxWidth="md">
        <Typography
          component="div"
          sx={{
            textAlign: {
              md: "justify",
            },
          }}
        >
          <Typography variant="h1" align="center" gutterBottom>
            Key Information & Application Form
          </Typography>
          <Typography variant="body2" paragraph>
            Thank you for your interest in the {selectedFund?.name} (the &ldquo;Fund&rdquo;). The application process is
            completely digital, and should take no more than ten minutes to complete. You will then be prompted to
            electronically sign the investment application form. At the end of the application process you will be
            provided with the bank details of where to transfer your investment.
          </Typography>
          <Typography variant="h3" gutterBottom>
            IMPORTANT NOTE
          </Typography>
          <Typography variant="body2" paragraph gutterBottom>
            This Application Form incorporates by reference the Information Memorandum, the Investment Management
            Agreement and the Key Information Document (KID) issued and approved by&nbsp;
            {selectedFund?.manager.companyDetails?.name} (&ldquo;{selectedFund?.manager.name}&rdquo;) which is
            authorised and regulated by the Financial Conduct Authority (FCA number:&nbsp;
            {selectedFund?.manager.companyDetails?.number}) in connection with the Fund and all other documents
            published by Sapphire or by {selectedFund?.name} which are expressed to be supplemental thereto (the
            &ldquo;Information Memorandum&rdquo;). Unless otherwise stated or as the context shall otherwise require,
            defined terms and expressions used in this Application Form have the meanings ascribed to them in the
            Information Memorandum.
          </Typography>
          <Typography variant="h3" gutterBottom>
            BEFORE YOU INVEST
          </Typography>
          <Typography variant="body2" paragraph gutterBottom>
            Before completing this Application Form, you should carefully review and understand the Information
            Memorandum, the Key Information Document and Investment Management Agreement (
            <strong>copies of which will be provided to you as part of the application process</strong>). Remember that
            an investment in the Fund may NOT be suitable for you, the information in the Information Memorandum does
            NOT constitute tax, legal or investment advice and&nbsp;
            <strong>
              you should seek your own independent advice by an independent financial advisor or other professional
              advisor authorised under the Financial Services and Markets Act 2000, as amended by the Financial Services
              and Markets Act 2023 (FSMA) who specialises in advising on investments of this type. Your capital is at
              risk.
            </strong>
          </Typography>
          <Typography variant="h3" gutterBottom>
            MINIMUM SUBSCRIPTION
          </Typography>
          <Typography variant="body2" paragraph>
            The minimum investment is&nbsp;
            {typeof minInvestment === "number" ? numberFormatter(minInvestment) : minInvestment || "unknown"}. At&nbsp;
            {selectedFund?.manager.name}&apos;s discretion, smaller investments may be accepted.
          </Typography>
          <Typography variant="body2" paragraph>
            If you need any assistance completing the Application Form or have any questions about the application
            process you should contact your financial advisor in the first instance. Additionally, you can contact&nbsp;
            <Link href={`mailto:${selectedFund?.contact.email}`}>{selectedFund?.contact.name}</Link> from&nbsp;
            {selectedFund?.companyDetails.name}
            {selectedFund?.manager.contacts.length === 1 && (
              <>
                &nbsp;or&nbsp;
                <Link href={`mailto:${selectedFund.manager.contacts[0].email}`}>
                  {selectedFund.manager.contacts[0].name}
                </Link>
                &nbsp;from {selectedFund?.manager.companyDetails?.name || selectedFund?.manager.name}
              </>
            )}
            {(selectedFund?.manager.contacts.length ?? 0) > 1 && (
              <>
                &nbsp;or any of the following from {selectedFund?.manager.companyDetails?.name}:{" "}
                {selectedFund?.manager.contacts?.map((contact, idx, arr) => (
                  <>
                    <Link href={`mailto:${contact.email}`}>{contact.name}</Link>
                    {idx !== arr.length - 1 && ", "}
                  </>
                ))}
              </>
            )}
            .
          </Typography>
          <Typography variant="h3">KEY INFORMATION DOCUMENTS</Typography>
          <KeyDocumentsComponent />
        </Typography>
        <Container maxWidth="md" disableGutters>
          <FormGroup row>
            <RouterButton to="../../funds">Back</RouterButton>
            <RouterButton to={continueTo} data-testid="continue">
              Continue
            </RouterButton>
          </FormGroup>
        </Container>
      </Container>
    </>
  );
};

export default KeyInformation;
