import { AnyField } from "form-gen";
import { FormikContextType, FormikErrors, FormikTouched } from "formik";
import _ from "lodash";
import Yup from "shared/utils/Yup";
import { LiteralUnion, PartialDeep } from "type-fest";
import { ArrayConfig, FormGenConfig } from "./types";

type Overrides<T extends FormGenConfig<any>> = {
  [Key in LiteralUnion<string & T[number]["name"], string>]?:
    | Partial<Record<string, any>>
    | (<C extends AnyField<any>>(config: C) => PartialDeep<C>);
};

export interface OverrideOptions<T extends FormGenConfig<any>> {
  namePrefix?: string;
  overrides?: Overrides<T>;
  /**
   * sets `.nullable(true)` and `.notRequired(true)` on `config.validation` for fields that have `validation` set
   */
  setAllNotRequired?: boolean;
}

export const getFullPrefix = <T extends string | undefined>(prefix: T) =>
  (prefix && !prefix.endsWith(".") ? `${prefix}.` : prefix ?? "") as T extends undefined ? "" : `${T}.`;

const prefixObject = <T extends string>(fullPrefix: T, obj: Record<string, any>) => {
  const res = {} as Record<`${T}${string}`, any>;
  for (const key in obj) {
    _.set(res, `${fullPrefix}${key}`, obj[key]);
  }
  return res;
};

const getOptionsObject = <T extends FormGenConfig<any>>(options: string | OverrideOptions<T>): OverrideOptions<T> => {
  if (typeof options === "string") return { namePrefix: options };
  return options;
};

const setNotRequired = (schema: Yup.AnySchema) => schema.nullable(true).notRequired(true);

export const overrideConfig = <T extends FormGenConfig<any>>(
  options: string | OverrideOptions<T>,
  config: T,
): FormGenConfig<any> => {
  const optsObj = getOptionsObject(options);
  const { namePrefix, overrides, setAllNotRequired } = optsObj;

  const fullPrefix = getFullPrefix(namePrefix);
  const overrideFunc = <K extends string, V, F extends (values: V) => any>(key: K, func: F | unknown) => {
    if (!namePrefix || !func || typeof func !== "function") return {};
    return { [key]: (values: V) => func(_.get(values, namePrefix)) };
  };
  const overrideValidation = <V extends AnyField<any>>(validation?: V["validation"]): Pick<V, "validation"> => {
    if (!validation) return {};
    if (!setAllNotRequired) return overrideFunc("validation", validation);
    if (typeof validation !== "function") return { validation: setNotRequired(validation) };
    const { validation: validationFunc } = overrideFunc("validation", validation);
    return {
      validation: (values: unknown) => {
        const schema = validationFunc(values);
        return setNotRequired(schema);
      },
    };
  };

  const overrideField = (c: AnyField<any> | (object & ArrayConfig<any>["field"])) => {
    const onChange = c.onChange;
    return {
      ...c,
      ...(!c.name || !namePrefix ? {} : { name: `${fullPrefix}${c.name}` }),
      ...overrideValidation(c.validation),
      ...(["condition", "label", "placeholder", "title"] as const).reduce(
        (prev, key) => ({ ...prev, ...overrideFunc(key, c[key]) }),
        {} as Partial<typeof c>,
      ),
      ...(!onChange || typeof onChange !== "function" || !namePrefix
        ? {}
        : {
            onChange(this: FormikContextType<any>, ...args: any[]) {
              onChange.call(
                {
                  ...this,
                  values: _.get(this.values, namePrefix),
                  setFieldValue: (name, ...a) => this.setFieldValue(`${fullPrefix}${name}`, ...a),
                  setValues: (values, ...a) =>
                    this.setValues(_.merge(this.values, prefixObject(fullPrefix, values)), ...a),

                  errors: _.get(this.errors, namePrefix) as FormikErrors<any>,
                  setFieldError: (name, ...a) => this.setFieldError(`${fullPrefix}${name}`, ...a),
                  setErrors: (errors, ...a) =>
                    this.setErrors(_.merge(this.errors, prefixObject(fullPrefix, errors)), ...a),

                  touched: _.get(this.touched, namePrefix) as FormikTouched<any>,
                  setFieldTouched: (name, ...a) => this.setFieldTouched(`${fullPrefix}${name}`, ...a),
                  setTouched: (touched, ...a) =>
                    this.setTouched(_.merge(this.touched, prefixObject(fullPrefix, touched)), ...a),
                } as FormikContextType<any>,
                ...args,
              );
            },
          }),
    };
  };

  return config.map((c) => {
    if (c.name && overrides?.[c.name]) {
      const override = overrides[c.name];
      c = _.mergeWith({ ...c }, typeof override !== "function" ? override : override(c), (_origVal, newVal, key) =>
        key !== "validation" ? undefined : newVal,
      );
    }
    return {
      ...overrideField(c),
      ...(!c.field ? {} : { field: overrideField(c.field) }),
      ...(!c.fields ? {} : { fields: overrideConfig(optsObj, c.fields) }),
    };
  }) as T;
};
