import { Collapse, Container, FormGroup, Typography } from "@mui/material";
import { submitCertification } from "client/api/certification";
import CheckboxField from "client/components/Fields/CheckboxField";
import FormButton from "client/components/FormButton";
import FormikErrorsList from "client/components/FormikErrorsList";
import useAuthContext, { AuthActions } from "client/context/auth";
import useReferrer from "client/hooks/useReferrer";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Xarrow, { Xwrapper } from "react-xarrows";
import { UserCertification } from "server/services/user";
import { CertificationType } from "server/services/user/consts";
import { CertificationForm } from "shared/application.consts";
import Yup from "shared/utils/Yup";
import { makeStyles } from "tss-react/mui";
import { RequiredStringSchema } from "yup/lib/string";
import CertificationCard from "./components/CertificationCard";
import HighNetWorth from "./HighNetWorth";
import Restricted from "./Restricted";
import Sophisticated from "./Sophisticated";
import InitialRiskWarning from "client/components/InitialRiskWarning";

const useStyles = makeStyles({ name: "Certification" })((theme) => ({
  arrow: {
    stroke: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  arrowHead: {
    fill: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  investorTypes: {
    display: "flex",
    padding: theme.spacing(0, 2),
    "& > div:not(:last-child)": {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > div:not(:last-child)": {
        marginRight: 0,
        marginBottom: theme.spacing(3),
      },
    },
  },
}));

const OPTIONS = [
  {
    value: CertificationType.SelfSophisticated,
    title: "Sophisticated",
    description: "Likely to be you if you have current or recent investment experience.",
  },
  {
    value: CertificationType.HighNetWorth,
    title: "High Net Worth",
    description: "Could be you if your income or net assets exceed certain minimum thresholds.",
  },
  {
    value: CertificationType.Restricted,
    title: "Restricted",
    description: "If you don't fit the other types and are happy to limit your investments.",
  },
] as const;

const ValuesSchema = Yup.object({
  [CertificationForm.values.understandsRisk.id]: Yup.boolean()
    .oneOf([true], "Please confirm you accept the risk")
    .required(),
  [CertificationForm.values.understandsSelfCert.id]: Yup.boolean()
    .oneOf([true], "Please confirm you understand")
    .required(),
  [CertificationForm.values.signingConfirmation.id]: Yup.boolean()
    .oneOf([true], "Please confirm that the above statement is true")
    .required("Please confirm the statement above"),
})
  .default({
    [CertificationForm.values.understandsRisk.id]: false,
    [CertificationForm.values.understandsSelfCert.id]: false,
    [CertificationForm.values.signingConfirmation.id]: false,
    [CertificationForm.values.notApplicable.id]: false,
    [CertificationForm.values.hasLargeIncome.id]: null,
    [CertificationForm.values.netIncome.id]: null,
    [CertificationForm.values.hasLargeNetAssets.id]: null,
    [CertificationForm.values.netAssets.id]: null,
    [CertificationForm.values.financeProviderForSMEsOrPEWorker.id]: null,
    [CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id]: "",
    [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id]: null,
    [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id]: "",
    [CertificationForm.values.multipleInvestmentsUnlistedCompany.id]: null,
    [CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id]: null,
    [CertificationForm.values.businessAngelNetworkMemberOver6Months.id]: null,
    [CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id]: "",
    [CertificationForm.values.investedInHighRiskInvestments.id]: null,
    [CertificationForm.values.investedInHighRiskInvestmentsPercentage.id]: null,
    [CertificationForm.values.limitHighRiskInvestment.id]: null,
    [CertificationForm.values.limitHighRiskInvestmentPercentage.id]: null,
  })
  .when("type", {
    is: CertificationType.HighNetWorth,
    then: (schema) =>
      schema.concat(
        Yup.object({
          [CertificationForm.values.hasLargeIncome.id]: Yup.boolean().nullable(),
          [CertificationForm.values.netIncome.id]: Yup.number()
            .nullable()
            .when(CertificationForm.values.hasLargeIncome.id, {
              is: true,
              then: Yup.number().nullable().required("Please enter your net income"),
            }),
          [CertificationForm.values.hasLargeNetAssets.id]: Yup.boolean().nullable(),
          [CertificationForm.values.netAssets.id]: Yup.number()
            .nullable()
            .when(CertificationForm.values.hasLargeNetAssets.id, {
              is: true,
              then: Yup.number().nullable().required("Please enter your net assets"),
            }),
          [CertificationForm.values.notApplicable.id]: Yup.boolean(),
        })
          .test("atLeastOneTrue", "Please select Yes for at least one of the questions above", function (values) {
            return (
              values?.[CertificationForm.values.hasLargeIncome.id] ||
              values?.[CertificationForm.values.hasLargeNetAssets.id] ||
              values?.[CertificationForm.values.notApplicable.id] ||
              this.createError({
                path: "atLeastOneTrue",
                message: "Please select Yes for at least one of the cases above",
              })
            );
          })
          .required(),
      ),
  })
  .when("type", {
    is: CertificationType.SelfSophisticated,
    then: (schema) =>
      schema
        .concat(
          Yup.object({
            [CertificationForm.values.financeProviderForSMEsOrPEWorker.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.financeProviderForSMEsOrPEWorker.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter name of the business or organisation"),
              }),

            [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter the name of the company"),
              }),
            [CertificationForm.values.multipleInvestmentsUnlistedCompany.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.multipleInvestmentsUnlistedCompany.id, {
                is: true,
                then: Yup.string()
                  .nullable()
                  .required("Please enter investments in unlisted companies you have made in the last two years"),
              }),

            [CertificationForm.values.businessAngelNetworkMemberOver6Months.id]: Yup.boolean()
              .nullable()
              .when(CertificationForm.values.notApplicable.id, {
                is: false,
                then: Yup.boolean().nullable().required("Please select either Yes or No"),
              }),
            [CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id]: Yup.string()
              .nullable()
              .when(CertificationForm.values.businessAngelNetworkMemberOver6Months.id, {
                is: true,
                then: Yup.string().nullable().required("Please enter the name of the network or syndicate?"),
              }),
            [CertificationForm.values.notApplicable.id]: Yup.boolean(),
          }),
        )
        .required(),
  })
  .when("type", {
    is: CertificationType.Restricted,
    then: (schema) =>
      schema.concat(
        Yup.object({
          [CertificationForm.values.investedInHighRiskInvestments.id]: Yup.boolean()
            .nullable()
            .required("Please select either Yes or No"),
          [CertificationForm.values.investedInHighRiskInvestmentsPercentage.id]: Yup.number()
            .nullable()
            .min(0, "Must be at least ${min}%")
            .max(100, "Must be at most ${max}%")
            .when(CertificationForm.values.investedInHighRiskInvestments.id, {
              is: true,
              then: Yup.number().nullable().required("Field is required"),
            }),
          [CertificationForm.values.limitHighRiskInvestment.id]: Yup.boolean()
            .nullable()
            .required("Please select either Yes or No"),
          [CertificationForm.values.limitHighRiskInvestmentPercentage.id]: Yup.number()
            .nullable()
            .min(0, "Must be at least ${min}%")
            .max(100, "Must be at most ${max}%")
            .when(CertificationForm.values.limitHighRiskInvestment.id, {
              is: true,
              then: Yup.number().nullable().required("Field is required"),
            }),
        })
          .test("atLeastOneTrue", "Please select Yes for at least one of the questions above", function (values) {
            return (
              values?.[CertificationForm.values.investedInHighRiskInvestments.id] ||
              values?.[CertificationForm.values.limitHighRiskInvestment.id] ||
              this.createError({
                path: "atLeastOneTrue",
                message: "Please select Yes for at least one of the cases above",
              })
            );
          })
          .required(),
      ),
  });
export const CertificationFormSchema = Yup.object({
  type: Yup.string()
    .default("")
    .oneOf(OPTIONS.map((option) => option.value))
    .required("Please select a certification type") as RequiredStringSchema<CertificationType>,
  values: ValuesSchema,
}).required();

export type CertificationFormValues = Omit<UserCertification, "date">;

const { understandsRisk, understandsSelfCert, ...ValuesDefault } = ValuesSchema.getDefault();
const InitialValues = CertificationFormSchema.getDefault() as CertificationFormValues;

const Certification: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const referrer = useReferrer();

  const handleSubmit = useCallback(
    async (values: CertificationFormValues) => {
      const res = await submitCertification(values);
      if (!res) return;
      dispatch({ type: AuthActions.SetUser, payload: res });
      if (referrer?.pathname) return navigate({ pathname: referrer.pathname, search: referrer.search });
      navigate("../apply/funds", { replace: true });
    },
    [dispatch, navigate, referrer?.pathname, referrer?.search],
  );

  return (
    <Formik initialValues={InitialValues} validationSchema={CertificationFormSchema} onSubmit={handleSubmit}>
      {({ errors, values, setFieldValue, submitCount }) => (
        <Form>
          <Container disableGutters style={{ paddingTop: 110 }}>
            <Container maxWidth="md">
              <Typography variant="h3" align="center">
                UNDERSTANDING RISK AND CERTIFICATION REQUIREMENT STATEMENT
              </Typography>
              <FormGroup>
                <Field
                  name={CertificationForm.values.understandsRisk.name}
                  component={CheckboxField}
                  label={CertificationForm.values.understandsRisk.label}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  name={CertificationForm.values.understandsSelfCert.name}
                  component={CheckboxField}
                  label={CertificationForm.values.understandsSelfCert.label}
                />
              </FormGroup>
            </Container>
            <Container maxWidth="lg">
              <Typography variant="h2" align="center" gutterBottom>
                What type of investor are you?
              </Typography>
              <Typography variant="body1" align="center">
                Please take your time to consider this carefully.
              </Typography>
            </Container>
            <Xwrapper>
              <div className={classes.investorTypes}>
                {OPTIONS.map(({ value, title, description }) => (
                  <CertificationCard
                    key={value}
                    id={value}
                    data-testid={value}
                    title={title}
                    onClick={() => {
                      setFieldValue("type", value);
                      setFieldValue("values", { ...values.values, ...ValuesDefault });
                    }}
                    selected={values.type === value}
                  >
                    {description}
                  </CertificationCard>
                ))}
              </div>
              {values.type && (
                <Xarrow
                  start={values.type}
                  startAnchor={["middle", "bottom"]}
                  end="statement"
                  endAnchor={["middle", "top"]}
                  path="grid"
                  strokeWidth={2}
                  headSize={2}
                  arrowBodyProps={{ className: classes.arrow }}
                  arrowHeadProps={{ className: clsx(classes.arrow, classes.arrowHead), scale: 0.5 }}
                />
              )}
            </Xwrapper>
            <Collapse in={Boolean(values.type)} timeout="auto">
              <Container maxWidth="md" sx={{ mb: 0, pb: 0 }}>
                <br />
                <div id="statement">
                  {values.type === CertificationType.SelfSophisticated && <Sophisticated />}
                  {values.type === CertificationType.HighNetWorth && <HighNetWorth />}
                  {values.type === CertificationType.Restricted && <Restricted />}
                </div>
              </Container>
            </Collapse>

            <Container maxWidth="md" sx={{ mt: 0, pt: 0 }} style={{ display: !values.type ? "none" : "" }}>
              <Typography variant="h4" sx={{ textDecoration: "underline" }}>
                Electronic Signature Notice
              </Typography>
              <Typography variant="body2">
                By submitting the button below labelled &quot;Click to Sign&quot; you are confirming your assent that
                this represents an electronic signature confirming your above High Net Worth Investor Statement. You
                acknowledge and agree that you will not contest the validity or enforceability of this electronically
                signed statement on the basis that it lacks an original handwritten signature. Your submission of the
                &quot;Click to Sign&quot; button shall be considered a valid signature as of the date of your above
                statement. Computer-maintained records, when produced in hard copy form, shall constitute business
                records and shall have the same validity as any other generally recognised business records.
              </Typography>

              <FormGroup>
                <Typography variant="h5">Click to Sign</Typography>
                <Field
                  name={CertificationForm.values.signingConfirmation.name}
                  component={CheckboxField}
                  label={CertificationForm.values.signingConfirmation.label}
                />
              </FormGroup>

              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <FormikErrorsList errors={errors} config={CertificationForm} />
                </FormGroup>
              )}

              <FormGroup>
                <FormButton type="submit">Continue</FormButton>
              </FormGroup>
            </Container>
          </Container>
          <InitialRiskWarning />
        </Form>
      )}
    </Formik>
  );
};

export default Certification;
