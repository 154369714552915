import SentryRoutes from "client/components/SentryRoutes";
import React from "react";
import { Route } from "react-router-dom";
import Preview from "./Preview";
import CompaniesTable from "./Table";

const Companies: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path=":companyId/preview" element={<Preview />} />
      <Route path="*" element={<CompaniesTable />} />
    </SentryRoutes>
  );
};

export default Companies;
