import { Box, FormGroup, Typography, Button, Link } from "@mui/material";
import TextField from "client/components/Fields/TextField";
import clsx from "clsx";
import FormButton from "components/FormButton";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import Image from "../../components/Image";
import Yup from "shared/utils/Yup";
import useAuthContext, { AuthActions } from "../../context/auth";
import { makeStyles } from "tss-react/mui";
import { useLocation, useNavigate } from "react-router-dom";
import useReferrer from "client/hooks/useReferrer";
import { submitTwoFA } from "client/api/public";
import TwoFASupportLink from "./TwoFASupportLink";

const useStyles = makeStyles({ name: "TwoFactorAuthForm" })((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: theme.spacing(1.25),
    transition: theme.transitions.create("height", {
      duration: theme.transitions.getAutoHeightDuration(350 - 225),
    }),
  },
}));

interface LocationState {
  twoFAEnabled: any;
  dataURL: any;
}

interface ResponseObject {
  redirectTo: any;
  data: any;
}

const TwoFactorAuth: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, dispatch } = useAuthContext();
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [responseObj, setResponseObj] = useState<ResponseObject>();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location?.state as LocationState;
  const isTwoFAEnabled = locationState?.twoFAEnabled;
  const dataUrl = locationState?.dataURL;
  const referrer = useReferrer();
  const { classes } = useStyles();

  const schema = Yup.object({
    oneTimePasscode: Yup.string().default("").required("Please enter your one time passcode"),
  });

  const initialValues = useMemo(() => schema.getDefault(), [schema]);

  const printHandler = () => {
    const printContent = document.getElementById("codes");
    const windowPrint = window.open("", "Portal Backup Codes", "width=900,height=650");
    windowPrint && printContent && windowPrint.document.write(printContent.innerHTML);
    windowPrint && windowPrint.document.close();
    windowPrint && windowPrint.focus();
    windowPrint && windowPrint.print();
  };

  const handleResponseFor2FA = useCallback(
    (res: any) => {
      if (res && res.redirectTo) return navigate(res.redirectTo);
      if (res && referrer?.pathname) return navigate({ pathname: referrer.pathname, search: referrer.search });
      if (res && user && user.isAdvisor) return navigate("/advisor/dashboard");
      navigate("/dashboard");
    },
    [navigate, user, referrer?.pathname, referrer?.search],
  );

  const handleSubmit = useCallback(
    async (values: Yup.Asserts<typeof schema>, { setErrors }) => {
      if (!isTwoFAEnabled) {
        const res = await submitTwoFA(values.oneTimePasscode);
        if (res && res?.data?.backupCodes && res?.data?.backupCodes.length === 0) {
          setErrors({ oneTimePasscode: "Passcode is not valid" });
          return;
        }
        if (res && res?.data?.backupCodes && res?.data?.backupCodes.length !== 0) {
          setBackupCodes(res?.data?.backupCodes);
          setResponseObj(res);
        }
      } else {
        const res: any = await submitTwoFA(values.oneTimePasscode);
        if (!res) {
          setErrors({ oneTimePasscode: "OTP does not match" });
          return;
        }
        dispatch({ type: AuthActions.SetUser, payload: res.data.retObj });
        handleResponseFor2FA(res);
      }
    },
    [dispatch, isTwoFAEnabled, handleResponseFor2FA],
  );

  return (
    <>
      {!isTwoFAEnabled && backupCodes.length === 0 ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Image sx={{ maxWidth: 150 }} src={location?.state && dataUrl} alt="QR" />
          </Box>
        </>
      ) : null}
      {backupCodes.length !== 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <Typography variant="body1" align="center" paragraph>
            Please keep a record of these backup codes. {<b>This is the only time you will see them.</b>} You can print
            them to keep a copy for your records.
          </Typography>
          <div
            id="codes"
            style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}
          >
            {backupCodes.map((code, index) => (
              <Typography variant="h4" key={index}>
                {code}
              </Typography>
            ))}
          </div>
          <Button variant="outlined" style={{ marginTop: 25 }} onClick={printHandler}>
            Print
          </Button>
          <Button
            id="backupContinue"
            style={{ marginTop: 15 }}
            onClick={() => {
              dispatch({ type: AuthActions.SetUser, payload: responseObj?.data.retObj });
              if (responseObj && responseObj.redirectTo) return navigate(responseObj.redirectTo);
              if (responseObj && referrer?.pathname)
                return navigate({ pathname: referrer.pathname, search: referrer.search });
              if (responseObj && user && user.isAdvisor) return navigate("/advisor/dashboard");
              navigate("/dashboard");
            }}
          >
            Continue
          </Button>
          <TwoFASupportLink />
        </Box>
      ) : (
        <>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body2" align="center" paragraph>
              {!isTwoFAEnabled
                ? "Please scan the QR Code with an authenticator app and enter the One Time Passcode shown."
                : "Please enter your One Time Passcode"}
            </Typography>
          </Box>
          <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize>
            <Form id="twoFactorAuth-form" className={clsx(classes.form)}>
              <FormGroup sx={{ margin: 0 }}>
                <Field
                  component={TextField}
                  name="oneTimePasscode"
                  data-testid="oneTimePasscode"
                  placeholder="One Time Passcode"
                  size="small"
                />
              </FormGroup>
              {backupCodes.length === 0 && isTwoFAEnabled && (
                <Link
                  variant="body2"
                  data-testid="forgot-password-link"
                  onClick={() => navigate("/backup")}
                  align="right"
                >
                  Access via your backup codes
                </Link>
              )}
              <FormGroup>
                <FormButton data-testid="login-button" size="small" type="submit">
                  Submit
                </FormButton>
              </FormGroup>
              <TwoFASupportLink />
              <TwoFASupportLink linkType="secondary" />
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};

export default TwoFactorAuth;
