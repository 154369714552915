export enum SharePriceSource {
  LastTrade = "last-trade",
  ExternalValuation = "external-valuation",
  Revaluation = "revaluation",
}

export enum DealStatus {
  Approved = "approved",
  Submitted = "submitted",
}

export enum DealInvestmentStage {
  Foundation = "foundation",
  Elevation = "elevation",
}

export enum BaseSectors {
  ADTech = "ADTech",
  AgriTech = "AgriTech",
  BioTech = "BioTech",
  CleanTech = "CleanTech",
  Commerce = "Commerce",
  EdTech = "EdTech",
  FinTech = "FinTech",
  FoodTech = "FoodTech",
  Gaming = "Gaming",
  GreenTech = "GreenTech",
  HealthTech = "HealthTech",
  Hospitality = "Hospitality",
  HRTech = "HRTech",
  InsurTech = "InsurTech",
  LegalTech = "LegalTech",
  MADTech = "MADTech",
  MarketingTech = "MarketingTech",
  Marketplace = "Marketplace",
  PropTech = "PropTech",
  PRTech = "PRTech",
  RetailTech = "RetailTech",
  TravelTech = "TravelTech",
  Wellbeing = "Wellbeing",
}

export const BaseSectorsArr = Object.values(BaseSectors);
