import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { ExportStructure } from "server/services/export";
import { ExportType } from "server/services/export/consts";

export const createExport = async (
  type: ExportType,
  body: { expiresAt?: string | null; structure: ExportStructure[] },
) => {
  try {
    const res = await ipAxios.post<{ url: string }>(`/admin/exports/${type}`, {
      expiresAt: body.expiresAt,
      structure: body.structure,
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
