import { usePreloadedState } from "@wearenova/use-sce";
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from "react";
import { LeanApplication } from "server/services/application";
import useAuthContext from "./auth";
import { Action, ActionMap, ProviderValue } from "./types";

interface State {
  readonly userApp: LeanApplication | null;
  readonly clientApp: LeanApplication | null;
}

export enum AppActions {
  SetUserApp = "SET_USER_APPLICATION",
  SetClientApp = "SET_CLIENT_APPLICATION",
  ClearApps = "CLEAR_APPLICATIONS",
}

interface ActionPayloadMap {
  [AppActions.SetUserApp]: LeanApplication;
  [AppActions.SetClientApp]: LeanApplication;
  [AppActions.ClearApps]: undefined;
}

const DEFAULT_VALUE = {
  userApp: null,
  clientApp: null,
} as const;

const AppContext = React.createContext<ProviderValue<State, ActionPayloadMap>>({
  ...DEFAULT_VALUE,
  dispatch: () => null,
});
AppContext.displayName = "AppContext";

const ACTION_MAP: ActionMap<State, ActionPayloadMap> = {
  [AppActions.SetUserApp]: (state, action) => ({ ...state, userApp: action.payload }),
  [AppActions.SetClientApp]: (state, action) => ({ ...state, clientApp: action.payload }),
  [AppActions.ClearApps]: () => ({ ...DEFAULT_VALUE }),
};

const AppReducer: Reducer<State, Action<ActionPayloadMap, keyof ActionPayloadMap>> = (state, action) => {
  if (!ACTION_MAP[action.type]) return state;
  return ACTION_MAP[action.type](state, action as any);
};

export const AppProvider: React.FC = (props) => {
  const preloadedApps = usePreloadedState<{ userApp?: LeanApplication; clientApp?: LeanApplication }>();
  const { user } = useAuthContext();
  const [state, dispatch] = useReducer(AppReducer, {
    ...DEFAULT_VALUE,
    userApp: preloadedApps?.userApp ?? DEFAULT_VALUE.userApp,
    clientApp: preloadedApps?.clientApp ?? DEFAULT_VALUE.clientApp,
  });

  useEffect(() => {
    if (!user) dispatch({ type: AppActions.ClearApps, payload: undefined });
  }, [user]);

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

const useAppContext = () => {
  return useContext(AppContext);
};

export default useAppContext;
