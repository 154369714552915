import { IFAFeeLimits } from "server/services/fund";
import { ClientFee } from "server/services/user";
import { FeeType, UserType } from "server/services/user/consts";

const getFixedLimit = (clientFee: ClientFee, fundLimit: number | null) => {
  if (clientFee.type !== FeeType.Fixed) return fundLimit;
  return Math.min(clientFee.amount, fundLimit ?? Infinity);
};

const getPercentageLimit = (clientFee: ClientFee, fundLimit: number) => {
  if (clientFee.type !== FeeType.Percentage) return fundLimit;
  return Math.min(clientFee.amount, fundLimit);
};

export const getFeeLimits = (userType: UserType, clientFee: ClientFee | null, fundLimits: IFAFeeLimits) => {
  if (userType !== UserType.Advised && userType !== UserType.Referred) return null;
  if (!clientFee) return null;
  const fundLimit = (userType === UserType.Referred && fundLimits.referred) || fundLimits.default;
  return {
    percentage: getPercentageLimit(clientFee, fundLimit.percentage),
    fixed: getFixedLimit(clientFee, fundLimit.fixed),
  };
};

export const calculateFee = ({
  totalAmount,
  userType,
  clientFee,
  fundLimits,
}: {
  totalAmount: number;
  userType: UserType;
  clientFee?: ClientFee | null;
  fundLimits?: IFAFeeLimits;
}) => {
  if (!fundLimits || !clientFee) return null;
  const limits = getFeeLimits(userType, clientFee, fundLimits);
  if (!limits) return null;
  const maxPercentageFee = (totalAmount * limits.percentage) / 100;
  return Math.min(maxPercentageFee, limits.fixed ?? Infinity);
};
