import { Button, ButtonProps, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import useHelperText from "client/hooks/useHelperText";
import { FieldProps } from "formik";
import React, { PropsWithChildren, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import TrackInput, { type TrackInputProps } from "../TrackInput";

interface Option {
  value: any;
  label: string;
}

interface ButtonSelectorProps<T extends Option[]>
  extends FieldProps<T[number]["value"]>,
    Pick<ButtonProps, "variant">,
    Omit<TrackInputProps<any>, "variant"> {
  label: string;
  options: T;
  row?: boolean;
  helperText?: string;
}

const useStyles = makeStyles({ name: "ButtonSelector" })((theme) => ({
  selectorsContainer: {
    margin: theme.spacing(0.625, 0),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
  },
  active: {
    pointerEvents: "none",
  },
  inactive: {
    backgroundColor: theme.palette.action.disabledBackground,
    "&:hover": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

const ButtonSelector = <T extends Option[]>({
  field,
  form: { setFieldValue },
  options: optionsProp,
  label,
  row,
  category,
  helperText: helperTextProp,
  ...props
}: PropsWithChildren<ButtonSelectorProps<T>>) => {
  const { classes } = useStyles();
  const { hasError, helperText } = useHelperText(field.name, helperTextProp);

  const options = useMemo(
    () => optionsProp.map((option) => ({ ...option, onChange: () => setFieldValue(field.name, option.value) })),
    [field.name, optionsProp, setFieldValue],
  );

  return (
    <TrackInput error={hasError} category={category} name={field.name} value={field.value}>
      <FormLabel>{label}</FormLabel>
      <FormGroup row={row} className={classes.selectorsContainer}>
        {options.map((option) => (
          <Button
            key={option.value}
            className={field.value === option.value ? classes.active : classes.inactive}
            onClick={option.onChange}
            {...props}
            data-testid={option.value}
          >
            {option.label}
          </Button>
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default ButtonSelector;
