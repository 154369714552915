import { Button, ButtonGroup } from "@mui/material";
import { ColumnDefinition, OnChangeObject, SelectOption, TableCellEditHandler } from "@wearenova/mui-data-table";
import useSCEffect from "@wearenova/use-sce";
import { Axios } from "axios";
import { getRoles } from "client/api/admin/roles";
import { getUsers } from "client/api/admin/users";
import { inviteUser, loginAsUser, patchUser, skipCoolOff } from "client/api/admin/users/user";
import useAdminContext, { AdminActions } from "client/context/admin";
import useAuthContext, { AuthActions } from "client/context/auth";
import notifications from "client/utils/notifications";
import _ from "lodash";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { LeanRole } from "server/services/role";
import { AdminUser } from "server/services/user";
import { CertificationType, UserStatus } from "server/services/user/consts";
import { singleLineAddress } from "shared/utils/address";
import DateTime from "shared/utils/DateTime";
import AdminTable from "../components/AdminTable";
import MainPanelContainer from "../components/MainPanelContainer";
import createTableDefinition from "../utils/createTableDefinition";
import UserForm from "./Form";
import SentryRoutes from "client/components/SentryRoutes";
import ExportLinkForm from "../components/ExportLinkForm";
import { ExportType } from "server/services/export/consts";

const Users: React.FC = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(-1);
  const { users, roles, dispatch: adminDispatch } = useAdminContext();
  const { user, dispatch: authDispatch } = useAuthContext();

  const usersArr = useMemo(() => Object.values(users), [users]);
  const roleOptions = useMemo(
    () =>
      Object.values(roles)
        .filter((role): role is Required<LeanRole> => Boolean(role.type))
        .map<SelectOption>((role) => ({ label: role.type, value: role._id })),
    [roles],
  );

  const setUsers = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getUsers(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      adminDispatch({ type: AdminActions.SetUsers, payload: res.data });
      setCount(res.count);
      return res.data;
    },
    [adminDispatch],
  );

  useSCEffect(
    async (axios?: Axios) => {
      const res = await getRoles.call(axios);
      if (res) adminDispatch({ type: AdminActions.SetRoles, payload: res.data });
      return res;
    },
    [adminDispatch],
    "roles",
  );

  const usersTable = useMemo<ColumnDefinition<AdminUser>[]>(
    () =>
      createTableDefinition([
        {
          key: "fullName",
          title: "Full Name",
          dataIndex: "fullName",
          hidden: true,
        },
        {
          key: "forenames",
          title: "Forenames",
          dataIndex: "forenames",
          editable: true,
        },
        {
          key: "surname",
          title: "Surname",
          dataIndex: "surname",
          editable: true,
        },
        {
          key: "email",
          title: "Email",
          dataIndex: "email",
          editable: true,
        },
        {
          key: "meta.emailConfirmed",
          title: "Confirmed?",
          dataIndex: "meta.emailConfirmed",
          dataType: "boolean",
          editable: true,
        },
        {
          key: "contactNumber",
          title: "Contact Number",
          dataIndex: "personalDetails.contactNumber",
          editable: true,
        },
        {
          key: "advisorFullName",
          title: "Adviser Full Name",
          dataIndex: "advisor.user.fullName",
        },
        {
          key: "advisorEmail",
          title: "Adviser Email",
          dataIndex: "advisor.user.email",
        },
        {
          key: "address",
          title: "Address",
          render: (record, isCSVExport) => {
            if (!record.personalDetails?.addressHistory?.[0]) return null;
            // if we're exporting to CSV, only export the user's latest address
            if (isCSVExport) return singleLineAddress(record.personalDetails.addressHistory[0]);
            return record.personalDetails.addressHistory.map(({ line1, line2, city, country, postcode }, index) => (
              <Fragment key={index}>
                {index > 0 && <hr />}
                <address>
                  {[line1, line2, city, country, postcode].filter(Boolean).map((line, idx) => (
                    <Fragment key={idx}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </address>
              </Fragment>
            ));
          },
          sorter: "personalDetails.addressHistory.line1",
          filterColumn: "personalDetails.addressHistory.line1",
        },
        {
          key: "role",
          title: "Role",
          dataIndex: "role.type",
          editable: {
            path: "role",
            type: "select",
            selectOptions: roleOptions,
          },
        },
        {
          key: "certification",
          title: "Certification",
          dataIndex: "certification.type",
          editable: {
            path: true,
            type: "select",
            selectOptions: Object.values(CertificationType),
          },
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
          editable: {
            path: "status",
            type: "select",
            selectOptions: Object.values(UserStatus),
          },
        },
        {
          key: "meta.registeredAt",
          title: "Registration Date",
          dataIndex: "meta.registeredAt",
          dataType: "date",
          render: (record) => DateTime.parse(record.meta.registeredAt).toFormat("dd/MM/yyyy"),
          editable: true,
        },
        {
          key: "cashBalanceTotal",
          title: "Total Balance",
          dataIndex: "cashBalance.total",
          numerical: true,
        },
        {
          key: "cashBalanceSeis",
          title: "SEIS Balance",
          dataIndex: "cashBalance.seis",
          numerical: true,
        },
        {
          key: "cashBalanceEis",
          title: "EIS Balance",
          dataIndex: "cashBalance.eis",
          numerical: true,
        },
        {
          key: "meta.inviteRequired",
          title: "Invite user",
          sorter: "meta.inviteRequired",
          filterColumn: { path: "meta.inviteRequired", type: "boolean" },
          render: (record) =>
            record.meta.inviteRequired && (
              <Button
                disabled={user?.isReadOnly}
                variant="text"
                size="small"
                color="primary"
                onClick={async (e) => {
                  e.stopPropagation();
                  const res = await inviteUser(record._id);
                  if (res) {
                    notifications.success("Email sent!");
                    adminDispatch({ type: AdminActions.UpdateUser, payload: res });
                  }
                }}
              >
                Invite
              </Button>
            ),
        },
        {
          title: "Min Investment",
          key: "meta.minInvestment",
          dataIndex: "meta.minInvestment",
          numerical: true,
          editable: true,
        },
        {
          title: "Max Investment",
          key: "meta.maxInvestment",
          dataIndex: "meta.maxInvestment",
          numerical: true,
          editable: true,
        },
        {
          key: "advisorVerified",
          title: "Verified Adviser?",
          sorter: "advisorDetails.isVerified",
          filterColumn: { path: "advisorDetails.isVerified", type: "boolean" },
          render: (record) =>
            typeof record.advisorDetails?.isVerified === "undefined" ? null : String(record.advisorDetails?.isVerified),
        },
        // {
        //   key: "Investor Report",
        //   title: "Investor Report",
        //   render: (record) => (
        //     <Button
        //       variant="text"
        //       size="small"
        //       onClick={async (e) => {
        //         e.stopPropagation();
        //         const data = await getUserPortfolio(record._id);
        //         if (!data) {
        //           notifications.error("Couldn't retrieve portfolio for this user.");
        //           return;
        //         }
        //         exportUserPortfolio(record._id, data);
        //         notifications.success("Portfolio exported!");
        //       }}
        //     >
        //       Download
        //     </Button>
        //   ),
        // },
        {
          key: "login",
          title: "Login as User",
          render: (record) => (
            <Button
              variant="text"
              size="small"
              color="primary"
              disabled={user?.isReadOnly}
              onClick={async (e) => {
                e.stopPropagation();
                const res = await loginAsUser(record._id);
                if (res) {
                  authDispatch({ type: AuthActions.SetUser, payload: res });
                  navigate("/apply/funds");
                }
              }}
            >
              Login
            </Button>
          ),
        },
        {
          key: "skipCool-off",
          title: "Skip Cool-off",
          render: (record) =>
            record.assessmentDetails?.status === "cooldown-success" ? (
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={async (e) => {
                  e.stopPropagation();
                  const res = await skipCoolOff(record._id);
                  if (res) {
                    notifications.success("Cool-off period skipped successfully");
                    adminDispatch({
                      type: AdminActions.UpdateUser,
                      payload: { ...record, assessmentDetails: { status: "completed-successfully" } },
                    });
                  }
                }}
              >
                Skip
              </Button>
            ) : null,
        },
      ]),
    [adminDispatch, authDispatch, navigate, roleOptions, user?.isReadOnly],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminUser>>(
    async ({ path, value }, record) => {
      const res = await patchUser(record._id, _.set({}, path, value === "null" ? null : value));
      if (res) adminDispatch({ type: AdminActions.UpdateUser, payload: res });
    },
    [adminDispatch],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);

  return (
    <>
      <MainPanelContainer>
        <AdminTable
          onChange={setUsers}
          count={count}
          tableData={usersArr}
          tableStructure={usersTable}
          rowClick={(record) => navigate(`./${record._id}/edit`)}
          tableProps={{ size: "small" }}
          exportToCSVOption
          csvFilename="Users"
          defaultSort={{ key: "meta.registeredAt", direction: "desc" }}
          onEdit={handleTableEdit}
        />
        <ButtonGroup variant="text">
          <Button disabled={!user || user?.isReadOnly} onClick={() => navigate("./create")}>
            Add User
          </Button>
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
      </MainPanelContainer>
      <SentryRoutes>
        <Route path="create" element={<UserForm open onClose={handleDialogClose} />} />
        <Route path=":userId/edit" element={<UserForm open onClose={handleDialogClose} />} />
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Users}
              data={usersArr}
              structure={usersTable}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default Users;
