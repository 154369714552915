import { AxiosInstance } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { UserDetails } from "server/services/user";

export async function getUserDetails(this: AxiosInstance | undefined) {
  try {
    const res = await (this || ipAxios).get<UserDetails>("/user/details");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
