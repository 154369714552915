import useAuthContext from "client/context/auth";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const UnimplementedRoutes: React.FC = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  if (!user?.isAdvised && !user?.isCertified) {
    return <Navigate to="/certify" state={{ from: location }} replace />;
  }
  return <Navigate to="/apply/funds" replace />;
};

export default UnimplementedRoutes;
