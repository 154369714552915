import { Link, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import RiskWarning from "../../../components/RiskWarning";

const RiskBanner: React.FC = () => {
  const riskWarningRef = useRef<() => void>(null);

  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          backgroundColor: "error.main",
          color: "error.contrastText",
          position: "fixed",
          width: "100%",
          pt: 2,
          pb: 2,
          zIndex: 1101,
        }}
      >
        <RiskWarning ref={riskWarningRef}>
          <Typography align="center">
            <b> Don&apos;t invest unless you&apos;re prepared to lose all your money.</b>
            <br />
            <b>This is a high-risk investment and you are unlikely to be protected if something goes wrong.</b>
            <br />
            <Link onClick={() => riskWarningRef.current?.()} color="inherit">
              Take 2 mins to learn more
            </Link>
          </Typography>
        </RiskWarning>
      </Paper>
    </>
  );
};
// pt: 2, pb: 2
export default RiskBanner;
