import { AlertColor } from "@mui/material";
import * as Sentry from "@sentry/react";
import "rc-notification/assets/index.css";
import { errorToMessage } from "./errors";
import notifications from "./notifications";

const Severities: readonly Sentry.SeverityLevel[] = ["fatal", "error", "warning", "log", "info", "debug"];

interface LoggerOptions {
  duration?: number;
  quiet?: boolean;
}
interface LoggerFunction {
  /**
   * Browser logger function.
   *
   * **The logger will also display a notification unless you set `quiet` to `true`**
   */
  (error: any, ...meta: Array<Record<string, unknown> & Partial<LoggerOptions>>): void;

  /**
   * Browser logger function.
   *
   * **The logger will also display a notification unless you set `quiet` to `true`**
   */
  (error: any, message: string, ...meta: Array<Record<string, unknown> & Partial<LoggerOptions>>): void;
}

const LevelAlertColorMap: { [key in Sentry.SeverityLevel]: AlertColor } = {
  fatal: "error",
  error: "error",
  warning: "warning",
  log: "info",
  info: "info",
  debug: "info",
};

const DisableSentry: { [key in Sentry.SeverityLevel]?: boolean } = {
  log: true,
  info: true,
  warning: true,
};

const getException = (error?: any, message?: string | null) => {
  if (error && message) return Object.assign(new Error(), error, { message });
  if (error) return error;
};

const process = ({
  error,
  message,
  level,
  ...meta
}: { error?: any; message?: string | null; level: Sentry.SeverityLevel } & Record<string, unknown>) => {
  const exception = getException(error, message);
  if (DisableSentry[level]) return;
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setContext("meta", meta);
    if (exception) return Sentry.captureException(exception);
    if (message) return Sentry.captureMessage(message, level);
  });
};

const logger: LoggerFunction = function (this: { level: Sentry.SeverityLevel }, error, message, ...meta) {
  const {
    duration = 1.5,
    quiet,
    ...mergedMeta
  } = meta.reduce((metaAcc, curr) => ({ ...metaAcc, ...curr }), typeof message === "string" ? {} : message || {});
  process({
    level: this.level,
    error,
    message: (typeof message === "string" && message) || null,
    ...mergedMeta,
  });

  if (quiet) return;
  notifications[LevelAlertColorMap[this.level]]((typeof message === "string" && message) || errorToMessage(error), {
    duration,
  });
};

/**
 * Browser logger.
 *
 * **Each function will also display a notification unless you set `quiet` to `true`**
 */
const Logging = Severities.reduce(
  (prev, level) => ({ ...prev, [level]: logger.bind({ level }) }),
  {} as { [key in Sentry.SeverityLevel]: LoggerFunction },
);

export default Logging;
