import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanFund } from "server/services/fund";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getAdminFunds<F extends LiteralUnion<keyof LeanFund, string>[] | undefined = undefined>(
  this: ThisAxios,
  queryParams: AdminQuery<LeanFund, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<LeanFund, F[number]>[] : LeanFund[]
    >("/admin/funds", { params: queryParams });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
