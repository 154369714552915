import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";

export const sendFundsReceivedEmail = async (appId: string) => {
  try {
    await ipAxios.post<void>("/admin/emails/funds-received/send", { appId: appId });
    return true;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
