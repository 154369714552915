import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { AxiosInstance } from "axios";
import { getPortfolios } from "client/api/admin/portfolios";
import { SelectOption } from "client/components/Fields/SelectField";
import { ObjectIdLike } from "mongoose";
import { useState } from "react";

export interface PortfolioOption extends Exclude<SelectOption, string> {
  fund: ObjectIdLike;
}

const usePortfolioOptions = () => {
  const { portfolioOptions: preloadedPortfolioOptions } = usePreloadedState<{ portfolioOptions?: PortfolioOption[] }>();
  const [portfolioOptions, setPortfolioOptions] = useState<PortfolioOption[]>(preloadedPortfolioOptions || []);

  useSCEffect(
    async (axios?: AxiosInstance) => {
      const res = await getPortfolios.call(axios, { fields: ["_id", "name", "fund"] });
      if (!res) return;
      const options = res.data.map<PortfolioOption>((p) => ({
        value: p._id,
        label: p.name,
        fund: p.fund as ObjectIdLike,
      }));
      setPortfolioOptions(options);
      return options;
    },
    [],
    "portfolioOptions",
  );

  return portfolioOptions;
};

export default usePortfolioOptions;
