import React from "react";
import { Link } from "@mui/material";
import useConfigContext from "client/context/config";

interface TwoFASupportLinkProps {
  linkType?: "primary" | "secondary";
}

const TwoFASupportLink: React.FC<TwoFASupportLinkProps> = ({ linkType }) => {
  const { portal } = useConfigContext();
  let href = portal?.links?.twoFAGuideLinks.primary;
  let text = "How to access your one time passcode";
  if (linkType === "secondary") {
    href = portal?.links?.twoFAGuideLinks.secondary;
    text = "I need help with 2FA";
  }
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      variant="body2"
      align="center"
      sx={{ marginTop: "16px" }}
    >
      {text}
    </Link>
  );
};

export default TwoFASupportLink;
