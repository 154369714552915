import { ButtonGroup, Button } from "@mui/material";
import { ColumnDefinition, OnChangeObject } from "@wearenova/mui-data-table";
import { getHoldings } from "client/api/admin/holdings";
import SentryRoutes from "client/components/SentryRoutes";
import useAdminContext, { AdminActions } from "client/context/admin";
import React, { useCallback, useMemo, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { ExportType } from "server/services/export/consts";
import { AdminHolding } from "server/services/transaction";
import AdminTable from "../components/AdminTable";
import ExportLinkForm from "../components/ExportLinkForm";
import MainPanelContainer from "../components/MainPanelContainer";
import createTableDefinition from "../utils/createTableDefinition";

const Subscriptions: React.FC = () => {
  const navigate = useNavigate();
  const { holdings, dispatch } = useAdminContext();
  const [count, setCount] = useState(-1);

  const holdingsArr = useMemo(() => Object.values(holdings), [holdings]);

  const setHoldings = useCallback(
    async (onChangeObject: OnChangeObject) => {
      const res = await getHoldings(onChangeObject);
      if (!res) return [];
      dispatch({ type: AdminActions.SetHoldings, payload: res.data });
      setCount(res.count);
      return res.data;
    },
    [dispatch],
  );

  const subscriptionsTable = useMemo(
    () =>
      createTableDefinition<ColumnDefinition<AdminHolding>>([
        {
          key: "companyName",
          title: "Company Name",
          dataIndex: "company.companyName",
        },
        {
          key: "brand",
          title: "Brand Name",
          dataIndex: "company.info.brandName",
        },
        {
          key: "companyNumber",
          title: "Company Number",
          dataIndex: "company.number",
        },
        {
          key: "portfolio",
          title: "Portfolio",
          dataIndex: "portfolio.name",
        },
        {
          key: "investor",
          title: "Investor",
          dataIndex: "investor",
        },
        {
          key: "settledAt",
          title: "Settle Date",
          dataType: "date",
          dataIndex: "settledAt",
        },
        {
          key: "tradedAt",
          title: "Trade Date",
          dataType: "date",
          dataIndex: "tradedAt",
        },
        {
          key: "shareClass",
          title: "Share Class",
          dataIndex: "shareClass.name",
        },
        {
          key: "sector",
          title: "Sector",
          dataIndex: "company.info.sector",
        },
        {
          key: "dealStage",
          title: "Deal Stage",
          dataIndex: "dealStage",
        },
        {
          key: "sharePrice",
          title: "Share Prices",
          colGroup: [
            {
              key: "sharePrice",
              title: "Purchase Price",
              dataIndex: "sharePrice",
              numerical: { path: true, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
            },
            {
              key: "currSharePrice",
              title: "Current Price",
              dataIndex: "currSharePrice",
              numerical: { path: true, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
            },
          ],
        },
        {
          key: "noShares",
          title: "Number of Shares",
          dataIndex: "noShares",
          filterColumn: { path: true, type: "number" },
        },
        {
          key: "cost",
          title: "Cost",
          numerical: "value",
          filterColumn: { path: "value", type: "number" },
        },
        {
          key: "value",
          title: "Value",
          numerical: "currentValue",
          filterColumn: { path: "currentValue", type: "number" },
        },
        {
          key: "change.value",
          title: "Change (£)",
          numerical: "change.value",
        },
        {
          key: "change.percent",
          title: "Change (%)",
          dataType: "number",
          dataIndex: "change.percentage",
          numerical: { path: true, currency: false, style: "percent" },
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
        },
        {
          key: "subscriber",
          title: "Subscriber",
          colGroup: [
            {
              key: "subscriber.name",
              title: "Full Name",
              dataIndex: "subscription.subscriber.fullName",
            },
            {
              key: "subscriber.email",
              title: "Email",
              dataIndex: "subscription.subscriber.email",
            },
          ],
        },
        {
          key: "applicationId",
          title: "Application ID",
          dataIndex: "subscription.application",
        },
        {
          key: "transactionId",
          title: "Transaction ID",
          dataIndex: "transactionId",
        },
        {
          key: "uir",
          title: "Unique Investment Reference (UIR)",
          dataIndex: "uir",
        },
      ]),
    [],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);
  return (
    <>
      <MainPanelContainer>
        <AdminTable
          tableStructure={subscriptionsTable}
          tableData={holdingsArr}
          onChange={setHoldings}
          count={count}
          exportToCSVOption
          csvFilename="Subscriptions"
          defaultSort={{ key: "settleDate", direction: "desc" }}
        />
        <ButtonGroup variant="text">
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
      </MainPanelContainer>
      <SentryRoutes>
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Subs}
              data={holdingsArr}
              structure={subscriptionsTable}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default Subscriptions;
