import { numberFormatter } from "@wearenova/mui-data-table";
import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getApplications } from "client/api/admin/applications";
import { SelectOption } from "client/components/Fields/SelectField";
import { useState } from "react";
import { AdminApplication, AdminAppUser } from "server/services/application";
import DateTime from "shared/utils/DateTime";

export interface AppOption extends Exclude<SelectOption, string>, Pick<AdminApplication, "fund"> {
  investor: AdminAppUser["user"];
}

const useAppOptions = () => {
  const { appOptions: preloadedAppOptions } = usePreloadedState<{ appOptions?: AppOption[] }>();
  const [appOptions, setAppOptions] = useState<AppOption[]>(preloadedAppOptions ?? []);

  useSCEffect(
    async (axios: Axios) => {
      const res = await getApplications.call(axios, {
        columnFilters: [{ type: "date", path: "completedAt", operator: "exists", value: true }],
        fields: ["_id", "investor.user", "fund", "completedAt", "data.investment.totalAmount"],
      });
      if (!res) return;
      const opts = (res.data as AdminApplication[]).map<AppOption>((app) => ({
        label: `${app.investor.user.fullName} - ${DateTime.parse(app.completedAt!).toFormat(
          "dd/MM/yyyy",
        )} - ${numberFormatter(app.data.investment.totalAmount)}`,
        value: app._id,
        investor: app.investor.user,
        fund: app.fund,
      }));
      setAppOptions(opts);
      return opts;
    },
    [],
    "appOptions",
  );

  return appOptions;
};

export default useAppOptions;
