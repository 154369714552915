import { Container } from "@mui/material";
import Image from "client/components/Image";
import useFundsContext from "client/context/funds";
import React from "react";

const LogoBanner: React.FC = () => {
  const { selected } = useFundsContext();
  return (
    <Container sx={{ display: "flex", justifyContent: "center", pb: 0 }} maxWidth="xs">
      {selected?.logo && <Image src={selected.logo} alt={selected.name} sx={{ maxWidth: 200 }} />}
    </Container>
  );
};

export default LogoBanner;
