export enum FundType {
  SEIS = "seis",
  EIS = "eis",
  LPVC = "lpvc",
  Other = "other",
}

export enum FeeRecipient {
  Fund = "fund",
  Client = "client",
}

export enum FundStatus {
  Open = "open",
  Closed = "closed",
}
