import Umbrella from "@mui/icons-material/BeachAccess";
import Lock from "@mui/icons-material/Lock";
import TrendingDown from "@mui/icons-material/TrendingDown";
import { Container, FormGroup, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import Divider from "client/components/Divider";
import { RadioOption } from "client/components/Fields/RadioField";
import FormButtons from "client/components/FormButtons";
import useAdvisorContext from "client/context/advisor";
import useAuthContext from "client/context/auth";
import useFundsContext from "client/context/funds";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { CheckboxConfig, ErrorsList, FormGenConfig, RadioConfig, SEISSplitConfig } from "form-gen";
import { FormikContextType } from "formik";
import { DateTime } from "luxon";
import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InvestmentDetails as InvestmentDetailsType } from "server/services/application";
import { InvestmentFrequency, InvestmentPolicies, PaymentMethod } from "server/services/application/consts";
import { LeanFund } from "server/services/fund";
import { ApplicationConfig } from "server/services/fund/model";
import { calculateFee } from "shared/utils/fees";
import { getMaxInvestment } from "shared/utils/investment";
import Yup from "shared/utils/Yup";
import RiskWarning from "../../components/RiskWarning";
import useApplication from "./hooks/useApplication";
import useAppStyles from "./hooks/useAppStyles";
import { Forms } from "./utils";
import { getLabel } from "./Objectives";

const DAYS_OF_MONTH = new Array(31).fill(0).map((_, i) => String(i + 1));

const WARNING_ITEMS = [
  {
    Icon: TrendingDown,
    text: (isAdvisor: boolean) => (
      <>
        <strong>What are the risks of Investing?</strong>
        {isAdvisor
          ? " Your client could lose all their money invested in this product."
          : " You could lose all your money invested in this product."}
      </>
    ),
  },
  {
    Icon: Umbrella,
    text: (isAdvisor: boolean) =>
      isAdvisor ? (
        <>
          <strong>Is my client&apos;s Investment protected?</strong> They may not have access to the Financial Services
          Compensation Scheme (FSCS) or Financial Ombudsman Service (FOS). Even if they are covered by FSCS or FOS, they
          will not receive compensation just because their investment performs badly.
        </>
      ) : (
        <>
          <strong>Is my Investment protected?</strong> You may not have access to the Financial Services Compensation
          Scheme (FSCS) or Financial Ombudsman Service (FOS). Even if you are covered by FSCS or FOS, you will not
          receive compensation just because your investment performs badly.
        </>
      ),
  },
  {
    Icon: Lock,
    text: (isAdvisor: boolean) =>
      isAdvisor ? (
        <>
          <strong>Can my client access their money if they need to?</strong> This is an illiquid investment - it might
          take them many years to take their money out, if ever.
        </>
      ) : (
        <>
          <strong>Can I access my money if I need to?</strong> This is an illiquid investment - it might take you many
          years to take your money out, if ever.
        </>
      ),
  },
] as const;

const Warning = ({ isAdvisor }: { isAdvisor: boolean }) => {
  const ref = useRef<() => void>(null);
  return (
    <>
      <List disablePadding>
        {WARNING_ITEMS.map(({ Icon, text }, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <Icon color="primary" fontSize="large" />
            </ListItemIcon>
            <ListItemText>{text(isAdvisor)}</ListItemText>
          </ListItem>
        ))}
      </List>
      <br />
      {!isAdvisor && (
        <RiskWarning ref={ref}>
          <Typography component="div">
            <Link onClick={() => ref.current?.()}>Why is this investment risky?</Link> This is your last chance to find
            out - it will only take 2 minutes.
          </Typography>
        </RiskWarning>
      )}
    </>
  );
};

const getCheckboxField = (
  config: Pick<CheckboxConfig<InvestmentDetailsType>, "name"> & Partial<CheckboxConfig<InvestmentDetailsType>>,
): CheckboxConfig<InvestmentDetailsType> => ({
  type: "checkbox",
  props: { sx: { mt: 1, mb: 1 } },
  validation: Yup.boolean()
    .typeError("Please check to agree")
    .oneOf([true], "Please check to agree")
    .required("Please check to agree"),
  ...config,
});

const handleFrequencyChange = (
  formik: FormikContextType<any>,
  { appConfig, value }: { appConfig: ApplicationConfig | undefined; value: string },
) => {
  if (!appConfig?.enableSelectSplit) formik.setFieldValue("split", null, false);
  formik.setFieldValue("frequency", value ?? "", false);
  formik.setFieldValue("oneOff", value !== InvestmentFrequency.OneOffPayment ? null : { date: null }, false);
  formik.setFieldValue(
    "monthlyPayments",
    value !== InvestmentFrequency.MonthlyInstalments ? null : { amount: "", noMonths: "", date: null },
    false,
  );
  formik.setFieldValue(
    "instalments",
    value !== InvestmentFrequency.MultipleInstalments ? null : [{ date: null, amount: "" }],
    false,
  );
  formik.setFieldValue("frequency", value);
};

const getFeeSection = (
  isAdvised: boolean,
  getFee?: (amount: number) => Falsy | number,
): FormGenConfig<InvestmentDetailsType> => {
  if (!isAdvised) return [];
  return [
    {
      type: "section",
      props: { sx: { margin: 0 } },
      title: (values) => {
        if (!values.totalAmount || !getFee) return null;
        const fee = getFee(values.totalAmount);
        if (typeof fee !== "number") return null;
        return <>IFA Charge: {numberFormatter(fee)}</>;
      },
    },
  ];
};

export const getInvestmentDetailsConfig = <T extends LeanFund>({
  fund,
  isAdvisor,
  getFee,
}: {
  fund: T | null;
  isAdvisor: boolean;
  getFee?: (amount: number) => Falsy | number;
}) => {
  const appConfig = fund?.applications;
  const maxInvestment = getMaxInvestment(appConfig?.maxInvestment, isAdvisor);
  const config: FormGenConfig<InvestmentDetailsType> = [
    ...(!appConfig?.enableMultiplePayments
      ? []
      : [
          {
            type: "radio",
            name: "frequency",
            label: "How frequently would you like to invest?",
            options: Object.values(InvestmentFrequency),
            get validation() {
              return Yup.string()
                .nullable()
                .oneOf(this.options as string[], "Please select an option")
                .required("Please select an option");
            },
            onChange(_e, value) {
              handleFrequencyChange(this, { appConfig, value });
            },
          } as RadioConfig<InvestmentDetailsType>,
        ]),
    {
      type: "section",
      condition: (values) => values.frequency === InvestmentFrequency.OneOffPayment,
      fields: [
        ...(!appConfig?.enableOneOffDate
          ? []
          : [
              {
                type: "date",
                name: "oneOff.date",
                label: "Enter the date you want to make this investment",
                props: {
                  disablePast: true,
                },
                validation: Yup.date()
                  .min(DateTime.now().startOf("day"), "Date cannot be in the past")
                  .typeError("Please enter a date")
                  .required("Please enter a date"),
              } as const,
            ]),
        {
          type: "number",
          name: "totalAmount",
          label: getLabel(
            isAdvisor,
            "Enter the amount your client wants to invest",
            "Enter the amount you want to invest",
          ),
          placeholder: "Enter here",
          props: { prefix: "£" },
          validation: Yup.number()
            .positive("Please enter a positive amount")
            .max(
              maxInvestment,
              `Maximum investment is ${numberFormatter(maxInvestment, { decimalPlaces: 2, currency: true })}`,
            )
            .typeError("Please enter a number")
            .required("Please enter a number"),
        },
      ],
    },
    {
      type: "number",
      name: "monthlyPayments.noMonths",
      label: "Please confirm the number of monthly instalments",
      placeholder: "Number of months",
      props: { disableMonetaryFormat: true, formatProps: { min: 12, step: 1, decimalScale: 0 } },
      condition: (values) => values.frequency === InvestmentFrequency.MonthlyInstalments,
      validation: Yup.number()
        .typeError("Please enter a number")
        .integer("Please enter an integer")
        .positive("Please enter a positive value")
        .min(12, "Minimum number of months is ${min}")
        .required("Please enter the number of monthly instalments"),
    },
    {
      type: "section",
      title: (
        <>
          Please provide <strong>one</strong> of the following two values:
        </>
      ),
      stringTitle: "Please provide one of the following two values:",
      condition: (values) => values.frequency === InvestmentFrequency.MonthlyInstalments,
      fields: [
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "number",
              name: "monthlyPayments.amount",
              label: "Monthly Investment",
              placeholder: "Enter Here",
              props: { prefix: "£" },
              validation: Yup.number()
                .positive("Please enter a positive amount")
                .typeError("Please enter a number")
                .required("Please enter a number"),
              onChange(_e, value) {
                this.setFieldValue("totalAmount", value && value * (this.values.monthlyPayments?.noMonths ?? 0));
                this.setFieldValue("monthlyPayments.amount", value);
              },
            },
            {
              type: "number",
              name: "totalAmount",
              label: "Total Investment",
              placeholder: "Enter Here",
              props: { prefix: "£" },
              validation: Yup.number()
                .typeError("Please enter a number")
                .positive("Please enter a positive amount")
                .required("Please enter a number"),
              onChange(_e, value) {
                this.setFieldValue(
                  "monthlyPayments.amount",
                  value && value / (this.values.monthlyPayments?.noMonths ?? 0),
                  false,
                );
                this.setFieldValue("totalAmount", value);
              },
            },
          ],
        },
        {
          type: "select",
          name: "monthlyPayments.date",
          label: "What day of the month do you plan to make the payment",
          placeholder: "Expected Date",
          options: DAYS_OF_MONTH,
          validation: Yup.number()
            .min(1)
            .max(31)
            .typeError("Please enter a day of the month")
            .required("Please enter a day of the month"),
        },
      ],
    },
    {
      type: "validation",
      validation: Yup.object({
        oneOff: Yup.object()
          .nullable()
          .when({
            is: (values: InvestmentDetailsType) => values?.frequency === InvestmentFrequency.OneOffPayment,
            then: Yup.object({}).label("One Off Payment").required("Please enter a date"),
          }),
        monthlyPayments: Yup.object()
          .nullable()
          .when({
            is: (values: InvestmentDetailsType) => values?.frequency === InvestmentFrequency.MonthlyInstalments,
            then: Yup.object({}).label("Monthly Instalments").required("Please provide details"),
          }),
      }),
    },
    {
      type: "instalments",
      name: "instalments",
      label: "Instalments",
      condition: (values) => values.frequency === InvestmentFrequency.MultipleInstalments,
      validation: Yup.array()
        .nullable()
        .when({
          is: (values: InvestmentDetailsType) => values?.frequency === InvestmentFrequency.MultipleInstalments,
          then: Yup.array(
            Yup.object({
              amount: Yup.number()
                .typeError("Please enter an amount for this instalment")
                .positive("Please enter a positive amount for this instalment")
                .required("Please enter an amount for this instalment"),
              date: Yup.date()
                .typeError("Please enter the date of this instalment")
                .min(DateTime.now().startOf("day").toJSDate(), "Please enter a valid date of this instalment")
                .required("Please enter the date of this instalment"),
            }),
          )
            .min(1, "Please add at least one instalment")
            .required("Please add at least one instalment"),
        }),
    },
    ...getFeeSection(isAdvisor, getFee),
    ...(!appConfig?.enableSelectPolicy
      ? []
      : [
          {
            type: "radio",
            name: "policy",
            label: "As per the investment memorandum please select one of the following investment policies:",
            options: [
              { label: "SEIS Only", value: InvestmentPolicies.SEIS },
              { label: "EIS Only", value: InvestmentPolicies.EIS },
              { label: "Mix of SEIS and EIS", value: InvestmentPolicies.Mixed },
            ],
            get validation() {
              return Yup.string()
                .nullable()
                .oneOf(
                  (this.options as Exclude<RadioOption, string>[]).map((o) => o.value as string),
                  "Please select an option",
                )
                .required("Please select an option");
            },
          } as RadioConfig<InvestmentDetailsType>,
        ]),
    ...(!appConfig?.enableSelectSplit
      ? []
      : [
          {
            type: "seisSplit",
            name: "split",
            condition: (values) => !appConfig?.enableSelectPolicy || values.policy === InvestmentPolicies.Mixed,
            validation: Yup.object()
              .nullable()
              .when({
                is: (values: InvestmentDetailsType) => values?.policy === InvestmentPolicies.Mixed,
                then: Yup.object({
                  seis: Yup.number()
                    .typeError("Please enter a percentage")
                    .min(0.0, "Minimum value is ${min}%")
                    .max(100.0, "Maximum value is ${max}%")
                    .required("Please enter a percentage"),
                  eis: Yup.number()
                    .typeError("Please enter a percentage")
                    .min(0.0, "Minimum value is ${min}%")
                    .max(100.0, "Maximum value is ${max}%")
                    .required("Please enter a percentage"),
                }),
              }),
          } as SEISSplitConfig<InvestmentDetailsType>,
        ]),
    { type: "divider" },
    {
      key: "warningSection",
      type: "section",
      title: <Warning isAdvisor={isAdvisor} />,
    },
    {
      type: "section",
      disableFormGroups: true,
      fields: [
        getCheckboxField({
          name: "understandsLoss",
          label: getLabel(
            isAdvisor,
            "Please tick this box to confirm that your client understands that they can lose all the money they are investing.",
            "Please tick this box to confirm that you understand that you can lose all the money you are investing.",
          ),
        }),
        getCheckboxField({
          name: "understandsLackOfProtection",
          label: getLabel(
            isAdvisor,
            "Please tick this box to confirm that your client understand that they may not be protected by the FSCS or FOS if their investment in this fund fails.",
            "Please tick this box to confirm that you understand that you may not be protected by the FSCS or FOS if your investment in this fund fails.",
          ),
        }),
      ],
    },
    { type: "divider" },
    {
      type: "radio",
      name: "waivesCancellationPeriod",
      label: "Please select one option for the declaration below:",
      props: { optionLabelProps: { variant: "body2" } },
      options: [
        {
          label: getLabel(
            isAdvisor,
            "I confirm that my client wishes to waive their 14-day cancellation period and the Investment Manager may make an investment on their behalf during their 14-day cancellation period",
            "I confirm that I wish to waive my 14-day cancellation period and the Investment Manager may make an investment on my behalf during my 14-day cancellation period",
          ),
          value: true,
        },
        {
          label: getLabel(
            isAdvisor,
            "I confirm that my client does not wish to waive their 14-day cancellation period and the Investment Manager may not make an investment on their behalf during their 14-day cancellation period.",
            "I confirm that I do not wish to waive my 14-day cancellation period and the Investment Manager may not make an investment on my behalf during my 14-day cancellation period.",
          ),
          value: false,
        },
      ],
      validation: Yup.boolean().nullable().required("Please select an option"),
    },
    {
      type: "radio",
      name: "paymentMethod",
      label: "Please select your method of payment:",
      options: [
        { label: "Bank transfer", value: PaymentMethod.Bank },
        { label: "Cheque/bankers' draft", value: PaymentMethod.Cheque },
      ],
      validation: Yup.string()
        .nullable()
        .oneOf(Object.values(PaymentMethod), "Please select an option")
        .required("Please select an option"),
    },
    {
      type: "section",
      condition: (values) => values.paymentMethod === PaymentMethod.Bank,
      title: (
        <>
          Once your application has been approved, you can then bank transfer funds using the details below:
          <br />
          <br />
          <strong>Bank Name:</strong> {fund?.paymentDetails.bank.name}
          <br />
          <strong>Sort code:</strong> {fund?.paymentDetails.bank.sortCode}
          <br />
          <strong>Account number:</strong> {fund?.paymentDetails.bank.accountNumber}
          <br />
          <strong>Account name:</strong> {fund?.paymentDetails.bank.accountName}
          <br />
          <strong>Reference:</strong> {fund?.paymentDetails.bank.reference}
          {fund?.paymentDetails.bank.iban && (
            <>
              <br />
              <strong>IBAN:</strong> {fund?.paymentDetails.bank.iban}
            </>
          )}
        </>
      ),
    },
    {
      type: "section",
      condition: (values) => values.paymentMethod === PaymentMethod.Cheque,
      title: (
        <>
          Once your application has been approved, please send a cheque from your personal account, make payable to
          &apos;{fund?.paymentDetails.cheque.payable}&apos;. We do not accept cheques from business accounts.
          Bankers&apos; drafts and building society cheques must specifically mention the investor&apos;s name.
          <br />
          <br />
          <strong>Address</strong>: {fund?.paymentDetails.cheque.address}
        </>
      ),
    },
    ...(!isAdvisor
      ? []
      : ([
          {
            type: "section",
            disableFormGroups: true,
            title: (
              <>
                Confirmation of Verification of Identity (COVI) Declaration
                <br />
                <small>
                  Click{" "}
                  <Link
                    href="https://invest-portal-v2-public.s3.eu-west-2.amazonaws.com/*/COVI+Template.docx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </Link>{" "}
                  to download the template
                </small>
              </>
            ),
          },
          {
            type: "file",
            name: "coviDeclaration",
            label: "Please upload the completed COVI Declaration below or continue and you can provide it later.",
            placeholder: "COVI Declaration",
            validation: Yup.string().nullable(),
          },
        ] as FormGenConfig<InvestmentDetailsType>)),
    {
      type: "section",
      disableFormGroups: true,
      props: {},
      fields: (
        [
          ...(isAdvisor
            ? []
            : [
                {
                  name: "noFinancialAdvice",
                  label: `Please tick this box to confirm that you have not received financial advice from ${
                    fund?.manager.companyDetails?.name ?? fund?.manager.name
                  }.`,
                },
              ]),
          {
            name: "hasReadIM",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that both you and your client have read and understood the Information Memorandum, Investment Management Agreement and Key Information Document and understood the investment objectives of the fund which are consistent with your client's personal investment objectives and personal risk profile.",
              "Please tick this box to confirm you have read and understood the Information Memorandum, Investment Management Agreement and Key Information Document and understood the investment objectives of the fund which are consistent with your personal investment objectives and personal risk profile.",
            ),
          },
          {
            name: "understandsCustodyBound",
            label: getLabel(
              isAdvisor,
              "Please check this box to confirm that your client understands that they will become bound by the terms of the Custody Agreement and Investment Management Agreement upon execution of the application form.",
              "Please check this box to confirm you understand that you will become bound by the terms of the Custody Agreement and Investment Management Agreement upon execution of the application form.",
            ),
          },
          {
            name: "understandsDiversifyInvestmentPortfolio",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client understands the need to diversify investment portfolios across asset classes.",
              "Please tick this box to confirm that you understand the need to diversify investment portfolios across asset classes.",
            ),
          },
          {
            name: "understandsNoTaxGuarantee",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client understands that there is no guarantee that the tax efficient status of the investment will be obtained or remain.",
              "Please tick this box to confirm that you understand that there is no guarantee that the tax efficient status of the investment will be obtained or remain.",
            ),
          },
          {
            name: "understandsNoSecondaryMarket",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client understands that there is no secondary market for this investment and that the investment may not be able to be realised early, at market value, or at all (i.e., they cannot get your money back whenever they want) and that they are investing for the long term (5-10 years or more) and do not require income or access to the capital invested in this period.",
              "Please tick this box to confirm that you understand that there is no secondary market for this investment and that the investment may not be able to be realised early, at market value, or at all (i.e., you cannot get your money back whenever you want) and that you are investing for the long term (5-10 years or more) and do not require income or access to the capital invested in this period.",
            ),
          },
          {
            name: "understandsHighRiskIlliquid",
            label: isAdvisor ? (
              <>
                Please tick this box to confirm that both you and your client have read the risk factors set out in the
                Information Memorandum and understand that this is a high risk, illiquid, speculative investment and
                that there is significant risk that they will lose <strong>ALL</strong> capital invested and they may
                not have access to any compensation.
              </>
            ) : (
              <>
                Please tick this box to confirm that you have read the risk factors set out in the Information
                Memorandum and understand that this is a high risk, illiquid, speculative investment and that there is
                significant risk that you will lose <strong>ALL</strong> capital invested and you may not have access to
                any compensation.
              </>
            ),
            stringLabel: getLabel(
              isAdvisor,
              "Please tick this box to confirm that both you and your client have read the risk factors set out in the Information Memorandum and understand that this is a high risk, illiquid, speculative investment and that there is significant risk that they will lose ALL capital invested and they may not have access to any compensation.",
              "Please tick this box to confirm that you have read the risk factors set out in the Information Memorandum and understand that this is a high risk, illiquid, speculative investment and that there is significant risk that you will lose ALL capital invested and you may not have access to any compensation.",
            ),
          },
          {
            name: "understandsHeldOnTrust",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client acknowledges that their investments will be registered in the name of the nominee but will be held on trust by the nominee and they will remain the beneficial owner of the investments.",
              "Please tick this box to confirm that you acknowledge that your investments will be registered in the name of the nominee but will be held on trust by the nominee and you will remain the beneficial owner of the investments.",
            ),
          },
          {
            name: "understandsCarryBack",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that you understand that there is no guarantee that carry back tax relief (to the prior tax year) relating to income tax and capital gains tax will be available and that your client's ability to take advantage of any tax benefit this investment provides depends on their personal tax circumstances and is subject to change.",
              "Please tick this box to confirm that you understand that there is no guarantee that carry back tax relief (to the prior tax year) relating to income tax and capital gains tax will be available and that your ability to take advantage of any tax benefit this investment provides depends on your personal tax circumstances and is subject to change.",
            ),
          },
          {
            name: "acceptsRejectApplication",
            label: `Please tick this box to confirm that ${fund?.manager.name} reserves the right to accept or reject any application at its sole discretion.`,
          },
          {
            name: "acceptsCompaniesFail",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client understands that a significant number of early-stage unquoted companies fail before providing any return to investors.",
              "Please tick this box to confirm that you understand that a significant number of early-stage unquoted companies fail before providing any return to investors.",
            ),
          },
          {
            name: "acceptsCompaniesDifficultToValue",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client understands that early stage unquoted companies can be difficult to value, rarely pay dividends and that their holding may be diluted if the company raises additional equity at a later date (as is likely) and they do not invest further.",
              "Please tick this box to confirm that you understand that early stage unquoted companies can be difficult to value, rarely pay dividends and that your holding may be diluted if the company raises additional equity at a later date (as is likely) and you do not invest further.",
            ),
          },
          {
            name: "appointsManager",
            label: getLabel(
              isAdvisor,
              `Please tick this box to confirm that your client appoints ${fund?.manager.name} to exercise all voting and other rights in relation to or arising in connection with or otherwise attaching to their investment in this fund.`,
              `Please tick this box to confirm that you appoint ${fund?.manager.name} to exercise all voting and other rights in relation to or arising in connection with or otherwise attaching to your investment in this fund.`,
            ),
          },
          ...(fund?.applications.disableEmailOptOut
            ? []
            : [
                {
                  name: "wantsToBeContacted",
                  label: isAdvisor ? (
                    <>
                      Please tick this box if your client <strong>WANTS</strong> to receive fund updates, newsletters
                      and/or notifications of other opportunities.
                    </>
                  ) : (
                    <>
                      Please tick this box if you <strong>WANT</strong> to receive fund updates, newsletters and/or
                      notifications of other opportunities.
                    </>
                  ),
                  stringLabel: `${fund?.companyDetails.name} and ${
                    fund?.manager.companyDetails?.name ?? fund?.manager.name
                  } may from time to time contact investors with news updates. Please tick this box to confirm that you WANT to receive such updates.`,
                  validation: Yup.boolean(),
                },
              ]),
          {
            name: "willRetainCapital",
            label: getLabel(
              isAdvisor,
              "Please tick this box to confirm that your client will retain sufficient capital in cash or readily realisable cash investments as an emergency fund in the event there is a total loss of all of their investment in this fund.",
              "Please tick this box to confirm that you will retain sufficient capital in cash or readily realisable cash investments as an emergency fund in the event there is a total loss of all of your investment in this fund.",
            ),
          },
          {
            name: "acknowledgesClosingDates",
            label: getLabel(
              isAdvisor,
              `Please tick this box to confirm that your client acknowledges and accepts that if the fund has multiple closing dates at  ${fund?.manager.name}'s discretion, they may not be invested in all investee companies that the fund ultimately invests in.`,
              `Please tick this box to confirm that you acknowledge and accept that if the fund has multiple closing dates at ${fund?.manager.name}'s discretion, you may not be invested in all investee companies that the fund ultimately invests in.`,
            ),
          },
          {
            name: "understandsCertificateTiming",
            label: getLabel(
              isAdvisor,
              `Please tick this box to confirm that your client understands that ${fund?.manager.name} cannot control the timing of the issue of the SEIS and/or EIS compliance certificates.`,
              `Please tick this box to confirm that you understand that ${fund?.manager.name} cannot control the timing of the issue of the SEIS and/or EIS compliance certificates.`,
            ),
          },
          {
            name: "authorisesElectronicVerification",
            label: getLabel(
              isAdvisor,
              `Please tick this box to confirm that your client authorises ${fund?.manager.name} to use an electronic verification service provided by a third-party to check AML, etc.`,
              `Please tick this box to confirm that you authorise ${fund?.manager.name} to use an electronic verification service provided by a third-party to check AML, etc.`,
            ),
          },
          {
            name: "managerProvidesInfo",
            label: getLabel(
              isAdvisor,
              `Please tick this box to confirm that your client acknowledges that ${fund?.manager.name} is required by the FCA rules to provide information to them and such information may be provided by means of ${fund?.manager.name}'s website.`,
              `Please tick this box to confirm that you acknowledge that ${fund?.manager.name} is required by the FCA rules to provide information to you and such information may be provided by means of ${fund?.manager.name}'s website.`,
            ),
          },
        ] as Array<{ name: keyof InvestmentDetailsType; label: ReactNode; stringLabel?: string }>
      ).map(getCheckboxField),
    },
  ];
  return config;
};

const InvestmentDetails: React.FC = () => {
  const { application, handleAppDataPatch, isAdmin, isAdvisor } = useApplication();
  useAppRedirect(isAdvisor);
  const { classes } = useAppStyles();
  const navigate = useNavigate();
  const { selected: selectedFund } = useFundsContext();
  const { client } = useAdvisorContext();
  const { user } = useAuthContext();
  const sectionText = isAdvisor ? "Section 4 of 4" : "Section 5 of 5";
  const initialValues = useMemo(
    () =>
      ({
        ...(isAdmin ? application?.data.investment : {}),
        ...(selectedFund?.applications.enableMultiplePayments
          ? {}
          : { frequency: InvestmentFrequency.OneOffPayment, monthlyPayments: null, instalments: null }),
      } as InvestmentDetailsType),
    [application?.data.investment, isAdmin, selectedFund?.applications.enableMultiplePayments],
  );

  const config = useMemo(
    () =>
      getInvestmentDetailsConfig({
        fund: selectedFund,
        isAdvisor: isAdvisor,
        getFee: (totalAmount: number) =>
          client?.fee &&
          calculateFee({
            totalAmount,
            userType: client?.type,
            clientFee: client?.fee,
            fundLimits: selectedFund?.applications.ifaFees,
          }),
      }),
    [client?.fee, client?.type, isAdvisor, selectedFund],
  );
  const goBackRoute = isAdvisor === false || user?.isReferred ? `../${Forms.Experience}` : `../${Forms.Finances}`;

  const goBack = useCallback(() => navigate(goBackRoute), [navigate, goBackRoute]);

  const handleFormSubmit = useCallback(
    async (values: InvestmentDetailsType) => {
      const res = await handleAppDataPatch({ investment: values });
      if (res) navigate(user?.isReferred ? `../${Forms.Fee}` : `../${Forms.Agreement}`);
    },
    [handleAppDataPatch, navigate, user?.isReferred],
  );

  return (
    <>
      <Container maxWidth="md">
        <FormGroup>
          <Typography variant="h4">
            <strong>{sectionText}:</strong>
          </Typography>
          <Typography variant="h4" paragraph>
            About the investment
          </Typography>
        </FormGroup>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <FormGen
          config={config}
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          classes={{ formGroup: classes.formGroup }}
          enableReinitialize
        >
          {({ errors, submitCount }) => (
            <>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} />
                </FormGroup>
              )}
              <FormGroup>
                <FormButtons primaryProps={{ label: "Finish" }} secondaryProps={{ label: "Back", onClick: goBack }} />
              </FormGroup>
            </>
          )}
        </FormGen>
      </Container>
    </>
  );
};

export default InvestmentDetails;
