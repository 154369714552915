import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AdminHolding } from "server/services/transaction";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getHoldings<F extends LiteralUnion<keyof AdminHolding, string>[] | undefined = undefined>(
  this: ThisAxios,
  onChangeObject?: AdminQuery<AdminHolding, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<AdminHolding, F[number]>[] : AdminHolding[]
    >("/admin/holdings", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
