import axios, { Axios, AxiosError } from "axios";
import qs from "qs";

export type ThisAxios = Axios | void;

const ipAxios = axios.create({
  baseURL: "/api",
  paramsSerializer: qs.stringify,
  withCredentials: true,
});

export const isAxiosError = <T = any, D = any>(error?: any): error is AxiosError<T, D> => Boolean(error?.isAxiosError);

export default ipAxios;
