import { Box, Container } from "@mui/material";
import Markdown from "client/components/Markdown";
import { useConfigContext } from "client/context/config";
import React, { Fragment, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import RiskBanner from "../Application/components/RiskBanner";

const useStyles = makeStyles({ name: "Info" })((theme) => ({
  normalLogo: {
    width: "300px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "180px",
    },
  },
  infoContent: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    minHeight: "100vh",
    paddingTop: "100px",
  },
  bodyContent: {
    "& .MuiTypography-root": {
      color: theme.palette.primary.contrastText,
    },
    "& hr": {
      borderColor: theme.palette.primary.contrastText,
    },
  },
  sectionB: {
    "& .MuiTypography-root": {
      textAlign: "center",
      padding: "3.5em 6em 3.5em 6em",
      [theme.breakpoints.down("sm")]: {
        padding: "3em 1em 3em 1em",
      },
    },
  },
}));

const Info: React.FC = () => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();

  const renderSection = useCallback(
    (section: string[] = []) =>
      section.map((item, index) => (
        <Fragment key={index}>
          <hr />
          <Markdown>{item.toString()}</Markdown>
        </Fragment>
      )),
    [],
  );

  return (
    <div>
      <RiskBanner />
      <div className={classes.infoContent}>
        {/* header */}
        <Container>{/* logo */}</Container>

        {/* body */}
        <Container className={classes.bodyContent}>
          {/* splash logo */}
          {portal.home.content.header && (
            <Box
              sx={(theme) => ({
                pb: 2,
                maxWidth: 625,
                "& > .MuiTypography-root": {
                  textTransform: "uppercase",
                  fontSize: "4em",
                  fontWeight: theme.typography.fontWeightBlack,
                },
              })}
            >
              <Markdown>{portal.home.content.header}</Markdown>
            </Box>
          )}

          {/* section A */}
          <div>{renderSection(portal.home.content.sectionA)}</div>

          {/* section B */}
          <div className={classes.sectionB}>{renderSection(portal.home.content.sectionB)}</div>
        </Container>
      </div>
    </div>
  );
};

export default Info;
