import { Link as MUILink, LinkProps as MUILinkProps } from "@mui/material";
import React from "react";
import { Link as RRDLink, LinkProps as RRDLinkProps, To } from "react-router-dom";

type ToProp = { to: To; href?: never } | { href: string; to?: To };

export type RouterLinkProps = Omit<MUILinkProps, "href"> & Omit<RRDLinkProps, "to"> & ToProp;

const RouterLink = React.forwardRef(function _RouterLink(
  { href, ...props }: RouterLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
) {
  return <MUILink {...props} to={props.to || href!} component={RRDLink} ref={ref} />;
});

export default RouterLink;
