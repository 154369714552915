import useSCEffect from "@wearenova/use-sce";
import { AxiosInstance } from "axios";
import { getUserDetails } from "client/api/user";
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from "react";
import { UserState } from "server/services/user";
import useAuthContext from "./auth";
import { Action, ActionMap, ProviderValue } from "./types";

interface State extends UserState {}

export const DEFAULT_USER_DATA: Omit<ProviderValue<State, ActionPayloadMap>, "dispatch"> = {
  details: {},
  balances: {
    fundsRemaining: { eis: 0, seis: 0, total: 0 },
    get fundsReceived() {
      return { ...this.fundsRemaining };
    },
  },
  holdings: null,
  historicValues: [],
  statement: [],
  applications: [],
  advisor: {},
};

export enum UserActions {
  SetData = "SET_DATA",
  ClearData = "CLEAR_DATA",

  SetDetails = "SET_DETAILS",

  SetBalances = "SET_BALANCES",
  SetHoldings = "SET_HOLDINGS",
  SetHistoricValues = "SET_HISTORIC_VALUES",

  SetStatement = "SET_STATEMENT",

  SetApplications = "SET_APPLICATIONS",

  SetAdvisor = "SET_ADVISOR",
}

interface ActionPayloadMap {
  [UserActions.SetData]: Omit<UserState, "clients">;
  [UserActions.ClearData]: undefined;
  [UserActions.SetDetails]: UserState["details"];
  [UserActions.SetBalances]: UserState["balances"];
  [UserActions.SetHoldings]: UserState["holdings"];
  [UserActions.SetHistoricValues]: UserState["historicValues"];
  [UserActions.SetStatement]: UserState["statement"];
  [UserActions.SetApplications]: UserState["applications"];
  [UserActions.SetAdvisor]: UserState["advisor"];
}

interface UserAction<K extends keyof ActionPayloadMap> extends Action<ActionPayloadMap, K> {}

const UserContext = React.createContext<ProviderValue<State, ActionPayloadMap>>({
  ...DEFAULT_USER_DATA,
  dispatch: () => null,
});
UserContext.displayName = "UserContext";

const ACTION_MAP: ActionMap<State, ActionPayloadMap> = {
  [UserActions.SetData]: (state, action) => ({ ...state, ...action.payload }),
  [UserActions.ClearData]: () => ({ ...DEFAULT_USER_DATA }),

  [UserActions.SetDetails]: (state, action) => ({ ...state, details: action.payload }),

  [UserActions.SetBalances]: (state, action) => ({ ...state, balances: action.payload }),
  [UserActions.SetHoldings]: (state, action) => ({ ...state, holdings: action.payload }),
  [UserActions.SetHistoricValues]: (state, action) => ({ ...state, historicValues: action.payload }),

  [UserActions.SetStatement]: (state, action) => ({ ...state, statement: action.payload }),

  [UserActions.SetApplications]: (state, action) => ({ ...state, applications: action.payload }),

  [UserActions.SetAdvisor]: (state, action) => ({ ...state, advisor: action.payload }),
};

const reducer: Reducer<State, UserAction<keyof ActionPayloadMap>> = (state, action) => {
  if (!ACTION_MAP[action.type]) return state;
  return ACTION_MAP[action.type](state, action as any);
};

export const UserProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_USER_DATA);
  const { user } = useAuthContext();

  useEffect(() => {
    if (!user) dispatch({ type: UserActions.ClearData, payload: undefined });
  }, [user]);

  useSCEffect(
    async (axios?: AxiosInstance) => {
      if (!user) return;
      const res = await getUserDetails.call(axios);
      if (res) dispatch({ type: UserActions.SetDetails, payload: res });
    },
    [user],
  );

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

const useUserContext = () => {
  return useContext(UserContext);
};

export default useUserContext;
