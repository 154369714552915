import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getOpenFunds } from "client/api/funds";
import React, { Reducer, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LeanFund } from "server/services/fund";
import { Action, ActionMap, ProviderValue } from "./types";

interface State {
  readonly funds: ReadonlyArray<LeanFund>;
  readonly selected: LeanFund | null;
}

export enum FundsActions {
  SetFunds = "SET_FUNDS",
  SetFund = "SET_FUND",
  ClearFunds = "CLEAR_FUNDS",
}

interface ActionPayloadMap {
  [FundsActions.SetFunds]: LeanFund[];
  [FundsActions.SetFund]: LeanFund;
  [FundsActions.ClearFunds]: undefined;
}

const DEFAULT_VALUE = {
  funds: [],
  selected: null,
} as const;

const FundsContext = React.createContext<ProviderValue<State, ActionPayloadMap>>({
  ...DEFAULT_VALUE,
  dispatch: () => null,
});
FundsContext.displayName = "FundsContext";

const ACTION_MAP: ActionMap<State, ActionPayloadMap> = {
  [FundsActions.SetFunds]: (state, action) => ({ ...state, funds: action.payload }),
  [FundsActions.SetFund]: (state, action) => ({ ...state, selected: action.payload }),
  [FundsActions.ClearFunds]: () => ({ ...DEFAULT_VALUE }),
};

const FundsReducer: Reducer<State, Action<ActionPayloadMap, keyof ActionPayloadMap>> = (state, action) => {
  if (!ACTION_MAP[action.type]) return state;
  return ACTION_MAP[action.type](state, action as any);
};

export const FundsProvider: React.FC = (props) => {
  const funds = usePreloadedState<{ funds: LeanFund[] }, "funds">("funds");
  const { fundId } = useParams<{ fundId?: string }>();
  const [state, dispatch] = React.useReducer(FundsReducer, { ...DEFAULT_VALUE }, (s) => {
    if (!funds) return s;
    return { ...s, funds, selected: (fundId && funds?.find((f) => f.urlId === fundId)) || DEFAULT_VALUE.selected };
  });

  useSCEffect(
    async (axios: Axios) => {
      const res = await getOpenFunds(axios);
      if (!res) return [];
      dispatch({ type: FundsActions.SetFunds, payload: res });
      return res;
    },
    [],
    "funds",
  );

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <FundsContext.Provider value={value}>{props.children}</FundsContext.Provider>;
};

const useFundsContext = () => {
  return useContext(FundsContext);
};

export default useFundsContext;
