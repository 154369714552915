import { FormControl, FormControlProps, SxProps, Theme } from "@mui/material";
import EVENTS from "client/utils/events.consts";
import clsx from "clsx";
import { noCase } from "no-case";
import React, { useCallback, useMemo } from "react";

const USEFUL_PROPS = [
  "color",
  "disabled",
  "error",
  "focused",
  "fullWidth",
  "hiddenLabel",
  "margin",
  "required",
  "size",
  "variant",
] as const;

export interface TrackInputProps<V = unknown> extends Pick<FormControlProps, typeof USEFUL_PROPS[number]> {
  category?: string;
  name?: string;
  value?: V;
  sx?: SxProps<Theme>;
  className?: string;
}

const TrackInput: React.FC<TrackInputProps> = ({ children, category, name, value, sx = [], className, ...props }) => {
  const usefulProps = useMemo<Partial<FormControlProps>>(() => {
    const res = {} as Partial<FormControlProps>;
    USEFUL_PROPS.forEach(<K extends typeof USEFUL_PROPS[number]>(key: K) => {
      res[key] = props[key];
    });
    return res;
  }, [props]);

  const handleBlur = useCallback(() => {
    if (typeof window === "undefined" || !window.dataLayer || !value || !category || !name) return;
    window.dataLayer.push({ event: EVENTS.INPUT.COMPLETED }, { category, label: noCase(name) });
  }, [category, name, value]);

  return (
    <FormControl
      onBlurCapture={handleBlur}
      {...usefulProps}
      sx={[{ display: "inline" }, ...(Array.isArray(sx) ? sx : [sx])]}
      className={clsx(className, "fs-exclude")}
    >
      {children}
    </FormControl>
  );
};

export default TrackInput;
