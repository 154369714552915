import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Container, Link, Typography } from "@mui/material";
import useConfigContext from "client/context/config";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { PortalSocials } from "server/services/portals";
import { makeStyles } from "tss-react/mui";
import Divider from "./Divider";
import Image from "./Image";
import Markdown from "./Markdown";

const IconMap = {
  facebook: <FacebookIcon />,
  linkedIn: <LinkedInIcon />,
  twitter: <TwitterIcon />,
  youtube: <YouTubeIcon />,
};

const useStyles = makeStyles({ name: "Footer" })((theme) => ({
  containers: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      width: "100%",
      "&:not(first-of-type)": { marginTop: theme.spacing(2) },
    },
  },
  dividers: {
    borderColor: theme.palette.secondary.light,
  },
  linkContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
}));

const Footer: React.FC = () => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();

  const socials = useMemo(() => {
    if (!portal.links.socials) return null;
    return (Object.entries(portal.links.socials) as Array<[social: keyof PortalSocials, link: string]>).filter(
      ([, link]) => Boolean(link),
    );
  }, [portal.links.socials]);

  return (
    <Container
      maxWidth={false}
      sx={{
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
        margin: 0,
        "&&": { pb: 0 },
        "& > *": {
          pt: 2,
          pb: 2,
        },
      }}
    >
      <Container className={classes.containers}>
        <div>
          <Markdown>{portal.footer}</Markdown>
        </div>
      </Container>
      <div>
        <Divider className={classes.dividers} fullWidth />
      </div>
      <Container className={classes.containers}>
        <Box sx={{ maxWidth: 250 }}>
          <Image src={portal.logos.secondary ?? portal.logos.primary} alt="Logo" />
        </Box>
        {Boolean(socials?.length) && (
          <Box sx={{ "&&": { width: "unset" } }}>
            {socials!.map(([social, link]) => (
              <Link key={social} href={link} color="inherit" target="_blank" rel="noopener noreferrer">
                {IconMap[social] || social}
              </Link>
            ))}
          </Box>
        )}
      </Container>
      <div>
        <Divider className={classes.dividers} fullWidth />
      </div>
      <Container
        maxWidth={false}
        sx={{ display: "flex", justifyContent: "space-between", paddingBottom: { xs: "58px", md: "40px" } }}
      >
        <Typography variant="caption">
          © {portal.meta.copyright} {DateTime.now().get("year")}
        </Typography>
        <div className={classes.linkContainer}>
          {portal.links.tsAndCs && (
            <Link
              href={portal.links.tsAndCs}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              align="center"
              variant="caption"
            >
              Terms and Conditions
            </Link>
          )}
          &nbsp;
          {portal.links.privacyPolicy && (
            <Link
              href={portal.links.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              align="center"
              variant="caption"
            >
              Privacy Policy
            </Link>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default Footer;
