import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";

export const sendEmailConfirmation = async () => {
  try {
    await ipAxios.post("/emails/email-confirmation/send");
    return true;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
