import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { CompanySector } from "server/services/company";
import { BaseSectors } from "server/services/company/consts";

export async function getCompanySectors(this: ThisAxios) {
  try {
    const res = await (this || ipAxios).get<CompanySector[]>("/companies/sectors");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return Object.values(BaseSectors);
  }
}
