import { DialogContentText } from "@mui/material";
import ResponsiveDialog from "client/components/ResponsiveDialog";
import React from "react";

interface WhyQuestionsProps {
  open: boolean;
  onClose: () => void;
}

const WhyQuestions: React.FC<WhyQuestionsProps> = (props) => {
  return (
    <ResponsiveDialog
      open={props.open}
      closeButton={{ onClick: props.onClose }}
      title="Why do we ask all the questions below?"
      actionButtons={[{ label: "Done", onClick: props.onClose }]}
    >
      <DialogContentText
        color="textPrimary"
        sx={{
          textAlign: {
            md: "justify",
          },
        }}
      >
        This fund, like all SEIS and/or EIS funds, will act as a group of individual bare trusts held separately in the
        name of the fund nominee with each investor being the sole beneficiary of each individual bare trust under a
        nominee arrangement. You will enter into such an agreement with Sapphire Capital Partners LLP (“Sapphire”).
        Sapphire is authorised and regulated by the Financial Conduct Authority (“FCA”).
        <br />
        <br />
        Sapphire is required by FCA rules to obtain sufficient information from you to determine whether investment in
        this fund is appropriate for you. The FCA requires that Sapphire considers information you provide us regarding
        your knowledge and experience in the investment field relevant to the specific type of product or service
        offered or demanded, so as to enable Sapphire to assess whether the service or product envisaged is appropriate
        for you, and to give us reasonable assurance in light of the nature of investments in funds such as this fund,
        that you are capable of making your own investment decisions and understanding the risks involved.
        <br />
        <br />
        There are three main areas we need information from you in order to make this decision:
        <br />
        <br />
        (a) The types of service, transaction and investments with which you are familiar;
        <br />
        <br />
        (b) The nature, volume, frequency of your previous relevant investments and the period over which they have been
        carried out; and
        <br />
        <br />
        (c) Your level of education, profession or relevant former profession.
        <br />
        <br />
        <br />
        Sapphire must assess that you have the necessary experience and knowledge in order to understand the risks
        involved in the management of your portfolio. To enable us to do this, please complete the following questions.
        Additional information may be required.
        <br />
        <br />
        Because Sapphire offers an investment management service in relation to higher risk, early-stage unquoted equity
        investments and will not be responsible for managing any element of your portfolio that is not dedicated to this
        type of investment, Sapphire will only make this assessment when you commit money to the fund. However, if your
        financial situations or investment objectives change during the course of your Investment Management Agreement
        with Sapphire, you must immediately inform us so that we can assess whether investments of this kind remain
        suitable for you.
        <br />
        <br />
        Investments in the Fund are intended to be medium to long term and must be held for at least three years to
        qualify for SEIS and/or EIS Relief. Investment in unquoted companies carries a higher risk than investing in
        shares quoted on public exchanges such as the London Stock Exchange. Any investment should be made on the basis
        that it is likely to be at least five years before there is any prospect of it being realised. A ten-year
        timescale or longer is probably more realistic. You should be comfortable with this time horizon and acknowledge
        that you will not need any income or capital back during this time period.
        <br />
        <br />
        You must therefore only consider committing an amount of money to this fund that you can afford to lose.
        <br />
        <br />
        To summarise, Sapphire offers an investment management service only in relation to higher risk, early stage,
        unquoted equity investments. We will not take an overview of your financial situation beyond the information you
        give us here and will not provide you with any advice. If you would like advice on any elements of your
        financial situation, or you are in any doubt about investing, please seek advice from an appropriately
        authorised financial adviser before proceeding. Sapphire does not offer such advice and cannot assess whether
        this investment is suitable for your needs.
        <br />
        <br />
        <strong>
          Please note that it is your responsibility to confirm that the risks and benefits of this investment are
          appropriate for your investment needs, objectives and risk profile.
        </strong>
      </DialogContentText>
    </ResponsiveDialog>
  );
};

export default WhyQuestions;
