import { Button, ButtonProps } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

const FormButton: React.FC<ButtonProps> = (props) => {
  const { isSubmitting } = useFormikContext();
  return <Button {...props} disabled={props.disabled || isSubmitting} />;
};

export default FormButton;
