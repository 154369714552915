import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { createPortfolio } from "client/api/admin/portfolios";
import { deletePortfolio, updatePortfolio } from "client/api/admin/portfolios/portfolio";
import { SelectOption } from "client/components/Fields/SelectField";
import useAdminContext, { AdminActions } from "client/context/admin";
import useFundsContext from "client/context/funds";
import FormGen, { ErrorsList, FormGenConfig } from "form-gen";
import { FormikConfig } from "formik";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LeanPortfolio } from "server/services/portfolio";
import Yup from "shared/utils/Yup";
import CRUDButtons from "../components/CRUDButtons";

interface PortfolioFormProps extends DialogProps {
  portfolio?: LeanPortfolio | null;
  onClose(): void;
}

export const getPortfolioFormConfig = (funds: SelectOption[] = []): FormGenConfig<LeanPortfolio> => [
  {
    type: "text",
    name: "name",
    label: "Name",
    placeholder: "Name",
    validation: Yup.string().required("Please provide a name for this portfolio"),
  },
  {
    type: "text",
    name: "nominee",
    label: "Nominee",
    placeholder: "Nominee",
    validation: Yup.string().required("Please indicate the nominee for this portfolio"),
  },
  {
    type: "select",
    name: "fund",
    label: "Fund",
    placeholder: "Fund",
    options: funds,
    get validation() {
      if (!funds.length) return Yup.string().required("Please select a fund");
      return Yup.string()
        .oneOf(funds.map((o) => (typeof o === "string" ? o : o.value)))
        .required("Please select a fund");
    },
  },
];

const Form: React.FC<PortfolioFormProps> = ({ onClose, open, portfolio, ...props }) => {
  const { portfoliosId } = useParams<{ portfoliosId: string }>();
  const { dispatch, portfolios } = useAdminContext();
  const { funds } = useFundsContext();

  const adminPortfolio = useMemo(() => portfolio || portfolios[portfoliosId!], [portfoliosId, portfolio, portfolios]);

  const portfolioFormConfig = useMemo(
    () => getPortfolioFormConfig(funds.map((f) => ({ label: f.name, value: f._id }))),
    [funds],
  );

  const initialValues = useMemo(() => {
    if (!adminPortfolio) return;
    return { ...adminPortfolio };
  }, [adminPortfolio]);

  const handleSubmit = useCallback<FormikConfig<LeanPortfolio>["onSubmit"]>(
    async (values) => {
      const res = await (!adminPortfolio ? createPortfolio(values) : updatePortfolio(adminPortfolio._id, values));
      if (!res) return;
      dispatch({ type: AdminActions.UpdatePortfolio, payload: res });
      onClose();
    },
    [dispatch, onClose, adminPortfolio],
  );

  const handleDelete = useCallback(async () => {
    if (!adminPortfolio) return;
    const res = await deletePortfolio(adminPortfolio._id);
    if (!res) return;
    dispatch({ type: AdminActions.RemovePortfolio, payload: res });
    onClose();
  }, [dispatch, onClose, adminPortfolio]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth {...props}>
      <DialogTitle>{adminPortfolio ? `Edit ${adminPortfolio.name}` : "Create Portfolio"}</DialogTitle>
      <DialogContent>
        <FormGen
          config={portfolioFormConfig}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          castRequiredSchema
        >
          <ErrorsList />
          <CRUDButtons onDelete={handleDelete} onCancel={onClose} />
        </FormGen>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
