import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { AxiosInstance } from "axios";
import { getUsers } from "client/api/admin/users";
import { SelectOption } from "client/components/Fields/SelectField";
import { useState } from "react";
import { UserType } from "server/services/user/consts";

interface UserOption extends Exclude<SelectOption, string> {
  type: UserType;
}

const useUserOptions = () => {
  const { userOptions: preloadedUserOptions } = usePreloadedState<{ userOptions: UserOption[] }>();
  const [userOptions, setUserOptions] = useState<UserOption[]>(preloadedUserOptions || []);

  useSCEffect(
    async (axios?: AxiosInstance) => {
      const res = await getUsers.call(axios, { fields: ["_id", "forenames", "surname", "email", "type"] });
      if (!res) return;
      const options = res.data.map((u) => ({
        value: u._id,
        label: `${u.forenames} ${u.surname} - ${u.email}`,
        type: u.type,
      }));
      setUserOptions(options);
      return options;
    },
    [],
    "userOptions",
  );

  return userOptions;
};

export default useUserOptions;
