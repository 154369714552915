import { AxiosInstance } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { DocumentDefinition } from "mongoose";
import { LeanPortfolio } from "server/services/portfolio";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getPortfolios<F extends LiteralUnion<keyof LeanPortfolio, string>[] | undefined = undefined>(
  this: AxiosInstance | void,
  onChangeObject?: AdminQuery<LeanPortfolio, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<LeanPortfolio, F[number]>[] : LeanPortfolio[]
    >("/admin/portfolios", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createPortfolio = async (portfolio: DocumentDefinition<LeanPortfolio>) => {
  try {
    const res = await ipAxios.post<LeanPortfolio>("/admin/portfolios", portfolio);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
