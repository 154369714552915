import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, FormGroup, FormLabel, IconButton } from "@mui/material";
import Divider from "client/components/Divider";
import { FieldArrayRenderProps, useFormikContext } from "formik";
import _ from "lodash";
import { singular } from "pluralize";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import Yup from "shared/utils/Yup";
import useFormGenContext from "../context";
import { AnyField, ArrayConfig, BaseValues } from "../types";
import BuildFields from "./BuildFields";

interface ArrayFieldProps<T extends BaseValues, V> {
  field: ArrayConfig<T, V>;
  helpers: FieldArrayRenderProps;
}

const ArrayField = <T extends BaseValues, V>({ field, helpers }: ArrayFieldProps<T, V>) => {
  const { schema } = useFormGenContext<T>();
  const formik = useFormikContext<T>();

  const arrValues = useMemo(() => _.get(formik.values, field.name, []) as BaseValues[], [field.name, formik.values]);

  const defaultValue = useMemo(() => {
    return (
      field.field?.initialValue ||
      field.initialValue?.[0] ||
      // @ts-expect-error Yup has incorrect types see https://github.com/jquense/yup#object-schema-defaults
      (Yup.reach(schema, `${field.name}[0]`) as Yup.AnyObjectSchema)?.default()
    );
  }, [field.field?.initialValue, field.initialValue, field.name, schema]);

  const debouncedAutomatic = useMemo(
    () => typeof field.automatic === "function" && _.debounce(field.automatic),
    [field.automatic],
  );

  const defaultPush = useCallback(
    () => helpers.push({ ...defaultValue, ...(field.onAdd?.() ?? {}) }),
    [defaultValue, field, helpers],
  );

  const singularisedLabel = useMemo(() => {
    if (typeof field.label !== "string" && !field.stringLabel) return;
    return singular(field.stringLabel ?? (field.label as string));
  }, [field.label, field.stringLabel]);

  useEffect(() => {
    if (!field.automatic) return;
    if (typeof debouncedAutomatic === "function") {
      debouncedAutomatic(formik.values, { ...helpers, push: (v) => helpers.push(v || defaultValue) });
      return;
    }
    if (!_.get(formik.values, field.name)?.length) {
      helpers.push(defaultValue);
    }
  }, [debouncedAutomatic, defaultValue, field, formik.values, helpers]);

  return (
    <>
      {(field.label || !field.automatic) && (
        <>
          <FormGroup row>
            {field.label && (
              <FormLabel required={field.props?.required}>
                <strong>{field.label}</strong>
              </FormLabel>
            )}
            {!field.automatic && (
              <IconButton onClick={defaultPush} edge="end">
                <AddIcon />
              </IconButton>
            )}
          </FormGroup>
          <Divider />
        </>
      )}
      {arrValues.map((_val, index) => (
        <Fragment key={index}>
          {!field.automatic && (
            <FormGroup row>
              <FormLabel>
                {singularisedLabel} {index + 1}
              </FormLabel>
              <IconButton onClick={() => helpers.remove(index)} edge="end">
                <RemoveIcon />
              </IconButton>
            </FormGroup>
          )}
          <BuildFields
            config={(field.fields || [field.field!]).map(
              (f) =>
                ({ ...f, name: [`${field.name}[${index}]`, ...(!f.name ? [] : [f.name])].join(".") } as AnyField<T>),
            )}
          />
          <FormGroup>
            <Divider />
          </FormGroup>
        </Fragment>
      ))}
      {!field.automatic && (
        <FormGroup sx={{ alignItems: "flex-end" }}>
          <Button onClick={defaultPush} endIcon={<AddIcon />} variant="text" size="small">
            Add {!arrValues.length ? "One" : "Another"}
          </Button>
        </FormGroup>
      )}
    </>
  );
};
ArrayField.whyDidYouRender = false;

export default ArrayField;
