import SentryRoutes from "client/components/SentryRoutes";
import { AdvisorProvider } from "client/context/advisor";
import useAuthContext from "client/context/auth";
import React from "react";
import { Navigate, Route } from "react-router-dom";
import Application from "../Application";
import AddClient from "./AddClient";
import Dashboard from "./Dashboard";
import Onboarding from "./Onboarding";

const Advisor: React.FC = () => {
  const { user } = useAuthContext();
  if (!user?.isAdvisor) {
    return <Navigate to="/dashboard" replace />;
  }
  return (
    <AdvisorProvider>
      <SentryRoutes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="onboarding" element={<Onboarding />} />
        <Route path="clients/create" element={<AddClient />} />
        <Route path="clients/:clientId/apply/*" element={<Application />} />
      </SentryRoutes>
    </AdvisorProvider>
  );
};

export default Advisor;
