export enum Forms {
  KeyInformation = "key-information",
  Funds = "funds",
  Begin = "begin",
  IdVerification = "id-verification",
  InvestorDetails = "details",
  Objectives = "objectives",
  Finances = "finances",
  Experience = "experience",
  Investment = "investment",
  Fee = "fee",
  Agreement = "agreement",
  Complete = "complete",
  Assessment = "assessment",
}
