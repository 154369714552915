import InfoIcon from "@mui/icons-material/Info";
import { Palette, SvgIcon, SvgIconProps } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CompanySector } from "server/services/company";

interface SectorIconProps extends SvgIconProps {
  sector?: CompanySector | null;
}

const SectorIconMap = {
  get agritech() {
    return import("@mui/icons-material/Agriculture");
  },
  get biotech() {
    return import("@mui/icons-material/Biotech");
  },
  get cleantech() {
    return import("@mui/icons-material/ElectricCar");
  },
  get commerce() {
    return import("client/assets/icons/sector-commerce.svg");
  },
  get edtech() {
    return import("client/assets/icons/sector-edtech.svg");
  },
  get fintech() {
    return import("client/assets/icons/sector-fintech.svg");
  },
  get foodtech() {
    return import("@mui/icons-material/RestaurantMenu");
  },
  get gaming() {
    return import("client/assets/icons/sector-gaming.svg");
  },
  get greentech() {
    return import("@mui/icons-material/SolarPower");
  },
  get healthtech() {
    return import("client/assets/icons/sector-healthtech.svg");
  },
  get hospitality() {
    return import("client/assets/icons/sector-hospitality.svg");
  },
  get hrtech() {
    return import("client/assets/icons/sector-hrtech.svg");
  },
  get legaltech() {
    return import("client/assets/icons/sector-legaltech.svg");
  },
  get marketplace() {
    return import("client/assets/icons/sector-marketplace.svg");
  },
  get proptech() {
    return import("@mui/icons-material/Apartment");
  },
  get retailtech() {
    return import("client/assets/icons/sector-retailtech.svg");
  },
  get traveltech() {
    return import("@mui/icons-material/ModeOfTravel");
  },
  get wellbeing() {
    return import("client/assets/icons/sector-wellbeing.svg");
  },
} as const;

const SectorColorMap: { [key in Lowercase<CompanySector>]?: keyof Palette } = {
  edtech: "alternate2",
  gaming: "alternate2",
  healthtech: "error",
  hospitality: "error",
  hrtech: "alternate2",
  legaltech: "alternate2",
  wellbeing: "error",
};

const SectorIcon: React.FC<SectorIconProps> = ({ sector, ...props }) => {
  const [iconColor, setIconColor] = useState<keyof Palette>("primary");
  const [SectorIcon, setSectorIcon] = useState<React.ElementType | null>(null);

  const commonProps = useMemo<SvgIconProps>(
    () => ({ ...props, sx: { color: `${iconColor}.main` } }),
    [iconColor, props],
  );

  useEffect(() => {
    (async () => {
      if (!sector) return;
      const lcSector: Lowercase<CompanySector> = sector.toLowerCase();
      setIconColor(SectorColorMap[lcSector] || "primary");
      const iconSrc = SectorIconMap[lcSector as keyof typeof SectorIconMap];
      if (!(await iconSrc)) return;
      const Icon = await iconSrc.then((value) => value.default).catch(() => null);
      setSectorIcon(() => Icon);
    })();
  }, [sector]);

  if (!SectorIcon) return <InfoIcon {...commonProps} />;
  return <SvgIcon {...commonProps} component={SectorIcon} inheritViewBox />;
};

export default SectorIcon;
