export enum TokenAuthStatus {
  Conflict = "conflict",
  Success = "success",
  Expired = "expired",
  Error = "error",
}
export enum RiskWarningStatus {
  READ = "risk warning read",
  ACKNOWLEDGED = "risk warning acknowledged",
}

export const TWO_FA_TEST_SECRET = "SbAuIRr1t9TQ3kttYDmfb2DwgtPmKg";
