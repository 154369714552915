import { FormGroup, Typography } from "@mui/material";
import Divider from "client/components/Divider";
import React from "react";

interface StatementProps {
  title: string;
}

const Statement: React.FC<StatementProps> = ({ title, ...props }) => {
  return (
    <>
      <Typography variant="body1" paragraph>
        <strong>Please confirm that the following statement is true by ticking the box below</strong>
      </Typography>
      <FormGroup>
        <Divider />
      </FormGroup>
      <FormGroup>
        <Typography variant="body1" gutterBottom>
          <strong>{title}</strong>
        </Typography>
        <Typography component="div" variant="body2">
          {props.children}
        </Typography>
      </FormGroup>
      <FormGroup>
        <Divider />
      </FormGroup>
    </>
  );
};

export default Statement;
