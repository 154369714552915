import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { UserFormType } from "client/views/AdminPanel/Users/Form";
import { AdminUser, LeanUser } from "server/services/user";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getUsers<F extends LiteralUnion<keyof LeanUser, string>[] | undefined = undefined>(
  this: ThisAxios,
  onChangeObject?: AdminQuery<AdminUser, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<LeanUser, F[number]>[] : AdminUser[]
    >("/admin/users", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createUser = async (data: UserFormType) => {
  try {
    const res = await ipAxios.post<AdminUser>(`/admin/users`, data);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
