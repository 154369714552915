import { ThreeToFiveYears, RiskLevels, NetWorthPercentages } from "server/services/application/consts";

const RecommendConfig = {
  data: {
    // Objectives
    "objectives.undestandsRisks": {
      true: 10,
      false: -Infinity,
    },
    "objectives.relyingOnInvestment": {
      true: -Infinity,
      false: 10,
    },
    "objectives.willingToLoseInvestment": {
      true: 10,
      false: -Infinity,
    },
    "objectives.deferCapitalGains": {
      true: -50,
      false: 10,
    },
    "objectives.whenNeedIncome": {
      [ThreeToFiveYears.NotWithinFive]: 10,
      [ThreeToFiveYears.ThreeToFive]: -Infinity,
      [ThreeToFiveYears.WithinThree]: -Infinity,
    },
    "objectives.whenNeedAccess": {
      [ThreeToFiveYears.NotWithinFive]: 10,
      [ThreeToFiveYears.ThreeToFive]: -Infinity,
      [ThreeToFiveYears.WithinThree]: -Infinity,
    },
    "objectives.receivedTaxAdvice": {
      // R
      true: 10,
      false: -50,
    },
    "objectives.capitalCommitments.present": {
      true: 0.3,
      false: 10,
    },

    // Finances
    "finances.netDisposableAbove": {
      true: 10,
      false: 0.3,
    },
    "finances.netAssetsAbove": {
      true: 10,
      false: 0.3,
    },

    // Experience
    // tax products if No to tax product or unquoted companies - review, tax product and unquoted - pass
    "experience.taxProducts.present": {
      true: 10,
      false: 0.3,
    },
    "experience.taxProducts.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.taxProducts.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.taxProducts.prevInvested": {
      true: 10,
      false: 0,
    },
    // large companies
    "experience.largeCompanies.present": {
      true: 10,
      false: 0,
    },
    "experience.largeCompanies.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.largeCompanies.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.largeCompanies.prevInvested": {
      true: 10,
      false: 0,
    },

    //small companies
    "experience.smallCompanies.present": {
      true: 10,
      false: 0,
    },
    "experience.smallCompanies.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.smallCompanies.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.smallCompanies.prevInvested": {
      true: 10,
      false: 0,
    },

    //aim companies
    "experience.aimCompanies.present": {
      true: 10,
      false: 0.3,
    },
    "experience.aimCompanies.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.aimCompanies.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.aimCompanies.prevInvested": {
      true: 10,
      false: 0,
    },

    //real estate
    "experience.realEstate.present": {
      true: 10,
      false: 0,
    },
    "experience.realEstate.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.realEstate.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.realEstate.prevInvested": {
      true: 10,
      false: 0,
    },

    // other sophisticated investments - need following yes
    "experience.otherSophisticatedInvestments.present": {
      true: 10,
      false: 0.3,
    },
    "experience.otherSophisticatedInvestments.noTimes": {
      "1": 1,
      "2-4": 10,
      "5-10": 10,
      "10+": 10,
    },
    "experience.otherSophisticatedInvestments.amount": {
      // R
      "Less than £5,000": 1,
      "£5,000 to £50,000": 10,
      "£50,000 to £100,000": 10,
      "£100,000 to £500,000": 10,
      "£500,000+": 10,
    },
    "experience.otherSophisticatedInvestments.prevInvested": {
      true: 10,
      false: 0,
    },

    // company director
    "experience.companyDirector.present": {
      true: 10,
      false: 0,
    },

    // management team
    "experience.managementTeam.present": {
      true: 10,
      false: 0,
    },

    // business angels
    "experience.businessAngels.present": {
      true: 10,
      false: 0,
    },

    // financial education
    "experience.financialEducation.present": {
      true: 10,
      false: 0,
    },

    // relevant profession
    "experience.relevantProfession.present": {
      true: 10,
      false: 0,
    },

    // levels of risk
    "experience.levelofRisk": {
      // Error for conservative to moderate - likely reject
      [RiskLevels.Conservative]: -Infinity,
      [RiskLevels.ModeratelyConservative]: -Infinity,
      [RiskLevels.Moderate]: -Infinity,
      [RiskLevels.ModeratelyAggressive]: 10,
      [RiskLevels.Aggressive]: 10,
    },

    // percentage of net worth
    "experience.percentageNetWorth": {
      [NetWorthPercentages.ZeroToFive]: 10,
      [NetWorthPercentages.SixToTen]: 10,
      [NetWorthPercentages.ElevenToTwenty]: -50,
      [NetWorthPercentages.TwentyOneToFifty]: -Infinity,
      [NetWorthPercentages.FiftyOnePlus]: -Infinity,
    },
  },

  investor: {
    "user.personalDetails.politicallyExposed.present": {
      true: 0.3,
      false: 10,
    },
  },

  // questions 4/5 pass 3/5 reject take again, 24 hours if fail,

  //confirmation check box to invest
  // pg 5 only after 24h
};

export default RecommendConfig;
