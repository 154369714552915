import { ObjectId } from "bson";
import { ObjectIdLike } from "mongoose";
import { MixedSchema } from "yup";

export class ObjectIdSchema extends MixedSchema {
  static create() {
    return new ObjectIdSchema();
  }

  constructor() {
    super();
    this.withMutation(() => {
      this.transform(function (value) {
        if (!value || typeof window !== "undefined" || value instanceof ObjectId) return value;
        return new ObjectId(value);
      });
    });
  }

  protected _typeCheck(value: any): value is ObjectIdLike {
    return ObjectId.isValid(value);
  }
}

export const objectId = ObjectIdSchema.create;

export * from "yup";
