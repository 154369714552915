import { AppBar, Button, Container, Link, Slide, Toolbar, useScrollTrigger } from "@mui/material";
import { logout } from "client/api/public";
import useAuthContext, { AuthActions } from "client/context/auth";
import useConfigContext from "client/context/config";
import clsx from "clsx";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import RouterLink from "../RouterLink";
import TouchNavbar from "./TouchNavbar";

const useStyles = makeStyles({ name: "InvestorNavbar" })((theme) => ({
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  navItem: {
    color: "inherit",
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightSemiBold,
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 2.5),
      "&:last-child": {
        paddingRight: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: theme.spacing(6.25),
    },
  },
  navBarItemBlack: {
    fontWeight: theme.typography.fontWeightBlack,
  },
  navHeight: {
    "@media print": {
      display: "none",
    },
  },
  navContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 0,
    paddingTop: 0,
  },
  normalNavItemsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const HideOnScroll: React.FC<{ children: ReactElement<any, any> }> = (props) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

const Navbar: React.FC = () => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();
  const { user, dispatch } = useAuthContext();
  const location = useLocation();

  const handleLogout = useCallback(async () => {
    const res = await logout();
    if (!res) return;
    dispatch({ type: AuthActions.ClearUser, payload: null });
  }, [dispatch]);

  const navItems = useMemo(() => {
    if (!user) {
      return (
        portal.links.alternateSite && (
          <Button href={portal.links.alternateSite} color="secondary">
            Go to website
          </Button>
        )
      );
    }
    const links: Array<
      { text: string; "data-testid"?: string } & ({ to: string; onClick?: never } | { onClick: () => void; to?: never })
    > = [];

    if (user.isAdmin) {
      links.push(
        { to: "/admin/users", text: "Admin Panel", "data-testid": "admin-panel" },
        { to: "/docs", text: "API Docs" },
        { to: "/apply/funds", text: "Apply", "data-testid": "apply" },
      );
    }
    if (user.isAdvisor) {
      links.push({ to: "/advisor/dashboard", text: "Adviser Dashboard" });
    }
    links.push({ text: user.adminUser ? "Back" : "Log out", "data-testid": "logout", onClick: handleLogout });

    return links.map(({ text, ...item }, idx) => {
      const linkProps = {
        ...item,
        key: item.to || item["data-testid"] || idx,
        variant: "h5",
        className: clsx(classes.navItem, {
          [classes.navBarItemBlack]: item.to && new RegExp(`^${item.to}`).test(location.pathname),
        }),
        children: text,
      } as const;
      return linkProps.to ? <RouterLink {...linkProps} /> : <Link {...linkProps} />;
    });
  }, [classes.navBarItemBlack, classes.navItem, handleLogout, location.pathname, portal.links.alternateSite, user]);

  return (
    <>
      <HideOnScroll>
        <AppBar color="primary" style={{ paddingTop: "125px" }}>
          <Toolbar className={classes.navHeight}>
            <Container className={classes.navContent}>
              <div className={classes.logoContainer}></div>
              <div className={classes.normalNavItemsContainer}>{navItems}</div>
              <TouchNavbar>{navItems}</TouchNavbar>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar className={classes.navHeight} style={{ paddingBottom: "60px" }} />
    </>
  );
};

export default Navbar;
