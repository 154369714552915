import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps as MUIDatePickerProps } from "@mui/x-date-pickers";
import useHelperText from "client/hooks/useHelperText";
import clsx from "clsx";
import { FieldProps } from "formik";
import { DateTime } from "luxon";
import React, { ReactNode, useCallback, useMemo } from "react";
import TrackInput, { TrackInputProps } from "../TrackInput";

export interface DatePickerProps extends MUIDatePickerProps<DateTime | Date | string | number, DateTime> {}

export interface DateFieldProps
  extends FieldProps<string>,
    Omit<Partial<DatePickerProps>, "onChange">,
    Omit<TrackInputProps<string>, "value"> {
  helperText?: ReactNode;
  placeholder?: string;
  onChange?: (value: string | null) => void;
}

const DateField: React.FC<DateFieldProps> = ({
  field,
  form: { setFieldValue, setFieldTouched },
  label,
  category,
  onChange,
  placeholder,
  ...props
}) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);

  const value = useMemo(() => {
    if (!field.value) return null;
    if (DateTime.isDateTime(field.value)) return field.value;
    if (typeof field.value === "string") return DateTime.fromISO(field.value);
    if (typeof field.value === "number") return DateTime.fromMillis(field.value);
    return DateTime.fromJSDate(field.value);
  }, [field.value]);

  const handleChange = useCallback<NonNullable<DatePickerProps["onChange"]>>(
    (newValue) => {
      const val = newValue && newValue.toFormat("yyyy-MM-dd");
      if (onChange) return onChange(val);
      setFieldValue(field.name, val);
    },
    [field.name, onChange, setFieldValue],
  );

  const handleBlur = useCallback(() => setFieldTouched(field.name, true), [field.name, setFieldTouched]);

  const handleInputRender = useCallback<DatePickerProps["renderInput"]>(
    (params) => {
      return (
        <TextField
          name={field.name}
          {...params}
          inputProps={{ ...params.inputProps, placeholder }}
          onBlur={handleBlur}
          error={hasError}
          helperText={helperText}
        />
      );
    },
    [field.name, handleBlur, hasError, helperText, placeholder],
  );

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      <DatePicker
        inputFormat="dd/MM/yyyy"
        {...props}
        value={value}
        label={label}
        onChange={handleChange}
        renderInput={handleInputRender}
        className={clsx(props.className, "fs-exclude")}
      />
    </TrackInput>
  );
};

export default DateField;
