import { Collapse, FormGroup, Link } from "@mui/material";
import { submitForgotPassword, submitLogin } from "client/api/public";
import TextField from "client/components/Fields/TextField";
import useQueryParams from "client/hooks/useQueryParams";
import EVENTS from "client/utils/events.consts";
import notifications from "client/utils/notifications";
import clsx from "clsx";
import FormButton from "components/FormButton";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TokenAuthStatus } from "shared/consts";
import Yup from "shared/utils/Yup";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "LoginForm" })((theme) => ({
  form: {
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: theme.spacing(1.25),
    transition: theme.transitions.create("height", {
      duration: theme.transitions.getAutoHeightDuration(350 - 225),
    }),
  },
  formSmall: {
    height: 175,
  },
}));

const Login: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [{ status, fp: forgotPasswordParam }, setQueryParams] =
    useQueryParams<{ status?: TokenAuthStatus; fp?: "1" | "0" }>();
  const [successfulSent, setSuccessfulSent] = useState<boolean | null>(null);

  const isForgotPassword = useMemo(() => forgotPasswordParam === "1", [forgotPasswordParam]);

  useEffect(() => {
    if (status === TokenAuthStatus.Expired) {
      notifications.info("Your forgot password link has expired. You can use the form below to send another.", {
        duration: 10,
      });
    }
    if (status === TokenAuthStatus.Error) {
      notifications.error(
        "There was an error processing your request. Please try again later or you can use the form below to send a new link.",
        { duration: 10 },
      );
    }
  }, [status]);

  const schema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().default("").email("Not a valid email").lowercase().required("Please enter your email"),
        password: Yup.string()
          .default("")
          .when({
            is: () => !isForgotPassword,
            then: (s) => s.required("Please enter your password"),
          }),
      }),
    [isForgotPassword],
  );

  const initialValues = useMemo(() => schema.getDefault(), [schema]);

  const onEmailChange = useCallback(() => {
    if (successfulSent) {
      setSuccessfulSent(false);
    }
  }, [successfulSent]);

  const handleForgotPassword = useCallback(async (values: Yup.Asserts<typeof schema>) => {
    window.dataLayer.push({ event: EVENTS.PAGE_VIEWS.FORGOT_PASSWORD });
    const res = await submitForgotPassword(values.email);
    setSuccessfulSent(res);
    if (!res) return;

    window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.FORGOT_PASSWORD_SUBMITTED_SUCCESSFULLY });
    notifications.success(
      <>
        If an account with this email exists, you will be sent a link to reset your password.
        <br />
        <br />
        <strong>Please check your inbox.</strong>
      </>,
      { duration: 10 },
    );
  }, []);

  const handleSubmit = useCallback(
    async (values: Yup.Asserts<typeof schema>) => {
      if (isForgotPassword) return handleForgotPassword(values);
      const res = await submitLogin(values.email, values.password);
      if (!res) return;
      window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.LOGIN_FORM_SUBMITTED_SUCCESSFULLY });
      navigate("/authenticate", { state: { twoFAEnabled: res.twoFAEnabled, dataURL: res.dataURL } });
    }, [handleForgotPassword, isForgotPassword, navigate])

  const handleForgotPasswordClick = useCallback(
    () => setQueryParams((params) => ({ ...params, fp: params.fp === "1" ? "0" : "1" })),
    [setQueryParams],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize>
      <Form id="login-form" className={clsx(classes.form, { [classes.formSmall]: isForgotPassword })}>
        <FormGroup onChange={onEmailChange}>
          <Field component={TextField} name="email" placeholder="Email" data-testid="loginEmail" size="small" />
        </FormGroup>
        <FormGroup sx={[isForgotPassword && { margin: 0 }]}>
          <Collapse in={!isForgotPassword}>
            <Field
              component={TextField}
              name="password"
              type="password"
              data-testid="loginPassword"
              placeholder="Password"
              size="small"
            />
          </Collapse>
        </FormGroup>
        <Link variant="body2" data-testid="forgot-password-link" onClick={handleForgotPasswordClick} align="right">
          {!isForgotPassword ? "Forgot your password?" : "Cancel"}
        </Link>
        <FormGroup>
          <FormButton
            disabled={Boolean(isForgotPassword && successfulSent)}
            data-testid="login-button"
            size="small"
            type="submit"
          >
            {isForgotPassword ? "Send Email" : "Login"}
          </FormButton>
        </FormGroup>
      </Form>
    </Formik>
  );
};

export default Login;
