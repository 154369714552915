import { Context, createContext, useContext } from "react";
import Yup from "shared/utils/Yup";
import Lazy from "yup/lib/Lazy";
import { AnyField, BaseValues, ComponentClasses } from "./types";

interface IFormGenContext<T extends BaseValues> {
  config: AnyField<T>[];
  schema: Lazy<Yup.ObjectSchema<T>>;
  classes?: ComponentClasses;
}

const FormGenContext = createContext({} as IFormGenContext<any>);
FormGenContext.displayName = "FormGenContext";

export const FormGenContextProvider = FormGenContext.Provider;

const useFormGenContext = <T extends BaseValues>() => useContext(FormGenContext as Context<IFormGenContext<T>>);

export default useFormGenContext;
