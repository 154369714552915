import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { TransactionFormType } from "client/views/AdminPanel/Transactions/Form";
import { AdminTransaction, LeanTransaction } from "server/services/transaction";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getTransactions<F extends LiteralUnion<keyof LeanTransaction, string>[] | undefined = undefined>(
  this: ThisAxios,
  onChangeObject?: AdminQuery<AdminTransaction, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<AdminTransaction, F[number]>[] : AdminTransaction[]
    >("/admin/transactions", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createTransaction = async (transaction: TransactionFormType) => {
  try {
    const res = await ipAxios.post<AdminTransaction>("/admin/transactions", transaction);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
