import Navbar from "client/components/Navbar";
import useScrollToTop from "client/hooks/useScrollToTop";
import React from "react";
import { Outlet } from "react-router-dom";
import RiskBanner from "./Application/components/RiskBanner";

const PageLayout: React.FC = () => {
  useScrollToTop();
  return (
    <>
      <RiskBanner />
      <Navbar />
      <Outlet />
    </>
  );
};

export default PageLayout;
