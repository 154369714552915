import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "MainPanelContainer" })((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight!}px)`,
  },
}));

const MainPanelContainer: React.FC = (props) => {
  const { classes } = useStyles();
  return <Box {...props} className={classes.root} />;
};

export default MainPanelContainer;
