import { Container, Typography } from "@mui/material";
import { getAdvisedSignatureURL } from "client/api/advisor/clients/client/applications/application";
import { getSignatureURL } from "client/api/applications/application";
import useConfigContext from "client/context/config";
import useFundsContext from "client/context/funds";
import useQueryParams from "client/hooks/useQueryParams";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import type HelloSign from "hellosign-embedded";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { default as Logging } from "../../utils/logging";
import useApplication from "./hooks/useApplication";
import { Forms } from "./utils";

let HS: typeof HelloSign;

const useStyles = makeStyles({ name: "SignAgreement" })((theme) => ({
  body: {
    minHeight: "70vh",
    width: "100%",
    padding: "0 20vw",
    marginTop: "50px",

    [theme.breakpoints.down("sm")]: {
      padding: "0 30px",
    },
  },
  hellosignLogo: {
    width: 180,
    marginLeft: -20,
    marginBottom: -40,
    [theme.breakpoints.down("sm")]: {
      width: 155,
      marginBottom: -34,
    },
  },
  iAContainer: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    height: "calc(100vh - 100px)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
}));

const SignAgreement: React.FC = () => {
  const { classes } = useStyles();
  const [{ sigId }] = useQueryParams<{ sigId?: string }>();
  const navigate = useNavigate();
  useAppRedirect(Boolean(sigId));

  const { selected: selectedFund } = useFundsContext();
  const iFrameRef = useRef<HTMLDivElement>(null);
  const { application, clientId, isAdvisor } = useApplication();
  const { portal, IS_PRODUCTION } = useConfigContext();

  const [signatureURL, setSignatureURL] = useState<string | null>(null);
  const [hellosignLoading, setHellosignLoading] = useState<boolean>(true);
  const [hellosignClient, setHellosignClient] = useState<HelloSign>();

  useEffect(() => {
    (async () => {
      const module = await import("hellosign-embedded");
      HS = module.default;
      const client = new HS({ clientId: portal.embeddedSigning.clientId });
      setHellosignClient(client);
    })();
  }, [portal.embeddedSigning.clientId]);

  const fetchSignatureURL = useCallback(() => {
    if (isAdvisor) return getAdvisedSignatureURL(clientId!, application!._id, sigId);
    return getSignatureURL(application!._id, sigId);
  }, [application, clientId, isAdvisor, sigId]);

  useEffect(() => {
    (async () => {
      if (!application) return;
      const res = await fetchSignatureURL();
      if (res) setSignatureURL(res.url);
    })();
  }, [application, fetchSignatureURL, sigId]);

  useEffect(() => {
    if (!hellosignClient) return;
    if (!iFrameRef.current || !signatureURL) return () => hellosignClient.close();
    hellosignClient.on(HS.events.SIGN, () => navigate(`../${Forms.Complete}`));
    hellosignClient.on(HS.events.ERROR, (data) => Logging.error(data));
    hellosignClient.on(HS.events.CANCEL, () =>
      Logging.info("The document must be signed to complete your application."),
    );
    hellosignClient.open(signatureURL, {
      skipDomainVerification: !IS_PRODUCTION,
      allowCancel: false,
      container: iFrameRef.current,
    });
    setHellosignLoading(false);
    return () => hellosignClient?.close();
  }, [IS_PRODUCTION, hellosignClient, navigate, signatureURL]);

  return (
    <Container maxWidth="md">
      <Typography variant="h1" align="center" paragraph>
        {isAdvisor
          ? "Your client's application"
          : ["Your investment agreement", ...(!sigId ? [] : `for the ${selectedFund?.name}.`)].join(" ")}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        {isAdvisor &&
          "Your client's application has been generated based on the answers provided. Please review this document and, when you are happy with it, scroll down to the adviser section to sign. Your client will then be informed of the application and instructed to sign the investor section."}
        {!isAdvisor &&
          (!sigId
            ? "This investment agreement has been generated based on the answers provided to us. Please review this document carefully and, when you are happy with it, scroll down to the bottom to sign. A copy of the document will then be emailed to you."
            : "This investment agreement has been generated based on the answers provided to us. Please carefully review this document and, when you are happy with it, scroll down to the bottom to sign. A copy of the document will then be emailed to you.")}
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        If you would like to change any of your answers, you can do so by selecting the back button in your browser.
      </Typography>

      <div className={classes.iAContainer} ref={iFrameRef}>
        {hellosignLoading && (
          <div className={classes.loading} data-testid="signatureLoading">
            <Typography variant="body1">
              Generating agreement...
              <br />
              (This can take up to 1 minute)
            </Typography>
          </div>
        )}
      </div>
    </Container>
  );
};

export default SignAgreement;
