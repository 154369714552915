import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { createApplication } from "client/api/admin/applications";
import { deleteApplication, updateApplication } from "client/api/admin/applications/application";
import { SelectOption } from "client/components/Fields/SelectField";
import useAdminContext, { AdminActions } from "client/context/admin";
import useFundsContext from "client/context/funds";
import { getInvestmentDetailsConfig } from "client/views/Application/InvestmentDetails";
import FormGen, { ErrorsList, FormGenConfig, SelectConfig } from "form-gen";
import { overrideConfig } from "form-gen/utils";
import { FormikConfig } from "formik";
import { ObjectIdLike } from "mongoose";
import React, { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AdminApplication, InvestmentDetails, LeanApplication } from "server/services/application";
import { AMLStatus, AppStatus, InvestmentFrequency } from "server/services/application/consts";
import { LeanFund } from "server/services/fund";
import * as Yup from "yup";
import CRUDButtons from "../components/CRUDButtons";
import useFundOptions from "../hooks/useFundOptions";
import usePortfolioOptions, { PortfolioOption } from "../hooks/usePortfolioOptions";
import useUserOptions from "../hooks/useUserOptions";
import splitUsers from "../utils/splitUsers";
import useIntroducers from "./hooks/useIntroducers";

interface AppFormProps extends DialogProps {
  application?: AdminApplication | null;
  onClose(): void;
}

export interface AppFormType extends LeanApplication {
  disableFundsReceivedEmail: boolean;
  disableApprovalEmail: boolean;
}

const getSelectValidation = (config: SelectConfig<AppFormType>) => {
  if (typeof config.options === "function") return Yup.string();
  const options = Array.isArray(config.options) ? config.options : Object.values(config.options);
  if (!options.length) return Yup.string();
  return Yup.string().oneOf([...options.map((o) => (typeof o === "string" ? o : o.value)), null]);
};

const filterPortfolios = (portfolios: PortfolioOption[], funds: ObjectIdLike[] = []) =>
  portfolios.filter((p) => funds.includes(p.fund));

export const getAppConfig = ({
  fund,
  funds = [],
  investors = [],
  advisors = [],
  introducers = [],
  portfolios = [],
}: {
  investors?: SelectOption[];
  advisors?: SelectOption[];
  introducers?: string[];
  portfolios?: PortfolioOption[];
  fund?: LeanFund | null;
  funds?: SelectOption[];
} = {}): FormGenConfig<AppFormType> => [
  {
    type: "select",
    name: "investor.user",
    label: "Investor",
    options: investors,
    get validation() {
      return getSelectValidation(this).nullable().required();
    },
  },
  {
    type: "select",
    name: "funds",
    label: "Fund",
    props: { multiple: true },
    options: funds,
    initialValue: [],
    get validation() {
      return Yup.array(getSelectValidation(this).nullable().required())
        .min(1)
        .max(1, "Currently, only single fund applications are allowed. Please remove one of the selected funds.");
    },
  },
  {
    type: "select",
    name: "portfolio",
    label: "Portfolio",
    condition: (values) => Boolean(values.funds?.length),
    options: (values) => filterPortfolios(portfolios, values.funds as ObjectIdLike[]),
    get validation() {
      return getSelectValidation(this);
    },
  },
  {
    type: "validation",
    validation: (values) => {
      const filteredPortfolios = filterPortfolios(portfolios, values?.funds as ObjectIdLike[] | undefined);
      return Yup.object({
        portfolio: !filteredPortfolios.length
          ? Yup.string()
          : Yup.string().oneOf(
              filteredPortfolios.map((p) => p.value),
              `Portfolio must be one of ${filteredPortfolios.map((p) => p.label).join(", ")}`,
            ),
      });
    },
  },
  {
    type: "section",
    props: { row: true },
    fields: [
      {
        type: "select",
        name: "meta.introducer",
        label: "Introducer",
        props: { freeSolo: true },
        options: introducers,
        validation: Yup.string().nullable(),
      },
      {
        type: "text",
        name: "meta.introducerId",
        label: "Introducer ID",
        validation: Yup.string().nullable(),
      },
    ],
  },
  {
    type: "select",
    name: "advisor.user",
    label: "Advisor",
    options: advisors,
    get validation() {
      return getSelectValidation(this).nullable();
    },
  },
  ...(overrideConfig(
    {
      namePrefix: "data.investment",
      overrides: {
        "oneOff.date": { props: { disablePast: false } },
        instalments: {
          props: { dateProps: { disablePast: false } },
          validation: Yup.array()
            .nullable()
            .when({
              is: (values?: InvestmentDetails) => values?.frequency === InvestmentFrequency.MultipleInstalments,
              then: Yup.array(
                Yup.object({
                  amount: Yup.number()
                    .typeError("Please enter an amount for this instalment")
                    .positive("Please enter a positive amount for this instalment")
                    .required("Please enter an amount for this instalment"),
                  date: Yup.date()
                    .typeError("Please enter the date of this instalment")
                    .required("Please enter the date of this instalment"),
                }),
              )
                .min(1, "Please add at least one instalment")
                .required("Please add at least one instalment"),
            }),
        },
      },
    },
    getInvestmentDetailsConfig({
      isAdvisor: false,
      fund:
        fund ||
        ({
          applications: {
            enableMultiplePayments: true,
            enableSelectPolicy: true,
            enableSelectSplit: true,
          },
          companyDetails: {},
          manager: {},
          paymentDetails: {
            bank: {},
            cheque: {},
          },
        } as LeanFund),
    }).filter(
      (c) =>
        (!c.key || c.key !== "warningSection") &&
        c.type !== "checkbox" &&
        (!c.fields || c.fields.every((f) => f.type !== "checkbox")),
    ),
  ) as FormGenConfig<AppFormType>),
  {
    type: "validation",
    name: "data.investment.oneOff",
    validation: (values) =>
      Yup.object({
        date: Yup.date().typeError("Please enter a date").required("Please enter a date"),
      })
        .nullable()
        .when({
          is: values?.data.investment?.frequency === InvestmentFrequency.OneOffPayment,
          then: (schema) => schema.label("One Off Payment").required("Please enter a date"),
        }),
  },
  {
    type: "validation",
    name: "data.investment.monthlyPayments",
    validation: (values) =>
      Yup.object()
        .nullable()
        .when({
          is: values?.data.investment?.frequency === InvestmentFrequency.MonthlyInstalments,
          then: (schema) => schema.label("Monthly Instalments").required("Please provide details"),
        }),
  },
  {
    type: "number",
    name: "meta.likelihood",
    label: "Likelihood of Funds",
    props: { disableMonetaryFormat: true, suffix: "%", formatProps: { min: 0, max: 100 } },
    validation: Yup.number(),
  },
  {
    type: "file",
    name: "file",
    label: "Agreement PDF",
    validation: Yup.string().nullable(),
  },
  {
    type: "select",
    name: "status",
    label: "Status",
    options: Object.values(AppStatus),
    get validation() {
      return getSelectValidation(this);
    },
  },
  {
    type: "text",
    name: "meta.custodianId",
    label: "Custodian ID",
    validation: Yup.string().nullable(),
  },
  {
    type: "date",
    name: "completedAt",
    label: "Completed At",
    validation: Yup.date().nullable(),
  },
  {
    type: "section",
    name: "meta.fundsWithheld",
    title: "Withheld Funds",
    fields: [
      {
        type: "radio",
        name: "present",
        label: "Are any funds withheld?",
        validation: Yup.boolean().nullable(),
      },
      {
        type: "number",
        name: "amount",
        label: "Amount",
        condition: (values) => values.meta?.fundsWithheld?.present,
        initialValue: null,
        validation: Yup.number()
          .nullable()
          .when("present", {
            is: true,
            then: (schema) => schema.required("Required"),
          }),
      },
    ],
  },
  {
    type: "radio",
    name: "aml.status",
    label: "AML Status",
    options: Object.values(AMLStatus),
    get validation() {
      return Yup.string()
        .oneOf(this.options as string[])
        .required("Required");
    },
  },
  {
    type: "radio",
    name: "investmentManager.approved",
    label: "Investment Manager Approved?",
    validation: Yup.boolean().nullable(),
  },
  {
    type: "date",
    name: "investmentManager.approvalChangedAt",
    label: "Investment Manager Approval Date?",
    validation: Yup.date().nullable(),
  },
  {
    type: "radio",
    name: "disableApprovalEmail",
    label: 'Disable "Application Approved" email notification?',
    validation: Yup.boolean().nullable(),
    initialValue: false,
  },
  {
    type: "text",
    name: "meta.notes",
    label: "Notes",
    validation: Yup.string().nullable(),
  },
  {
    type: "array",
    name: "fundsReceived",
    label: "Funds Received",
    fields: [
      {
        type: "section",
        props: { row: true },
        fields: [
          {
            type: "number",
            name: "amount",
            label: "Amount",
            validation: Yup.number().positive("Amount must be positive").required("Required"),
          },
          {
            type: "date",
            name: "date",
            label: "Date",
            validation: Yup.date().required("Required"),
          },
        ],
      },
      {
        type: "text",
        name: "notes",
        label: "Notes",
        placeholder: "Notes",
        validation: Yup.string().nullable(),
      },
    ],
  },
  {
    type: "radio",
    name: "disableFundsReceivedEmail",
    label: 'Disable "Funds Received" email notification?',
    validation: Yup.boolean().nullable(),
    initialValue: false,
  },
];

const AppForm: FC<AppFormProps> = ({ onClose, application: applicationProp, ...props }) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const { dispatch, applications } = useAdminContext();
  const { funds } = useFundsContext();
  const fundOptions = useFundOptions();
  const userOptions = useUserOptions();
  const portfolioOptions = usePortfolioOptions();
  const introducers = useIntroducers();

  const application = useMemo(
    () => applicationProp || applications[applicationId!],
    [applicationId, applicationProp, applications],
  );
  const { investors, advisors } = useMemo(() => splitUsers(userOptions), [userOptions]);

  const config = useMemo(
    () =>
      getAppConfig({
        investors,
        advisors,
        introducers,
        portfolios: portfolioOptions,
        fund: application && funds.find((f) => f._id === (application.funds[0] as ObjectIdLike)),
        funds: fundOptions,
      }),
    [advisors, application, fundOptions, funds, introducers, investors, portfolioOptions],
  );

  const handleSubmit = useCallback<FormikConfig<AppFormType>["onSubmit"]>(
    async (values) => {
      if (typeof values.meta.overAllocation?.seis !== "number" || typeof values.meta.overAllocation?.eis !== "number") {
        values.meta.overAllocation = {};
      }
      const newApplication = await (application
        ? updateApplication(application._id, values)
        : createApplication(values));
      if (!newApplication) return;
      dispatch({ type: AdminActions.UpdateApp, payload: newApplication });
      onClose();
    },
    [application, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (application) {
      const appId = await deleteApplication(application._id);
      if (!appId) return;
      dispatch({ type: AdminActions.RemoveApp, payload: appId });
      onClose();
    }
  }, [application, dispatch, onClose]);

  const initialValues = useMemo<Partial<AppFormType>>(() => {
    if (!application) return { funds: [] };
    const { fund, ...vals } = application;
    return {
      ...vals,
      portfolio: vals.portfolio?._id || null,
      investor: {
        ...vals.investor,
        user: vals.investor.user._id || null,
      },
      advisor: {
        ...vals.advisor,
        user: vals.advisor?.user?._id || null,
      },
      disableApprovalEmail: false,
      disableFundsReceivedEmail: false,
    };
  }, [application]);

  return (
    <Dialog onClose={onClose} maxWidth="lg" fullWidth {...props}>
      <DialogTitle>{application ? "Edit Application" : "Add Application"}</DialogTitle>
      <DialogContent>
        <FormGen
          config={config}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          castRequiredSchema
        >
          <ErrorsList />
          <CRUDButtons onDelete={handleDelete} onCancel={onClose} />
        </FormGen>
      </DialogContent>
    </Dialog>
  );
};

export default AppForm;
