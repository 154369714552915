import CheckboxUncheckedIcon from "@mui/icons-material/CheckBoxOutlineBlankSharp";
import CheckboxCheckedIcon from "@mui/icons-material/CheckBoxSharp";
import RadioSelectedIcon from "@mui/icons-material/Circle";
import CheckboxIndeterminateIcon from "@mui/icons-material/IndeterminateCheckBoxSharp";
import { createTheme, responsiveFontSizes, Theme, ThemeOptions } from "@mui/material/styles";
import { merge } from "lodash";
import React from "react";

const getFontFamily = (fontFamily = "Montserrat") =>
  [`'${fontFamily}'`, "'Helvetica'", "'Arial'", "sans-serif"].join(",");

const baseTheme: ThemeOptions = {
  palette: {
    white: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#CCCCCC",
    },
    alternate1: {
      light: "#5BCABB",
      main: "#1B998B",
      dark: "#006A5E",
    },
    alternate2: {
      light: "#3F6A52",
      main: "#143F29",
      dark: "#001A00",
    },
    alternate3: {
      light: "#B7B4A1",
      main: "#878472",
      dark: "#5A5747",
    },
    alternate4: {
      light: "#59517A",
      main: "#2E294E",
      dark: "#080026",
    },
    error: {
      light: "#FF6168",
      main: "#D7263D",
      dark: "#9E0017",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#000000",
    },
    info: {
      light: "#B7B4A1",
      main: "#878472",
      dark: "#5A5747",
    },
  },
  typography: {
    fontFamily: getFontFamily(),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBlack: 800,
    h1: {
      fontSize: "2.625rem", // 42px
      lineHeight: 1.43,
      letterSpacing: "0.10em",
    },
    h2: {
      fontSize: "2.25rem", // 36px
      lineHeight: 1.33,
      letterSpacing: "0.075em",
    },
    h3: {
      fontSize: "1.5rem", // 24px
      lineHeight: 1.25,
      letterSpacing: "0.075em",
    },
    h4: {
      fontSize: "1.25rem", // 20px
      lineHeight: 1.5,
      letterSpacing: "0.05em",
    },
    h5: {
      fontSize: "0.875rem", // 14px
      lineHeight: 1.71,
      letterSpacing: "0.05em",
    },
    h6: {
      fontSize: "0.75rem", // 12px
      lineHeight: 1.18,
      letterSpacing: "0.02em",
    },
    body1: {
      fontSize: "1.25rem", // 20px
      lineHeight: 1.5,
      letterSpacing: "0.05em",
    },
    body2: {
      fontSize: "1rem", // 16px
      lineHeight: 1.5,
      letterSpacing: "0.05em",
    },
    caption: {
      fontSize: "0.75rem", // 12px
      lineHeight: 1.5,
      letterSpacing: "0.02em",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 95,
    },
  },
  components: {
    MuiAlert: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
    },
    MuiDatePicker: {
      defaultProps: {
        inputFormat: "dd/MM/yyyy",
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: "scrollable",
        indicatorColor: "secondary",
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: { shrink: true },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
  },
};

const extendTheme = (theme: Theme) =>
  createTheme(theme, {
    palette: {
      divider: theme.palette.common.black,
    },
    typography: {
      h1: { fontWeight: theme.typography.fontWeightBold },
      h2: { fontWeight: theme.typography.fontWeightBold },
      h3: { fontWeight: theme.typography.fontWeightBold },
      h4: { fontWeight: theme.typography.fontWeightMedium },
      h5: { fontWeight: theme.typography.fontWeightSemiBold },
      h6: { fontWeight: theme.typography.fontWeightMedium },
      body1: { fontWeight: theme.typography.fontWeightMedium },
      body2: { fontWeight: theme.typography.fontWeightMedium },
      caption: { fontWeight: theme.typography.fontWeightMedium },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          img: {
            display: "block",
            maxWidth: "100%",
            width: "100%",
            height: "auto",
            maxHeight: "inherit",
          },
          html: {
            body: {
              "--primary-light": theme.palette.primary.light,
              "--primary-main": theme.palette.primary.main,
              "--primary-dark": theme.palette.primary.dark,
              "--primary-contrast-text": theme.palette.primary.contrastText,
              "--secondary-light": theme.palette.secondary.light,
              "--secondary-main": theme.palette.secondary.main,
              "--secondary-dark": theme.palette.secondary.dark,
              "--secondary-contrast-text": theme.palette.secondary.contrastText,
              "--action-hover": theme.palette.action.hover,
            },
            "& .rc-notification-notice": { padding: 0 },
            "& #cc_c2a": { zIndex: theme.zIndex.modal },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            maxWidth: theme.breakpoints.values.lg,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
          outlined: {
            borderWidth: 2,
            borderColor: theme.palette.secondary.main,
            "&:hover": {
              borderWidth: 2,
            },
          },
          text: {
            textTransform: "none",
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            // ? Reduces font size of year dropdown due to formatting issues
            "& div[role='presentation'] > div > div": {
              fontSize: "1rem",
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: <CheckboxUncheckedIcon />,
          checkedIcon: <CheckboxCheckedIcon />,
          indeterminateIcon: <CheckboxIndeterminateIcon />,
        },
        styleOverrides: {
          root: {
            color: "inherit",
            "& .MuiSvgIcon-root": {
              fontSize: 30,
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "inherit",
            "&&": {
              whiteSpace: "unset",
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            marginLeft: theme.spacing(0.5),
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            marginTop: theme.spacing(3),
            [theme.breakpoints.down("md")]: {
              marginTop: theme.spacing(2),
            },
          },
          row: {
            [theme.breakpoints.up("sm")]: {
              "& > *:not(.MuiIconButton-root)": {
                flex: 1,
                "&:first-child": {
                  marginRight: theme.spacing(1),
                },
                "&:not(:last-child):not(:first-child)": {
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                },
                "&:last-child": {
                  marginLeft: theme.spacing(1),
                },
              },
            },
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              "& > *": {
                flex: 1,
                "&:first-child": {
                  marginBottom: theme.spacing(1),
                },
                "&:not(:last-child):not(:first-child)": {
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                },
                "&:last-child": {
                  marginTop: theme.spacing(1),
                },
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: theme.palette.info.main,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            position: "unset",
            transform: "unset",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
          underlineAlways: {
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            top: 0,
            borderRadius: 0,
            borderColor: theme.palette.common.black,
            "& > legend": {
              display: "none",
              visibility: "hidden",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      get MuiRadio() {
        return {
          defaultProps: {
            checkedIcon: <RadioSelectedIcon />,
          },
          styleOverrides: this.MuiCheckbox?.styleOverrides,
        } as const;
      },
    },
  } as ThemeOptions);

export default function mergeThemes(portalTheme?: ThemeOptions) {
  const portalFontFamily = typeof portalTheme?.typography === "function" ? null : portalTheme?.typography?.fontFamily;
  const mergedThemes = createTheme(
    merge({}, baseTheme, portalTheme, {
      typography: { fontFamily: getFontFamily(portalFontFamily as string) },
    } as ThemeOptions),
  );
  return responsiveFontSizes(extendTheme(mergedThemes));
}
