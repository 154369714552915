import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useAppStyles from "../hooks/useAppStyles";
import { getRemainingTimeUntilMsTimestamp } from "client/utils/CountdownTimerUtils";

interface CoolDownTimerProps {
  isSuccessCoolDown: boolean;
  message: string;
  coolDownTime: Date | null;
}

const CoolDownTimer: React.FC<CoolDownTimerProps> = ({ isSuccessCoolDown, message, coolDownTime }) => {
  const { classes } = useAppStyles();
  const defaultRemainingTime = {
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00",
  };

  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  const countdownTimestampMs = useMemo(() => coolDownTime, [coolDownTime]);

  function updateRemainingTime(countdown: any) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownTimestampMs]);

  return (
    <>
      <Typography gutterBottom={true} variant="h2" align="center">
        {message}
      </Typography>
      <Container>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <Grid item md={12}>
            <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.hours}</span>
            <span className={classes.countDownSeparator}>:</span>
            <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.minutes}</span>
            <span className={classes.countDownSeparator}>:</span>
            <span className={classes.countDownDigits}>{isSuccessCoolDown ? "00" : remainingTime.seconds}</span>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default CoolDownTimer;
