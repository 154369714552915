import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import type { AdminCompany, CompanyDocDef, LeanCompany } from "server/services/company";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getCompanies<F extends LiteralUnion<keyof LeanCompany, string>[] | undefined = undefined>(
  this: ThisAxios,
  onChangeObject?: AdminQuery<AdminCompany, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<AdminCompany, F[number]>[] : AdminCompany[]
    >("/admin/companies", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createCompany = async (company: CompanyDocDef) => {
  try {
    const res = await ipAxios.post<AdminCompany>(`/admin/companies`, company);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
