import { ThemeProvider } from "@emotion/react";
import { Alert, AlertColor, AlertProps } from "@mui/material";
import { uniqueId } from "lodash";
import Notification from "rc-notification";
import "rc-notification/assets/index.css";
import { NotificationInstance } from "rc-notification/lib/Notification";
import React, { FC, ReactNode } from "react";
import mergeThemes from "shared/mergeThemes";

type NotifyFunction = (message: ReactNode, options?: { duration?: number }) => void;

const theme = typeof window === "undefined" ? null : mergeThemes(window.__CONFIG__.portal.theme);

let notificationInstance: NotificationInstance;
if (typeof window !== "undefined") {
  Notification.newInstance(
    {
      style: {
        zIndex: theme!.zIndex.snackbar,
        top: 10,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        right: "unset",
      },
    },
    (n) => (notificationInstance = n),
  );
}

const Content: FC<AlertProps> = ({ children, ...props }) =>
  theme && (
    <ThemeProvider theme={theme}>
      <Alert {...props} variant="standard">
        {children}
      </Alert>
    </ThemeProvider>
  );

const notify: NotifyFunction = function (this: { severity: AlertColor }, message, { duration = 1.5 } = {}) {
  if (!notificationInstance) return;
  const key = uniqueId();
  notificationInstance.notice({
    key,
    duration,
    content: (
      <Content severity={this.severity} onClose={() => notificationInstance.removeNotice(key)}>
        {message}
      </Content>
    ),
  });
};

const notifications = (["success", "info", "warning", "error"] as AlertColor[]).reduce(
  (prev, severity) => ({
    ...prev,
    [severity]: notify.bind({ severity }),
  }),
  {} as { [key in AlertColor]: NotifyFunction },
);

export default notifications;
