import { supportedValuesOf } from "@formatjs/intl-enumerator";
import { Button, Dialog, DialogActions, DialogContent, LinearProgress, TextField, ButtonGroup } from "@mui/material";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getTransactions } from "client/api/admin/transactions";
import { patchTransaction } from "client/api/admin/transactions/transaction";
import useAdminContext, { AdminActions } from "client/context/admin";
import useAuthContext from "client/context/auth";
import useToggle from "client/hooks/useToggle";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { AdminTransaction } from "server/services/transaction";
import { BuySell, TransactionStatuses, TransactionType } from "server/services/transaction/consts";
import AdminTable from "../components/AdminTable";
import MainPanelContainer from "../components/MainPanelContainer";
import useFundOptions from "../hooks/useFundOptions";
import usePortfolioOptions from "../hooks/usePortfolioOptions";
import createTableDefinition from "../utils/createTableDefinition";
import Form from "./Form";
import SentryRoutes from "client/components/SentryRoutes";
import { Route, useNavigate } from "react-router-dom";
import ExportLinkForm from "../components/ExportLinkForm";
import { ExportType } from "server/services/export/consts";

const Transactions: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { transactions, dispatch } = useAdminContext();
  const portfolioOptions = usePortfolioOptions();
  const fundOptions = useFundOptions();
  const [editConfirmOpen, toggleEditConfirmOpen] = useToggle(false);
  const [updating, toggleUpdating] = useToggle(false);
  const [tableEditData, setTableEditData] = useState<{ path: string; value: unknown; record: string }>();
  const [count, setCount] = useState(-1);

  const transArr = useMemo(() => Object.values(transactions), [transactions]);

  const setHoldings = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getTransactions(onChangeObject);
      if (!res) return [];
      if (isExport) return res.data;
      dispatch({ type: AdminActions.SetTransactions, payload: res.data });
      setCount(res.count);
      return res.data;
    },
    [dispatch],
  );

  const shareTransactionsTable = useMemo(
    () =>
      createTableDefinition<ColumnDefinition<AdminTransaction>>([
        {
          key: "project",
          title: "Project",
          dataIndex: "company.info.brandName",
        },
        {
          key: "companyName",
          title: "Company Name",
          dataIndex: "company.companyName",
        },
        {
          key: "companyNumber",
          title: "Company Number",
          dataIndex: "company.number",
        },
        {
          key: "fund",
          title: "Fund",
          dataIndex: "fund.name",
        },
        {
          key: "portfolio",
          title: "Portfolio",
          dataIndex: "portfolio.name",
          editable: {
            path: "portfolio",
            type: "select",
            selectOptions: portfolioOptions,
            defaultValue: "",
          },
        },
        {
          key: "investor",
          title: "Investor",
          dataIndex: "investor",
          editable: true,
        },
        {
          key: "settledAt",
          title: "Settle Date",
          dataType: "date",
          dataIndex: "settledAt",
          editable: true,
        },
        {
          key: "tradedAt",
          title: "Trade Date",
          dataType: "date",
          dataIndex: "tradedAt",
          editable: true,
        },
        {
          key: "nominee",
          title: "Nominee",
          dataIndex: "nominee",
          editable: true,
        },
        {
          key: "buySell",
          title: "Buy/Sell",
          dataIndex: "buySell",
          editable: {
            path: true,
            type: "select",
            selectOptions: Object.values(BuySell),
          },
        },
        {
          key: "shareClass",
          title: "Share Class",
          dataIndex: "shareClass.name",
          editable: {
            path: true,
            type: "select",
            selectOptions: (row) => row.company.shareClasses.map((shareClass) => shareClass.name),
          },
        },
        {
          key: "sharePrice",
          title: "Price Per Share",
          dataType: "number",
          dataIndex: "sharePrice",
          numerical: { path: true, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
          editable: true,
        },
        {
          key: "nominalValue",
          title: "Nominal Price",
          dataType: "number",
          dataIndex: "nominalValue",
          numerical: { path: true, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
          editable: true,
        },
        {
          key: "totalValue",
          title: "Total Investment",
          dataType: "number",
          dataIndex: "value",
          numerical: { path: true, currency: true, minDecimalPlaces: 2, maxDecimalPlaces: 10 },
        },
        {
          key: "noShares",
          title: "Total Shares",
          dataType: "number",
          dataIndex: "noShares",
          numerical: { path: true, currency: false, decimalPlaces: 0 },
        },
        {
          key: "type",
          title: "Transaction Type",
          dataIndex: "type",
          editable: {
            path: true,
            type: "select",
            selectOptions: Object.values(TransactionType),
          },
        },
        {
          key: "currency",
          title: "Currency",
          dataIndex: "meta.currency",
          editable: {
            path: true,
            type: "select",
            selectOptions: supportedValuesOf("currency"),
          },
        },
        {
          key: "investmentManager",
          title: "Investment Manager",
          dataIndex: "investmentManager",
          editable: {
            path: true,
            type: "select",
            selectOptions: _.uniq(fundOptions.map((f) => f.manager)),
          },
        },
        {
          key: "noSubscriptions",
          title: "No. Subscribers",
          dataType: "number",
          dataIndex: "noSubscribers",
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
          editable: {
            path: true,
            type: "select",
            selectOptions: TransactionStatuses,
          },
        },
        {
          key: "notes",
          title: "Notes",
          dataIndex: "notes",
          editable: {
            path: true,
            component: ({ onChange, ...editProps }) => (
              <TextField {...editProps} onChange={(e) => onChange(e.target.value)} variant="standard" multiline />
            ),
          },
        },
      ]),
    [fundOptions, portfolioOptions],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminTransaction>>(
    async ({ path, value }, record) => {
      if (path.includes("status") && value === "FINALISED") {
        setTableEditData({ path, value, record: record._id });
        toggleEditConfirmOpen.on();
        return;
      }
      const res = await patchTransaction(record._id, {
        [path]: value === "null" ? null : value,
        disableEmails: true,
      });
      if (!res) return;
      dispatch({ type: AdminActions.UpdateTransaction, payload: res });
    },
    [dispatch, toggleEditConfirmOpen],
  );

  const handleEmailDialog = useCallback(
    async (doNotSendEmails) => {
      if (!tableEditData) return;
      toggleUpdating.on();
      const res = await patchTransaction(tableEditData.record, {
        [tableEditData!.path]: tableEditData.value === "null" ? null : tableEditData.value,
        disableEmails: doNotSendEmails,
      });

      if (!res) return;
      dispatch({ type: AdminActions.UpdateTransaction, payload: res });
      toggleUpdating.off();
      toggleEditConfirmOpen.off();
    },
    [dispatch, tableEditData, toggleEditConfirmOpen, toggleUpdating],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);

  return (
    <>
      <MainPanelContainer>
        <AdminTable
          onChange={setHoldings}
          count={count}
          tableData={Object.values(transactions)}
          tableStructure={shareTransactionsTable}
          rowClick={(record) => navigate(`./${record._id}/edit`)}
          defaultSort={{ key: "settledAt", direction: "desc" }}
          onEdit={handleTableEdit}
          csvFilename="ShareTransactions"
          exportToCSVOption
        />
        <ButtonGroup variant="text">
          <Button data-testid="addShareTransaction" disabled={user?.isReadOnly} onClick={() => navigate("./create")}>
            Add Share Transaction
          </Button>
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
        <Dialog open={editConfirmOpen}>
          <DialogContent>
            Changing the status to finalised will send an email to investors and advisors notifying them of a completed
            deployment. Do you want to send this email?
            {updating && (
              <>
                <br />
                <br /> Updating transaction... <br /> <br />
                <LinearProgress />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleEmailDialog(false)} disabled={updating}>
              Yes
            </Button>
            <Button onClick={() => handleEmailDialog(true)} disabled={updating}>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </MainPanelContainer>
      <SentryRoutes>
        <Route path="create" element={<Form open onClose={handleDialogClose} />} />
        <Route path=":transactionId/edit" element={<Form open onClose={handleDialogClose} />} />
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Trans}
              data={transArr}
              structure={shareTransactionsTable}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default Transactions;
