import { isAxiosError } from "./ipAxios";

const DEFAULT_MESSAGE = "Oops. Something went wrong. Please try again.";

const ErrorsMap: Record<string, string> = {
  fund_not_found: "Sorry! We are unable to find this Fund, you may need to request access to it.",
  emailAlreadyExist: "Email already exists.",
};

export const errorToMessage = <T>(error?: T) => {
  if (!isAxiosError(error) && error instanceof Error) return ErrorsMap[error.message] || DEFAULT_MESSAGE;
  if (
    !isAxiosError(error) ||
    !error.response ||
    error.response.status >= 500 ||
    typeof error.response.data !== "string"
  ) {
    return DEFAULT_MESSAGE;
  }
  return ErrorsMap[error.response.data] || DEFAULT_MESSAGE;
};
