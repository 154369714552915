import { Typography } from "@mui/material";
import { FormikErrors } from "formik";
import _ from "lodash";
import React, { Fragment, useCallback, useMemo } from "react";
import { FormConfig } from "shared/application.consts";

interface FormikErrorsListProps {
  config: FormConfig;
  errors: FormikErrors<unknown>;
  nestedKey?: string;
}

const FormikErrorsList: React.FC<FormikErrorsListProps> = ({ config, errors, nestedKey: nestedKeyProp }) => {
  const nestedKey = useMemo(() => (nestedKeyProp ? `${nestedKeyProp}.` : ""), [nestedKeyProp]);

  const getLabel = useCallback(
    (fieldId: string, value: unknown) => {
      if (typeof value === "string" || typeof value === "function") {
        return (
          <>
            {nestedKeyProp ? "- " : ""}
            {_.get(config, `${nestedKey}${fieldId}.label`) ||
              _.get(config, `${nestedKey}${fieldId}.placeholder`, fieldId)}
          </>
        );
      }
      if (Array.isArray(value)) {
        return value.map((arrayValue, index) => (
          <Fragment key={index}>
            <Typography component="div" variant="inherit" color="inherit" gutterBottom>
              {_.get(config, `${nestedKey}${fieldId}.title`, fieldId)}&nbsp;{index + 1}
            </Typography>
            <FormikErrorsList config={config} errors={arrayValue} nestedKey={`${nestedKey}${fieldId}`} />
          </Fragment>
        ));
      }
      return (
        <>
          <Typography component="div" variant="inherit" color="inherit" gutterBottom>
            {_.get(config, `${nestedKey}${fieldId}.title`, fieldId)}
          </Typography>
          <FormikErrorsList
            config={config}
            errors={value as FormikErrors<unknown>}
            nestedKey={`${nestedKey}${fieldId}`}
          />
        </>
      );
    },
    [config, nestedKey, nestedKeyProp],
  );

  return (
    <>
      {Object.entries(errors).map(([fieldId, value]) => (
        <Typography
          key={fieldId}
          component="div"
          variant="body2"
          color="error"
          paragraph={!nestedKeyProp}
          gutterBottom={Boolean(nestedKeyProp)}
        >
          {getLabel(fieldId, value)}
        </Typography>
      ))}
    </>
  );
};

export default FormikErrorsList;
