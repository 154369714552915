import { Dialog, DialogContent, DialogProps, DialogTitle, FormGroup } from "@mui/material";
import { ObjectId } from "bson";
import { createCompany } from "client/api/admin/companies";
import { deleteCompany, updateCompany } from "client/api/admin/companies/company";
import { SelectOption } from "client/components/Fields/SelectField";
import useAdminContext, { AdminActions } from "client/context/admin";
import FormGen, { ErrorsList, FormGenConfig } from "form-gen";
import { FormikConfig } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AdminCompany, CompanySector, LeanCompany } from "server/services/company";
import { SharePriceSource } from "server/services/company/consts";
import * as Yup from "yup";
import CRUDButtons from "../components/CRUDButtons";
import useFundOptions from "../hooks/useFundOptions";
import useSectors from "./useSectors.hook";

interface CompanyFormProps extends DialogProps {
  company?: AdminCompany | null;
  onClose(): void;
}

export const getCompanyConfig = ({
  fundOptions,
  sectors,
}: { fundOptions?: SelectOption[]; sectors?: CompanySector[] } = {}): FormGenConfig<LeanCompany> => {
  return [
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Company Details",
      fields: [
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "text",
              name: "companyName",
              label: "Company Name",
              placeholder: "Company Name",
              validation: Yup.string().nullable().required(),
            },
            {
              type: "text",
              name: "number",
              label: "Company Number",
              placeholder: "Company Number",
              validation: Yup.string().nullable().required(),
            },
          ],
        },
        {
          type: "select",
          name: "funds",
          label: "Funds",
          placeholder: "Funds",
          initialValue: [],
          options: fundOptions ?? [],
          props: { multiple: true, helperText: "Which funds have this company as part of their portfolio?" },
          validation: Yup.array().nullable().min(1).of(Yup.string().required()).required(),
        },
        {
          type: "address",
          name: "info.address",
          props: { disableLengths: true },
          label: "Address",
          validation: Yup.object()
            .nullable()
            .label("Address")
            .shape({
              line1: Yup.string().nullable().label("Address Line One").required(),
              line2: Yup.string().nullable().label("Address Line Two"),
              city: Yup.string().nullable().label("City").required(),
              country: Yup.string().nullable().label("Country").required(),
              postcode: Yup.string().nullable().label("Postcode").required(),
            }),
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "General Information",
      fields: [
        {
          type: "text",
          name: "info.brandName",
          label: "Brand Name",
          placeholder: "Brand Name",
          validation: Yup.string().nullable(),
        },
        {
          type: "select",
          name: "info.sector",
          label: "Sector",
          placeholder: "Sector",
          options: sectors ?? [],
          props: { freeSolo: true },
          validation: Yup.string().nullable().required(),
        },
        {
          type: "text",
          name: "info.headline",
          label: "Headline",
          placeholder: "Headline",
          validation: Yup.string().nullable(),
        },
        {
          type: "text",
          name: "info.description",
          label: "Description",
          placeholder: "Description",
          props: { multiline: true, rows: 4 },
          validation: Yup.string().nullable(),
        },
        {
          type: "text",
          name: "info.videoURL",
          label: "Video",
          placeholder: "Video",
          validation: Yup.string().url().nullable(),
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Logos",
      fields: [
        {
          type: "file",
          name: "logos.primary",
          label: "Primary Logo",
          placeholder: "Logo",
          props: { publicFile: true },
          validation: Yup.string().nullable(),
        },
        {
          type: "file",
          name: "logos.secondary",
          label: "Secondary Logo",
          placeholder: "Logo",
          props: { publicFile: true },
          validation: Yup.string().nullable(),
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Links",
      fields: [
        {
          type: "array",
          name: "links",
          label: "Links",
          fields: [
            {
              type: "section",
              props: { row: true },
              fields: [
                {
                  type: "text",
                  name: "name",
                  label: "Name",
                  placeholder: "Name",
                  validation: Yup.string().nullable().required(),
                },
                {
                  type: "text",
                  name: "category",
                  label: "Category",
                  placeholder: "Category",
                  validation: Yup.string().nullable(),
                },
              ],
            },
            {
              type: "text",
              name: "url",
              label: "URL",
              placeholder: "URL",
              validation: Yup.string().nullable().url().required(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Team",
      fields: [
        {
          type: "array",
          name: "info.team",
          label: "Team",
          fields: [
            {
              type: "text",
              name: "name",
              label: "Name",
              placeholder: "Name",
              validation: Yup.string().nullable().required(),
            },
            {
              type: "text",
              name: "role",
              label: "Role",
              placeholder: "Role",
              validation: Yup.string().nullable().required(),
            },
            {
              type: "text",
              name: "profileURL",
              label: "Link to Profile",
              placeholder: "Link to Profile",
              validation: Yup.string().nullable().url(),
            },
            {
              type: "file",
              name: "image",
              label: "Image",
              placeholder: "Image",
              props: { publicFile: true },
              validation: Yup.string().nullable(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Share Classes",
      fields: [
        {
          type: "array",
          name: "shareClasses",
          label: "Share Classes",
          onAdd: () => ({
            _id: new ObjectId().toHexString(),
            name: "",
            description: "",
          }),
          fields: [
            {
              type: "text",
              name: "name",
              label: "Name",
              placeholder: "Name",
              validation: Yup.string().nullable().required(),
            },
            {
              type: "text",
              name: "description",
              label: "Description",
              placeholder: "Description",
              validation: Yup.string().nullable(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Share Prices",
      condition: (values) => values.shareClasses?.length,
      fields: [
        {
          type: "array",
          name: "sharePrices",
          label: "Share Prices",
          fields: [
            {
              type: "select",
              name: "shareClass",
              label: "Share Class",
              placeholder: "Share Class",
              options: (values) =>
                (values.shareClasses ?? []).map<SelectOption>((sc) => ({ label: sc.name as string, value: sc._id })),
              validation: Yup.string().nullable().required(),
            },
            {
              type: "date",
              name: "date",
              label: "Date of Change",
              placeholder: "Date of Change",
              validation: Yup.date().nullable().required(),
            },
            {
              type: "section",
              props: { row: true },
              fields: [
                {
                  type: "number",
                  name: "price",
                  label: "Price",
                  placeholder: "Price",
                  props: { formatProps: { decimalScale: 10, fixedDecimalScale: false } },
                  validation: Yup.number().nullable().min(0, "Price must be at least £0").required(),
                },
                {
                  type: "number",
                  name: "nominalValue",
                  label: "Nominal Value",
                  placeholder: "Nominal Value",
                  props: { formatProps: { decimalScale: 7, fixedDecimalScale: false } },
                  validation: Yup.number().nullable().min(0, "Nominal Value must be at least £0").required(),
                },
              ],
            },
            {
              type: "select",
              name: "source",
              label: "Source of Change",
              placeholder: "Source of Change",
              options: SharePriceSource,
              validation: Yup.string()
                .nullable()
                .oneOf(
                  Object.values(SharePriceSource),
                  `Source must be one of ${Object.keys(SharePriceSource).join(", ")}`,
                )
                .required(),
            },
            {
              type: "select",
              name: "funds",
              label: "Apply to Funds",
              placeholder: "Funds",
              options: fundOptions ?? [],
              initialValue: null,
              validation: Yup.array().nullable().of(Yup.string().required()),
              props: {
                multiple: true,
                helperText: (
                  <>
                    To include this price per share and nominal value to all current and future funds, press clear
                    (&ldquo;
                    <kbd>x</kbd>&rdquo;) to the right of the dropdown. Else it will apply only to the selected funds.
                  </>
                ),
              },
            },
            {
              type: "text",
              name: "reason",
              label: "Reason for Change",
              placeholder: "Reason",
              initialValue: null,
              validation: Yup.string().nullable(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "Updates",
      fields: [
        {
          type: "array",
          name: "info.updates",
          label: "Updates",
          fields: [
            {
              type: "date",
              name: "date",
              label: "Date of Update",
              placeholder: "Date",
              validation: Yup.date().nullable().required(),
            },
            {
              type: "text",
              name: "title",
              label: "Title",
              placeholder: "Title",
              initialValue: null,
              validation: Yup.string().nullable(),
            },
            {
              type: "text",
              name: "content",
              label: "Description",
              placeholder: "Description",
              props: { multiline: true, rows: 4 },
              validation: Yup.string().nullable().required(),
            },
          ],
        },
      ],
    },
  ];
};

const CompanyForm: FC<CompanyFormProps> = ({ onClose, company, ...props }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const { dispatch, companies } = useAdminContext();
  const fundOptions = useFundOptions();
  const sectors = useSectors();

  const admCompany = useMemo(() => company || companies[companyId!], [companyId, company, companies]);

  const config = useMemo(() => getCompanyConfig({ fundOptions, sectors }), [fundOptions, sectors]);

  const handleSubmit = useCallback<FormikConfig<LeanCompany>["onSubmit"]>(
    async (values) => {
      const newCompany = await (!admCompany ? createCompany(values) : updateCompany(admCompany._id, values));
      if (!newCompany) return;
      dispatch({ type: AdminActions.UpdateCompany, payload: newCompany });
      onClose();
    },
    [admCompany, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (!admCompany) return;
    const compId = await deleteCompany(admCompany._id);
    if (!compId) return;
    dispatch({ type: AdminActions.RemoveCompany, payload: compId });
    onClose();
  }, [admCompany, dispatch, onClose]);

  const initialValues = useMemo<LeanCompany | undefined>(() => {
    if (!admCompany) return;
    const { valuation, capacityRemaining, deployed, ...adminCompany } = admCompany;
    return {
      ...adminCompany,
      shareClasses: admCompany.shareClasses.map(({ deployed: _deployed, ...shareClass }) => ({ ...shareClass })),
      funds: admCompany.funds.map((f) => f._id),
    };
  }, [admCompany]);

  return (
    <Dialog onClose={onClose} maxWidth="lg" fullWidth {...props}>
      <DialogTitle>{admCompany ? "Edit Company" : "Add Company"}</DialogTitle>
      <DialogContent>
        <FormGen
          config={config}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          castRequiredSchema
        >
          <FormGroup>
            <ErrorsList />
          </FormGroup>
          <CRUDButtons onDelete={handleDelete} onCancel={onClose} />
        </FormGen>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyForm;
