import { UserType } from "../user/consts";

export enum AppStatus {
  Created = "created",
  Started = "started",
  InvestorDetails = "investor-details-section-completed",
  Finances = "finances-section-completed",
  Experience = "experience-section-completed",
  Objectives = "objectives-section-completed",
  Investment = "investment-details-section-completed",
  SignaturePending = "signature-pending",
  Signed = "signed",
  FundsReceived = "funds-received",
  FundsDeployed = "funds-deployed",
  LostOrNoInterest = "lost-or-no-interest",
}

export enum AMLStatus {
  Pending = "pending",
  Refer = "refer",
  SignedOff = "signed-off",
}

export enum InvestmentFrequency {
  OneOffPayment = "One off payment",
  MonthlyInstalments = "Monthly instalments",
  MultipleInstalments = "Multiple instalments",
}

export enum PaymentMethod {
  Bank = "bank",
  Cheque = "cheque",
}

export enum ThreeToFiveYears {
  WithinThree = "Within 3 years",
  ThreeToFive = "Within 3 to 5 years",
  NotWithinFive = "Not within 5 years",
}

export enum Times {
  One = "1",
  TwoToFour = "2-4",
  FiveToTen = "5-10",
  TenPlus = "10+",
}

export enum Amounts {
  LTFive = "Less than £5,000",
  FiveToFifty = "£5,000 to £50,000",
  FiftyToHundred = "£50,000 to £100,000",
  HundredToFiveHundred = "£100,000 to £500,000",
  FiveHundredPlus = "£500,000+",
}

export enum EducationLevels {
  Secondary = "Secondary school level",
  Bachelors = "Bachelor's degree",
  Masters = "Master's degree",
  Postgrad = "Postgraduate diploma",
  Doctorate = "Doctorate",
}

export enum FinancialEducationLevels {
  Degree = "Finance related degree",
  Doctorate = "Finance related doctorate",
  Masters = "Finance related master's degree",
  Postgrad = "Finance related postgraduate diploma",
  MBA = "MBA",
  CFA = "CFA",
  ACCAOrACAOrCPA = "ACCA or ACA or CPA",
  CMA = "CMA",
  IMC = "IMC",
  CFP = "CFP",
  FRM = "FRM",
  CAIA = "CAIA",
  Other = "Other",
}

export enum RiskLevels {
  Conservative = "Conservative",
  ModeratelyConservative = "Moderately Conservative",
  Moderate = "Moderate",
  ModeratelyAggressive = "Moderately Aggressive",
  Aggressive = "Aggressive",
}

export enum NetWorthPercentages {
  ZeroToFive = "0-5%",
  SixToTen = "6-10%",
  ElevenToTwenty = "11-20%",
  TwentyOneToFifty = "21%-50%",
  FiftyOnePlus = "51%+",
}

export enum CashEquivalents {
  AboveToOneMillion = "Above £1,000,000",
  HalfMillionToOneMillion = "£500,000-1,000,000",
  TwoHundredFiftyThousandToHalfMillion = "£250,000-500,000",
  OneHundredToTwoHundredFiftyThousand = "£100,000-250,000",
  FiftyToOneHundredThousand = "£50,000-100,000",
  LessTOFiftyThousand = "Less than £50,000",
}

export enum InvestmentPolicies {
  SEIS = "seis",
  EIS = "eis",
  Mixed = "mixed",
}

export const AppTypeMap: { [key in UserType]: string } = {
  [UserType.Advised]: "Advised Client",
  [UserType.Referred]: "Referred Client",
  [UserType.Direct]: "Direct",
  [UserType.Advisor]: "Advisor",
};
