import React from "react";
import useFormGenContext from "../context";
import { AnyField, BaseValues } from "../types";
import FormGenField from "./FormGenField";

const BuildFields = <T extends BaseValues>({
  config: configProp,
  ...props
}: {
  config?: AnyField<T>[];
  disableFormGroups?: boolean;
}) => {
  const { config } = useFormGenContext<T>();
  return (
    <>
      {(configProp || config).map((field) => (
        <FormGenField key={field.key || field.name} config={field} {...props} />
      ))}
    </>
  );
};
BuildFields.whyDidYouRender = false;

export default BuildFields;
