import ipAxios, { isAxiosError, ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanApplication } from "server/services/application";

export async function getLatestClientApplication(this: ThisAxios, clientId: string, fundId: string) {
  try {
    const res = await (this || ipAxios).get<LeanApplication>(`/advisor/clients/${clientId}/applications/latest`, {
      params: { fund: fundId },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    if (isAxiosError(error) && error.response?.data === "fund_not_found") return { redirectTo: "/apply/funds" };
    return null;
  }
}

export async function getClientApplicationById(this: ThisAxios, clientId: string, applicationId: string) {
  try {
    const res = await (this || ipAxios).get<LeanApplication>(
      `/advisor/clients/${clientId}/applications/${applicationId}`,
    );
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
