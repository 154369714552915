import ArticleIcon from "@mui/icons-material/Article";
import BusinessIcon from "@mui/icons-material/Business";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LayersIcon from "@mui/icons-material/Layers";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ChartIcon from "@mui/icons-material/SsidChart";
import TaskIcon from "@mui/icons-material/Task";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  SvgIconProps,
  Tooltip,
} from "@mui/material";
import RouterLink from "client/components/RouterLink";
import SentryRoutes from "client/components/SentryRoutes";
import { AdminProvider } from "client/context/admin";
import useToggle from "client/hooks/useToggle";
import clsx from "clsx";
import React from "react";
import { Route, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Applications from "./Applications";
import ApplicationApproval from "./ApplicationApproval";

import Companies from "./Companies";
import Portfolios from "./Portfolios";
import Subscriptions from "./Subscriptions";
import Transactions from "./Transactions";
import Users from "./Users";

interface RouterListItemProps extends ListItemProps {
  to: string;
  label: string;
  labelIcon: React.ElementType<SvgIconProps>;
  active: boolean;
  open?: boolean;
}

const DRAWER_WIDTH = 250;

const RouterListItem: React.FC<RouterListItemProps> = ({ label, labelIcon: LabelIcon, to, open, active, ...props }) => {
  return (
    <ListItem {...props} disablePadding sx={{ display: "block" }}>
      <Tooltip title={open ? "" : label} placement="right">
        <ListItemButton
          component={RouterLink}
          to={to}
          sx={{
            px: 2.5,
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
          }}
        >
          <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 0, justifyContent: "center" }}>
            <LabelIcon color={active ? "primary" : "action"} />
          </ListItemIcon>
          <ListItemText primary={label} sx={{ visibility: open ? "visible" : "hidden" }} />
        </ListItemButton>
      </Tooltip>
    </ListItem>
  );
};

const useStyles = makeStyles({ name: "AdminPanel" })((theme) => ({
  expansionIcon: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  expansionIconOpen: {
    transform: "rotate(180deg)",
  },
  sidebar: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    borderRight: "1px solid",
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    overflowX: "hidden",
  },
  sidebarOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarClosed: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)})`,
    },
  },
}));

const ListItems = [
  { to: "users", label: "Users", labelIcon: PersonIcon, element: <Users /> },
  { to: "applications", label: "Applications", labelIcon: ArticleIcon, element: <Applications /> },
  { to: "applicationApproval", label: "Application Approval", labelIcon: TaskIcon, element: <ApplicationApproval /> },
  { to: "portfolios", label: "Portfolios", labelIcon: LayersIcon, element: <Portfolios /> },
  { to: "companies", label: "Companies", labelIcon: BusinessIcon, element: <Companies /> },
  { to: "transactions", label: "Share Transactions", labelIcon: ReceiptIcon, element: <Transactions /> },
  { to: "subscriptions", label: "Subscriptions", labelIcon: ChartIcon, element: <Subscriptions /> },
];

const AdminPanel: React.FC = () => {
  const { classes } = useStyles();
  const { "*": activePanel } = useParams();
  const [sidebarOpen, toggleSidebarOpen] = useToggle(false);

  return (
    <AdminProvider>
      <Box sx={{ display: "flex", flex: 1, paddingTop: "125px" }}>
        <Box
          className={clsx(classes.sidebar, {
            [classes.sidebarOpen]: sidebarOpen,
            [classes.sidebarClosed]: !sidebarOpen,
          })}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={toggleSidebarOpen} sx={{ position: "sticky", right: 0 }}>
              <ChevronRightIcon className={clsx(classes.expansionIcon, { [classes.expansionIconOpen]: sidebarOpen })} />
            </IconButton>
          </Box>
          <List dense disablePadding>
            {ListItems.map(({ to, ...itemProps }) => (
              <RouterListItem
                key={to}
                to={`./${to}`}
                open={sidebarOpen}
                active={new RegExp(`^${to}`).test(activePanel || "")}
                {...itemProps}
              />
            ))}
          </List>
        </Box>
        <SentryRoutes>
          {ListItems.map(({ to, element }) => (
            <Route key={to} path={`${to}/*`} element={element} />
          ))}
        </SentryRoutes>
      </Box>
    </AdminProvider>
  );
};

export default AdminPanel;
