import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getAdminFunds } from "client/api/admin/funds";
import { SelectOption } from "client/components/Fields/SelectField";
import { useState } from "react";
import { LeanFund } from "server/services/fund";

export interface FundOption extends Exclude<SelectOption, string>, Pick<LeanFund, "type" | "defaultPortfolio"> {
  manager?: string;
  nominee?: string;
}

const useFundOptions = () => {
  const { fundOptions: preloadedFundOptions } = usePreloadedState<{ fundOptions: FundOption[] }>();
  const [fundOptions, setFundOptions] = useState<FundOption[]>(preloadedFundOptions ?? []);

  useSCEffect(
    async (axios?: Axios) => {
      const res = await getAdminFunds.call(axios, {
        sortKey: "name",
        sortDirection: "asc",
        fields: ["_id", "name", "type", "defaultPortfolio", "custodian.nominee", "manager.name"],
      });
      if (!res) return;
      const options: FundOption[] = res.data.map((f) => ({
        value: f._id,
        label: f.name,
        type: f.type,
        defaultPortfolio: f.defaultPortfolio,
        manager: f.manager.name,
        nominee: f.custodian?.nominee,
      }));
      setFundOptions(options);
      return options;
    },
    [],
    "fundOptions",
  );

  return fundOptions;
};

export default useFundOptions;
