import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getCompany } from "client/api/admin/companies/company";
import useAdminContext from "client/context/admin";
import CompanyInfo from "client/views/CompanyInfo";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AdminCompany } from "server/services/company";

const Preview: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { company: preloadedCompany } = usePreloadedState<{ company?: AdminCompany }>();
  const { companies } = useAdminContext();
  const [company, setCompany] = useState(preloadedCompany ?? null);

  useSCEffect(
    async (axios: Axios) => {
      if (!companyId) return;
      const comp = companies[companyId] || (await getCompany.call(axios, companyId));
      setCompany(comp);
      return comp;
    },
    [companyId],
    "company",
  );

  return company && <CompanyInfo company={company} />;
};

export default Preview;
