import { DialogContentText, Link, Typography } from "@mui/material";
import ResponsiveDialog from "client/components/ResponsiveDialog";
import useToggle from "client/hooks/useToggle";
import React, { forwardRef, useImperativeHandle } from "react";

interface Props {
  onWarningRead?: () => void;
}
const RiskWarning = forwardRef<() => void, React.PropsWithChildren<Props>>(function _RiskWarning(props, ref) {
  const [open, toggleOpen] = useToggle(false);
  useImperativeHandle(ref, () => toggleOpen.on, [toggleOpen.on]);

  const onClick = () => {
    if (props.onWarningRead) {
      props.onWarningRead();
    }
    toggleOpen.off();
  };
  return (
    <>
      {props.children}
      <ResponsiveDialog
        open={open}
        closeButton={{ onClick: toggleOpen.off }}
        title="Risk Warning"
        actionButtons={[{ label: "I understand the risks", onClick: onClick }]}
      >
        <DialogContentText component="div" align="justify" sx={{ color: "inherit" }}>
          <Typography paragraph>
            Due to the potential for losses, the Financial Conduct Authority (FCA) considers this investment to be high
            risk.
            <br />
            <br />
            What are the key risks?
          </Typography>
          <br />
          <Typography component="div" paragraph>
            <Typography gutterBottom>
              <strong>1. You could lose all the money you invest</strong>
            </Typography>
            If the business you invest in fails, you are likely to lose 100% of the money you invested. Most start-up
            businesses fail.
          </Typography>
          <br />
          <Typography component="div" paragraph>
            <Typography gutterBottom>
              <strong>2. You are unlikely to be protected if something goes wrong</strong>
            </Typography>
            The business offering this investment is not regulated by the FCA. Protection from the Financial Services
            Compensation Scheme (FSCS) only considers claims against failed regulated firms. Learn more about FSCS
            protection&nbsp;
            <Link href="https://www.fscs.org.uk/what-we-cover/investments/" target="_blank" rel="noopener noreferrer">
              here.
            </Link>
            <br />
            <br />
            Protection from the Financial Ombudsman Service (FOS) does not cover poor investment performance. If you
            have a complaint against an FCA-regulated firm, FOS may be able to consider it. Learn more about FOS
            protection&nbsp;
            <Link href="https://www.financial-ombudsman.org.uk/consumers" target="_blank" rel="noopener noreferrer">
              here.
            </Link>
          </Typography>
          <br />
          <Typography component="div" paragraph>
            <Typography gutterBottom>
              <strong>3. You won’t get your money back quickly</strong>
            </Typography>
            Even if the business you invest in is successful, it may take several years to get your money back. You are
            unlikely to be able to sell your investment early. The most likely way to get your money back is if the
            business is bought by another business or lists its shares on an exchange such as the London Stock Exchange.
            These events are not common. If you are investing in a start-up business, you should not expect to get your
            money back through dividends. Start-up businesses rarely pay these.
          </Typography>
          <br />
          <Typography component="div" paragraph>
            <Typography gutterBottom>
              <strong>4. Don’t put all your eggs in one basket</strong>
            </Typography>
            Putting all your money into a single business or type of investment, for example, is risky. Spreading your
            money across different investments makes you less dependent on any one to do well. A good rule of thumb is
            not to invest more than 10% of your money in <u>high-risk investments</u>. Read more about it&nbsp;
            <Link
              href="https://www.fca.org.uk/investsmart/5-questions-ask-you-invest"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </Link>
          </Typography>
          <br />
          <Typography component="div" paragraph>
            <Typography gutterBottom>
              <strong>5. The value of your investment can be reduced</strong>
            </Typography>
            The percentage of the business that you own will decrease if the business issues more shares. This could
            mean that the value of your investment reduces, depending on how much the business grows. Most start-up
            businesses issue multiple rounds of shares. These new shares could have additional rights that your shares
            don’t have, such as the right to receive a fixed dividend, which could further reduce your chances of
            getting a return on your investment. If you are interested in learning more about how to protect yourself,
            visit the FCA’s website&nbsp;
            <Link href="https://www.fca.org.uk/investsmart" target="_blank" rel="noopener noreferrer">
              here.
            </Link>
          </Typography>
          <br />
          <Typography>
            The above is a summary of the material risk factors that we believe to be associated with an investment in
            this fund but does not necessarily include all the risks associated with such an investment. You should seek
            your own independent advice by an independent financial advisor or other professional advisor authorised
            under the Financial Services and Markets Act 2000 who specialises in advising on investments of this type.
          </Typography>
          <br />
          <Typography>
            Please find the PDF version&nbsp;
            <Link
              href="https://217255.fs1.hubspotusercontent-na1.net/hubfs/217255/Text%20for%20Take%202%20minutes-1.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </Link>
          </Typography>
        </DialogContentText>
      </ResponsiveDialog>
    </>
  );
});

export default RiskWarning;
