import Yup from "shared/utils/Yup";
import { LiteralUnion } from "type-fest";
import { DefaultValueMap } from "./config";
import { AnyComplexField, AnyField, BaseValues, SectionConfig, ValidationConfig } from "./types";

const getSchemaLabel = <T extends BaseValues>(field: AnyField<T>, values?: T) => {
  if (typeof field.label === "string") return field.label;
  const label = typeof field.label === "function" && values ? field.label(values) : field.label;
  return typeof label === "string" ? label : field.stringLabel || null;
};

const getValidator = <T extends BaseValues>(field: AnyComplexField<T>, values?: T) => {
  if (field.type !== "array") {
    return (field.validation as Yup.AnySchema).default(
      typeof field.initialValue === "undefined" ? DefaultValueMap[field.type] : field.initialValue,
    );
  }
  const validator = (field.validation ?? Yup.array()).default(field.initialValue ?? []);
  if (field.condition && (!values || !field.condition(values))) return validator;
  return validator.of(field.field ? field.field.validation : buildSchema(field.fields!, values));
};

const test = <T extends BaseValues, C extends AnyField<any>>(
  field: C,
): field is C & { name: LiteralUnion<keyof T, string> } => Boolean(field.name);

const buildNestedSchema = <
  T extends BaseValues,
  C extends AnyField<any>,
  S extends Yup.AnySchema,
  R extends C extends { name: LiteralUnion<keyof T, string> } ? Yup.AnyObjectSchema : S,
>(
  field: C,
  validator: S,
): R => {
  if (!test(field)) return validator as R;
  if (!field._isNested) return Yup.object({ [field.name]: validator }) as R;
  const [lastKey, ...paths] = field._splitName!;
  const nested = paths.reduce((s, key) => ({ [key]: Yup.object(s) }), {
    [lastKey]: validator as any,
  } as Record<string, Yup.ObjectSchema<any>>);
  return Yup.object(nested) as R;
};

const getSectionSchema = <T extends BaseValues>(field: SectionConfig<T>, values?: T) => {
  const baseSectionSchema: Yup.ObjectSchema<T> = field.initialValue
    ? buildSchema(field.fields!, values).default(field.initialValue)
    : buildSchema(field.fields!, values);
  const sectionSchema = baseSectionSchema.label(
    typeof field.title === "string" ? field.title : field.stringTitle || "",
  );
  if (!field.name) return sectionSchema;
  return buildNestedSchema(field, sectionSchema);
};

const getValidationSchema = <T extends BaseValues>(field: ValidationConfig<T>, values?: T) => {
  const validationSchema = typeof field.validation === "function" ? field.validation(values) : field.validation;
  if (!field.name) return validationSchema;
  return buildNestedSchema(field, validationSchema);
};

export const buildSchema = <T extends BaseValues>(config: AnyField<T>[], values?: T): Yup.ObjectSchema<T> =>
  config.reduce<Yup.ObjectSchema<T>>((schema, field) => {
    if ((field.type === "section" && !field.fields) || field.type === "divider") return schema;
    if (field.type === "section") return schema.concat(getSectionSchema(field, values));
    if (field.type === "validation") return schema.concat(getValidationSchema(field, values));

    const label = getSchemaLabel(field, values);
    let validator: Yup.AnySchema = getValidator(field, values);
    if (label) validator = validator.label(label);

    const nested = buildNestedSchema(field, validator);
    return schema.concat(nested);
  }, Yup.object({} as T));
