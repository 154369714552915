import { FormGroup, FormHelperText, FormLabel, TextField as MUITextField, Typography } from "@mui/material";
import useConfigContext from "client/context/config";
import useDynamicFieldText from "client/hooks/useDynamicFieldText";
import useHelperText from "client/hooks/useHelperText";
import type { ClickToAddress, FetchifyOptions } from "fetchify";
import { Field, FieldProps } from "formik";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { UserAddress } from "server/services/user";
import { BaseAddress } from "shared/types";
import TrackInput, { TrackInputProps } from "../TrackInput";
import NumberField from "./NumberField";
import SelectField from "./SelectField";
import TextField from "./TextField";
import useAuthContext from "client/context/auth";

export interface AddressFieldProps
  extends FieldProps<BaseAddress | UserAddress>,
    TrackInputProps<BaseAddress | UserAddress> {
  label?: ReactNode;
  helperText?: string;
  disableLengths?: boolean;
}

const ARRAY_ELEM_REGEX = /\[(?<arrayIndex>\d+)\]/;

countries.registerLocale(en);
const NationalityOptions = Object.entries(countries.getNames("en", { select: "official" })).map(([code, name]) => ({
  label: name,
  value: code,
}));

let fetchify: ClickToAddress | null = null;

const AddressField: React.FC<AddressFieldProps> = ({
  field,
  form: { setFieldValue },
  category,
  disableLengths,
  label: labelProp,
  ...props
}) => {
  const { FETCHIFY_KEY } = useConfigContext();
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const label = useDynamicFieldText(labelProp);
  const [fetchifyLoaded, setFetchifyLoaded] = useState(Boolean(fetchify));
  const { user } = useAuthContext();

  const arrIndex = useMemo(() => {
    const regexRes = ARRAY_ELEM_REGEX.exec(field.name);
    if (!regexRes || !regexRes.groups?.arrayIndex || !parseInt(regexRes.groups.arrayIndex)) return false;
    return parseInt(regexRes.groups.arrayIndex);
  }, [field]);

  const fieldNames = useMemo(
    () => ({
      search: `${field.name}.search`,
      line1: `${field.name}.line1`,
      line2: `${field.name}.line2`,
      city: `${field.name}.city`,
      country: `${field.name}.country`,
      postcode: `${field.name}.postcode`,
      lengthMonths: `${field.name}.lengthMonths`,
      lengthYears: `${field.name}.lengthYears`,
    }),
    [field.name],
  );

  const initialiseFetchify = useCallback(() => {
    const config: Omit<FetchifyOptions, "accessToken"> = {
      dom: { search: fieldNames.search },
      onResultSelected: (_c2a, _els, address) =>
        setFieldValue(field.name, {
          line1: address.line_1,
          line2: address.line_2,
          city: address.locality,
          postcode: address.postal_code,
          country: address.country.iso_3166_1_alpha_2.toUpperCase(),
          ...(disableLengths ? {} : { lengthYears: "", lengthMonths: "" }),
        }),
    };
    if (fetchify) return fetchify.attach(config.dom, { onResultSelected: config.onResultSelected });
    if (!window.clickToAddress) return;
    fetchify = new window.clickToAddress({
      ...config,
      accessToken: FETCHIFY_KEY,
      getIpLocation: false,
    });
    setFetchifyLoaded(true);
  }, [FETCHIFY_KEY, disableLengths, field.name, fieldNames.search, setFieldValue]);

  useEffect(() => {
    if (window.clickToAddress) {
      initialiseFetchify();
      return;
    }
    const script = document.createElement("script");
    script.id = "fetchify";
    script.src = "https://cc-cdn.com/generic/scripts/v1/cc_c2a.min.js";
    script.async = true;
    script.onload = initialiseFetchify;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [initialiseFetchify]);

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      {!arrIndex && <FormLabel>{label}</FormLabel>}
      {arrIndex && (
        <>
          <br />
          <Typography sx={{ color: "inherit" }}>
            As you have lived at {arrIndex === 1 ? "your current address" : "the provided addresses"} for{" "}
            {arrIndex > 1 && "a total of"} less than 5 years, please also add {arrIndex === 1 ? "your" : "another"}{" "}
            previous residential address:
          </Typography>
        </>
      )}
      <FormGroup sx={[!fetchifyLoaded && { display: "none" }]}>
        <MUITextField name={fieldNames.search} variant="outlined" size="small" defaultValue="" />
      </FormGroup>
      <FormGroup>
        <Field name={fieldNames.line1} component={TextField} label="Line One" placeholder="Line One" />
      </FormGroup>
      <FormGroup>
        <Field name={fieldNames.line2} component={TextField} label="Line Two" placeholder="Line Two" />
      </FormGroup>
      <FormGroup>
        <Field name={fieldNames.city} component={TextField} label="City" placeholder="City" />
      </FormGroup>
      <FormGroup>
        <Field
          name={fieldNames.country}
          component={SelectField}
          options={NationalityOptions}
          label="Country"
          placeholder="Country"
        />
      </FormGroup>
      <FormGroup>
        <Field name={fieldNames.postcode} component={TextField} label="Postcode" placeholder="Postcode" />
      </FormGroup>
      {!disableLengths && (
        <FormGroup
          row
          sx={{
            alignItems: {
              sm: "flex-end",
            },
          }}
        >
          <Field
            name={fieldNames.lengthYears}
            component={NumberField}
            label={`${user?.isAdvisor ? "How long has your client lived here?" : "How long have you lived here?"}`}
            suffix="years"
            disableMonetaryFormat
            helperText={" "}
          />
          <Field
            name={fieldNames.lengthMonths}
            component={NumberField}
            suffix="months"
            disableMonetaryFormat
            helperText={" "}
          />
        </FormGroup>
      )}
      {helperText && typeof helperText === "string" && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default AddressField;
