import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getCompanies } from "client/api/admin/companies";
import { SelectOption } from "client/components/Fields/SelectField";
import { ObjectIdLike } from "mongoose";
import { useState } from "react";
import { CompanyDeal, ShareClass } from "server/services/company";

export interface CompanyOption extends Exclude<SelectOption, string> {
  shareClasses: ShareClass[];
  funds: ObjectIdLike[];
  deals?: CompanyDeal[];
}

const useCompanyOptions = () => {
  const { compOptions: preloadedCompOptions } = usePreloadedState<{ compOptions?: CompanyOption[] }>();
  const [compOptions, setCompOptions] = useState<CompanyOption[]>(preloadedCompOptions ?? []);

  useSCEffect(
    async (axios?: Axios) => {
      const res = await getCompanies.call(axios, {
        fields: ["_id", "companyName", "shareClasses", "deals", "funds", "info.brandName"],
      });
      if (!res) return;
      const opts = res.data.map<CompanyOption>((c) => ({
        label: [c.companyName, ...(!c.info.brandName ? [] : [c.info.brandName])].join(" - "),
        value: c._id,
        shareClasses: c.shareClasses,
        deals: c.deals,
        funds: c.funds.map((f) => f._id),
      }));
      setCompOptions(opts);
      return opts;
    },
    [],
    "compOptions",
  );

  return compOptions;
};

export default useCompanyOptions;
