import { AppProvider } from "client/context/app";
import useAuthContext from "client/context/auth";
import { FundsProvider } from "client/context/funds";
import { UserProvider } from "client/context/user";
import PageLayout from "client/views/PageLayout";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const AuthedRoutes: React.FC = () => {
  const { user } = useAuthContext();
  const location = useLocation();

  if (!user) {
    return (
      <Navigate
        to={location.pathname.includes("/apply/") ? "/register" : "/login"}
        state={{ from: location }}
        replace
      />
    );
  }
  return (
    <UserProvider>
      <FundsProvider>
        <AppProvider>
          <PageLayout />
        </AppProvider>
      </FundsProvider>
    </UserProvider>
  );
};

export default AuthedRoutes;
