import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import RouterLink from "client/components/RouterLink";
import useAdvisorContext from "client/context/advisor";
import useAuthContext from "client/context/auth";
import useConfigContext from "client/context/config";
import React, { useMemo } from "react";
import { LeanFund } from "server/services/fund";
import { FeeType, UserType } from "server/services/user/consts";
import { getFeeLimits } from "shared/utils/fees";
import { Forms } from "../utils";

interface FundCardProps {
  fund: LeanFund;
}

const FundCard: React.FC<FundCardProps> = ({ fund }) => {
  const { portal } = useConfigContext();
  const { client } = useAdvisorContext();
  const { user } = useAuthContext();

  const clientFee = useMemo(() => {
    if (client) return client.fee;
    if (user?.isReferred) return user.advisor.fee;
    return null;
  }, [client, user?.advisor?.fee, user?.isReferred]);

  const fee = useMemo(
    () =>
      (client || user?.isReferred) &&
      getFeeLimits(client ? UserType.Advised : UserType.Referred, clientFee, fund.applications.ifaFees),
    [client, clientFee, fund.applications.ifaFees, user?.isReferred],
  );

  return (
    <Card
      sx={[
        {
          height: "100%",
          backgroundPosition: "center",
        },
        !user?.isAdvised && {
          "&:hover": {
            boxShadow: (theme) => theme.shadows[20],
          },
        },
        fund.meta.splashImage
          ? { backgroundImage: `url(${fund.meta.splashImage})`, backgroundSize: "cover" }
          : {
              backgroundImage: `url(${portal.logos.primary})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            },
      ]}
    >
      <CardActionArea
        href={`../${fund.urlId}/${Forms.KeyInformation}`}
        disabled={user?.isAdvised}
        LinkComponent={RouterLink}
        data-testid={fund.urlId}
      >
        <CardContent
          sx={{
            height: "100%",
            minHeight: 250,
            color: "common.white",
            backdropFilter: "brightness(0.5)",
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h3" paragraph>
              {fund.name}
            </Typography>
            {fund.description && <Typography variant="body2">{fund.description}</Typography>}
          </div>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            {!user?.isAdvised && (
              <Typography sx={{ display: "flex", alignItems: "center", textTransform: "uppercase" }}>
                Invest&nbsp;&nbsp;
                <ArrowRightAltIcon fontSize="large" />
              </Typography>
            )}
            {fee && (
              <Typography variant="body2" align="right" sx={{ justifySelf: "flex-end" }}>
                IFA Charge:{" "}
                <strong>
                  {clientFee?.type === FeeType.Percentage ? (
                    <>
                      {numberFormatter(fee.percentage / 100, { currency: false, style: "percent" })}
                      {fee.fixed !== null && ` up to ${numberFormatter(fee.fixed)}`}
                    </>
                  ) : (
                    <>
                      {numberFormatter(fee.fixed!)}
                      {fee.percentage !== 100 &&
                        ` up to ${numberFormatter(fee.percentage / 100, { currency: false, style: "percent" })}`}
                    </>
                  )}
                </strong>
              </Typography>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default FundCard;
