import { Container, FormGroup, Typography } from "@mui/material";
import { createClient } from "client/api/advisor/clients";
import Divider from "client/components/Divider";
import FormButtons from "client/components/FormButtons";
import useAdvisorContext, { AdvisorActions } from "client/context/advisor";
import EVENTS from "client/utils/events.consts";
import FormGen, { FormGenConfig } from "form-gen";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FeeType, UserType } from "server/services/user/consts";
import Yup from "shared/utils/Yup";

const CATEGORY = EVENTS.ADVISOR.INPUT.CATEGORIES.ADD_CLIENT;

export interface AddClientValues {
  forenames: string;
  surname: string;
  email: string;
  type: UserType;
  fee:
    | { type: FeeType.Fixed; fixed: number; percentage: null }
    | { type: FeeType.Percentage; fixed: null; percentage: number };
}

export const AddClientConfig: FormGenConfig<AddClientValues> = [
  {
    type: "text",
    name: "forenames",
    label: "Forenames",
    placeholder: "Forenames",
    props: { category: CATEGORY },
    validation: Yup.string().required("This field is required"),
  },
  {
    type: "text",
    name: "surname",
    label: "Surname",
    placeholder: "Surname",
    props: { category: CATEGORY },
    validation: Yup.string().required("This field is required"),
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    placeholder: "Email",
    props: { category: CATEGORY },
    validation: Yup.string().email("Please enter a valid email address").required("This field is required"),
  },
  {
    type: "radio",
    name: "type",
    label: "Client's Advice Type",
    props: { category: CATEGORY },
    options: [
      { label: "Advised", value: UserType.Advised },
      { label: "Referred", value: UserType.Referred },
    ],
    get validation() {
      return Yup.string()
        .nullable()
        .oneOf([UserType.Advised, UserType.Referred], "Please select one of the available options")
        .required("This field is required");
    },
  },
  {
    type: "section",
    title: (
      <>
        <strong>An Advised client</strong> is someone that has received professional financial advice about this
        investment opportunity and is having their application managed on their behalf.
        <br />
        <br />
        <strong>A Referred client</strong> is someone that may or may not have received professional financial advice
        about this investment opportunity and will manage their application on their own.
      </>
    ),
    titleProps: { variant: "body2" },
  },
  { type: "divider" },
  {
    key: "fee",
    type: "section",
    title: (
      <>
        Desired Financial Advisor/Intermediary Initial Charge (%)
        <br />
        <small>Enter the desired charge* below, either as a percentage or as a fixed amount in GBP</small>
      </>
    ),
    stringTitle: "Desired Financial Advisor/Intermediary Initial Charge (%)",
    props: { row: true },
    fields: [
      {
        type: "number",
        label: "Percentage",
        name: "fee.percentage",
        placeholder: "Percentage",
        props: {
          category: CATEGORY,
          suffix: "%",
          disableMonetaryFormat: true,
          formatProps: {
            min: 0,
            max: 100,
            decimalScale: 2,
          },
        },
        validation: Yup.number()
          .nullable()
          .min(0, "Must be at least ${min}%")
          .max(100, "Must be at most ${max}%")
          .when({
            is: (values: any) => values?.type === FeeType.Percentage,
            then: (schema) => schema.required("This field is required"),
          }),
        onChange(_e, value) {
          this.setValues({
            ...this.values,
            fee: {
              ...this.values.fee,
              type: FeeType.Percentage,
              percentage: value as number,
              fixed: null,
            },
          });
        },
      },
      {
        type: "number",
        label: "Fixed",
        name: "fee.fixed",
        placeholder: "Fixed",
        props: {
          category: CATEGORY,
          formatProps: {
            min: 0,
            decimalScale: 2,
          },
        },
        validation: Yup.number()
          .nullable()
          .min(0, "Must be at least £{min}")
          .when("type", {
            is: (values: any) => values?.type === FeeType.Fixed,
            then: (schema) => schema.required("This field is required"),
          }),
        onChange(_e, value) {
          this.setValues({
            ...this.values,
            fee: {
              ...this.values.fee,
              type: FeeType.Fixed,
              fixed: value as number,
              percentage: null,
            },
          });
        },
      },
    ],
  },
  {
    type: "validation",
    name: "fee",
    validation: Yup.object({
      type: Yup.string()
        .default(null)
        .label("Fee Type")
        .oneOf(Object.values(FeeType), "Please provide a charge as either a percentage or fixed amount")
        .required("Please provide a charge as either a percentage or fixed amount"),
      percentage: Yup.number()
        .nullable()
        .typeError("Please provide a charge as either a percentage or fixed amount")
        .min(0, "Must be at least ${min}%")
        .max(100, "Must be at most ${max}%")
        .when({
          is: (values?: AddClientValues["fee"]) => values?.type === FeeType.Percentage,
          then: (schema) => schema.required("This field is required"),
        }),
      fixed: Yup.number()
        .nullable()
        .typeError("Please provide a charge as either a percentage or fixed amount")
        .min(0, "Must be at least £{min}")
        .when({
          is: (values?: AddClientValues["fee"]) => values?.type === FeeType.Fixed,
          then: (schema) => schema.required("This field is required"),
        }),
    }),
  },
];

const AddClient: React.FC = () => {
  const navigate = useNavigate();
  const { dispatch } = useAdvisorContext();

  const handleSubmit = useCallback(
    async (values: AddClientValues) => {
      const res = await createClient({
        ...values,
        fee: {
          type: values.fee.type,
          amount: values.fee.type === FeeType.Fixed ? values.fee.fixed : values.fee.percentage,
        },
      });
      if (!res) return window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.ADD_CLIENT_FORM_SUBMITTED_UNSUCCESSFULLY });
      window.dataLayer.push({ event: EVENTS.PAGE_ACTIONS.ADD_CLIENT_FORM_SUBMITTED_SUCCESSFULLY });
      dispatch({ type: AdvisorActions.SetClient, payload: res, clientId: res.userId });
      navigate("/advisor/dashboard");
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    window.dataLayer.push({ event: EVENTS.PAGE_VIEWS.ADD_CLIENT });
  }, []);

  return (
    <>
      <Container maxWidth="md" style={{ paddingTop: 150 }}>
        <Typography variant="h1" align="center" gutterBottom>
          Add a Client
        </Typography>
        <Typography variant="h4" align="center" gutterBottom>
          Enter your client&apos;s information to begin their application
        </Typography>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <FormGen config={AddClientConfig} enableReinitialize onSubmit={handleSubmit}>
          {() => (
            <>
              <Typography variant="body2" paragraph>
                Please note that some funds will pay the fee on behalf of your client, so long as the application is
                completed online. The fee is charged either as a percentage of the net amount to be subscribed to the
                Fund or as a fixed fee.
                <br />
                <br />
                *Please note that the fee <strong>can</strong> be capped per fund. In the event that the desired charge
                is greater than the cap, the fund&apos;s cap shall be applied.
              </Typography>
              <FormGroup>
                <FormButtons secondaryProps={{ onClick: () => navigate("/advisor/dashboard") }} />
              </FormGroup>
            </>
          )}
        </FormGen>
      </Container>
    </>
  );
};

export default AddClient;
