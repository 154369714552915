import { Button, Container } from "@mui/material";
import { ColumnDefinition, OnChangeObject } from "@wearenova/mui-data-table";
import { getApplications } from "client/api/admin/applications";
import useAdminContext, { AdminActions } from "client/context/admin";
import React, { useCallback, useMemo, useState } from "react";
import { AdminApplication } from "server/services/application";
import AdminTable from "../components/AdminTable";
import MainPanelContainer from "../components/MainPanelContainer";
import usePortfolioOptions from "../hooks/usePortfolioOptions";
import useUserOptions from "../hooks/useUserOptions";
import createTableDefinition from "../utils/createTableDefinition";
import splitUsers from "../utils/splitUsers";
import useIntroducers from "../Applications/hooks/useIntroducers";
import RecommendConfig from "../utils/RecommendConfig";
import _ from "lodash";
import { patchApplication } from "client/api/admin/applications/application";
import { DateTime } from "luxon";

const ApplicationApproval: React.FC = () => {
  const { applications, dispatch: adminDispatch } = useAdminContext();
  const userOptions = useUserOptions();
  const portfolioOptions = usePortfolioOptions();
  const introducers = useIntroducers();

  const [count, setCount] = useState(-1);

  const appsArr = useMemo(() => Object.values(applications), [applications]);

  const { investors, advisors } = useMemo(() => {
    const splitUserOptions = splitUsers(userOptions);
    return { ...splitUserOptions, advisors: [{ label: "none", value: null }, ...splitUserOptions.advisors] };
  }, [userOptions]);

  const setApplications = useCallback(
    async (_onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getApplications({
        columnFilters: [{ type: "data", path: "completedAt", operator: "exists", value: null }],
      });
      if (!res) return [];
      if (!isExport) {
        adminDispatch({ type: AdminActions.SetApps, payload: res.data });
        setCount(res.count);
      }
      return res.data;
    },
    [adminDispatch],
  );

  function calculateRecommendation(app: AdminApplication) {
    let total = 0;
    Object.entries(RecommendConfig).forEach(([dataKey, questions]) => {
      Object.entries(questions).forEach(([answerKey, answerOptions]) => {
        const answer = _.get(app, `${dataKey}.${answerKey}`);
        const score = answerOptions[answer as keyof typeof answerOptions];
        if (typeof score === "number") total += score;
      });
    });
    console.log(total);
    if (total <= 50) {
      return "Deny";
    } else if ((total > 50 && total < 300) || total % 1 != 0) {
      return "Review";
    } else if (total > 300) {
      return "Accept";
    }
  }

  const applicationTable = useMemo<ColumnDefinition<AdminApplication>[]>(
    () =>
      createTableDefinition([
        {
          key: "investorName",
          title: "Investor Name",
          dataIndex: "investor.user.fullName",
        },
        {
          key: "investorEmail",
          title: "Investor Email",
          dataIndex: "investor.user.email",
        },
        {
          key: "fund",
          title: "Fund",
          dataIndex: "fund.name",
        },
        {
          key: "understandsRisk",
          title: "Understands Risk",
          dataType: "boolean",
          dataIndex: "data.objectives.understandsRisk",
        },
        {
          key: "notNeedCapitalAccess",
          title: "Not Need Capital Access",
          dataType: "boolean",
          dataIndex: "data.objectives.notNeedCapitalAccess",
        },
        {
          key: "relyingOnInvestment",
          title: "Relying on Investment",
          dataType: "boolean",
          dataIndex: "data.objectives.relyingOnInvestment",
        },
        {
          key: "willingToLoseInvestment",
          title: "Willing to Lose Investment",
          dataType: "boolean",
          dataIndex: "data.objectives.willingToLoseInvestment",
        },
        {
          key: "whenNeedIncome",
          title: "When Need Income",
          dataIndex: "data.objectives.whenNeedIncome",
        },
        {
          key: "whenNeedAccess",
          title: "When Need Access",
          dataIndex: "data.objectives.whenNeedAccess",
        },
        {
          key: "receivedTaxAdvice",
          title: "Recieved Tax Advice",
          dataType: "boolean",
          dataIndex: "data.objectives.receivedTaxAdvice",
        },
        {
          key: "useSEISRelief",
          title: "Using SEIS Relief",
          dataType: "boolean",
          dataIndex: "data.objectives.useSEISRelief",
        },
        {
          key: "claimIncomeTaxRelief",
          title: "Claim Income Tax Relief",
          dataType: "boolean",
          dataIndex: "data.objectives.claimIncomeTaxRelief",
        },
        {
          key: "poloticallyExposed",
          title: "Politically Exposed",
          dataType: "boolean",
          dataIndex: "investor.user.personalDetails.politicallyExposed.present",
        },
        {
          key: "netDisposableIncome",
          title: "Net Disposable Income",
          dataType: "number",
          dataIndex: "data.finances.netDisposableIncome",
        },
        {
          key: "netAssetsValue",
          title: "Net Assets Value",
          dataType: "number",
          dataIndex: "data.finances.netAssetsValue",
        },
        {
          key: "incomeSources",
          title: "Income Sources",
          dataType: "string",
          dataIndex: "data.finances.incomeSources",
        },
        {
          key: "otherIncomeSources",
          title: "Other Income Sources",
          dataType: "string",
          dataIndex: "data.finances.otherIncomeSources",
        },
        // experience
        {
          key: "taxProducts",
          title: "Invested in Tax Products",
          dataType: "boolean",
          dataIndex: "data.experience.taxProducts.present",
        },
        {
          key: "largeCompanies",
          title: "Invested in Large Companies",
          dataType: "boolean",
          dataIndex: "data.experience.largeCompanies.present",
        },
        {
          key: "smallCompanies",
          title: "Invested in Smaller Companies",
          dataType: "boolean",
          dataIndex: "data.experience.smallCompanies.present",
        },
        {
          key: "aimCompanies",
          title: "Invested in Aim Companies",
          dataType: "boolean",
          dataIndex: "data.experience.aimCompanies.present",
        },
        {
          key: "realEstate",
          title: "Invested in Real Estate",
          dataType: "boolean",
          dataIndex: "data.experience.realEstate.present",
        },
        {
          key: "otherSophisticatedInvestments",
          title: "Invested in Other Investments",
          dataType: "boolean",
          dataIndex: "data.experience.smallCompanies.present",
        },
        {
          key: "companyDirector",
          title: "Been a Company Director (1M+)",
          dataType: "boolean",
          dataIndex: "data.experience.companyDirector.present",
        },
        {
          key: "managementTeam",
          title: "Early Management Team",
          dataType: "boolean",
          dataIndex: "data.experience.managementTeam.present",
        },
        {
          key: "vocationalEducation",
          title: "Vocational Education",
          dataType: "string",
          dataIndex: "data.experience.vocationalEducation",
        },
        {
          key: "financialEducation",
          title: "Financial Education",
          dataType: "boolean",
          dataIndex: "data.experience.financialEducation.present",
        },
        {
          key: "businessAngels",
          title: "Investor Network/Business Angels",
          dataType: "boolean",
          dataIndex: "data.experience.businessAngels.present",
        },
        {
          key: "occupation",
          title: "Occupation",
          dataType: "string",
          dataIndex: "data.experience.occupation",
        },
        {
          key: "levelOfRisk",
          title: "Level of Risk",
          dataType: "string",
          dataIndex: "data.experience.levelOfRisk",
        },
        {
          key: "holdInEarlyStageCompanies",
          title: "Hold In Early Stage Companies",
          dataType: "number",
          dataIndex: "data.experience.holdInEarlyStageCompanies",
        },
        {
          key: "recommendation",
          title: "Reccomendation",
          dataType: "string",
          render: (record) => {
            return calculateRecommendation(record);
          },
        },
        {
          key: "confirmApplication",
          title: "Confirm Application",
          render: (record) => {
            console.log(record);
            return (
              <Container>
                <Button
                  variant="contained"
                  disabled={Boolean(record.investmentManager.approvalChangedAt)}
                  size="small"
                  onClick={async () => {
                    const date = DateTime.now();
                    const res = await patchApplication(record._id, {
                      "investmentManager.approved": true,
                      "investmentManager.approvalChangedAt": date,
                      "aml.status": "signed-off",
                    });
                    if (res) adminDispatch({ type: AdminActions.UpdateApp, payload: res });
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  disabled={Boolean(record.investmentManager.approvalChangedAt)}
                  size="small"
                  color="error"
                  onClick={async () => {
                    const date = DateTime.now();
                    const res = await patchApplication(record._id, {
                      "investmentManager.approved": false,
                      "investmentManager.approvalChangedAt": date,
                      "aml.status": "refer",
                    });
                    if (res) adminDispatch({ type: AdminActions.UpdateApp, payload: res });
                  }}
                >
                  Deny
                </Button>
              </Container>
            );
          },
        },
      ]),
    [investors, portfolioOptions, introducers, advisors, adminDispatch],
  );

  return (
    <MainPanelContainer>
      <AdminTable
        tableData={appsArr}
        onChange={setApplications}
        count={count}
        tableStructure={applicationTable}
        tableProps={{ size: "small" }}
        exportToCSVOption
        csvFilename="Applications"
        defaultSort={{ key: "meta.completedAt", direction: "desc" }}
      />
    </MainPanelContainer>
  );
};

export default ApplicationApproval;
