const EVENTS = {
  ADVISOR: {
    BUTTON: { REGISTER: "Advisor - Register Button Clicked" },
    INPUT: { CATEGORIES: { ONBOARDING: "Advisor - Onboarding", ADD_CLIENT: "Advisor - Add Client" } },
  },
  ACTIONS: { REGISTER: "registerbuttonclick" },
  PAGE_VIEWS: {
    ADVISOR_HOMEPAGE: "advisor homepage viewed",
    INVESTOR_HOMEPAGE: "investor homepage viewed",
    REGISTER: "register page viewed",
    LOGIN: "login page viewed",
    FORGOT_PASSWORD: "forgot password viewed",
    INVESTOR_APPLICATION_QUALIFY_TO_INVEST: "investor application qualify to invest viewed",
    INVESTOR_APPLICATION_QUALIFIED: "investor application qualified viewed",
    INVESTOR_APPLICATION_BEGIN: "investor application begin viewed",
    INVESTOR_APPLICATION_ABOUT: "investor application about viewed",
    INVESTOR_APPLICATION_OBJECTIVES: "investor application objectives viewed",
    INVESTOR_APPLICATION_FINANCIAL: "investor application financial viewed",
    INVESTOR_APPLICATION_KNOWLEDGE: "investor application knowledge viewed",
    INVESTOR_APPLICATION_INVESTMENT: "investor application investment viewed",
    INVESTOR_APPLICATION_COMPLETE: "investor application complete viewed",
    INVESTOR_DASHBOARD: "investor dashboard viewed",
    INVESTOR_DASHBOARD_ACCOUNT_OVERVIEW: "investor dashboard account overview viewed",
    INVESTOR_DASHBOARD_PORTFOLIO_INVESTMENTS: "investor dashboard portfolio of investments viewed",
    INVESTOR_DASHBOARD_COMPANY_INFO: "investor dashboard company info viewed",
    INVESTOR_DASHBOARD_STATEMENT: "investor dashboard statement viewed",
    INVESTOR_DASHBOARD_INVEST_AGREEMENTS: "investor dashboard invest agreements viewed",
    IM_WELCOME: "im welcome viewed",
    IM_OVERVIEW: "im overview viewed",
    IM_HOW_TO: "im how to viewed",
    IM_DETAIL: "im detail viewed",
    IM_RISK: "im risk viewed",
    IM_AGREEMENT: "im agreement viewed",
    IM_IMPORTANT_INFORMATION: "im important information viewed",
    IM_REVIEWS: "im reviews viewed",
    IM_RETURNS_CALCULATOR: "im returns calculator viewed",
    IM_DOCUMENTS: "im documents viewed",
    RISK_WARNING: "risk warning viewed",
    ADVISOR_DASHBOARD: "advisor dashboard viewed",
    ADVISOR_VERIFICATION: "advisor verification viewed",
    ADD_CLIENT: "add client viewed",
    EDIT_CLIENT_FEE: "edit client fee viewed",
    CLIENT_APPLICATION_FORM_BEGIN: "client application form begin viewed",
    CLIENT_APPLICATION_FORM_ABOUT: "client application form about viewed",
    CLIENT_APPLICATION_FORM_OBJECTIVES: "client application form objectives viewed",
    CLIENT_APPLICATION_FORM_FINANCES: "client application form finances viewed",
    CLIENT_APPLICATION_FORM_EXPERIENCE: "client application form experience viewed",
    CLIENT_APPLICATION_FORM_INVESTMENT: "client application form investment viewed",
    CLIENT_APPLICATION_FORM_YOUR_AGREEMENT: "client application form your agreement viewed",
    CLIENT_APPLICATION_FORM_COMPLETE: "client application form complete viewed",
    CLIENT_INVESTMENTS: "client investments viewed",
  },
  PAGE_ACTIONS: {
    REGISTER_FORM_SUBMITTED_SUCCESSFULLY: "register form submitted successfully",
    REGISTER_FORM_SUBMITTED_UNSUCCESSFULLY: "register form submitted unsuccessfully",
    LOGIN_FORM_SUBMITTED_SUCCESSFULLY: "login form submitted successfully",
    LOGIN_FORM_SUBMITTED_UNSUCCESSFULLY: "login form submitted unsuccessfully",
    FORGOT_PASSWORD_SUBMITTED_SUCCESSFULLY: "forgotten password form submitted successfully",
    FORGOT_PASSWORD_SUBMITTED_UNSUCCESSFULLY: "forgotten password submitted unsuccessfully",
    RESEND_CONFIRMATION_EMAIL: "resend confirmation email",
    INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_SUCCESSFULLY: "investor application about submitted successfully",
    INVESTOR_APPLICATION_FORM_ABOUT_SUBMITTED_UNSUCCESSFULLY: "investor applicaton about submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_OBJECTIVES_SUBMITTED_SUCCESSFULLY:
      "investor application objectives submitted successfully",
    INVESTOR_APPLICATION_FORM_OBJECTIVES_SUBMITTED_UNSUCCESSFULLY:
      "investor application objectives submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_FINANCES_SUBMITTED_SUCCESSFULLY: "investor application finaces submitted successfully",
    INVESTOR_APPLICATION_FORM_FINANCES_SUBMITTED_UNSUCCESSFULLY:
      "investor application finances submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_EXPERIENCE_SUBMITTED_SUCCESSFULLY:
      "investor application experience submitted successfully",
    INVESTOR_APPLICATION_FORM_EXPERIENCE_SUBMITTED_UNSUCCESSFULLY:
      "investor application experience submitted unsuccessfully",
    INVESTOR_APPLICATION_FORM_INVESTMENT_SUBMITTED_SUCCESSFULLY:
      "investor application investment submitted successfully",
    INVESTOR_APPLICATION_FORM_INVESTMENT_SUBMITTED_UNSUCCESSFULLY:
      "investor application investment submitted unsuccessfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED_SUCCESSFULLY: "qualifying certificate file downloaded successfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED_UNSUCCESSFULLY: "qualifying certificate file downloaded unsuccessfully",
    QUALIFYING_EVIDENCE_FILE_DOWNLOADED_SUCCESSFULLY: "qualifying evidence file downloaded successfully",
    QUALIFYING_EVIDENCE_FILE_DOWNLOADED_UNSUCCESSFULLY: "qualifying evidence file downloaded unsuccessfully",
    CONTRACT_NOTE_DOWNLOADED_SUCCESSFULLY: "contact note downloaded successfully",
    COMPANY_INFO_LINK_CLICKED: "company info link clicked",
    INVESTMENT_AGREEMENT_FILE_DOWNLOADED: "investment agreement file downloaded",
    RETURNS_CALCULATOR_FORM_SUBMITTED_SUCCESSFULLY: "returns calculator form submitted successfully",
    RETURNS_CALCULATOR_FORM_SUBMITTED_UNSUCCESSFULLY: "returns calculator form submitted unsuccessfully",
    IM_RISK_FILE_DOWNLOADED: "im risk file downloaded",
    IM_FILE_VIEWED: "im file viewed",
    IM_KID_VIEWED: "im key information document viewed",
    IM_PRODUCT_OVERVIEW_VIEWED: "im product overview viewed",
    IM_TAX_CASE_STUDY_VIEWED: "im tax case study viewed",
    IM_GROWTH_CASE_STUDY_VIEWED: "im growth case study viewed",
    IM_IFA_DOC_VIEWED: "im ifa doc viewed",
    INVESTOR_APPLICATION_FORM_INVESTMENT_FILE_DOWNLOADED: "investor application form investment file downloaded",
    ADVISOR_VERIFICATION_FORM_SUBMITTED_SUCCESSFULLY: "advisor verification form submitted successfully",
    ADVISOR_VERIFICATION_FORM_SUBMITTED_UNSUCCESSFULLY: "advisor verification form submitted unsuccessfully",
    ADD_CLIENT_FORM_SUBMITTED_SUCCESSFULLY: "add client form submitted successfully",
    ADD_CLIENT_FORM_SUBMITTED_UNSUCCESSFULLY: "add client form submitted unsuccessfully",
    EDIT_CLIENT_FEE_FORM_SUBMITTED_SUCCESSFULLY: "edit client fee form submitted successfully",
    EDIT_CLIENT_FEE_FORM_SUBMITTED_UNSUCCESSFULLY: "edit client fee form submitted unsuccessfully",
    CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_SUCCESSFULLY: "client application about form submitted successfully",
    CLIENT_APPLICATION_ABOUT_FORM_SUBMITTED_UNSUCCESSFULLY: "client application about form submitted unsuccessfully",
    CLIENT_APPLICATION_OBJECTIVES_FORM_SUBMITTED_SUCCESSFULLY:
      "client application objectives form submitted successfully",
    CLIENT_APPLICATION_OBJECTIVES_FORM_SUBMITTED_UNSUCCESSFULLY:
      "client application objectives form submitted unsuccessfully",
    CLIENT_APPLICATION_FINANCES_FORM_SUBMITTED_SUCCESSFULLY: "client application finances form submitted successfully",
    CLIENT_APPLICATION_FINANCES_FORM_SUBMITTED_UNSUCCESSFULLY:
      "client application finances form submitted unsuccessfully",
    CLIENT_APPLICATION_EXPERIENCE_FORM_SUBMITTED_SUCCESSFULLY:
      "client application experience form submitted successfully",
    CLIENT_APPLICATION_EXPERIENCE_FORM_SUBMITTED_UNSUCCESSFULLY:
      "client application experience form submitted unsuccessfully",
    CLIENT_APPLICATION_INVESTMENT_FORM_SUBMITTED_SUCCESSFULLY:
      "client application investment form submitted successfully",
    CLIENT_APPLICATION_INVESTMENT_FORM_SUBMITTED_UNSUCCESSFULLY:
      "client application investment form submitted unsuccessfully",
    QUALIFYING_CERTIFICATE_FILE_DOWNLOADED: "qualifying certificate file downloaded",
    PORTFOLIO_FILTER_CLICKED: "filter clicked on portfolio statement",
    PORTFOLIO_DOCUMENT_DOWNLOAD_CLICKED: "portfolio document download clicked",
    CLIENT_PORTFOLIO_PDF: "client portfolio pdf downloaded",
    PORTFOLIO_PDF: "portfolio pdf downloaded",
    CLIENT_PORTFOLIO_CSV: "client portfolio csv downloaded",
    PORTFOLIO_CSV: "portfolio csv downloaded",
  },
  BUTTON: {
    APPLY: "Apply Button Clicked",
    CERTIFY: "Certify Button Clicked",
    REGISTER: "Register Button Clicked",
    LOGIN: "Login Button Clicked",
    INVEST_FUNDS: "Invest Funds Button Clicked",
    DASHBOARD: "Dashboard Button Clicked",
    ADVISOR_DASHBOARD: "Advisor Dashboard Button Clicked",
    ADVISOR_ONBOARD: "Advisor Onboard Button Clicked",
    EDIT_MONTHLY_INVESTMENTS: "Edit Monthly Investment Button Clicked",
    RESEND_APPLICATION: "Resend Application Button Clicked",
    SIGN_AGREEMENT: "Sign Agreement Button Clicked",
  },
  INPUT: {
    COMPLETED: "Input Completed",
    CATEGORIES: {
      PERSONAL_DETAILS: "Personal Details",
      INVESTMENT_OBJECTIVES: "Investment Objectives",
      KNOWLEDGE_EXPERIENCE: "Knowledge Experience",
      FINANCIAL_SITUATION: "Financial Situation",
      INVESTMENT_DETAILS: "Investment Details",
    }, // Currently the event labels for all text, number and radio inputs within these categories are generated from the ids
    CHECKBOX: {
      IS_READ_IM: "Is Read IM",
      UNDERSTAND_RISK: "Understand Risk",
      IS_FINANCIAL_ADVICE: "Is Financial Advice",
    },
  },
} as const;

export default EVENTS;
