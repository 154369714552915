import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AppFormType } from "client/views/AdminPanel/Applications/Form";
import { DocumentDefinition } from "mongoose";
import { AdminApplication, LeanApplication } from "server/services/application";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getApplications<F extends LiteralUnion<keyof LeanApplication, string>[] | undefined = undefined>(
  this: ThisAxios,
  onChangeObject?: AdminQuery<AdminApplication, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<AdminApplication, F[number]>[] : AdminApplication[]
    >("/admin/applications", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export async function getIntroducers(this: ThisAxios) {
  try {
    const res = await (this || ipAxios).get<string[]>("/admin/applications/introducers");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createApplication = async ({
  disableApprovalEmail,
  disableFundsReceivedEmail,
  ...app
}: DocumentDefinition<AppFormType>) => {
  try {
    const res = await ipAxios.post<AdminApplication>("/admin/applications", app, {
      params: { disableApprovalEmail, disableFundsReceivedEmail },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
