import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import type { OnboardingValues } from "client/views/Advisor/Onboarding";
import { AuthContextUser } from "server/services/user";

export const onboardAdvisor = async (values: OnboardingValues) => {
  try {
    const res = await ipAxios.post<AuthContextUser>("/advisor/onboard", values);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
