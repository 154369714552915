import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import useToggle from "client/hooks/useToggle";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "TouchNavbar" })((theme) => ({
  menuButton: {
    marginLeft: "auto",
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "unset",
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(6),
  },
  touchNavContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const TouchNavbar: React.FC = (props) => {
  const [dialogOpen, toggleDialog] = useToggle(false);
  const { classes } = useStyles();
  return (
    <>
      <IconButton onClick={toggleDialog.on} color="inherit" className={classes.menuButton}>
        <MenuIcon />
      </IconButton>
      <Dialog open={dialogOpen} onClick={toggleDialog.off} onClose={toggleDialog.off} fullScreen>
        <DialogContent className={classes.touchNavContainer}>
          <IconButton onClick={toggleDialog.off} color="inherit" className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TouchNavbar;
