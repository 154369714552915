import { DialogContentText, Link, Typography } from "@mui/material";
import ResponsiveDialog from "client/components/ResponsiveDialog";
import useAuthContext from "client/context/auth";
import useConfigContext from "client/context/config";
import useToggle from "client/hooks/useToggle";
import React, { useEffect, useRef, useState } from "react";
import RiskWarning from "./RiskWarning";
import { RiskWarningStatus } from "../../shared/consts";

interface Props {
  isPersistent?: boolean;
}
const InitialRiskWarning: React.FC<Props> = ({ isPersistent = true }) => {
  const [open, toggleOpen] = useToggle(false);
  const riskWarningRef = useRef<() => void>(null);
  const [riskWarningLocalStateKey, setRiskWarningLocalStateKey] = useState<string>("");

  const [stayButtonDisabled, setStayButtonDisable] = useState<boolean>(true);

  const { user } = useAuthContext();
  const { portal } = useConfigContext();

  useEffect(() => {
    const handlePersistency = () => {
      if (user && user.userId) {
        setRiskWarningLocalStateKey(user.userId);

        const riskWarningStatus = localStorage.getItem(riskWarningLocalStateKey);
        if (riskWarningStatus) {
          if (riskWarningStatus === RiskWarningStatus.READ) {
            toggleOpen.set(true);
            setStayButtonDisable(false);
          } else if (riskWarningStatus === RiskWarningStatus.ACKNOWLEDGED) {
            toggleOpen.set(false);
          }
        } else {
          toggleOpen.set(true);
        }
      }
    };
    if (isPersistent) {
      handlePersistency();
    } else {
      toggleOpen.set(true);
    }
  }, [isPersistent, riskWarningLocalStateKey, toggleOpen, user]);

  const redirectToLandingPage = () => {
    window.location.href = portal.links.alternateSite;
  };

  const onWarningRead = () => {
    if (user?.userId && isPersistent) {
      localStorage.setItem(riskWarningLocalStateKey, RiskWarningStatus.READ);
    }
    setStayButtonDisable(false);
  };
  const handleStayButtonClicked = () => {
    if (user?.userId && isPersistent) {
      localStorage.setItem(riskWarningLocalStateKey, RiskWarningStatus.ACKNOWLEDGED);
    }
    toggleOpen.off();
  };
  return (
    <>
      <RiskWarning ref={riskWarningRef} onWarningRead={onWarningRead}>
        <ResponsiveDialog
          open={open}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClose={() => {}}
          actionButtons={[
            {
              label: "Stay",
              disabled: stayButtonDisabled,
              tooltip: stayButtonDisabled
                ? "Please select the “Take 2 mins to learn more” link above before continuing"
                : "",
              onClick: handleStayButtonClicked,
              attributes: { "data-testid": "stay" },
            },
            { label: "Leave", onClick: redirectToLandingPage },
          ]}
        >
          <DialogContentText component="div" align="justify" sx={{ color: "inherit" }}>
            <Typography paragraph>
              {`${user?.forenames} ${user?.surname}`}, this is a high-risk investment. How would you feel if you lost
              the money you’re about to invest?{" "}
              <Link
                onClick={() => {
                  riskWarningRef.current?.();
                }}
                data-testid="take-2-mins-to-learn"
              >
                Take 2 mins to learn more
              </Link>
            </Typography>
          </DialogContentText>
        </ResponsiveDialog>
      </RiskWarning>
    </>
  );
};

export default InitialRiskWarning;
