import { DialogContentText } from "@mui/material";
import DynamicList from "client/components/DynamicList";
import ResponsiveDialog from "client/components/ResponsiveDialog";
import React from "react";

interface WhyQuestionsProps {
  open: boolean;
  onClose(): void;
}

const NetAssetsExclusions: React.FC<WhyQuestionsProps> = (props) => {
  return (
    <ResponsiveDialog
      open={props.open}
      closeButton={{ onClick: props.onClose }}
      actionButtons={[{ label: "Done", onClick: props.onClose }]}
      title="What should I exclude from net assets?"
    >
      <DialogContentText color="textPrimary" align="justify" component="div">
        You should not include the following in your estimated value of your net assets:
        <DynamicList
          variant="numbered"
          items={[
            "Your principal private residence or any loan secured on your residence.",
            "Any rights under an insurance policy (other than investment linked policies).",
            "Any benefits (in the form of pensions or otherwise) which are payable on termination of service or on the death or retirement and to which you (or your dependents) are, or may be entitled.",
          ]}
        />
      </DialogContentText>
    </ResponsiveDialog>
  );
};

export default NetAssetsExclusions;
