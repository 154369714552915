import { AxiosInstance } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AdvisorStateClient, ClientUser, LeanUser } from "server/services/user";

export async function getClients(this: AxiosInstance | undefined) {
  try {
    const res = await (this || ipAxios).get<AdvisorStateClient[]>("/advisor/clients");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const createClient = async (
  values: Pick<LeanUser, "forenames" | "surname" | "email"> & Pick<ClientUser, "fee">,
) => {
  try {
    const res = await ipAxios.post<AdvisorStateClient>("/advisor/clients", values);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
