import { Link, LinkProps, Typography, TypographyProps } from "@mui/material";
import LinkifyIt from "linkify-it";
import React, { Fragment, ReactNode, useMemo } from "react";

interface LinkifyProps extends TypographyProps {
  linkProps?: Partial<LinkProps>;
}

const linkify = new LinkifyIt();

const split = (value?: ReactNode): ReactNode | Array<string | LinkifyIt.Match> => {
  if (typeof value !== "string") return value;

  const matches = linkify.match(value);
  if (!matches?.length) return value;

  const res = matches.flatMap((match, idx) => {
    const prefix = match.index && value.substring(matches[idx - 1]?.lastIndex ?? 0, match.index);
    if (!prefix) return match;
    return [prefix, match];
  });

  const lastMatch = matches[matches.length - 1];
  const rest = value.substring(lastMatch.lastIndex);
  if (rest) res.push(rest);

  return res;
};

const Linkify: React.FC<LinkifyProps> = ({ linkProps, ...props }) => {
  const els = useMemo(() => split(props.children), [props.children]);
  return (
    <Typography variant="inherit" color="inherit" whiteSpace="pre-line" {...props}>
      {!Array.isArray(els)
        ? els
        : els.map((el, idx) => {
            if (typeof el === "string") return <Fragment key={idx}>{el}</Fragment>;
            return (
              <Link target="_blank" rel="noopener noreferrer" variant="inherit" {...linkProps} key={idx} href={el.url}>
                {el.text}
              </Link>
            );
          })}
    </Typography>
  );
};

export default Linkify;
