import ipAxios, { isAxiosError, ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanApplication } from "server/services/application";

export async function getLatestApplication(this: ThisAxios, fundId: string) {
  try {
    const res = await (this || ipAxios).get<LeanApplication>("/applications/latest", { params: { fund: fundId } });
    return res.data;
  } catch (error) {
    Logging.error(error);
    if (isAxiosError(error) && error.response?.data === "fund_not_found") return { redirectTo: "/apply/funds" };
    return null;
  }
}
