import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";

const useScrollToTop = (deps?: unknown) => {
  const { pathname } = useLocation();
  useEffect(
    () => {
      animateScroll.scrollToTop({ duration: 0 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    !deps || !Array.isArray(deps) ? [deps ?? pathname] : deps,
  );
};

export default useScrollToTop;
