import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Forms } from "../utils";

const PAGE_ID_REGEX = /\/(?<pageId>[^/]+)(\?.+)?$/i;

const useFormId = () => {
  const location = useLocation();
  return useMemo(() => location.pathname.match(PAGE_ID_REGEX)?.groups?.pageId as Forms, [location.pathname]);
};

export default useFormId;
