import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import useToggle from "client/hooks/useToggle";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";

interface Props {
  title: string;
}

const useStyles = makeStyles({ name: "FAQItem" })((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.contrastText}`,
    "&:before": {
      display: "none",
    },
  },
  summary: {
    flexDirection: "row-reverse",
    "& > .MuiAccordionSummary-expandIconWrapper": {
      color: "inherit",
      transform: "none",
      paddingRight: theme.spacing(1),
    },
  },
}));

const AccordionItem: FC<Props> = ({ title, children }) => {
  const { classes } = useStyles();
  const [expanded, toggleExpanded] = useToggle(false);
  return (
    <Accordion expanded={expanded} onChange={toggleExpanded} className={classes.root}>
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />} className={classes.summary}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
