import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import type { CertificationFormValues } from "client/views/Certification";
import { AuthContextUser } from "server/services/user";

export const submitCertification = async (values: CertificationFormValues) => {
  try {
    const res = await ipAxios.post<AuthContextUser>("/certification", values);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
