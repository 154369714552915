import React, { useContext } from "react";
import { ClientConfig } from "server/config";

const ConfigContext = React.createContext<ClientConfig>({} as ClientConfig);

export const ConfigProvider = ConfigContext.Provider;

export const useConfigContext = () => {
  return useContext(ConfigContext);
};

export default useConfigContext;
