import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterLuxon as LuxonAdapter } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Settings } from "luxon";
import React, { useMemo } from "react";
import { Route } from "react-router-dom";
import { ClientConfig } from "server/config";
import mergeThemes from "shared/mergeThemes";
import Footer from "./components/Footer";
import AuthedRoutes from "./components/Routing/AuthedRoutes";
import UnimplementedRoutes from "./components/Routing/UnimplementedRoute";
import SentryRoutes from "./components/SentryRoutes";
import { AuthProvider } from "./context/auth";
import { ConfigProvider } from "./context/config";
import useDescription from "./hooks/useDescription";
import useTitle from "./hooks/useTitle";
import AdminPanel from "./views/AdminPanel";
import Advisor from "./views/Advisor";
import Application from "./views/Application";
import Certification from "./views/Certification";
import ConfirmEmail from "./views/ConfirmEmail";
import Home from "./views/Home";
import BackupCodes from "./views/Home/BackupCodes";
import Login from "./views/Home/Login";
import Register from "./views/Home/Register";
import TwoFactorAuth from "./views/Home/TwoFactorAuth";
import PageLayout from "./views/PageLayout";
import SetPassword from "./views/SetPassword";

Settings.defaultLocale = "en-GB";

interface AppProps {
  config: ClientConfig;
}

const App: React.FC<AppProps> = ({ config }) => {
  useTitle(config.portal.meta.titles.default);
  useDescription(config.portal.meta.descriptions.default);

  const theme = useMemo(() => mergeThemes(config.portal.theme), [config.portal.theme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfigProvider value={config}>
        <AuthProvider>
          <LocalizationProvider dateAdapter={LuxonAdapter} adapterLocale="en-GB">
            <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
              <SentryRoutes>
                <Route path="/" element={<Home />}>
                  <Route index element={<Login />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="authenticate" element={<TwoFactorAuth />} />
                  <Route path="backup" element={<BackupCodes />} />
                </Route>
                <Route path="*" element={<PageLayout />}>
                  <Route path="reset-password" element={<SetPassword isReset />} />
                  <Route path="set-password" element={<SetPassword />} />
                  <Route path="email-confirmation" element={<ConfirmEmail />} />
                </Route>
                <Route path="*" element={<AuthedRoutes />}>
                  <Route path="admin/*" element={<AdminPanel />} />
                  <Route path="certify" element={<Certification />} />
                  <Route path="apply/*" element={<Application />} />
                  <Route path="applications/:applicationId/*" element={<Application />} />
                  <Route path="advisor/*" element={<Advisor />} />
                  <Route element={<UnimplementedRoutes />}>
                    <Route path="dashboard" />
                  </Route>
                </Route>
              </SentryRoutes>
            </Box>
            <Footer />
          </LocalizationProvider>
        </AuthProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
