import { Button, Grid, Container, Typography, FormGroup, Link } from "@mui/material";
import React, { useCallback, useMemo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InvestorDetails } from "server/services/application/model";
import { AssessmentStatus } from "server/services/assessment/consts";
import useApplication from "../hooks/useApplication";
import useAppStyles from "../hooks/useAppStyles";
import useFundsContext from "client/context/funds";
import { completeUserAssessment } from "client/api/applications/application";
import { completeAdvisedAssessment, skipCooldown } from "client/api/advisor/clients/client/applications/application";
import CheckboxField from "../../../components/Fields/CheckboxField";
import { Field, Form, Formik } from "formik";
import CoolDownTimer from "./CoolDownTimer";

interface CoolDownProps {
  investorDetail: InvestorDetails | undefined;
  assessmentStatus: AssessmentStatus;
  coolDownTime: Date | null;
  assessmentId: string;
}

const CoolDown: React.FC<CoolDownProps> = (props) => {
  const { classes } = useAppStyles();
  const navigate = useNavigate();
  const { isAdvisor, clientId } = useApplication();
  const { selected: currentFund } = useFundsContext();
  const { assessmentStatus, investorDetail, coolDownTime, assessmentId } = props;
  const investorName =
    investorDetail?.forenames && investorDetail?.surname
      ? `${investorDetail?.forenames} ${investorDetail?.surname}`
      : `${investorDetail?.forenames}`;
  const topReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topReference.current?.focus();
  }, []);

  const goFunds = useCallback(() => navigate(isAdvisor ? "../../funds" : `../../funds`), [isAdvisor, navigate]);

  const handleSubmit = useCallback(async () => {
    if (isAdvisor && clientId != null) {
      const res = await completeAdvisedAssessment(clientId);
      if (res && res.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) navigate(0);
    } else {
      const res = await completeUserAssessment(assessmentId);
      if (res && res.status == AssessmentStatus.CONTINUE_TO_THE_APPLICATION) navigate(0);
    }
  }, [assessmentId, clientId, isAdvisor, navigate]);

  const skipCooldownSubmit = useCallback(async () => {
    if (isAdvisor && clientId != null) {
      const res = await skipCooldown(clientId);
      if (res && res.status == AssessmentStatus.COMPLETED_SUCCESSFULLY) navigate(0);
    }
  }, [clientId, isAdvisor, navigate]);

  const unsuccessfullyCoolDown = useCallback(() => {
    return (
      <>
        <Container>
          <CoolDownTimer isSuccessCoolDown={false} message={"Application unsuccessful"} coolDownTime={coolDownTime} />

          <Typography className={classes.paraGraph} paragraph={true}>
            Dear {investorName},
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Unfortunately, based on the results of your appropriateness test assessment, it appears that investing in
            early stage companies may not be the best fit for you at this time. It&apos;s important to remember that
            investing in early stage companies carries a higher level of risk and uncertainty than investing in more
            established companies. We strongly advise that you consider your personal financial situation and risk
            tolerance before making any investment decisions.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Investing in early stage companies can be a highly speculative and risky endeavor. These companies are
            typically in the early stages of development and may not have a proven track record of success. They may
            also be in the process of raising capital, or have a limited history of revenue and earnings. As a result,
            the value of your investment may be highly volatile and may be subject to significant fluctuations.
            Additionally, there is a risk that the company may not achieve its goals or may fail entirely, resulting in
            a total loss of your investment.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Given these risks, it is important that you carefully consider your risk tolerance and financial situation
            before making any investment decisions. We understand that the allure of high returns can be tempting, but
            it is crucial that you only invest what you can afford to lose. We invite you to retake the appropriateness
            test in 24 hours, or to consult with a financial advisor to determine if this type of investment is suitable
            for you.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            It&apos;s important to note that investing in early stage companies can also be rewarding, as they have the
            potential to generate significant returns if they are successful. However, it&apos;s important to approach
            these investments with caution and to fully understand the risks involved. We hope that you will take the
            time to reassess your risk tolerance and financial situation before making any investment decisions. Please
            do not hesitate to reach out to us if you have any questions or need further information.
          </Typography>

          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            {currentFund?.name} Manager
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true} sx={{ fontWeight: "bold" }}>
            When your countdown has finished, please refresh this page to continue
          </Typography>
        </Container>
      </>
    );
  }, [classes.paraGraph, coolDownTime, currentFund?.name, investorName]);
  const successfullyCoolDownInvestor = useCallback(() => {
    return (
      <>
        <Container>
          <CoolDownTimer isSuccessCoolDown={false} message={"Success!"} coolDownTime={coolDownTime} />
          <Typography className={classes.paraGraph} paragraph={true}>
            Dear {investorName},
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            We are writing to inform you that before proceeding with your investment in the {currentFund?.name}, you are
            required to wait a 24-hour cooling-off period. This period is intended to give you an opportunity to reflect
            on the risks associated with investing in early-stage and high-risk investments, and to ensure that you
            fully understand the potential consequences of your investment decision.
          </Typography>

          <Typography className={classes.paraGraph} paragraph={true}>
            Early-stage and high-risk investments carry a higher level of risk compared to more established and
            lower-risk investments. This means that there is a higher possibility of experiencing significant losses on
            the investment. It is important for investors to carefully consider the risks and potential returns of any
            investment before making a decision, and to ensure that they are suitable for the level of risk involved.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            We encourage you to use this cooling-off period to carefully review the information provided to you about
            the {currentFund?.name}, including the risks and potential returns. We also recommend that you seek
            independent financial advice if you have any concerns about your ability to handle the risks associated with
            this type of investment.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Thank you for your attention to this matter.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sincerely,
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP Investment manager of the {currentFund?.name}
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true} sx={{ fontWeight: "bold" }}>
            When your countdown has finished, please refresh this page to continue
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Please note, if you are an existing investor in a previous fund from this provider, you may be eligible to
            bypass the 24-hour cooling off period. To do so, please email Beth at{" "}
            <Link href={`mailto:beth@sapphirecapitalpartners.co.uk`}>beth@sapphirecapitalpartners.co.uk</Link> or Ben at{" "}
            <Link href={`mailto:ben@sapphirecapitalpartners.co.uk`}>ben@sapphirecapitalpartners.co.uk</Link>.
          </Typography>
        </Container>
      </>
    );
  }, [classes.paraGraph, coolDownTime, currentFund?.name, investorName]);
  const successfullyCoolDownAdvisor = useCallback(
    () => (
      <>
        <Container>
          <CoolDownTimer isSuccessCoolDown={false} message={"Success!"} coolDownTime={coolDownTime} />
          <Typography className={classes.paraGraph} paragraph={true}>
            Dear Third-Party Advisor,
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            As an authorized alternative investment manager under the Financial Conduct Authority (FCA), we are
            committed to ensuring that all of our investment offerings comply with the FCA policy statement on consumer
            duty (PS22/10) as issued in November 2022.
          </Typography>

          <Typography className={classes.paraGraph} paragraph={true}>
            In accordance with this policy, we are writing to confirm that you have fulfilled the following requirements
            prior to the investment of your client:
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            <ol>
              <li>You have shown the appropriate risk warning.</li>
              <li>You have assessed the appropriateness of the investor to this type of investment.</li>
              <li>
                You have applied a 24-hour cooling off period, during which you have asked the investor to confirm their
                intention to proceed with the investment.
              </li>
            </ol>
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            If all of the above requirements have been met, we invite you to confirm your compliance by clicking the
            &quot;I Confirm&quot; button below.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Your confirmation serves as a legal and formal acknowledgement that you have acted in accordance with the
            FCA&apos;s policy statement on consumer duty (PS22/10) and that the investment in question meets the
            necessary standards for consumer protection.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            We thank you for your cooperation and for helping to ensure that our investments are conducted in a
            responsible and compliant manner.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sincerely,
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP,
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Authorised Alternative Investment Manager
          </Typography>

          <Formik onSubmit={skipCooldownSubmit} initialValues={{ confirm: false }}>
            {(values) => {
              return (
                <Form>
                  <FormGroup>
                    <Field name="confirm" component={CheckboxField} label={"I Confirm"} />
                    {values.values.confirm && (
                      <Button type="submit" variant="contained" color="primary">
                        Continue
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </>
    ),
    [classes.paraGraph, coolDownTime, skipCooldownSubmit],
  );
  const successfullyCoolDown = useCallback(() => {
    return isAdvisor ? successfullyCoolDownAdvisor() : successfullyCoolDownInvestor();
  }, [isAdvisor, successfullyCoolDownAdvisor, successfullyCoolDownInvestor]);
  const completedCoolDown = useCallback(() => {
    return (
      <>
        <Container>
          <CoolDownTimer isSuccessCoolDown={true} message={"Do you wish to proceed?"} coolDownTime={coolDownTime} />
          <Typography className={classes.paraGraph} paragraph={true}>
            Dear {investorName},
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            We are writing to inform you that the 24-hour cooling-off period has now passed, and you are now able to
            proceed with your investment in the {currentFund?.name} if you choose to do so.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Before making a decision, we encourage you to consider the following:
            <ul>
              <li>Do you wish to proceed with the investment in the {currentFund?.name}?</li>
              <li>
                Do you understand the risks associated with investing in early-stage and high- risk investments, and are
                you comfortable with the potential consequences of your investment decision?
              </li>
            </ul>
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Please let us know if you wish to proceed with the investment or if you do not wish to proceed. If you
            choose to proceed, we will need your confirmation that you understand the risks involved and are comfortable
            with the potential consequences of your investment decision.
          </Typography>

          <Typography className={classes.paraGraph} paragraph={true}>
            Thank you for your attention to this matter.
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sincerely,
          </Typography>
          <Typography className={classes.paraGraph} paragraph={true}>
            Sapphire Capital Partners LLP Investment manager of the {currentFund?.name}
          </Typography>
        </Container>

        <Container>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button variant="contained" sx={{ display: "block", px: 6 }} onClick={handleSubmit}>
                <Typography variant="h4">YES</Typography>
                <Typography variant="body2" sx={{ textTransform: "none" }}>
                  I wish to proceed with my initial investment
                </Typography>
              </Button>
            </Grid>

            <Grid item md={6}>
              <Button variant="contained" sx={{ display: "block", px: 6 }} onClick={goFunds}>
                <Typography variant="h4">NO</Typography>
                <Typography variant="body2" sx={{ textTransform: "none" }}>
                  This investment is not suitable for me
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }, [classes.paraGraph, coolDownTime, currentFund?.name, goFunds, handleSubmit, investorName]);
  const showCoolDown = useMemo(() => {
    if (assessmentStatus == AssessmentStatus.COOLDOWN_SUCCESS) return successfullyCoolDown();
    if (assessmentStatus == AssessmentStatus.COOLDOWN_FAILED) return unsuccessfullyCoolDown();
    if (assessmentStatus == AssessmentStatus.COMPLETED_SUCCESSFULLY) return completedCoolDown();
  }, [assessmentStatus, completedCoolDown, successfullyCoolDown, unsuccessfullyCoolDown]);

  return (
    <>
      <div ref={topReference} tabIndex={-1} />
      {showCoolDown}
    </>
  );
};

export default CoolDown;
