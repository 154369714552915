import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { UserFormType } from "client/views/AdminPanel/Users/Form";
import { AdminUser } from "server/services/user";

export const updateUser = async (id: string, changes: UserFormType) => {
  try {
    const res = await ipAxios.put<AdminUser>(`/admin/users/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const patchUser = async (id: string, changes: Partial<UserFormType>) => {
  try {
    const res = await ipAxios.patch<AdminUser>(`/admin/users/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/users/${id}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getUserPortfolio = async (id: string) => {
  try {
    const res = await ipAxios.get(`/admin/users/${id}/portfolio`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const inviteUser = async (id: string) => {
  try {
    const res = await ipAxios.post(`/admin/users/${id}/invite`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const skipCoolOff = async (id: string) => {
  try {
    const res = await ipAxios.post(`/admin/users/${id}/skip-cool-off`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const loginAsUser = async (id: string) => {
  try {
    const res = await ipAxios.post(`/admin/users/${id}/login`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
