import {
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import useHelperText from "client/hooks/useHelperText";
import enumToOptions from "client/utils/enumToOptions";
import { FieldProps } from "formik";
import React, { ReactNode, useCallback, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import TrackInput, { TrackInputProps } from "../TrackInput";

export type RadioOption = string | { label: FormControlLabelProps["label"]; value: string | boolean | number };

export type EnumRadioOptions = { [label: string]: string };

export interface RadioFieldProps extends FieldProps, Omit<RadioGroupProps, "color">, TrackInputProps<any> {
  label: string;
  options?: RadioOption[] | EnumRadioOptions;
  helperText?: ReactNode;
  optionLabelProps?: TypographyProps;
}

const useStyles = makeStyles({ name: "RadioField" })((theme) => ({
  optionLabel: {
    padding: theme.spacing(1, 0),
  },
}));

const RadioField: React.FC<RadioFieldProps> = ({
  form,
  field,
  label,
  category,
  onChange,
  optionLabelProps,
  options: optionsProp = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  ...props
}) => {
  const { classes } = useStyles();
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const options = useMemo(
    () =>
      !Array.isArray(optionsProp)
        ? enumToOptions(optionsProp)
        : optionsProp.map((option) => (typeof option === "object" ? option : { label: option, value: option })),
    [optionsProp],
  );

  const handleChange = useCallback<Required<RadioGroupProps>["onChange"]>(
    (e, value) => {
      if (onChange) return onChange(e, value);
      const selectedValue = options.find((option) => String(option.value) === value);
      form.setFieldValue(field.name, selectedValue?.value);
    },
    [field.name, form, onChange, options],
  );

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup {...field} {...props} onChange={handleChange}>
        {options.map((option) => (
          <FormControlLabel
            key={String(option.value)}
            control={<Radio className="fs-exclude" />}
            value={option.value}
            label={
              <Typography variant="inherit" {...optionLabelProps} className={classes.optionLabel}>
                {option.label}
              </Typography>
            }
            className="fs-exclude"
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default RadioField;
