import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import Divider from "client/components/Divider";
import DynamicAvatar from "client/components/DynamicAvatar";
import Linkify from "client/components/Linkify";
import useConfigContext from "client/context/config";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import ReactPlayer from "react-player/lazy";
import { animateScroll } from "react-scroll";
import { AdminCompany, CompanyWithHoldings } from "server/services/company";
import DateTime from "shared/utils/DateTime";
import { makeStyles } from "tss-react/mui";
import SectorDetails from "./SectorDetails.component";

interface CompanyInfoProps {
  company: AdminCompany | CompanyWithHoldings;
}

const useStyles = makeStyles({ name: "CompanyInfo" })((theme) => ({
  aboutHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  details: {
    "& > div": {
      paddingBottom: theme.spacing(2),
    },
  },
  mediaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      maxHeight: 300,
    },
  },
  updates: {
    maxHeight: 450,
    overflowY: "auto",
  },
}));

const StyledReactPlayer = styled(ReactPlayer)({
  display: "flex",
  width: "100%",
  aspectRatio: "16 / 9",
  minHeight: 220,
});

const CompanyInfo: React.FC<CompanyInfoProps> = ({ company }) => {
  const { classes } = useStyles();
  const { portal } = useConfigContext();

  useEffect(() => {
    animateScroll.scrollToTop({ duration: 0 });
  }, []);

  const updates = useMemo(() => {
    if (!company.info.updates.length) return null;
    return _.orderBy(company.info.updates, "date", "desc");
  }, [company.info.updates]);

  const links = useMemo(() => {
    if (!company.links?.length) return null;
    return _.sortBy(company.links, "name");
  }, [company.links]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6} className={classes.mediaContainer}>
          {company.info.videoURL && ReactPlayer.canPlay(company.info.videoURL) ? (
            <StyledReactPlayer
              url={company.info.videoURL}
              fallback={<Skeleton sx={{ height: "100%", width: "100%" }} />}
              width="100%"
              height="100%"
              onStart={() =>
                window.dataLayer.push({
                  event: "company video link clicked",
                  eventProps: { companyName: company.companyName },
                })
              }
            />
          ) : (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                background: `url(${company.logos?.primary || portal.logos.primary}) center/contain no-repeat`,
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" textTransform="uppercase">
            {company.info.brandName || company.companyName}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {company.info.brandName && company.companyName}
          </Typography>
          <Typography variant="body1">{company.info.headline}</Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.details}>
          {Boolean(company.info.team.length) && (
            <div>
              <Typography variant="h3" gutterBottom textTransform="uppercase">
                Team
              </Typography>
              <List disablePadding>
                {company.info.team.map((member, index) => (
                  <ListItem key={index} disableGutters>
                    <ListItemAvatar>
                      <DynamicAvatar src={member.image} text={member.name} />
                    </ListItemAvatar>
                    <ListItemText secondary={member.role}>
                      {!member.profileURL && member.name}
                      {member.profileURL && (
                        <Link href={member.profileURL} variant="inherit">
                          {member.name}
                        </Link>
                      )}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          {links && (
            <div>
              <Typography variant="h3" gutterBottom textTransform="uppercase">
                Links
              </Typography>
              <List dense disablePadding>
                {links.map((link) => (
                  <ListItem key={link._id} disableGutters>
                    <ListItemText secondary={link.category}>
                      <Link href={link.url} variant="inherit" target="_blank" rel="noopener noreferrer">
                        {link.name}
                      </Link>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" gutterBottom className={classes.aboutHeader}>
            ABOUT
            <SectorDetails sector={company.info.sector} />
          </Typography>
          <Typography variant="body2" paragraph>
            {company.info.description}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <br />
      {updates && (
        <Accordion variant="outlined" defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>UPDATES</AccordionSummary>
          <AccordionDetails>
            <List className={classes.updates}>
              {updates.map((update) => (
                <ListItem key={update._id} divider disableGutters>
                  <ListItemText>
                    {update.title && (
                      <Typography variant="h4">
                        <strong>{update.title}</strong>
                      </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary" paragraph>
                      <strong>{DateTime.parse(update.date).toLocaleString(DateTime.DATE_FULL)}</strong>
                    </Typography>
                    <Linkify variant="body2">{update.content}</Linkify>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Container>
  );
};

export default CompanyInfo;
