import { Button, ButtonProps } from "@mui/material";
import { sendEmailConfirmation } from "client/api/emails";
import useToggle from "client/hooks/useToggle";
import notifications from "client/utils/notifications";
import React, { useCallback } from "react";

export interface ResendEmailConfirmationProps extends ButtonProps {}

const ResendEmailConfirmation: React.FC<ResendEmailConfirmationProps> = (props) => {
  const [clicked, toggleClicked] = useToggle(false);

  const handleClick = useCallback(async () => {
    const emailSent = await sendEmailConfirmation();
    if (!emailSent) return;
    notifications.success("Email Sent!");
    toggleClicked.on();
  }, [toggleClicked]);

  return (
    <Button onClick={handleClick} {...props} disabled={props.disabled || clicked}>
      Resend Confirmation Email
    </Button>
  );
};

export default ResendEmailConfirmation;
