import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { AxiosInstance } from "axios";
import { getIntroducers } from "client/api/admin/applications";
import { useState } from "react";

const useIntroducers = () => {
  const { introducers: preloadedIntroducers } = usePreloadedState<{ introducers: string[] }>();
  const [introducers, setIntroducers] = useState<string[]>(preloadedIntroducers || []);

  useSCEffect(
    async (axios?: AxiosInstance) => {
      const res = await getIntroducers.call(axios);
      if (!res) return;
      setIntroducers(res);
      return res;
    },
    [],
    "introducers",
  );

  return introducers;
};

export default useIntroducers;
