import { Button, Container, FormGroup, LinearProgress, Typography } from "@mui/material";
import { getExtractedAMLDetails, getIdVerificationURL, setAMLFlowCompleted } from "client/api/applications/application";
import useAppContext, { AppActions } from "client/context/app";
import notifications from "client/utils/notifications";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { FormGenConfig } from "form-gen";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeanApplication } from "server/services/application";
import Yup from "shared/utils/Yup";
import { makeStyles } from "tss-react/mui";
import useApplication from "./hooks/useApplication";
import { Forms } from "./utils";

const getIdVerificationConfig = ({
  verificationURL,
  onComplete,
  onSkip,
}: {
  verificationURL?: string;
  onComplete?: () => void;
  onSkip?: () => void;
} = {}): FormGenConfig<LeanApplication> => [
  {
    type: "section",
    title: "We need to verify your identity",
    titleProps: { variant: "h2" },
  },
  {
    type: "section",
    title:
      "For Anti-Money Laundering (AML) Regulation purposes, you are required to provide a valid form of identification and your most recent proof of address. Please proceed by selecting the button below to upload your documents. If you cannot provide these now, you will be contacted after completing your online application to verify your identity. Failure to provide the required documents may delay the application process. We therefore encourage you to complete the AML now so that your application is complete and can be processed.",
  },
  {
    type: "idVerification",
    name: "aml",
    props: { verificationURL, onComplete, onSkip },
    validation: Yup.object({
      completeNow: Yup.boolean().default(false),
      completed: Yup.boolean()
        .default(null)
        .nullable()
        .when("completeNow", {
          is: (value?: boolean) => Boolean(value),
          then: Yup.boolean()
            .oneOf([true], "Please complete the ID Verification")
            .required("Please complete the ID Verification"),
          otherwise: Yup.boolean().nullable(),
        }),
    }),
  },
];

const useStyles = makeStyles({ name: "IdVerification" })(() => ({
  container: {
    minHeight: "70vh",
    display: "flex",
    overflowY: "auto",
  },
}));

const IdVerification: React.FC = () => {
  useAppRedirect();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const { application, isAdvisor } = useApplication();
  const [updatedApplication, setUpdatedApplication] = useState<LeanApplication>();
  const [docInterval, setDocInterval] = useState<ReturnType<typeof setInterval>>();
  const [loading, setLoading] = useState(false);
  const [yotiURL, setYotiURL] = useState<string | undefined>();

  useEffect(() => {
    if (!application) return;
    (async () => {
      const res = await getIdVerificationURL(application._id);
      if (res) setYotiURL(res.url);
    })();
  }, [application]);

  const handleContinue = useCallback(() => {
    navigate(`../${Forms.Assessment}`);
  }, [navigate]);
  const handleBack = useCallback(() => navigate(`../${Forms.KeyInformation}`), [navigate]);

  const handleVerificationComplete = useCallback(async () => {
    await setAMLFlowCompleted(application!._id);
    setLoading(true);
    setDocInterval(
      setInterval(async () => {
        const details = await getExtractedAMLDetails(application!._id);
        if (details) setUpdatedApplication(details);
      }, 1000),
    );
  }, [application]);

  const idVerificationConfig = useMemo(
    () =>
      getIdVerificationConfig({
        verificationURL: yotiURL,
        onComplete: handleVerificationComplete,
        onSkip: handleContinue,
      }),
    [yotiURL, handleVerificationComplete, handleContinue],
  );

  const handleClearInterval = useCallback(() => {
    if (!docInterval) return;
    clearInterval(docInterval);
    setDocInterval(undefined);
  }, [docInterval]);

  useEffect(() => {
    if (!loading) return;
    const timeout = setTimeout(() => {
      setLoading(false);
      notifications.info(
        "Thank you for uploading your documentation. This will now be reviewed by our team. Please continue completing the application form.",
        { duration: 10 },
      );
      handleClearInterval();
      handleContinue();
    }, 10000);
    return () => clearTimeout(timeout);
  }, [handleClearInterval, loading, handleContinue]);

  useEffect(() => {
    if (!updatedApplication) return;
    notifications.success(
      "Thank you for uploading your documentation. This will now be reviewed by our team. We have automatically extracted your information and included it in the application form, please continue completing the application form.",
      { duration: 20 },
    );
  }, [updatedApplication]);

  useEffect(() => {
    if (!updatedApplication) return;
    dispatch({ type: isAdvisor ? AppActions.SetClientApp : AppActions.SetUserApp, payload: updatedApplication });
    handleClearInterval();
    handleContinue();
  }, [handleClearInterval, updatedApplication, handleContinue, dispatch, isAdvisor]);

  return (
    <Container maxWidth="md" className={classes.container}>
      <FormGen config={idVerificationConfig} onSubmit={handleContinue}>
        {() => (
          <>
            {loading && (
              <FormGroup>
                <LinearProgress color="primary" />
                <Typography variant="body1" align="center">
                  Fetching data....
                </Typography>
              </FormGroup>
            )}
            <FormGroup>
              <Button onClick={handleBack} variant="outlined" size="small" disabled={loading}>
                Previous
              </Button>
            </FormGroup>
          </>
        )}
      </FormGen>
    </Container>
  );
};

export default IdVerification;
