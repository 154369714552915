import { Typography, TypographyProps } from "@mui/material";
import SectorIcon from "client/components/Icons/SectorIcon";
import clsx from "clsx";
import React from "react";
import { CompanySector } from "server/services/company";
import { makeStyles } from "tss-react/mui";

interface SectorDetailsProps extends TypographyProps {
  sector?: CompanySector | null;
}

const useStyles = makeStyles({ name: "SectorDetails" })(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    textTransform: "none",
  },
  icon: {
    width: "auto",
    height: "1em",
    marginLeft: "0.5em",
  },
}));

const SectorDetails: React.FC<SectorDetailsProps> = ({ sector, className, ...props }) => {
  const { classes } = useStyles();
  return (
    <Typography variant="body2" {...props} className={clsx(classes.container, className)}>
      <SectorIcon sector={sector} className={classes.icon} fontSize="inherit" />
      &nbsp;
      {sector ?? "Other"}
    </Typography>
  );
};

export default SectorDetails;
