import { useMemo } from "react";

export const ParentFieldNameRegex = /(.+\[\d+\])$|(.+)\.[^.]+$/;

export const getParentFieldName = (fieldName: string) => {
  const match = fieldName.match(ParentFieldNameRegex);
  return match?.groups?.[0] || match?.groups?.[1] || fieldName;
};

const useParentFieldName = (fieldName: string) => {
  return useMemo(() => getParentFieldName(fieldName), [fieldName]);
};

export default useParentFieldName;
