import { Button, ButtonGroup } from "@mui/material";
import { ColumnDefinition, OnChangeObject, TableCellEditHandler } from "@wearenova/mui-data-table";
import { getApplications } from "client/api/admin/applications";
import { downloadAgreement, patchApplication } from "client/api/admin/applications/application";
import { sendFundsReceivedEmail } from "client/api/admin/emails";
import SentryRoutes from "client/components/SentryRoutes";
import useAdminContext, { AdminActions } from "client/context/admin";
import useAuthContext from "client/context/auth";
import _ from "lodash";
import { ObjectIdLike } from "mongoose";
import React, { useCallback, useMemo, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { AdminApplication } from "server/services/application";
import { AMLStatus, AppStatus } from "server/services/application/consts";
import { ExportType } from "server/services/export/consts";
import DateTime from "shared/utils/DateTime";
import * as Yup from "yup";
import AdminTable from "../components/AdminTable";
import ExportLinkForm from "../components/ExportLinkForm";
import MainPanelContainer from "../components/MainPanelContainer";
import usePortfolioOptions from "../hooks/usePortfolioOptions";
import useUserOptions from "../hooks/useUserOptions";
import createTableDefinition from "../utils/createTableDefinition";
import splitUsers from "../utils/splitUsers";
import AppForm from "./Form";
import useIntroducers from "./hooks/useIntroducers";

const Table: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { applications, dispatch: adminDispatch } = useAdminContext();
  const userOptions = useUserOptions();
  const portfolioOptions = usePortfolioOptions();
  const introducers = useIntroducers();

  const [count, setCount] = useState(-1);

  const appsArr = useMemo(() => Object.values(applications), [applications]);

  const setApplications = useCallback(
    async (onChangeObject: OnChangeObject, isExport: boolean) => {
      const res = await getApplications(onChangeObject);
      if (!res) return [];
      if (!isExport) {
        adminDispatch({ type: AdminActions.SetApps, payload: res.data });
        setCount(res.count);
      }
      return res.data;
    },
    [adminDispatch],
  );

  const { investors, advisors } = useMemo(() => {
    const splitUserOptions = splitUsers(userOptions);
    return { ...splitUserOptions, advisors: [{ label: "none", value: null }, ...splitUserOptions.advisors] };
  }, [userOptions]);

  const applicationTable = useMemo<ColumnDefinition<AdminApplication>[]>(
    () =>
      createTableDefinition([
        {
          key: "investorName",
          title: "Investor Name",
          sorter: "investor.user.fullName",
          dataIndex: "investor.user.fullName",
          editable: {
            path: "investor.user",
            type: "select",
            selectOptions: investors,
            defaultValue: "",
          },
        },
        {
          key: "investorEmail",
          title: "Investor Email",
          sorter: "investor.user.email",
          dataIndex: "investor.user.email",
          editable: {
            path: "investor.user",
            type: "select",
            selectOptions: investors,
            defaultValue: "",
          },
        },
        {
          key: "fund",
          title: "Fund",
          dataIndex: "fund.name",
        },
        {
          key: "portfolio",
          title: "Portfolio",
          dataIndex: "portfolio.name",
          editable: {
            path: "portfolio",
            type: "select",
            selectOptions: (record) =>
              portfolioOptions.filter((o) => (record.funds as ObjectIdLike[]).includes(o.fund)),
            defaultValue: "",
          },
        },
        {
          key: "introducer",
          title: "Introducer",
          dataIndex: "meta.introducer",
          editable: {
            path: true,
            type: "select",
            selectOptions: introducers,
            defaultValue: "",
          },
        },
        {
          key: "introducerId",
          title: "Introducer ID",
          dataIndex: "meta.introducerId",
          editable: true,
        },
        {
          key: "advisorName",
          title: "Adviser Name",
          sorter: "advisor.user.fullName",
          dataIndex: "advisor.user.fullName",
          editable: {
            path: "advisor.user",
            type: "select",
            selectOptions: advisors,
            defaultValue: "",
            validate: (value) =>
              Yup.string()
                .oneOf(advisors.map((a) => a.value))
                .nullable()
                .validate(value),
          },
        },
        {
          key: "advisorEmail",
          title: "Adviser Email",
          dataIndex: "advisor.user.email",
          editable: {
            path: "advisor.user",
            type: "select",
            selectOptions: advisors,
            defaultValue: "",
          },
        },
        {
          key: "registrationDate",
          title: "Registration Date",
          dataIndex: "investor.user.meta.registeredAt",
          dataType: "date",
        },
        {
          key: "startDate",
          title: "Start Date",
          dataIndex: "meta.startedAt",
          dataType: "date",
          render: (record) => record.meta.startedAt && DateTime.parse(record.meta.startedAt).toFormat("dd/MM/yyyy"),
        },
        {
          key: "date",
          title: "Signature Date",
          dataIndex: "completedAt",
          dataType: "date",
          editable: true,
        },
        {
          key: "lastActivityDate",
          title: "Last Activity",
          dataIndex: "data.updatedAt",
          dataType: "date",
        },
        {
          key: "usersLastActivityDate",
          title: "User's Last Activity",
          dataIndex: "meta.userUpdatedAt",
          dataType: "date",
        },
        {
          key: "totalAmount",
          title: "Amount",
          dataIndex: "data.investment.totalAmount",
          numerical: true,
          editable: true,
        },
        {
          key: "frequency",
          title: "Investment Frequency",
          dataIndex: "data.investment.frequency",
        },
        {
          key: "percentageSplit",
          title: "SEIS/EIS Split",
          colGroup: [
            {
              key: "data.investment.seis",
              title: "SEIS",
              render: (record) =>
                typeof record.data.investment.split?.seis === "number" && `${record.data.investment.split?.seis}%`,
              sorter: "data.investment.split.seis",
            },
            {
              key: "data.investment.eis",
              title: "EIS",
              render: (record) =>
                typeof record.data.investment.split?.eis === "number" && `${record.data.investment.split?.eis}%`,
              sorter: "data.investment.split.eis",
            },
          ],
        },
        {
          key: "likelihood",
          title: "Likelihood of Funds",
          dataType: "number",
          dataIndex: "meta.likelihood",
          render: (record) => typeof record.meta.likelihood === "number" && `${record.meta.likelihood}%`,
          editable: true,
        },
        {
          key: "hasCarryBack",
          title: "Carry Back Present?",
          dataIndex: "data.objectives.carryBack.present",
          dataType: "boolean",
        },
        {
          key: "fundsReceived",
          title: "Funds Received",
          dataIndex: "balances.received.total",
          numerical: true,
        },
        {
          key: "newFundsReceived",
          title: "Send Funds Received Email",
          render: (record, isExport) => {
            if (isExport) return;

            const someNewFundsReceived = record.fundsReceived?.some((fundReceived) => fundReceived.new);
            if (!someNewFundsReceived) return null;

            return (
              <Button
                variant="text"
                size="small"
                onClick={async (e) => {
                  e.stopPropagation();
                  const success = await sendFundsReceivedEmail(record._id);
                  if (!success) return;
                  adminDispatch({
                    type: AdminActions.UpdateApp,
                    payload: { fundsReceived: record.fundsReceived!.map((f) => ({ ...f, new: false })) },
                  });
                }}
              >
                Send Funds Received Email
              </Button>
            );
          },
        },
        {
          key: "deployed",
          title: "Funds Deployed",
          colGroup: [
            {
              key: "deployed.total",
              title: "Total",
              dataIndex: "balances.deployed.total",
              numerical: true,
            },
            {
              key: "deployed.seis",
              title: "SEIS",
              dataIndex: "balances.deployed.seis",
              numerical: true,
            },
            {
              key: "deployed.eis",
              title: "EIS",
              dataIndex: "balances.deployed.eis",
              numerical: true,
            },
          ],
        },
        {
          key: "remaining",
          title: "Funds Remaining",
          colGroup: [
            {
              key: "remaining.total",
              title: "Total",
              dataIndex: "balances.remaining.total",
              numerical: true,
            },
            {
              key: "remaining.seis",
              title: "SEIS",
              dataIndex: "balances.remaining.seis",
              numerical: true,
            },
            {
              key: "remaining.eis",
              title: "EIS",
              dataIndex: "balances.remaining.eis",
              numerical: true,
            },
          ],
        },
        {
          key: "status",
          title: "Status",
          dataIndex: "status",
          editable: {
            path: "status",
            type: "select",
            selectOptions: Object.entries(AppStatus).map(([key, value]) => ({ label: key, value: value })),
          },
        },
        // {
        //   key: "updateApplication",
        //   title: "Update Application",
        //   render: (record) => (
        //     <Button
        //       variant="text"
        //       size="small"
        //       onClick={async (e) => {
        //         e.stopPropagation();
        //         const application = await generateApplication(record._id);
        //         appDispatch({ type: AppActions.SetUserApp, payload: application });
        //         navigate(`/admin/application/${application._id}/apply/personalDetails`);
        //       }}
        //     >
        //       Update
        //     </Button>
        //   ),
        // },
        {
          key: "agreement",
          title: "Agreement",
          dataIndex: "file",
          render: (record, isExport) =>
            isExport ? null : (
              <Button
                variant="text"
                size="small"
                disabled={!record.file}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadAgreement(record._id, _.get(record, "investor.user.surname"));
                }}
              >
                Download Full PDF
              </Button>
            ),
        },
        {
          key: "aml.status",
          title: "AML Completed",
          dataIndex: "aml.status",
          editable: {
            path: "aml.status",
            type: "select",
            selectOptions: Object.values(AMLStatus),
          },
        },
        {
          key: "investmentManager.name",
          title: "Investment Manager",
          render: (record) => _.startCase(record.investmentManager.name) || "N/A",
          filterColumn: "investmentManager.name",
          editable: {
            path: "investmentManager.name",
            type: "select",
            selectOptions: ["Sapia", "Sapphire", "Stellar"],
          },
        },
        {
          key: "investmentManager.approved",
          title: "Investment Manager Approved",
          dataType: "boolean",
          dataIndex: "investmentManager.approved",
          editable: true,
        },
        {
          key: "custodianId",
          title: "Custodian ID",
          dataIndex: "meta.custodianId",
          editable: true,
        },
        {
          key: "notes",
          title: "Notes",
          dataIndex: "meta.notes",
          editable: true,
        },
      ]),
    [investors, portfolioOptions, introducers, advisors, adminDispatch],
  );

  const handleTableEdit = useCallback<TableCellEditHandler<AdminApplication>>(
    async ({ path, value }, record) => {
      const res = await patchApplication(record._id, {
        [path]: value === "null" ? null : value,
        disableApprovalEmail: true,
      });
      if (res) adminDispatch({ type: AdminActions.UpdateApp, payload: res });
    },
    [adminDispatch],
  );

  const handleDialogClose = useCallback(() => navigate("./"), [navigate]);

  return (
    <>
      <MainPanelContainer>
        <AdminTable
          tableData={appsArr}
          onChange={setApplications}
          count={count}
          rowClick={(record) => navigate(`./${record._id}/edit`)}
          tableStructure={applicationTable}
          tableProps={{ size: "small" }}
          exportToCSVOption
          csvFilename="Applications"
          defaultSort={{ key: "meta.completedAt", direction: "desc" }}
          onEdit={handleTableEdit}
        />
        <ButtonGroup variant="text">
          <Button disabled={!user || user.isReadOnly} onClick={() => navigate("./create")}>
            Add Application
          </Button>
          <Button onClick={() => navigate("./export")}>Create Export</Button>
        </ButtonGroup>
      </MainPanelContainer>
      <SentryRoutes>
        <Route path="create" element={<AppForm open onClose={handleDialogClose} />} />
        <Route path=":applicationId/edit" element={<AppForm open onClose={handleDialogClose} />} />
        <Route
          path="export"
          element={
            <ExportLinkForm
              open
              type={ExportType.Apps}
              data={appsArr}
              structure={applicationTable}
              onClose={handleDialogClose}
            />
          }
        />
      </SentryRoutes>
    </>
  );
};

export default Table;
