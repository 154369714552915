import { FormGroup, Link, Typography } from "@mui/material";
import useFundsContext from "client/context/funds";
import React, { useMemo } from "react";
import DynamicList from "./DynamicList";

const KeyDocuments: React.FC = () => {
  const { selected } = useFundsContext();

  const listItems = useMemo(() => {
    if (!selected) return [];
    return [
      { link: selected.links.im, text: "The Information Memorandum & Investment Management Agreement" },
      { link: selected.links.kid, text: "The Key Information Document (KID)" },
    ]
      .filter((l) => Boolean(l.link))
      .map(({ link, text }) => (
        <Link key={link} href={link} variant="inherit" target="_blank" rel="noopener noreferrer">
          {text}
        </Link>
      ));
  }, [selected]);

  return (
    <Typography
      variant="body2"
      sx={{
        textAlign: {
          md: "justify",
        },
      }}
      component="div"
    >
      <FormGroup>
        Thank you for starting the application process relating to the {selected?.name}.
        <DynamicList items={listItems} alignIcons="flex-start" />
        Before continuing, please read very carefully all the documents provided in the link
        {listItems.length > 1 && "s"} above.
      </FormGroup>
      <FormGroup>
        <span>
          Please remember that the value of an investment may go down as well as up, and you may not get back the full
          amount invested, and you may therefore lose some or all of your investment. Tax reliefs depend on individual
          circumstances.&nbsp;
          <strong>
            Prospective investors are advised to take their own investment and taxation advice and should consult their
            own professional advisors on the implications of investing
          </strong>
          . If after reading the Information Memorandum & Investment Management Agreement as well as the Key Information
          Document (KID), you still wish to proceed with this investment, please fill out the following questions.
        </span>
      </FormGroup>
    </Typography>
  );
};

export default KeyDocuments;
