import * as Sentry from "@sentry/react";
import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getAuthContext } from "client/api/public";
import React, { FC, Reducer, useContext, useEffect, useMemo, useReducer } from "react";
import { AuthContextUser } from "server/services/user";
import { Action, ActionMap, ProviderValue } from "./types";

interface State {
  readonly user?: AuthContextUser | null;
}

export enum AuthActions {
  SetUser = "SET_USER",
  SetConfirmed = "SET_CONFIRMED",
  ClearUser = "CLEAR_USER",
}

interface ActionPayloadMap {
  [AuthActions.SetUser]: AuthContextUser;
  [AuthActions.SetConfirmed]: boolean;
  [AuthActions.ClearUser]: null;
}

const AuthContext = React.createContext<ProviderValue<State, ActionPayloadMap>>({ dispatch: () => null });
AuthContext.displayName = "AuthContext";

const ACTION_MAP: ActionMap<State, ActionPayloadMap> = {
  [AuthActions.SetUser]: (state, action) => ({ ...state, user: action.payload }),
  [AuthActions.SetConfirmed]: (state, action) => ({
    ...state,
    user: { ...state.user!, isConfirmed: action.payload },
  }),
  [AuthActions.ClearUser]: (state) => ({ ...state, user: null }),
};

const reducer: Reducer<State, Action<ActionPayloadMap, keyof ActionPayloadMap>> = (state, action) => {
  if (!ACTION_MAP[action.type]) return state;
  return ACTION_MAP[action.type](state, action as any);
};

export const AuthProvider: FC = (props) => {
  const auth = usePreloadedState<{ auth: AuthContextUser }, "auth">("auth");
  const [state, dispatch] = useReducer(reducer, { user: auth });

  useSCEffect(
    async (axios: Axios) => {
      const res = await getAuthContext(axios);
      dispatch(!res ? { type: AuthActions.ClearUser, payload: null } : { type: AuthActions.SetUser, payload: res });
      return res;
    },
    [],
    "auth",
  );

  useEffect(() => {
    if (!state.user?.userId) return;
    const { email: _email, adminUser, ...extraData } = state.user || {};
    Sentry.configureScope((scope) => {
      scope.setUser(!state.user?.userId ? null : { id: state.user.userId });
      scope.setContext("auth", { ...extraData, adminUser: adminUser?.userId } as Record<string, any>);
    });
  }, [state.user]);

  useEffect(() => {
    if (state.user?.userId) return;
    Sentry.configureScope((scope) => scope.clear());
  }, [state.user?.userId]);

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  return useContext(AuthContext);
};

export default useAuthContext;
