import { DateTime } from "luxon";
import React, { ReactNode } from "react";

export interface QuestionConfig {
  id: string;
  title?: string;
  name?: string;
  label?: ReactNode;
  options?: unknown[] | Array<{ label: ReactNode; value: unknown }>;
}

export interface FormConfig {
  [key: string]: QuestionConfig;
}

export const CertificationForm = {
  values: {
    id: "values",
    title: "",
    understandsRisk: {
      id: "understandsRisk",
      name: "values.understandsRisk",
      label:
        "Please tick this box to accept that the investments to which the promotions on this website relate may expose you to a significant risk of losing all of the money or other property involved.",
    },

    understandsSelfCert: {
      id: "understandsSelfCert",
      name: "values.understandsSelfCert",
      label:
        "Please tick this box to confirm that you understand that you are being asked to self-certify because the investments to which the promotions will relate are considered to be unsuitable for the majority of UK investors.",
    },
    signingConfirmation: {
      id: "signingConfirmation",
      name: "values.signingConfirmation",
      label: (
        <>
          I confirm that the above statement is true as at this date:{" "}
          <strong>{DateTime.now().toFormat("d MMMM yyyy")}</strong>
        </>
      ),
    },
    hasLargeIncome: {
      id: "hasLargeIncome",
      name: "values.hasLargeIncome",
      label: (
        <p>
          A) An annual <b>income of £100,000 or more?</b> Income does <u>NOT</u> include any one-off pension
          withdrawals.
        </p>
      ),
    },
    netIncome: {
      id: "netIncome",
      name: "values.netIncome",
      label: "If yes, please specify your income (as defined above) to the nearest £10,000 in the last financial year.",
    },
    hasLargeNetAssets: {
      id: "hasLargeNetAssets",
      name: "values.hasLargeNetAssets",
      label: (
        <p>
          B )<b> Net assets of £250,000 or more?</b> Net assets do <u>NOT</u> include: your home (primary residence),
          any loan secured on it or any equity released from it, your pension (or any pension withdrawals) or any rights
          under insurance contracts. Net assets are total assets minus any debts you owe.
        </p>
      ),
    },
    netAssets: {
      id: "netAssets",
      name: "values.netAssets",
      label:
        "If yes, please specify your net assets (as defined above) to the nearest £100,000 in the last financial year.",
    },
    selfCertifiedCompanyName: {
      id: "selfCertifiedCompanyName",
      name: "values.selfCertifiedCompanyName",
      label: "Please provide the company name you were a director of for this period.",
      placeholder: "Company name",
    },

    financeProviderForSMEsOrPEWorker: {
      id: "financeProviderForSMEsOrPEWorker",
      name: "values.financeProviderForSMEsOrPEWorker",
      label:
        "A) Worked in a professional capacity in the private equity sector or in the provision of finance for small and medium enterprises, in the last two years?",
    },
    financeProviderForSMEsOrPEWorkerDetail: {
      id: "financeProviderForSMEsOrPEWorkerDetail",
      name: "values.financeProviderForSMEsOrPEWorkerDetail",
      label: "If yes, what is/was the name of the business or organisation?",
    },
    directorOfCompanyWithAnnualTurnoverOver1M: {
      id: "directorOfCompanyWithAnnualTurnoverOver1M",
      name: "values.directorOfCompanyWithAnnualTurnoverOver1M",
      label: "B) Been the director of a company with an annual turnover of at least £1 million, in the last two years?",
    },
    directorOfCompanyWithAnnualTurnoverOver1MDetail: {
      id: "directorOfCompanyWithAnnualTurnoverOver1MDetail",
      name: "values.directorOfCompanyWithAnnualTurnoverOver1MDetail",
      label:
        "If yes, what is/was the name of the company, and its Companies House number (or international equivalent)?",
    },
    multipleInvestmentsUnlistedCompany: {
      id: "multipleInvestmentsUnlistedCompany",
      name: "values.multipleInvestmentsUnlistedCompany",
      label: "C) Made two or more investments in an unlisted company?",
    },
    multipleInvestmentsUnlistedCompanyDetail: {
      id: "multipleInvestmentsUnlistedCompanyDetail",
      name: "values.multipleInvestmentsUnlistedCompanyDetail",
      label: "If yes, how many investments in unlisted companies have you made in the last two years?",
      props: {
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 1000,
          decimalScale: 0,
        },
      },
    },
    businessAngelNetworkMemberOver6Months: {
      id: "businessAngelNetworkMemberOver6Months",
      name: "values.businessAngelNetworkMemberOver6Months",
      label:
        "D) Been a member of a network or syndicate of business angels for more than six months, and are still a member?",
    },
    businessAngelNetworkMemberOver6MonthsDetail: {
      id: "businessAngelNetworkMemberOver6MonthsDetail",
      name: "values.businessAngelNetworkMemberOver6MonthsDetail",
      label: "If yes, what is the name of the network or syndicate?",
    },
    investedInHighRiskInvestments: {
      id: "investedInHighRiskInvestments",
      name: "values.investedInHighRiskInvestments",
      label:
        "A) In the past twelve months have you invested less than 10% of your net assets in high-risk investments (as defined above)?",
      options: [
        { label: "Yes (I have invested less than 10% of my net assets)", value: true },
        { label: "No (I have invested more than 10% of my net assets)", value: false },
      ],
    },
    investedInHighRiskInvestmentsPercentage: {
      id: "investedInHighRiskInvestmentsPercentage",
      name: "values.investedInHighRiskInvestmentsPercentage",
      label:
        "over the last twelve months roughly what percentage of your net assets have you invested in high-risk investments (as defined above)?",
      props: {
        suffix: "%",
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 100,
          decimalScale: 2,
        },
      },
    },
    limitHighRiskInvestment: {
      id: "limitHighRiskInvestment",
      name: "values.limitHighRiskInvestment",
      label:
        "B) In the next twelve months do you intend to limit your investment in high-risk investments (as defined above) to less than 10% of your net assets?",
      options: [
        { label: "Yes (I intend to invest less than 10% of my net assets)", value: true },
        { label: "No (I intend to invest more than 10% of my net assets)", value: false },
      ],
    },
    limitHighRiskInvestmentPercentage: {
      id: "limitHighRiskInvestmentPercentage",
      name: "values.limitHighRiskInvestmentPercentage",
      label:
        "in the next twelve months roughly what percentage of your net assets do you intend to invest in high-risk investments {as defined above)? ",
      props: {
        suffix: "%",
        disableMonetaryFormat: true,
        formatProps: {
          min: 0,
          max: 100,
          decimalScale: 2,
        },
      },
    },
    notApplicable: {
      id: "notApplicable",
      name: "values.notApplicable",
      label: "This does not apply to me.",
    },
  },

  atLeastOneTrue: {
    id: "atLeastOneTrue",
    name: "atLeastOneTrue",
    label: "Please select Yes for at least one of the cases above",
  },
} as const;
