import ipAxios, { ThisAxios } from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AppFormType } from "client/views/AdminPanel/Applications/Form";
import fileDownload from "js-file-download";
import { SimpleUpdateQuery } from "mongoose";
import { AdminApplication, LeanApplication } from "server/services/application";

export const patchApplication = async (
  appId: string,
  { disableApprovalEmail, disableFundsReceivedEmail, ...changes }: Partial<SimpleUpdateQuery<AppFormType>>,
) => {
  try {
    const res = await ipAxios.patch<AdminApplication>(`/admin/applications/${appId}`, changes, {
      params: { disableApprovalEmail, disableFundsReceivedEmail },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const updateApplication = async (
  appId: string,
  { disableApprovalEmail, disableFundsReceivedEmail, ...changes }: AppFormType,
) => {
  try {
    const res = await ipAxios.put<AdminApplication>(`/admin/applications/${appId}`, changes, {
      params: { disableApprovalEmail, disableFundsReceivedEmail },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const deleteApplication = async (appId: string) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/applications/${appId}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export async function getAdminApplication(this: ThisAxios, appId: string) {
  try {
    const res = await (this || ipAxios).get<LeanApplication>(`/admin/applications/${appId}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const patchAdminApplicationData = async (
  appId: string,
  values: Partial<NonNullable<LeanApplication["data"]>>,
) => {
  try {
    const res = await ipAxios.patch<LeanApplication>(`/admin/applications/${appId}/data`, values);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const downloadAgreement = async (appId: string, surname: string) => {
  try {
    const res = await ipAxios.get(`/admin/applications/${appId}/agreement`, { responseType: "blob" });
    if (res.status === 200) {
      const blob = new Blob([res.data]);
      fileDownload(blob, `${surname}-${appId}.pdf`);
      return true;
    }
    return false;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
