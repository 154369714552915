import { AppStatus } from "server/services/application/consts";
import { UserStatus } from "server/services/user/consts";

const DetailedStatusMap: { [key in AppStatus | UserStatus]: string } = {
  [UserStatus.NotStarted]: "Not Started",
  [AppStatus.Created]: "Created",
  [AppStatus.Started]: "Application Started",
  [UserStatus.AppStarted]: "Application Started",
  [AppStatus.InvestorDetails]: "In Progress: Investor Details section completed",
  [AppStatus.Finances]: "In Progress: Finances section completed",
  [AppStatus.Experience]: "In Progress: Experience section completed",
  [AppStatus.Objectives]: "In Progress: Objectives section completed",
  [AppStatus.Investment]: "In Progress: Investment section completed",
  [AppStatus.SignaturePending]: "Awaiting signature",
  [AppStatus.Signed]: "Application Signed",
  [UserStatus.AppSigned]: "Application Signed",
  [AppStatus.FundsReceived]: "Funds Received",
  [AppStatus.FundsDeployed]: "Funds Deployed",
  [AppStatus.LostOrNoInterest]: "Lost/no interest",
};

const getDetailedStatus = <T extends AppStatus | UserStatus>(status: T): typeof DetailedStatusMap[T] =>
  DetailedStatusMap[status];

export default getDetailedStatus;
