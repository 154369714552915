import { DateTime } from "luxon";
declare module "luxon/src/datetime" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace DateTime {
    const parse: typeof _parse;
  }
}

type OptionsOf<T extends (...args: any[]) => DateTime> = T extends (value: any, options?: infer U) => DateTime
  ? U
  : never;

const TypeMap = {
  string: DateTime.fromISO,
  number: DateTime.fromMillis,
};

const _parse = <T extends Date | string | number | undefined = undefined>(
  date?: T,
  opts?: OptionsOf<
    T extends Date
      ? typeof DateTime.fromJSDate
      : T extends string
      ? typeof TypeMap.string
      : T extends number
      ? typeof TypeMap.number
      : never
  >,
) => {
  if (!date) return null as T extends undefined ? null : never;
  const type = typeof date as keyof typeof TypeMap;
  const parser = TypeMap[type] || DateTime.fromJSDate;
  return parser(date as never, opts) as T extends undefined ? never : DateTime;
};

Object.getPrototypeOf(DateTime).parse = _parse;

export default DateTime;
