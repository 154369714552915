import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanApplication } from "server/services/application";
import { LeanAssessment } from "server/services/assessment";

export const patchAdvisedApplicationData = async (
  clientId: string,
  applicationId: string,
  values: Partial<NonNullable<LeanApplication["data"]>>,
) => {
  try {
    const res = await ipAxios.patch<LeanApplication>(
      `/advisor/clients/${clientId}/applications/${applicationId}/`,
      values,
    );
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getAdvisedSignatureURL = async (clientId: string, appId: string, signatureId?: string) => {
  try {
    const res = await ipAxios.get<{ url: string }>(`/advisor/clients/${clientId}/applications/${appId}/signature`, {
      params: { signatureId },
    });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getAdvisedAssessment = async (clientId: string) => {
  try {
    const res = await ipAxios.get<LeanAssessment>(`/advisor/clients/${clientId}/assessment`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export async function completeAdvisedAssessment(clientId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/advisor/clients/${clientId}/assessment/continue`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export async function skipCooldown(clientId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/advisor/clients/${clientId}/assessment/cooldown/skip`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
