import { FormGroup, Typography } from "@mui/material";
import RadioField from "client/components/Fields/RadioField";
import CheckboxField from "client/components/Fields/CheckboxField";
import NumberField from "client/components/Fields/NumberField";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { CertificationForm } from "shared/application.consts";
import Statement from "./components/Statement";
import CommonStatement from "./components/CommonStatement";

type QuestionIds = typeof CertificationForm.values[
  | "hasLargeIncome"
  | "hasLargeNetAssets"
  | "notApplicable"
  | "netIncome"
  | "netAssets"]["id"];

const HIGH_NET_WORTH_TEXT = "I declare that I have answered yes to A and/or B, and wish to be treated as a high net worth individual."

const HighNetWorth: React.FC = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<{
    atLeastOneTrue: boolean;
    values: {
      [key in QuestionIds]: boolean;
    };
  }>();

  useEffect(() => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.hasLargeIncome.name, null);
      setFieldValue(CertificationForm.values.hasLargeNetAssets.name, null);
    }
  }, [setFieldValue, values.values.notApplicable]);

  const handleRadioChange = () => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.notApplicable.name, false);
    }
  };

  return (
    <>
      <FormGroup>
        <Statement title="High Net Worth Investor Statement">
          If you meet <b> condition A or B below,</b> you may choose to be classified as high net worth individual for
          the purposes of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005.
          <br />
          <br />
          In the last financial year, did you have:
          <FormGroup>
            <Field
              name={CertificationForm.values.hasLargeIncome.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={<Typography variant="body2">{CertificationForm.values.hasLargeIncome.label}</Typography>}
            />
          </FormGroup>
          <FormGroup>
            {values.values.hasLargeIncome && (
              <Field
                name={CertificationForm.values.netIncome.name}
                component={NumberField}
                label={<Typography variant="body2">{CertificationForm.values.netIncome.label}</Typography>}
              />
            )}
          </FormGroup>
          <br />
          <Typography variant="h3">AND/OR</Typography>
          <FormGroup>
            <Field
              name={CertificationForm.values.hasLargeNetAssets.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={<Typography variant="body2">{CertificationForm.values.hasLargeNetAssets.label}</Typography>}
            />
          </FormGroup>
          {values.values.hasLargeNetAssets && (
            <FormGroup>
              <Field
                name={CertificationForm.values.netAssets.name}
                component={NumberField}
                label={<Typography variant="body2">{CertificationForm.values.netAssets.label}</Typography>}
              />
            </FormGroup>
          )}
          <br />
          <Typography variant="h3">OR</Typography>
          <FormGroup>
            <Typography variant="body2">C) None of these apply to me.</Typography>
            <Field name={CertificationForm.values.notApplicable.name} component={CheckboxField} label={"Yes"} />
          </FormGroup>
          <FormGroup>
            {(touched.values?.[CertificationForm.values.hasLargeIncome.id] ||
              touched.values?.[CertificationForm.values.hasLargeNetAssets.id]) &&
              errors.atLeastOneTrue && (
                <Typography variant="body2" color="error">
                  {errors.atLeastOneTrue}
                </Typography>
              )}
          </FormGroup>
          <br />
          <CommonStatement uniqueText={HIGH_NET_WORTH_TEXT}/>
        </Statement>
      </FormGroup>
    </>
  );
};

export default HighNetWorth;
