import SentryRoutes from "client/components/SentryRoutes";
import React from "react";
import { Route } from "react-router-dom";
import Table from "./Table";

const Transactions: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="*" element={<Table />} />
    </SentryRoutes>
  );
};

export default Transactions;
