import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormHelperText,
  FormLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Link,
  OutlinedInputProps,
  Paper,
  Typography,
} from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import bytes from "bytes";
import { getFileDetails, uploadFile } from "client/api/uploads";
import useDynamicFieldText from "client/hooks/useDynamicFieldText";
import useDynamicRef from "client/hooks/useDynamicRef";
import useHelperText from "client/hooks/useHelperText";
import { FieldProps } from "formik";
import { uniqueId } from "lodash";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { Promisable } from "type-fest";
import TrackInput, { TrackInputProps } from "../TrackInput";

const DEFAULT_MAX_SIZE = bytes.parse("10MB");

export interface FileDetails {
  name: string;
  type: string;
  size: number;
}

export interface UploadFieldProps<T>
  extends FieldProps<File | string | null, T>,
    Pick<OutlinedInputProps, "label">,
    Omit<TrackInputProps<string | null>, "value" | "margin"> {
  disableUpload?: boolean;
  onChange?: <T extends File>(event: DropEvent, files: T[], fileRejections: FileRejection[]) => Promisable<void>;
  options?: Omit<DropzoneOptions, "onDrop">;
  owner?: string | ((values: T, name: string) => string);
  publicFile?: boolean;
  helperText?: string;
}

const UploadField = <T,>({
  field,
  form: { setFieldError, setFieldValue, setFieldTouched, values },
  category,
  disableUpload,
  onChange,
  options,
  owner,
  publicFile,
  ...props
}: PropsWithChildren<UploadFieldProps<T>>) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const label = useDynamicFieldText(props.label);
  const valuesRef = useDynamicRef(values);

  const [progress, setProgress] = useState<number | null>(null);
  const [fileDetails, setFileDetails] = useState<FileDetails | null>(null);

  const inputId = useMemo(() => uniqueId(field.name), [field.name]);

  const handleChange = useCallback<
    <V extends File>(files: V[], fileRejections: FileRejection[], event: DropEvent) => void
  >(
    async (files, fileRejections, e) => {
      try {
        if (onChange) return await onChange(e, files, fileRejections);
        if (disableUpload) return setFieldValue(field.name, files[0] ?? null);
        await Promise.all(
          files.map(async (file: File) => {
            const upload = await uploadFile({
              name: file.name,
              body: file,
              onProgress: (p) => p.lengthComputable && setProgress(p.loaded / p.total),
              owner: typeof owner !== "function" ? owner : owner(valuesRef.current, field.name),
              publicFile,
            });
            setProgress(null);
            if (!upload) return;
            setFieldValue(field.name, upload.key);
            setFileDetails({
              name: file.name,
              type: file.type,
              size: file.size,
            });
          }),
        );
      } catch (error) {
        setFieldError(field.name, "Error during file upload. Please try again.");
      } finally {
        setFieldTouched(field.name, true);
      }
    },
    [field.name, disableUpload, onChange, owner, publicFile, setFieldError, setFieldTouched, setFieldValue, valuesRef],
  );

  useEffect(() => {
    const value = field.value;
    if (fileDetails || !(value instanceof File)) return;
    setFileDetails({ name: value.name, type: value.type, size: value.size });
  }, [field.value, fileDetails]);

  useEffect(() => {
    const value = field.value;
    if (fileDetails || !value || typeof value !== "string") return;
    (async () => {
      const details = await getFileDetails(value);
      if (!details) return setFieldValue(field.name, null);
      setFileDetails(details);
    })();
  }, [field.name, field.value, fileDetails, setFieldValue]);

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: DEFAULT_MAX_SIZE,
    ...options,
    maxFiles: 1,
    multiple: false, // TODO: allow multiple files
    onDrop: handleChange,
    disabled: Boolean(options?.disabled || typeof progress === "number" || (!options?.multiple && field.value)),
  });

  const handleClear = useCallback(() => setFieldValue(field.name, null), [field.name, setFieldValue]);

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} variant="outlined">
      <FormLabel htmlFor={inputId}>{label}</FormLabel>
      <Box {...getRootProps()}>
        <InputBase id={inputId} inputProps={getInputProps()} />
        <Paper
          variant="outlined"
          sx={[
            {
              p: 2,
              minHeight: 100,
              position: "relative",
              border: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
            !field.value && progress === null && { borderStyle: "dashed", borderWidth: 1 },
            progress === null && Boolean(field.value) && { borderStyle: "solid", borderWidth: 1 },
          ]}
        >
          {!field.value && progress === null && (
            <Typography>
              Drag & Drop your files here or <Link>Browse</Link>
            </Typography>
          )}
          {typeof progress === "number" && (
            <>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={progress * 100}
                sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}
              />
              <Typography sx={{ color: "primary.contrastText", position: "relative" }}>
                {numberFormatter(progress, { style: "percent" })}
              </Typography>
            </>
          )}
          {field.value && fileDetails && (
            <>
              <span>
                <Typography>{fileDetails.name}</Typography>
                <Typography variant="caption">Size: {bytes.format(fileDetails.size)}</Typography>
              </span>
              <IconButton onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Paper>
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </TrackInput>
  );
};

export default UploadField;
