import { alpha, Card, CardContent, CardHeader, CardProps, Typography } from "@mui/material";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "CertificationCard" })((theme) => ({
  card: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 0,
    flex: 1,
    cursor: "pointer",
  },
  selected: {
    boxShadow: `${theme.spacing(0, 1.5, 3)} ${alpha(theme.palette.primary.dark, 0.2)}`,
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
  },
}));

interface CertificationCardProps extends CardProps {
  title: string;
  selected: boolean;
  onClick: () => void;
}

const CertificationCard = React.forwardRef<HTMLDivElement, PropsWithChildren<CertificationCardProps>>(
  function _CertificationCard({ title, selected, onClick, ...props }, ref) {
    const { classes } = useStyles();
    return (
      <Card
        elevation={0}
        className={clsx(classes.card, { [classes.selected]: selected })}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <CardHeader
          title={
            <Typography variant="body1" sx={{ color: "info.main" }}>
              <strong>{title}</strong>
            </Typography>
          }
        />
        <CardContent>
          <Typography variant="body1" sx={{ color: "info.main" }}>
            {props.children}
          </Typography>
        </CardContent>
      </Card>
    );
  },
);

export default CertificationCard;
