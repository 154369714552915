import {
  Checkbox,
  CheckboxProps,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import useAuthContext from "client/context/auth";
import useFundsContext from "client/context/funds";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FundType } from "server/services/fund/consts";
import { makeStyles } from "tss-react/mui";
import FundCard from "./components/FundCard";

const OptionTypeMap = {
  seis_eis: [FundType.SEIS, FundType.EIS],
  seis: [FundType.SEIS],
  eis: [FundType.EIS],
  lpvc: [FundType.LPVC],
};

const useStyles = makeStyles({ name: "Funds" })((theme) => ({
  summary: {
    paddingTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  filters: {
    "& > *:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

const Funds: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { funds } = useFundsContext();
  const { user } = useAuthContext();
  const [activeFilters, setActiveFilters] = useState(() =>
    Object.keys(OptionTypeMap).reduce(
      (prev, name) => ({ ...prev, [name]: false }),
      {} as Record<keyof typeof OptionTypeMap, boolean>,
    ),
  );

  const filteredFunds = useMemo(() => {
    const filterEntries = Object.entries(activeFilters) as Array<[name: keyof typeof OptionTypeMap, value: boolean]>;
    if (filterEntries.every(([, value]) => !value)) return funds;
    return funds.filter((f) =>
      filterEntries.some(
        ([name, value]) =>
          value && OptionTypeMap[name].length === f.type.length && f.type.every((t) => OptionTypeMap[name].includes(t)),
      ),
    );
  }, [activeFilters, funds]);

  const handleChange = useCallback<NonNullable<CheckboxProps["onChange"]>>((e, checked) => {
    setActiveFilters((currValues) => ({ ...currValues, [e.target.name]: checked }));
  }, []);

  useEffect(() => {
    if (user?.isDirect && !user.isCertified) navigate("/certify", { replace: true });
  }, [navigate, user?.isCertified, user?.isDirect]);

  const options = useMemo(
    () =>
      Object.entries({
        seis_eis: "SEIS-EIS",
        seis: "SEIS",
        eis: "EIS",
        lpvc: "LP",
      }) as Array<[name: keyof typeof OptionTypeMap, label: string]>,
    [],
  );

  return (
    <Container>
      <Typography variant="h2">{user?.isAdvised ? "View the available Funds" : "Select a Fund"}</Typography>
      {user?.isAdvised && <Typography>Contact your adviser to invest again.</Typography>}
      <div className={classes.summary}>
        <div>
          <Typography variant="body2">
            {filteredFunds.length} {pluralize("Funds", filteredFunds.length)}
          </Typography>
        </div>
        <FormControl>
          <FormLabel>
            <Typography variant="body2" align="right">
              Filter Funds
            </Typography>
          </FormLabel>
          <div className={classes.filters}>
            {options.map(([name, label]) => (
              <FormControlLabel
                key={label}
                control={<Checkbox name={name} onChange={handleChange} size="small" />}
                label={<Typography variant="body2">{label}</Typography>}
              />
            ))}
          </div>
        </FormControl>
      </div>
      <FormGroup>
        <Grid container spacing={2}>
          {filteredFunds.map((fund) => (
            <Grid item key={fund._id} xs={12} md={4}>
              <FundCard fund={fund} />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Container>
  );
};

export default Funds;
