import BulletIcon from "@mui/icons-material/Circle";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  ListProps,
  SvgIconProps,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";

interface DynamicListProps {
  items?: ReactNode[];
  children?: ReactNode[];
  variant?: "bulleted" | "numbered";
  ListProps?: ListProps;
  ListItemProps?: ListItemProps;
  alignIcons?: ListItemProps["alignItems"];
  IconProps?: SvgIconProps;
}

const DynamicList: React.FC<DynamicListProps> = ({ alignIcons, variant = "bulleted", ...props }) => (
  <List {...props.ListProps}>
    {(props.children || props.items)?.map((item, index) => (
      <ListItem key={index} alignItems={alignIcons} {...props.ListItemProps}>
        <ListItemIcon>
          {variant === "bulleted" ? (
            <BulletIcon fontSize="inherit" color="primary" {...props.IconProps} />
          ) : (
            <Typography variant="inherit" color="primary">
              <strong>{index + 1}.</strong>
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText disableTypography>{item}</ListItemText>
      </ListItem>
    ))}
  </List>
);

export default DynamicList;
