export enum TransactionType {
  Primary = "primary",
  Secondary = "secondary",
  ShareSplit = "split",
  Transfer = "transfer",
  Reserve = "reserve",
}

export enum TransactionDealStage {
  SEIS = "seis",
  EIS = "eis",
  None = "none",
  Initial = "initial",
  Unknown = "unknown",
}

export enum TransactionStatus {
  Created = "created",
  Ready = "ready",
  Finalised = "finalised",
}

export enum BuySell {
  Buy = "buy",
  Sell = "sell",
}

export const TransactionStatuses = Object.values(TransactionStatus);
