import { Axios } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanFund } from "server/services/fund";

export const getOpenFunds = async (axios?: Axios) => {
  try {
    const res = await (axios || ipAxios).get<LeanFund[]>("/funds");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
