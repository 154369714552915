import { Typography } from "@mui/material";
import { FormikErrors, useFormikContext } from "formik";
import React, { Fragment, useMemo, useRef } from "react";
import Yup from "shared/utils/Yup";
import useFormGenContext from "../context";

interface ErrorListProps<T> {
  existingPath?: string;
  errors?: FormikErrors<T>;
}

const ErrorsList: React.FC<ErrorListProps<Record<string, any>>> = ({ errors: errorsProp, existingPath = "" }) => {
  const { schema } = useFormGenContext();
  const formik = useFormikContext();
  const formikRef = useRef(formik);

  const pathPrefix = useMemo(() => (existingPath ? `${existingPath}.` : ""), [existingPath]);
  const errors = useMemo(() => errorsProp || formik.errors, [errorsProp, formik.errors]);

  const list = useMemo(
    () =>
      Object.entries(errors)
        .reverse()
        .map(([fieldId, value]) => {
          if (!value) return null;
          const fullPath = `${pathPrefix}${fieldId}`;
          let nestedSchema: Yup.AnySchema | undefined;
          try {
            nestedSchema = Yup.reach(schema, fullPath, formikRef.current.values);
          } catch (error) {
            console.info(error);
          }
          if (!nestedSchema) return null;
          const { label } = nestedSchema.describe();
          if (typeof value === "string") {
            return (
              <Typography
                key={fieldId}
                component="div"
                variant="inherit"
                paragraph
                sx={[Boolean(existingPath) && { paddingLeft: 1 }]}
              >
                {existingPath ? "- " : ""}
                {label}
              </Typography>
            );
          }
          if (Array.isArray(value)) {
            return (
              <Fragment key={fieldId}>
                {value.map((arrayValue, index) => (
                  <Fragment key={index}>
                    <Typography component="div" variant="inherit" gutterBottom>
                      {label}&nbsp;{index + 1}
                    </Typography>
                    {typeof arrayValue !== "string" && <ErrorsList errors={arrayValue} existingPath={fullPath} />}
                  </Fragment>
                ))}
              </Fragment>
            );
          }
          return (
            <Fragment key={fieldId}>
              <Typography component="div" variant="inherit" gutterBottom>
                {label}
              </Typography>
              <ErrorsList errors={value as FormikErrors<any>} existingPath={fullPath} />
            </Fragment>
          );
        }),
    [errors, existingPath, pathPrefix, schema],
  );

  return (
    <Typography
      component="div"
      variant="caption"
      color="error"
      paragraph={!existingPath}
      gutterBottom={Boolean(existingPath)}
      className="fs-exclude"
    >
      {list}
    </Typography>
  );
};

export default ErrorsList;
