import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteValue,
  Chip,
  createFilterOptions,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import useHelperText from "client/hooks/useHelperText";
import useParentFieldName from "client/hooks/useParentFieldName";
import enumToOptions from "client/utils/enumToOptions";
import clsx from "clsx";
import { FieldProps } from "formik";
import React, { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";
import TrackInput, { TrackInputProps } from "../TrackInput";

export type SelectOption = string | { label: string; value: string; disabled?: boolean };

export type EnumSelectOptions = { [label: string]: string };

export interface SelectFieldProps<
  T,
  O extends SelectOption | null,
  Multiple extends boolean | undefined = any,
  DisableClearable extends boolean | undefined = any,
  FreeSolo extends boolean | undefined = any,
> extends FieldProps<AutocompleteValue<O, Multiple, DisableClearable, FreeSolo>, T>,
    Omit<Partial<AutocompleteProps<O, Multiple, DisableClearable, FreeSolo>>, "color" | "options" | "onChange">,
    Omit<TrackInputProps, "value"> {
  options: EnumSelectOptions | O[] | ((values: T, name: string) => EnumSelectOptions | O[]);
  label?: ReactNode;
  helperText?: ReactNode;
  onChange?: (
    event: { target: { name: string } },
    value: AutocompleteValue<O, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<O>,
  ) => void;
}

const filterOptions = createFilterOptions<any>({
  matchFrom: "any",
  ignoreCase: true,
  stringify: (option: SelectOption) => (typeof option === "object" ? [option.label, option.value].join() : option),
});

const getLabel = <T extends SelectOption>(option: T | null): string =>
  option !== null && typeof option === "object" ? option.label : option ?? "";

export const getValue = <T extends SelectOption>(option: T | null): string | null =>
  option !== null && typeof option === "object" ? option.value : option;

const getIsSelected = <T extends SelectOption>(option: T, selected: T) => {
  const optionValue = getValue(option);
  const selectedValue = getValue(selected);
  return optionValue === selectedValue;
};

const SelectField = <
  T,
  O extends SelectOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  field,
  form: { setFieldValue, values },
  label,
  required,
  placeholder,
  className,
  options: optionsProp,
  category,
  onChange,
  ...props
}: PropsWithChildren<SelectFieldProps<T, O, Multiple, DisableClearable, FreeSolo>>) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const parentFieldName = useParentFieldName(field.name);

  const options = useMemo<O[]>(() => {
    const resOpts = typeof optionsProp !== "function" ? optionsProp : optionsProp(values, parentFieldName);
    if (Array.isArray(resOpts)) return resOpts;
    return enumToOptions(resOpts) as O[];
  }, [optionsProp, parentFieldName, values]);

  const getMatchingOptionLabel = useCallback<NonNullable<typeof props.getOptionLabel>>(
    (val) => {
      const found = options.find((option) => getValue(option) === getValue(val));
      if (!found && !props.freeSolo) return "";
      return getLabel(found ?? val);
    },
    [options, props.freeSolo],
  );

  const value = useMemo(<R extends AutocompleteValue<O, Multiple, DisableClearable, FreeSolo>>(): R => {
    if (!field.value) return (!props.multiple ? null : []) as R;
    if (!Array.isArray(field.value)) return field.value as R;
    return field.value.map((val) => getValue(val)) as R;
  }, [field.value, props.multiple]);

  const handleChange = useCallback<NonNullable<AutocompleteProps<O, Multiple, DisableClearable, FreeSolo>["onChange"]>>(
    (_e, selected, ...args) => {
      if (onChange) return onChange({ target: { name: field.name } }, selected, ...args);
      if (selected == null) return setFieldValue(field.name, "");
      if (Array.isArray(selected)) return setFieldValue(field.name, selected.map(getValue));
      setFieldValue(field.name, getValue(selected));
    },
    [field.name, onChange, setFieldValue],
  );

  const handleInputRender = useCallback<NonNullable<typeof props.renderInput>>(
    (params) => (
      <TextField
        {...params}
        name={field.name}
        onBlur={field.onBlur}
        label={label}
        placeholder={placeholder}
        error={hasError}
        helperText={helperText}
        InputLabelProps={{ required }}
      />
    ),
    [field.name, field.onBlur, hasError, helperText, label, placeholder, required],
  );

  const handleOptionRender = useCallback<NonNullable<typeof props.renderOption>>(
    (params, option) => (
      <MenuItem {...params} key={getValue(option)} disabled={typeof option === "object" && option.disabled}>
        <Typography variant="body2" className="fs-exclude">
          {getLabel(option)}
        </Typography>
      </MenuItem>
    ),
    [],
  );

  const handleTagsRender = useCallback<NonNullable<typeof props.renderTags>>(
    (allSelected, getTagProps) =>
      allSelected.map((selected, index) => {
        const fullOption = options.find((o) => getIsSelected(selected, o));
        // eslint-disable-next-line react/jsx-key
        return <Chip {...getTagProps({ index })} label={fullOption ? getLabel(fullOption) : ""} />;
      }),
    [options],
  );

  const handleFilterOptions = useCallback<NonNullable<typeof props.filterOptions>>(
    (os, params) => {
      const filtered = filterOptions(os, params);
      if (!props.freeSolo) return filtered;
      const doesExist = filtered.some((o) => getLabel(o).toLowerCase() === params.inputValue.toLowerCase());
      if (!doesExist && params.inputValue) {
        return [...filtered, { label: `+\tAdd "${params.inputValue}"`, value: params.inputValue }];
      }
      return filtered;
    },
    [props.freeSolo],
  );

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name}>
      <Autocomplete<O, Multiple, DisableClearable, FreeSolo>
        value={value}
        options={options}
        filterOptions={handleFilterOptions}
        renderInput={handleInputRender}
        isOptionEqualToValue={getIsSelected}
        getOptionLabel={getMatchingOptionLabel}
        renderOption={handleOptionRender}
        renderTags={handleTagsRender}
        className={clsx(className, "fs-exclude")}
        handleHomeEndKeys={Boolean(props.freeSolo)}
        selectOnFocus={Boolean(props.freeSolo)}
        clearOnBlur={Boolean(props.freeSolo)}
        {...props}
        onChange={handleChange}
      />
    </TrackInput>
  );
};

export default SelectField;
