import { Box, Typography } from "@mui/material";
import React from "react";
import useApplication from "../hooks/useApplication";

interface ProgressBarProps {
  activeStage?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ activeStage = 0 }) => {
  const { isAdvisor } = useApplication();
  const sections = ["About", "Objectives", "Finances", "Investment"];
  if (!isAdvisor) sections.splice(3, 0, "Experience");
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        "& > div": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginRight: 0.5,
          "&:first-of-type > div": {
            borderRadius: "50px 0px 0px 50px",
          },
          "&:last-of-type > div": {
            borderRadius: "0px 50px 50px 0px",
          },
        },
      }}
    >
      {sections.map((stage, index) => (
        <div key={stage}>
          <Typography variant="body2" align="center" sx={[activeStage > index + 1 && { color: "text.disabled" }]}>
            {stage}
          </Typography>
          <Box
            sx={[
              {
                height: 10,
                marginTop: 0.5,
                backgroundColor: "action.disabled",
              },
              activeStage >= index + 1 && {
                backgroundColor: "primary.main",
              },
            ]}
          />
        </div>
      ))}
    </Box>
  );
};

export default ProgressBar;
