import React, { useMemo, useCallback } from "react";
import FormButton from "components/FormButton";
import { Field, Form, Formik } from "formik";
import { FormGroup, Typography } from "@mui/material";
import Yup from "shared/utils/Yup";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import TextField from "client/components/Fields/TextField";
import { submitBackupCodes } from "client/api/public";
import { useNavigate } from "react-router-dom";
import useReferrer from "client/hooks/useReferrer";
import useAuthContext, { AuthActions } from "../../context/auth";
import TwoFASupportLink from "./TwoFASupportLink";

const useStyles = makeStyles({ name: "LoginForm" })((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: theme.spacing(1.25),
    transition: theme.transitions.create("height", {
      duration: theme.transitions.getAutoHeightDuration(350 - 225),
    }),
  },
}));

const BackupCodes: React.FC = () => {
  const navigate = useNavigate();
  const referrer = useReferrer();
  const { user, dispatch } = useAuthContext();

  const schema = useMemo(
    () =>
      Yup.object({
        backupCode: Yup.string().default("").required("Please enter your Backup Code"),
      }),
    [],
  );

  const initialValues = useMemo(() => schema.getDefault(), [schema]);

  const handleSubmit = useCallback(
    async (values: Yup.Asserts<typeof schema>, { setErrors }) => {
      const responseObj = await submitBackupCodes(values.backupCode);
      if (!responseObj) {
        setErrors({ backupCode: "Backup code does not match" });
        return;
      }
      dispatch({ type: AuthActions.SetUser, payload: responseObj.data });
      if (responseObj && responseObj.redirectTo) return navigate(responseObj.redirectTo);
      if (responseObj && referrer?.pathname) return navigate({ pathname: referrer.pathname, search: referrer.search });
      if (responseObj && user && user.isAdvisor) return navigate("/advisor/dashboard");
      navigate("/dashboard");
    },
    [navigate, referrer?.pathname, referrer?.search, user, dispatch],
  );
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="body2" align="center" paragraph>
        Please enter one of the Backup Codes associated with your account
      </Typography>
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} enableReinitialize>
        <Form id="backupCode-form" className={clsx(classes.form)}>
          <FormGroup sx={{ margin: 0 }}>
            <Field
              component={TextField}
              name="backupCode"
              type="text"
              data-testid="backupCode"
              placeholder="Backup Code"
              size="small"
            />
          </FormGroup>
          <FormGroup>
            <FormButton data-testid="backupCode-button" size="small" type="submit">
              Submit
            </FormButton>
          </FormGroup>
          <TwoFASupportLink />
        </Form>
      </Formik>
    </>
  );
};

export default BackupCodes;
