import { Axios } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanRole } from "server/services/role";
import { AdminQuery } from "shared/types/admin";
import { PartialObject } from "shared/types/utils";
import { LiteralUnion } from "type-fest";

export async function getRoles<F extends LiteralUnion<keyof LeanRole, string>[] | undefined = undefined>(
  this: Axios | undefined | void,
  onChangeObject?: AdminQuery<LeanRole, F>,
) {
  try {
    const res = await (this || ipAxios).get<
      F extends Array<string> ? PartialObject<LeanRole, F[number]>[] : LeanRole[]
    >("/admin/roles", { params: onChangeObject });
    return { data: res.data, count: parseInt(res.headers["x-count"]) };
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
