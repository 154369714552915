import { FormGroup, FormLabel } from "@mui/material";
import FormButtons, { FormButtonsProps } from "client/components/FormButtons";
import useToggle from "client/hooks/useToggle";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";

interface CRUDButtonsProps {
  isCreate?: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

const CRUDButtons: React.FC<CRUDButtonsProps> = ({ isCreate: isCreateProp, onCancel, onDelete }) => {
  const [isDelete, toggleIsDelete] = useToggle(false);
  const { initialValues } = useFormikContext<{ _id?: string }>();

  const isCreate = useMemo(() => isCreateProp ?? !initialValues._id, [initialValues._id, isCreateProp]);

  const formButtonsProps = useMemo<FormButtonsProps>(() => {
    if (isDelete) {
      return {
        primaryProps: { label: "Yes", onClick: onDelete, color: "error" },
        secondaryProps: { label: "No", onClick: toggleIsDelete.off },
      };
    }
    if (isCreate) return { secondaryProps: { onClick: onCancel } };
    return { secondaryProps: { label: "Delete", onClick: toggleIsDelete.on, color: "error", variant: "contained" } };
  }, [isCreate, isDelete, onCancel, onDelete, toggleIsDelete.off, toggleIsDelete.on]);

  return (
    <FormGroup>
      <FormLabel>{!isDelete ? <>&nbsp;</> : "Are you sure?"}</FormLabel>
      <FormButtons {...formButtonsProps} />
    </FormGroup>
  );
};

export default CRUDButtons;
