import { InputAdornment, StandardTextFieldProps, TextField as MUITextField, Typography } from "@mui/material";
import useDynamicFieldText from "client/hooks/useDynamicFieldText";
import useHelperText from "client/hooks/useHelperText";
import clsx from "clsx";
import { FieldProps, useFormikContext } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import TrackInput, { TrackInputProps } from "../TrackInput";

type FormatProps = Omit<NumberFormatProps, "form" | "color" | "size" | "onChange" | "onValueChange">;

interface NumberFormatEvent {
  target: {
    name: string;
    value: number | "";
    valueAsString: string;
    valueAsFormatted: string;
  };
}

export interface NumberFieldProps
  extends FieldProps<number>,
    Omit<StandardTextFieldProps, "onChange">,
    Omit<TrackInputProps, "variant" | keyof FormatProps> {
  disableMonetaryFormat?: boolean;
  formatProps?: FormatProps;
  onChange?: (e: NumberFormatEvent, value: number | "") => void;
  prefix?: string;
  suffix?: string;
}

const MUINumberFormat = React.forwardRef<
  NumberFormat<unknown>,
  FormatProps & {
    name: string;
    onChange?: NumberFieldProps["onChange"];
    disableMonetaryFormat?: boolean;
    value: number | "" | null;
  }
>(function _MUINumberFormat({ onChange, disableMonetaryFormat, ...props }, ref) {
  const { setFieldValue } = useFormikContext();
  const [value, setValue] = useState(props.value);

  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const debouncedHandler = useMemo(
    () =>
      _.debounce((e: NumberFormatEvent, v: number | "") => {
        if (onChangeRef.current) return onChangeRef.current(e, v);
        setFieldValue(props.name, v);
      }),
    [props.name, setFieldValue],
  );

  const handleChange = useCallback<NonNullable<NumberFormatProps["onValueChange"]>>(
    (vals, { source }) => {
      if (source === "prop") return;
      const val = vals.floatValue ?? "";
      const v = typeof val === "string" || isNaN(val) ? "" : val;
      setValue(v);
      debouncedHandler(
        {
          target: {
            name: props.name,
            value: val,
            valueAsString: vals.value,
            valueAsFormatted: vals.formattedValue,
          },
        },
        v,
      );
    },
    [debouncedHandler, props.name],
  );

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <NumberFormat
      thousandSeparator
      isNumericString
      {...(disableMonetaryFormat ? {} : { decimalScale: 2, fixedDecimalScale: true })}
      {...props}
      value={value ?? ""}
      onValueChange={handleChange}
      getInputRef={ref}
      inputMode="numeric"
    />
  );
});

const NumberField: React.FC<NumberFieldProps> = ({
  field,
  form: _form,
  label: labelProp,
  placeholder: placeholderProp,
  disableMonetaryFormat,
  className,
  formatProps,
  category,
  prefix,
  suffix,
  ...props
}) => {
  const { hasError, helperText } = useHelperText(field.name, props.helperText);
  const label = useDynamicFieldText(labelProp);
  const placeholder = useDynamicFieldText(placeholderProp);

  const InputProps = useMemo(
    () => ({
      ...props.InputProps,
      inputComponent: MUINumberFormat as any,
      inputProps: { disableMonetaryFormat, ...props.inputProps, ...formatProps },
      startAdornment: (
        <InputAdornment position="start">
          <Typography>{prefix || (!disableMonetaryFormat ? "£" : "")}</Typography>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <Typography> {suffix}</Typography>
        </InputAdornment>
      ),
    }),
    [disableMonetaryFormat, formatProps, prefix, props.InputProps, props.inputProps, suffix],
  );

  return (
    <TrackInput {...props} error={hasError} category={category} name={field.name} value={field.value}>
      <MUITextField
        error={hasError}
        label={label}
        placeholder={placeholder}
        className={clsx(className, "fs-exclude")}
        {...field}
        {...(props as any)}
        InputProps={InputProps}
        helperText={helperText}
      />
    </TrackInput>
  );
};

export default NumberField;
