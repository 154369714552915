import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, FormGroup } from "@mui/material";
import React, { PropsWithChildren, ReactNode } from "react";
import useFormGenContext from "../context";
import { AccordionConfigObj, BaseValues } from "../types";

export interface AccordionWrapProps
  extends Pick<object & Omit<AccordionConfigObj, "enabled">, "summaryProps" | "detailsProps">,
    Partial<AccordionProps> {
  summary: ReactNode;
}

const AccordionWrap = <T extends BaseValues>({
  summary,
  summaryProps,
  detailsProps,
  ...props
}: PropsWithChildren<AccordionWrapProps>) => {
  const { classes } = useFormGenContext<T>();

  return (
    <FormGroup className={classes?.formGroup}>
      <Accordion variant="outlined" {...props}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} {...summaryProps}>
          {summary}
        </AccordionSummary>
        <AccordionDetails {...detailsProps}>{props.children}</AccordionDetails>
      </Accordion>
    </FormGroup>
  );
};

export default AccordionWrap;
