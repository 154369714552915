import { Avatar, AvatarProps } from "@mui/material";
import stringToColour from "client/utils/stringToColour";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface DynamicAvatarProps extends AvatarProps {
  text?: string;
}

const InitialsRegex = /(\b\w).+(\b\w).*|(\b\w).*/;

const DynamicAvatar: React.FC<DynamicAvatarProps> = ({ src, text, sx, ...props }) => {
  const [source, setSource] = useState(src);

  const initials = useMemo(() => text?.replace(InitialsRegex, "$1$2$3"), [text]);

  const handleError = useCallback(() => setSource(undefined), []);

  useEffect(() => {
    setSource(src);
  }, [src]);

  return (
    <Avatar
      alt={text}
      {...props}
      src={source}
      sx={[{ letterSpacing: 0 }, text && { bgcolor: stringToColour(text) }, ...(Array.isArray(sx) ? sx : [sx])]}
      imgProps={{ onError: handleError }}
    >
      {initials}
    </Avatar>
  );
};

export default DynamicAvatar;
