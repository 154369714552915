import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { getRoles } from "client/api/admin/roles";
import { createUser } from "client/api/admin/users";
import { deleteUser, updateUser } from "client/api/admin/users/user";
import { getValue, SelectOption } from "client/components/Fields/SelectField";
import useAdminContext, { AdminActions } from "client/context/admin";
import { AddClientConfig, AddClientValues } from "client/views/Advisor/AddClient";
import { OnboardingConfig } from "client/views/Advisor/Onboarding";
import { getInvestorDetailsConfig } from "client/views/Application/InvestorDetails";
import FormGen, { AnyField, ArrayConfig, FormGenConfig, SelectConfig } from "form-gen";
import { overrideConfig } from "form-gen/utils";
import { FormikConfig } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AdminUser, Advisor, ClientUser, LeanUser } from "server/services/user";
import { CertificationType, FeeType, UserStatus, UserType } from "server/services/user/consts";
import Yup from "shared/utils/Yup";
import CRUDButtons from "../components/CRUDButtons";
import useFundOptions from "../hooks/useFundOptions";
import useUserOptions from "../hooks/useUserOptions";
import splitUsers from "../utils/splitUsers";
import { useParams } from "react-router-dom";
import ResetTwoFA from "client/components/CTAs/ResetTwoFA";
import { UserMeta } from "server/services/user/model";
import { DateTime } from "luxon";

interface FormProps extends DialogProps {
  onClose(): any;
  user?: AdminUser | null;
}

export interface UserFormClient extends Pick<ClientUser, "fee"> {
  user: string;
  type: UserType.Advised | UserType.Referred;
}
export interface UserFormType extends Omit<LeanUser, "advisorDetails" | "meta"> {
  advisorDetails?: Advisor & {
    clients: UserFormClient[];
  };
  meta?: Partial<UserMeta>;
}

interface FormConfigType extends Omit<UserFormType, "advisorDetails" | "advisor"> {
  advisor?: Omit<object & UserFormType["advisor"], "fee"> & Pick<AddClientValues, "fee">;
  advisorDetails?: Advisor & {
    clients: Array<
      Omit<UserFormClient, "fee"> & {
        fee: {
          type: FeeType;
          fixed: number | null;
          percentage: number | null;
        };
      }
    >;
  };
}

const getSelectValidation = (config: SelectConfig<FormConfigType>) =>
  Yup.string()
    .oneOf(config.options as string[])
    .required("Required");

const TransformedAddClientConfig = AddClientConfig.filter((field) => [field.name, field.key].includes("fee")).flatMap(
  (field) => (!field.fields ? field : field.fields),
) as FormGenConfig<FormConfigType>;

const PersonalDetailsGroupsMap = {
  contactNumber: "group1",
  addressHistory: "group1",

  dob: "group2",
  placeOfBirth: "group2",
  nationality: "group2",
  taxResidency: "group2",
  tiNumber: "group2",
  usCitizen: "group2",
  pep: "group2",
  "dualTaxResidency.present": "group2",
  "dualTaxResidency.nationality": "group2",
  "dualTaxResidency.tiNumber": "group2",
  "dualTaxResidency.addressHistory": "group2",
} as const;

const InvestorDetailsConfig = getInvestorDetailsConfig(false);

const PersonalDetailsGroups = {
  group1: [] as FormGenConfig<FormConfigType>,
  group2: [] as FormGenConfig<FormConfigType>,
} as const;
InvestorDetailsConfig.forEach((config) => {
  const groupKey = PersonalDetailsGroupsMap[(config.name || config.key) as keyof typeof PersonalDetailsGroupsMap];
  if (groupKey) PersonalDetailsGroups[groupKey].push(config as AnyField<FormConfigType>);
});

const AddressHistoryOverrides: Partial<ArrayConfig<any>> = {
  min: 0,
  automatic: false,
  initialValue: [],
  validation: Yup.array().min(0),
};

const isClient = <T extends { type?: UserType | null }>(values: T) =>
  Boolean(values.type && [UserType.Advised, UserType.Referred].includes(values.type));

export const getUserConfig = ({
  roles,
  advisors,
  investors,
  funds,
}: {
  roles?: SelectOption[];
  advisors?: SelectOption[];
  investors?: SelectOption[];
  funds?: SelectOption[];
} = {}): FormGenConfig<FormConfigType> => {
  return [
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Personal Details",
      fields: [
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "text",
              name: "title",
              label: "Title",
              placeholder: "Title",
              validation: Yup.string().nullable(),
            },
            {
              type: "text",
              name: "forenames",
              label: "Forenames",
              placeholder: "Forenames",
              validation: Yup.string().required("Required"),
            },
            {
              type: "text",
              name: "surname",
              label: "Surname",
              placeholder: "Surname",
              validation: Yup.string().required("Required"),
            },
          ],
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          placeholder: "Email",
          validation: Yup.string().email("Invalid email address"),
        },
        ...overrideConfig(
          {
            namePrefix: "personalDetails",
            setAllNotRequired: true,
            overrides: {
              addressHistory: AddressHistoryOverrides,
            },
          },
          PersonalDetailsGroups.group1,
        ),
      ],
    },
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Account Details",
      fields: [
        {
          type: "date",
          name: "meta.registeredAt",
          label: "Registration Date",
          placeholder: "Registration Date",
          initialValue: DateTime.now().toISO(),
          validation: Yup.date().required("Required"),
        },
        {
          type: "select",
          name: "role",
          label: "Role",
          options: roles ?? [],
          validation: Yup.string()
            .when({
              is: Boolean(roles),
              then: (schema) => schema.oneOf(roles!.map((r) => (typeof r === "string" ? r : r.value))),
            })
            .required("Required"),
        },
        {
          type: "select",
          name: "type",
          label: "User Type",
          options: Object.values(UserType),
          get validation() {
            return getSelectValidation(this);
          },
          onChange(_e, value) {
            this.setFieldValue("type", value);
            if (isClient({ type: getValue(value as SelectOption | null) as UserType }))
              this.setFieldValue("advisor", {});
          },
        },
        {
          type: "select",
          name: "certification.type",
          label: "Certification Type",
          options: Object.values(CertificationType),
          get validation() {
            return getSelectValidation(this);
          },
        },
        {
          type: "select",
          name: "status",
          label: "Application Status",
          options: Object.values(UserStatus),
          get validation() {
            return getSelectValidation(this);
          },
        },
        {
          type: "radio",
          name: "meta.emailConfirmed",
          label: "Email Confirmed?",
          validation: Yup.boolean().nullable(),
        },
        {
          type: "select",
          name: "funds",
          label: "User's Funds",
          props: {
            multiple: true,
            helperText:
              "This is the list of Funds the user has access to. Clear the list to give them access to all Funds.",
          },
          options: funds ?? [],
          validation: Yup.array().nullable().of(Yup.objectId().required()),
        },
      ],
    },
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Advisor & Fee Details",
      condition: isClient,
      fields: [
        {
          type: "select",
          name: "advisor.user",
          label: "Advisor",
          options: advisors ?? [],
          validation: Yup.string()
            .when({
              is: Boolean(advisors),
              then: (schema) => schema.oneOf(advisors!.map((a) => (typeof a === "string" ? a : a.value))),
            })
            .when({
              is: (values?: UserFormType) => values?.type === UserType.Advised || values?.type === UserType.Referred,
              then: (schema) => schema.required("Required"),
            }),
        },
        ...overrideConfig("advisor", TransformedAddClientConfig),
      ],
    },
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Advisor Details",
      condition: (values) => values.type === UserType.Advisor,
      initialValue: null,
      fields: [
        ...overrideConfig(
          "advisorDetails",
          OnboardingConfig.filter(
            (field) => !field.name || !["title", "forenames", "surname", "contactNumber"].includes(field.name),
          ),
        ),
        {
          type: "array",
          name: "advisorDetails.clients",
          label: "Clients",
          fields: [
            {
              type: "select",
              name: "user",
              label: "Client",
              options: investors || [],
              validation: Yup.string()
                .when({
                  is: Boolean(investors),
                  then: (schema) => schema.oneOf(investors!.map((c) => (typeof c === "string" ? c : c.value))),
                })
                .required("Required"),
            },
            ...TransformedAddClientConfig,
          ],
        },
      ],
    },
    {
      type: "validation",
      validation: Yup.object({
        advisor: Yup.object()
          .nullable()
          .default(null)
          .when("type", {
            is: (type: UserType) => type === UserType.Advised || type === UserType.Referred,
            then: (schema) => schema.required(),
          }),
        advisorDetails: Yup.object()
          .nullable()
          .default(null)
          .when("type", {
            is: UserType.Advisor,
            then: (schema) => schema.required(),
          }),
        meta: Yup.object({
          twoFASecret: Yup.string().nullable(),
          backupCodes: Yup.array().nullable(),
          twoFAEnabled: Yup.boolean(),
        }),
      }),
    },
    {
      type: "section",
      accordion: true,
      title: "Additional Personal Details",
      fields: overrideConfig(
        {
          namePrefix: "personalDetails",
          setAllNotRequired: true,
          overrides: {
            nationality: { validation: Yup.string() },
            taxResidency: { validation: Yup.string() },
            "dualTaxResidency.nationality": { validation: Yup.string() },
            "dualTaxResidency.addressHistory": AddressHistoryOverrides,
          },
        },
        PersonalDetailsGroups.group2,
      ),
    },
  ];
};

const UserForm: React.FC<FormProps> = ({ onClose, user, ...props }) => {
  const { userId } = useParams<{ userId: string }>();
  const { dispatch, users } = useAdminContext();
  const [allRoles, setAllRoles] = useState<SelectOption[]>([]);
  const userOptions = useUserOptions();
  const fundOptions = useFundOptions();

  const adminUser = useMemo(() => user || users[userId!], [userId, user, users]);

  useEffect(() => {
    (async () => {
      const res = await getRoles({ fields: ["_id", "type"] });
      if (res) setAllRoles(res.data.map((r) => ({ value: r._id, label: r.type! })));
    })();
  }, []);

  const { advisors, investors } = useMemo(() => splitUsers(userOptions), [userOptions]);

  const config = useMemo(
    () => getUserConfig({ roles: allRoles, advisors, investors, funds: fundOptions }),
    [advisors, allRoles, fundOptions, investors],
  );

  const handleSubmit = useCallback<FormikConfig<FormConfigType>["onSubmit"]>(
    async ({ password: _password, advisorDetails, advisor, ...values }) => {
      const resolvedValues: UserFormType = {
        ...values,
        ...(!advisor
          ? {}
          : {
              advisor: {
                ...advisor,
                fee: {
                  type: advisor.fee.type,
                  amount: advisor.fee.type === FeeType.Fixed ? advisor.fee.fixed : advisor.fee.percentage,
                },
              },
            }),
        ...(!advisorDetails
          ? {}
          : {
              advisorDetails: {
                ...advisorDetails,
                clients: advisorDetails.clients.map((client) => ({
                  ...client,
                  fee: {
                    type: client.fee.type,
                    amount: client.fee.type === FeeType.Fixed ? client.fee.fixed! : client.fee.percentage!,
                  },
                })),
              },
            }),
      };
      const res = adminUser ? await updateUser(adminUser._id, resolvedValues) : await createUser(resolvedValues);
      if (!res) return;
      dispatch({ type: AdminActions.UpdateUser, payload: res });
      onClose();
    },
    [dispatch, onClose, adminUser],
  );

  const handleDelete = useCallback(async () => {
    if (!adminUser) return;
    const res = await deleteUser(adminUser._id);
    if (!res) return;
    dispatch({ type: AdminActions.RemoveUser, payload: res });
    onClose();
  }, [dispatch, onClose, adminUser]);

  const handleResetTwoFA = useCallback(
    (setFieldValue) => {
      if (!adminUser) return;
      setFieldValue("meta.twoFAEnabled", false);
      setFieldValue("meta.twoFASecret", null);
      setFieldValue("meta.backupCodes", []);
    },
    [adminUser],
  );

  const initialValues = useMemo<FormConfigType | undefined>(() => {
    if (!adminUser) return;
    const { advisor, advisorDetails, meta, ...initValues } = adminUser;
    const clientValues: Pick<FormConfigType, "advisor"> | false | null | undefined = isClient(adminUser) &&
      advisor && {
        advisor: {
          ...advisor,
          user: advisor.user._id,
          fee:
            advisor.fee.type === FeeType.Fixed
              ? {
                  type: FeeType.Fixed,
                  fixed: advisor.fee.amount,
                  percentage: null,
                }
              : {
                  type: FeeType.Percentage,
                  percentage: advisor.fee.amount,
                  fixed: null,
                },
        },
      };
    return {
      ...initValues,
      role: adminUser.role._id,
      meta: {
        ...meta,
        minInvestment: meta.minInvestment ?? null,
        maxInvestment: meta.maxInvestment ?? null,
      },
      ...clientValues,
      ...(adminUser.type !== UserType.Advisor || !advisorDetails
        ? {}
        : {
            advisorDetails: {
              ...advisorDetails,
              clients: advisorDetails.clients.map((client) => ({
                user: client._id,
                type: client.type as UserType.Advised | UserType.Referred,
                fee: {
                  type: client.advisor!.fee.type,
                  fixed: client.advisor!.fee.type === FeeType.Fixed ? client.advisor!.fee.amount : null,
                  percentage: client.advisor!.fee.type === FeeType.Percentage ? client.advisor!.fee.amount : null,
                },
              })),
            },
          }),
    };
  }, [adminUser]);

  return (
    <Dialog onClose={onClose} maxWidth="lg" {...props}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <FormGen config={config} onSubmit={handleSubmit} initialValues={initialValues} castRequiredSchema>
          <ResetTwoFA userId={initialValues?._id} onReset={handleResetTwoFA} />
          <CRUDButtons onCancel={onClose} onDelete={handleDelete} />
        </FormGen>
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;
