import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { Axios } from "axios";
import { getCompanySectors } from "client/api/companies";
import { useState } from "react";
import { CompanySector } from "server/services/company";

const useSectors = () => {
  const { sectors: preloadedSectors } = usePreloadedState<{ sectors?: CompanySector[] }>();
  const [sectors, setSectors] = useState<CompanySector[]>(preloadedSectors ?? []);

  useSCEffect(
    async (axios: Axios) => {
      const res = await getCompanySectors.call(axios);
      setSectors(res);
      return res;
    },
    [],
    "sectors",
  );

  return sectors;
};

export default useSectors;
