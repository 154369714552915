import { FormGroup, FormLabel } from "@mui/material";
import { Field, FieldProps } from "formik";
import React, { useCallback, useMemo } from "react";
import { InvestmentDetails } from "server/services/application";
import { makeStyles } from "tss-react/mui";
import { TrackInputProps } from "../TrackInput";
import NumberField, { NumberFieldProps } from "./NumberField";
import SliderField, { SliderFieldProps } from "./SliderField";

export interface SEISSplitFieldProps
  extends FieldProps<NonNullable<InvestmentDetails["split"]>>,
    Pick<TrackInputProps, "category"> {
  label?: string;
  row?: boolean;
  InputProps?: Omit<NumberFieldProps, keyof FieldProps>;
  SliderProps?: Omit<SliderFieldProps, keyof FieldProps>;
}

const useStyles = makeStyles({ name: "SEISSplitField" })((theme) => ({
  split: {
    alignItems: "center",
    "& > div:nth-of-type(2)": {
      flex: 3,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      flexDirection: "column",
      "& > *": {
        width: "100%",
      },
      "& > *:not(:last-child)": {
        marginRight: 0,
      },
    },
  },
}));

const SEISSplitField: React.FC<SEISSplitFieldProps> = ({
  field,
  form: { setFieldValue },
  row = true,
  InputProps,
  SliderProps,
  category,
  label,
}) => {
  const { classes } = useStyles();
  const seisName = useMemo(() => `${field.name}.seis`, [field.name]);
  const eisName = useMemo(() => `${field.name}.eis`, [field.name]);

  const handleInputChange = useCallback<NonNullable<NumberFieldProps["onChange"]>>(
    (e, newValue) => {
      setFieldValue(e.target.name, newValue, false);
      setFieldValue(e.target.name === seisName ? eisName : seisName, newValue && 100 - newValue);
    },
    [eisName, seisName, setFieldValue],
  );

  const handleSliderChange = useCallback(
    (_event, value: number) => {
      setFieldValue(eisName, Number(value));
      setFieldValue(seisName, Number(100 - value));
    },
    [eisName, seisName, setFieldValue],
  );

  return (
    <>
      {label && <FormLabel className="fs-exclude">{label}</FormLabel>}
      <FormGroup row={row} className={classes.split}>
        <Field
          component={NumberField}
          name={seisName}
          placeholder="SEIS"
          label="SEIS Split"
          category={category}
          suffix="%"
          min="0"
          max="100"
          step={1}
          disableMonetaryFormat
          onChange={handleInputChange}
          {...InputProps}
        />
        <Field
          name={eisName}
          component={SliderField}
          category={category}
          track={false}
          onChange={handleSliderChange}
          {...SliderProps}
        />
        <Field
          component={NumberField}
          name={eisName}
          placeholder="EIS"
          label="EIS Split"
          category={category}
          suffix="%"
          min="0"
          max="100"
          step={1}
          disableMonetaryFormat
          onChange={handleInputChange}
          {...InputProps}
        />
      </FormGroup>
    </>
  );
};

export default SEISSplitField;
