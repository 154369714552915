import { useFormikContext } from "formik";
import _ from "lodash";
import { ReactNode, useMemo } from "react";

const useHelperText = (name: string, helperText?: ReactNode) => {
  const { errors, touched } = useFormikContext();
  return useMemo(() => {
    const errMessage = _.get(errors, name);
    const hasErr = _.get(touched, name) && Boolean(errMessage);
    return { hasError: hasErr, helperText: hasErr ? errMessage : helperText };
  }, [errors, helperText, name, touched]);
};

export default useHelperText;
