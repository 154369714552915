import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { TransactionFormType } from "client/views/AdminPanel/Transactions/Form";
import { AdminTransaction } from "server/services/transaction";

export const patchTransaction = async (id: string, changes: Partial<TransactionFormType>) => {
  try {
    const res = await ipAxios.patch<AdminTransaction>(`/admin/transactions/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const updateTransaction = async (id: string, changes: TransactionFormType) => {
  try {
    const res = await ipAxios.put<AdminTransaction>(`/admin/transactions/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const deleteTransaction = async (id: string) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/transactions/${id}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
