import { Container, FormGroup, Link, Typography } from "@mui/material";
import { setPassword } from "client/api/public";
import TextField from "client/components/Fields/TextField";
import FormButton from "client/components/FormButton";
import useAuthContext, { AuthActions } from "client/context/auth";
import useConfigContext from "client/context/config";
import useQueryParams from "client/hooks/useQueryParams";
import { Field, Form, Formik } from "formik";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TokenAuthStatus } from "shared/consts";
import Yup from "shared/utils/Yup";
import { PasswordSchema } from "./Home/Register";

const Schema = PasswordSchema.clone();

const InitialValues = Schema.cast({});

const SetPassword: React.FC<{ isReset?: boolean }> = ({ isReset }) => {
  const navigate = useNavigate();
  const { portal } = useConfigContext();
  const { dispatch } = useAuthContext();
  const [{ status }] = useQueryParams<{ status: Exclude<TokenAuthStatus, TokenAuthStatus.Conflict> }>();

  const isSuccessful = useMemo(() => status !== TokenAuthStatus.Error && status !== TokenAuthStatus.Expired, [status]);

  const reasonDescription = useMemo<ReactNode>(() => {
    if (status === TokenAuthStatus.Error) {
      return "There was an error processing your request. Please try again later.";
    }
    if (status === TokenAuthStatus.Expired) {
      return (
        <>
          Your link has expired. Please contact the support team{" "}
          <Link href={`mailto:${portal.supportContact.email}`}>here</Link> to request a new link.
        </>
      );
    }
    return null;
  }, [portal.supportContact.email, status]);

  const handleSubmit = useCallback(
    async (values: Yup.Asserts<typeof Schema>) => {
      const res = await setPassword(values.password);
      if (!res) return;
      dispatch({ type: AuthActions.SetUser, payload: res.user });
      navigate(res.redirectTo ?? "/dashboard", { replace: true });
    },
    [dispatch, navigate],
  );

  return (
    <Container maxWidth="sm" style={{ paddingTop: 150 }}>
      <Typography variant="h2" align="center" paragraph>
        {isReset ? "Reset password" : "Set your password"}
      </Typography>
      {reasonDescription && (
        <Typography variant="body1" align="center" paragraph>
          {reasonDescription}
        </Typography>
      )}
      {!reasonDescription && (
        <Typography variant="body2" align="center" sx={{ color: "info.main" }}>
          {isReset
            ? "You can reset your password here, just enter and confirm your new password."
            : "Before continuing, you need to set a password for your account."}
        </Typography>
      )}
      <Container maxWidth="xs" sx={{ py: 0 }}>
        {isSuccessful && (
          <Formik initialValues={InitialValues} validationSchema={Schema} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="password"
                    placeholder="Password*"
                    type="password"
                    autoComplete="password"
                    size="small"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    component={TextField}
                    name="confirmPassword"
                    placeholder="Confirm your password*"
                    type="password"
                    autoComplete="confirm-password"
                    size="small"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormButton type="submit" size="small">
                    Submit
                  </FormButton>
                </FormGroup>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </Container>
  );
};

export default SetPassword;
