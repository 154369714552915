import { Container, FormGroup, Link, Typography } from "@mui/material";
import Divider from "client/components/Divider";
import FormButtons from "client/components/FormButtons";
import useToggle from "client/hooks/useToggle";
import useAppRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { ErrorsList, FormGenConfig } from "form-gen";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Finances as FinancesType } from "server/services/application";
import Yup from "shared/utils/Yup";
import NetAssetsExclusions from "./components/NetAssetsExclusions";
import useApplication from "./hooks/useApplication";
import useAppStyles from "./hooks/useAppStyles";
import { Forms } from "./utils";
import useAuthContext from "client/context/auth";
export const getFinancesConfig = (isAdvisor?: boolean, onNAVClick?: () => void): FormGenConfig<FinancesType> => [
  {
    type: "number",
    name: "netDisposableIncome",
    label: isAdvisor
      ? "Please indicate your client's annual net disposable income (after all regular financial commitments) from employment and investment income, etc."
      : "Please indicate your annual net disposable income (after all regular financial commitments) from employment and investment income, etc.",
    placeholder: "Disposable income, e.g. £250000",
    validation: Yup.number().required("Please indicate your annual net disposable income"),
    props: {
      helperText: (
        <>
          <span style={{ color: "black" }}>
            Answer to the nearest <b>10,000</b>
          </span>
        </>
      ),
    },
  },
  {
    type: "number",
    name: "netAssetsValue",
    get label() {
      return (
        <>
          {this.stringLabel} <Link onClick={onNAVClick}>What should I exclude from net assets?</Link> (It will only take
          30 secs to read)
        </>
      );
    },
    stringLabel: isAdvisor
      ? "Please indicate the estimated value of your client's net assets (excluding their residence, net of any loans or other liabilities)"
      : "Please indicate the estimated value of your net assets (excluding your residence, net of any loans or other liabilities)",
    placeholder: "Net Assets Value, e.g. £500,000",
    validation: Yup.number().required("Please indicate your annual net disposable income"),
    props: {
      helperText: (
        <>
          <span style={{ color: "black" }}>
            Answer to the nearest <b>100,000</b>
          </span>
        </>
      ),
    },
  },
  {
    type: "select",
    name: "incomeSources",
    placeholder: isAdvisor
      ? "Describe the source(s) of their subscription"
      : "Describe the source(s) of your subscription",
    label: isAdvisor
      ? "Please describe the source(s) of their subscription"
      : "Please describe the source(s) of your subscription",
    props: { multiple: true },
    initialValue: [],
    options: [
      "Earnings from Employment",
      "Earnings from Business Interest",
      "Investment Income",
      "Inheritance",
      "House Sale",
      "Company Sale",
      "Gift",
      "Winning Lottery or Prize Money",
      "Divorce Settlement",
      "Other (please specify)",
    ],
    validation: Yup.array(Yup.string())
      .default([])
      .min(1, "Please enter at least one source of income")
      .required("Please enter at least one source of income"),
  },
  {
    type: "text",
    name: "otherIncomeSources",
    label: "Other Income Sources",
    placeholder: "Please describe the other source(s) of your subscription",
    condition: (values) => values.incomeSources?.includes("Other (please specify)"),
    validation: Yup.string().when("incomeSources", {
      is: (incomeSources: string[]) => incomeSources?.includes("Other (please specify)"),
      then: Yup.string().required("Please enter the other source of income"),
    }),
  },
];

const Finances: React.FC = () => {
  useAppRedirect();
  const { classes } = useAppStyles();
  const navigate = useNavigate();
  const { application, handleAppDataPatch, isAdvisor } = useApplication();
  const { user } = useAuthContext();
  const sectionText = isAdvisor ? "Section 3 of 4" : "Section 3 of 5";
  const [dialogOpen, toggleDialog] = useToggle(false);

  const config = useMemo(() => getFinancesConfig(isAdvisor, toggleDialog.on), [isAdvisor, toggleDialog.on]);

  const goBack = useCallback(() => navigate(`../${Forms.Objectives}`), [navigate]);

  const handleData = useCallback(
    async (values: FinancesType) => {
      if (values.netAssetsValue && values.netAssetsValue >= 150000) {
        values.netAssetsAbove = true;
      } else {
        values.netAssetsAbove = false;
      }
      if (values.netDisposableIncome && values.netDisposableIncome >= 150000) {
        values.netDisposableAbove = true;
      } else {
        values.netDisposableAbove = false;
      }
      const app = await handleAppDataPatch({ finances: values });
      app && (app?.advisor === null || user?.isReferred)
        ? navigate(`../${Forms.Experience}`)
        : navigate(`../${Forms.Investment}`);
    },
    [handleAppDataPatch, navigate, user?.isReferred],
  );

  return (
    <>
      <Container maxWidth="md">
        <FormGroup>
          <Typography variant="h4" gutterBottom>
            <strong>{sectionText}:</strong>
          </Typography>
          <Typography variant="h4" paragraph>
            Financial Situation
          </Typography>
          <Typography variant="h4" paragraph>
            {isAdvisor
              ? "The figures below should include your client's spouse or civil partner, where applicable."
              : "The figures below should include your spouse or civil partner, where applicable."}
            &#160;
            <a
              href="https://217255.fs1.hubspotusercontent-na1.net/hubfs/217255/First%20Video.mp4"
              target="_blank"
              rel="noreferrer"
            >
              See our short video for more information
            </a>
          </Typography>
        </FormGroup>
      </Container>
      <Divider />
      <Container maxWidth="md">
        <FormGen
          config={config}
          initialValues={application?.data.finances}
          onSubmit={handleData}
          classes={{ formGroup: classes.formGroup }}
          enableReinitialize
        >
          {({ errors, submitCount }) => (
            <>
              {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                <FormGroup>
                  <Typography variant="body2" color="error" paragraph>
                    Please complete the following fields:
                  </Typography>
                  <ErrorsList errors={errors} />
                </FormGroup>
              )}
              <FormGroup>
                <FormButtons primaryProps={{ label: "Next" }} secondaryProps={{ label: "Back", onClick: goBack }} />
              </FormGroup>
            </>
          )}
        </FormGen>
      </Container>
      <NetAssetsExclusions open={dialogOpen} onClose={toggleDialog.off} />
    </>
  );
};

export default Finances;
