import { Collapse, Container, Fade, Typography } from "@mui/material";
import RouterLink from "client/components/RouterLink";
import { useConfigContext } from "client/context/config";
import useDescription from "client/hooks/useDescription";
import useTitle from "client/hooks/useTitle";
import clsx from "clsx";
import React, { FC, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "LoginRegisterView" })(() => ({
  root: {
    position: "sticky",
    top: 0,
    paddingTop: "40px",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
}));

const Forms: FC = () => {
  const { portal } = useConfigContext();
  useTitle(portal.meta.titles.home || portal.meta.titles.default);
  useDescription(portal.meta.descriptions.home || portal.meta.descriptions.default);
  const { classes } = useStyles();
  const { state, ...location } = useLocation();
  const home = useMemo(() => location.pathname === "/", [location.pathname]);
  const register = useMemo(() => location.pathname === "/register", [location.pathname]);
  const login = useMemo(() => location.pathname === "/login", [location.pathname]);
  const twoFactorAuth = useMemo(() => location.pathname === "/authenticate", [location.pathname]);
  const backupCodes = useMemo(() => location.pathname === "/backup", [location.pathname]);

  return (
    <Container maxWidth="xs" className={classes.root}>
      <div className={classes.cardHeader}>
        <Fade in={home || login} className={clsx({ [classes.hide]: !home && !login })}>
          <div>
            <Typography variant="h1" align="center" paragraph>
              Welcome back
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Haven&apos;t signed up?{" "}
              <RouterLink to={{ ...location, pathname: "/register" }} state={state} data-testid="to-registration">
                Register
              </RouterLink>
            </Typography>
          </div>
        </Fade>
        <Fade in={register} className={clsx({ [classes.hide]: !register })}>
          <div>
            <Typography variant="h1" align="center" paragraph>
              Welcome
            </Typography>
            <Typography variant="body2" align="center" paragraph>
              Already have an account?{" "}
              <RouterLink to={{ ...location, pathname: "/login" }} state={state} data-testid="to-login">
                Login
              </RouterLink>
            </Typography>
          </div>
        </Fade>
        <Fade in={twoFactorAuth} className={clsx({ [classes.hide]: !twoFactorAuth })}>
          <div>
            <Typography variant="h1" align="center" paragraph style={{ fontSize: "3rem" }}>
              Two-Factor Authentication
            </Typography>
          </div>
        </Fade>
        <Fade in={backupCodes} className={clsx({ [classes.hide]: !backupCodes })}>
          <div>
            <Typography variant="h1" align="center" paragraph>
              Backup Codes
            </Typography>
          </div>
        </Fade>
      </div>
      <Collapse in>
        <Outlet />
      </Collapse>
    </Container>
  );
};

export default Forms;
