import { Box, Button, Container, Tooltip, Typography } from "@mui/material";
import { FeeType, UserType } from "server/services/user/consts";
import { AppStatus } from "server/services/application/consts";
import RouterLink from "client/components/RouterLink";
import DataTable, { ColumnDefinition, numberFormatter } from "@wearenova/mui-data-table";
import RouterButton from "client/components/RouterButton";
import useAdvisorContext from "client/context/advisor";
import useAuthContext from "client/context/auth";
import useScrollToTop from "client/hooks/useScrollToTop";
import EVENTS from "client/utils/events.consts";
import getDetailedStatus from "client/utils/getDetailedStatus";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdvisorStateClient } from "server/services/user";
import { titleCase } from "title-case";
import { makeStyles } from "tss-react/mui";
const EDIT_FEE_DISABLED_STATES: string[] = [AppStatus.SignaturePending];
const useStyles = makeStyles({ name: "AdvisorDashboard" })((theme) => ({
  bannerWrapper: {
    borderTop: `3px solid ${theme.palette.secondary.main}`,
    borderBottom: `3px solid ${theme.palette.secondary.main}`,
  },
  bannerContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const Dashboard: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { clients } = useAdvisorContext();
  const { state: fund } = useLocation();
  useScrollToTop();
  const handleApplyLinkRender = useCallback((record: AdvisorStateClient) => {
    if (record.type !== UserType.Advised) return null;
    const isDisabled = EDIT_FEE_DISABLED_STATES.includes(record.status);
    let createDynamicRoute: any = `/advisor/clients/${record.userId}/apply/funds`;
    if (fund !== null) createDynamicRoute = `/advisor/clients/${record.userId}/apply/${fund}`;
    const to = isDisabled ? "./" : createDynamicRoute;
    return (
      <Tooltip
        title={
          !isDisabled
            ? ""
            : "Your client needs to sign the in-progress application before you can start a new one on their behalf."
        }
      >
        <div>
          <RouterLink
            to={to}
            data-client-id={record.userId}
            data-testid="applyNow"
            underline={isDisabled ? "none" : "always"}
            sx={[isDisabled && { color: "action.disabled", pointerEvents: "none" }]}
          >
            Apply now
          </RouterLink>
        </div>
      </Tooltip>
    );
  }, []);
  const tableStructure = useMemo<ColumnDefinition<AdvisorStateClient>[]>(
    () => [
      {
        key: "clientName",
        title: "Client Name",
        render: (record) => `${record.details.forenames} ${record.details.surname}`,
      },
      {
        key: "status",
        title: "Application Status",
        dataType: "string",
        dataIndex: "status",
        render: (record) => getDetailedStatus(record.status),
      },
      {
        key: "type",
        title: "Advice Type",
        render: (record) => titleCase(record.type),
      },
      {
        key: "fee",
        title: "Desired Fee",
        dataType: "number",
        dataIndex: "fee",
        render: (record) =>
          numberFormatter(record.fee.type === FeeType.Fixed ? record.fee.amount : record.fee.amount / 100, {
            style: record.fee.type === FeeType.Percentage ? "percent" : "currency",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }),
      },
      ...(user?.isReadOnly
        ? []
        : ([
            {
              key: "apply",
              title: "Apply",
              render: handleApplyLinkRender,
            },
          ] as ColumnDefinition<AdvisorStateClient>[])),
    ],
    [handleApplyLinkRender, user?.isReadOnly],
  );

  useEffect(() => {
    window.dataLayer.push({ event: EVENTS.PAGE_VIEWS.ADVISOR_DASHBOARD });
  }, []);

  const handleAddClientClick = useCallback(() => {
    navigate("/advisor/clients/create");
  }, [navigate]);

  return (
    <>
      {(!user?.hasOnboarded || !user?.isVerified) && (
        <div className={classes.bannerWrapper} style={{ paddingTop: 150 }}>
          <Container className={classes.bannerContentContainer}>
            {!user?.hasOnboarded ? (
              <>
                <Typography variant="h3" paragraph>
                  Please verify your IFA status before you are able to add clients.
                </Typography>
                <RouterButton to="/advisor/onboarding" data-testid="onboard">
                  Go to verification
                </RouterButton>
              </>
            ) : (
              <Typography variant="h3">One of our team will contact you shortly to verify your account.</Typography>
            )}
          </Container>
        </div>
      )}
      <Container style={{ paddingTop: 150 }}>
        <Typography variant="h1" gutterBottom>
          Welcome to your dashboard
        </Typography>
        <Typography variant="body2" paragraph>
          Below, you can find and manage the list of your clients.
        </Typography>
        <Container disableGutters>
          <DataTable tableData={Object.values(clients)} tableStructure={tableStructure} disablePagination />
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!user?.isReadOnly && (
            <Button onClick={handleAddClientClick} disabled={!user?.isVerified} data-testid="add-client">
              Add a client
            </Button>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Dashboard;
