import { FormGroup, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import useFormGenContext from "../context";
import { AnyField, BaseValues, SectionConfig } from "../types";
import AccordionWrap from "./AccordionWrap";
import BuildFields from "./BuildFields";

const SectionField = <T extends BaseValues>({
  config: { titleProps: { strong, ...titleProps } = {}, accordion: accordionProp, ...config },
}: {
  config: SectionConfig<T>;
}) => {
  const { classes } = useFormGenContext<T>();
  const { values } = useFormikContext<T>();

  const { enabled: accordionEnabled, props: accordionProps } = useMemo(() => {
    if (typeof accordionProp === "boolean" || !accordionProp) return { enabled: accordionProp, props: {} };
    const { enabled: accEnabled, ...accProps } = accordionProp;
    return { enabled: accEnabled, props: accProps };
  }, [accordionProp]);

  const sectionFields = useMemo(
    () =>
      !config.name
        ? config.fields
        : config.fields?.map(
            (f) => ({ ...f, name: !f.name ? config.name : `${config.name}.${f.name}` } as AnyField<T>),
          ),
    [config.fields, config.name],
  );

  const title = useMemo(() => {
    const resolvedTitle = typeof config.title === "function" ? config.title(values, config.name) : config.title;
    return (
      resolvedTitle && (
        <Typography component="div" {...titleProps}>
          {!strong ? resolvedTitle : <strong>{resolvedTitle}</strong>}
        </Typography>
      )
    );
  }, [config, strong, titleProps, values]);

  if (accordionEnabled) {
    return (
      <AccordionWrap summary={title} {...accordionProps}>
        <BuildFields config={sectionFields} disableFormGroups={config.disableFormGroups} />
      </AccordionWrap>
    );
  }

  return (
    <>
      {title && (
        <FormGroup {...config.props} className={classes?.formGroup}>
          {title}
        </FormGroup>
      )}
      {sectionFields && (
        <FormGroup {...config.props} className={classes?.formGroup} sx={{ marginTop: 0 }}>
          <BuildFields config={sectionFields} disableFormGroups={config.disableFormGroups} />
        </FormGroup>
      )}
    </>
  );
};

export default SectionField;
