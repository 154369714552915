import { ButtonProps, FormGroup } from "@mui/material";
import React, { ReactNode } from "react";
import FormButton from "./FormButton";

interface FormButtonProps extends ButtonProps {
  label?: ReactNode;
}

export interface FormButtonsProps extends Omit<ButtonProps, "onClick"> {
  primaryProps?: FormButtonProps;
  secondaryProps?: FormButtonProps;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  primaryProps: { label: primaryLabel = "Submit", ...primaryProps } = {},
  secondaryProps: { label: secondaryLabel = "Cancel", ...secondaryProps } = {},
  ...props
}) => {
  return (
    <FormGroup row>
      <FormButton variant="outlined" {...props} {...secondaryProps}>
        {secondaryLabel}
      </FormButton>
      <FormButton type={primaryProps.onClick ? "button" : "submit"} {...props} {...primaryProps}>
        {primaryLabel}
      </FormButton>
    </FormGroup>
  );
};

export default React.memo(FormButtons);
