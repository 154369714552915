import { FormGroup, Typography } from "@mui/material";
import RadioField from "client/components/Fields/RadioField";
import TextField from "client/components/Fields/TextField";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { CertificationForm } from "shared/application.consts";
import Statement from "./components/Statement";
import CheckboxField from "client/components/Fields/CheckboxField";
import CommonStatement from "./components/CommonStatement";
import NumberField from "client/components/Fields/NumberField";

type QuestionIds = typeof CertificationForm.values[
  | "financeProviderForSMEsOrPEWorker"
  | "financeProviderForSMEsOrPEWorkerDetail"
  | "directorOfCompanyWithAnnualTurnoverOver1M"
  | "directorOfCompanyWithAnnualTurnoverOver1MDetail"
  | "multipleInvestmentsUnlistedCompany"
  | "multipleInvestmentsUnlistedCompanyDetail"
  | "businessAngelNetworkMemberOver6Months"
  | "businessAngelNetworkMemberOver6MonthsDetail"
  | "notApplicable"]["id"];

const SOPHISTICATED_TEXT =
  "I declare that I have answered yes to A and/or B and/or C and/or D, and wish to be treated as a self-certified sophisticated investor.";

const Sophisticated: React.FC = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<{
    atLeastOneTrue: boolean;
    values: {
      [key in QuestionIds]: boolean;
    };
  }>();

  useEffect(() => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.financeProviderForSMEsOrPEWorker.name, null);
      setFieldValue(CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.name, null);
      setFieldValue(CertificationForm.values.multipleInvestmentsUnlistedCompany.name, null);
      setFieldValue(CertificationForm.values.businessAngelNetworkMemberOver6Months.name, null);
    }
  }, [setFieldValue, values.values.notApplicable]);

  const handleRadioChange = () => {
    if (values.values.notApplicable) {
      setFieldValue(CertificationForm.values.notApplicable.name, false);
    }
  };

  return (
    <>
      <FormGroup>
        <Statement title="Self-Certified Sophisticated Investor Statement">
          <Typography variant="body2">
            If you meet <b> condition A,B,C or D below, </b> you may choose to be classified as a self-certified
            sophisticated investor for the purposes of the Financial Services and Markets Act 2000 (Financial Promotion)
            Order 2005.
            <br />
            <br />
            Have you:
          </Typography>
          <FormGroup key={CertificationForm.values.financeProviderForSMEsOrPEWorker.id}>
            <Field
              name={CertificationForm.values.financeProviderForSMEsOrPEWorker.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={
                <Typography variant="body2">
                  {CertificationForm.values.financeProviderForSMEsOrPEWorker.label}
                </Typography>
              }
            />
          </FormGroup>
          {values.values.financeProviderForSMEsOrPEWorker && (
            <FormGroup key={CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id}>
              <Field
                name={CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.name}
                component={TextField}
                label={
                  <Typography variant="body2">
                    {CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.label}
                  </Typography>
                }
              />
            </FormGroup>
          )}
          <br />
          <FormGroup key={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id}>
            <Field
              name={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={
                <Typography variant="body2">
                  {CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.label}
                </Typography>
              }
            />
          </FormGroup>
          {values.values.directorOfCompanyWithAnnualTurnoverOver1M && (
            <FormGroup key={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id}>
              <Field
                name={CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.name}
                component={TextField}
                label={
                  <Typography variant="body2">
                    {CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.label}
                  </Typography>
                }
              />
            </FormGroup>
          )}
          <br />
          <FormGroup key={CertificationForm.values.multipleInvestmentsUnlistedCompany.id}>
            <Field
              name={CertificationForm.values.multipleInvestmentsUnlistedCompany.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={
                <Typography variant="body2">
                  {CertificationForm.values.multipleInvestmentsUnlistedCompany.label}
                </Typography>
              }
            />
          </FormGroup>
          {values.values.multipleInvestmentsUnlistedCompany && (
            <FormGroup key={CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id}>
              <Field
                name={CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.name}
                component={NumberField}
                label={
                  <Typography variant="body2">
                    {CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.label}
                  </Typography>
                }
                {...CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.props}
              />
            </FormGroup>
          )}
          <br />
          <FormGroup key={CertificationForm.values.businessAngelNetworkMemberOver6Months.id}>
            <Field
              name={CertificationForm.values.businessAngelNetworkMemberOver6Months.name}
              component={RadioField}
              onClick={handleRadioChange}
              label={
                <Typography variant="body2">
                  {CertificationForm.values.businessAngelNetworkMemberOver6Months.label}
                </Typography>
              }
            />
          </FormGroup>
          {values.values.businessAngelNetworkMemberOver6Months && (
            <FormGroup key={CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id}>
              <Field
                name={CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.name}
                component={TextField}
                label={
                  <Typography variant="body2">
                    {CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.label}
                  </Typography>
                }
              />
            </FormGroup>
          )}
          <br />
          <Typography variant="h3">OR</Typography>
          <FormGroup key={CertificationForm.values.notApplicable.id}>
            <Typography variant="body2">E) None of these apply to me.</Typography>
            <Field
              name={CertificationForm.values.notApplicable.name}
              component={CheckboxField}
              label={<Typography variant="body2">Yes</Typography>}
            />
          </FormGroup>
          <FormGroup>
            {(touched.values?.[CertificationForm.values.financeProviderForSMEsOrPEWorker.id] ||
              touched.values?.[CertificationForm.values.financeProviderForSMEsOrPEWorkerDetail.id]) &&
              (touched.values?.[CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1M.id] ||
                touched.values?.[CertificationForm.values.directorOfCompanyWithAnnualTurnoverOver1MDetail.id]) &&
              (touched.values?.[CertificationForm.values.multipleInvestmentsUnlistedCompany.id] ||
                touched.values?.[CertificationForm.values.multipleInvestmentsUnlistedCompanyDetail.id]) &&
              (touched.values?.[CertificationForm.values.businessAngelNetworkMemberOver6Months.id] ||
                touched.values?.[CertificationForm.values.businessAngelNetworkMemberOver6MonthsDetail.id]) &&
              errors.atLeastOneTrue && (
                <Typography variant="body2" color="error">
                  {errors.atLeastOneTrue}
                </Typography>
              )}
          </FormGroup>
          <br />
          <CommonStatement uniqueText={SOPHISTICATED_TEXT} />
        </Statement>
      </FormGroup>
    </>
  );
};

export default Sophisticated;
