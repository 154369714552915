import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardHeader,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import useHelperText from "client/hooks/useHelperText";
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from "formik";
import { sumBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Instalment } from "server/services/application";
import FormButton from "../FormButton";
import DateField, { DateFieldProps } from "./DateField";
import NumberField, { NumberFieldProps } from "./NumberField";

export interface InstalmentsFieldProps extends FieldProps<Instalment[]> {
  dateProps?: Partial<DateFieldProps>;
  numberProps?: Partial<NumberFieldProps>;
}
export interface InstalmentFieldProps extends Pick<FieldProps<Instalment[]>, "field">, FieldArrayRenderProps {
  index: number;
  dateProps?: Partial<DateFieldProps>;
  numberProps?: Partial<NumberFieldProps>;
}

const InstalmentField: React.FC<InstalmentFieldProps> = ({
  index,
  field,
  form: { setFieldValue },
  push: _push,
  remove,
  dateProps,
  numberProps,
  ...props
}) => {
  const name = useMemo(() => `${props.name}[${index}]`, [index, props.name]);
  const handleAmountBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    () => setFieldValue("totalAmount", sumBy(field.value, "amount")),
    [field.value, setFieldValue],
  );
  return (
    <FormGroup>
      <Card elevation={4}>
        <CardHeader
          title={<FormLabel>Instalment {index + 1}</FormLabel>}
          action={
            field.value.length > 1 && (
              <IconButton size="small" edge="end" onClick={() => remove(index)}>
                <CloseIcon />
              </IconButton>
            )
          }
        />
        <CardContent>
          <FormGroup row>
            <Field
              label="Instalment Date"
              placeholder="Date"
              disablePast
              {...dateProps}
              component={DateField}
              name={`${name}.date`}
            />
            <Field
              label="Instalment Amount"
              placeholder="Amount"
              prefix="£"
              {...numberProps}
              component={NumberField}
              name={`${name}.amount`}
              onBlur={handleAmountBlur}
            />
          </FormGroup>
        </CardContent>
      </Card>
    </FormGroup>
  );
};

const InstalmentsField: React.FC<InstalmentsFieldProps> = ({ field, form, dateProps, numberProps }) => {
  const { helperText, hasError } = useHelperText("totalAmount");
  return (
    <>
      <FieldArray name={field.name}>
        {(arrayHelpers) => (
          <>
            {field.value.map((_instalment, index) => (
              <InstalmentField
                key={`${field.name}[${index}]`}
                index={index}
                field={field}
                {...arrayHelpers}
                dateProps={dateProps}
                numberProps={numberProps}
              />
            ))}
            {field.value.length !== 4 && (
              <FormGroup>
                <FormButton
                  variant="text"
                  disabled={field.value.length === 4}
                  onClick={() => arrayHelpers.push({ amount: "", date: null })}
                >
                  Add another instalment
                </FormButton>
              </FormGroup>
            )}
          </>
        )}
      </FieldArray>
      <FormGroup>
        <Typography>
          Total Investment: <strong>{numberFormatter(form.values.totalAmount)}</strong>
        </Typography>
        {hasError && <FormHelperText error>{helperText}</FormHelperText>}
      </FormGroup>
    </>
  );
};

export default InstalmentsField;
