import { Container } from "@mui/material";
import useSCEffect from "@wearenova/use-sce";
import SentryRoutes from "client/components/SentryRoutes";
import useAdvisorContext, { AdvisorActions } from "client/context/advisor";
import useAuthContext from "client/context/auth";
import useConfigContext from "client/context/config";
import React, { useEffect } from "react";
import { Navigate, Route, useNavigate, useParams } from "react-router-dom";
import Complete from "./Complete";
import AppLayout from "./components/AppLayout";
import Experience from "./Experience";
import Assessment from "./Assessment";
import Fee from "./Fee";
import Finances from "./Finances";
import Funds from "./Funds";
import IdVerification from "./IdVerification";
import InvestmentDetails from "./InvestmentDetails";
import InvestorDetails from "./InvestorDetails";
import KeyInformation from "./KeyInformation";
import Objectives from "./Objectives";
import SignAgreement from "./SignAgreement";
import { Forms } from "./utils";

const Application: React.FC = () => {
  const { clientId, applicationId } = useParams<{ clientId?: string; applicationId?: string }>();
  const params = useParams();
  const { portal } = useConfigContext();
  const { user } = useAuthContext();
  const { dispatch, clients } = useAdvisorContext();
  const navigate = useNavigate();

  useSCEffect(
    () => {
      if (!user?.isAdvisor) return null;
      const selected = (clientId && clients[clientId]) || null;
      dispatch({ type: AdvisorActions.SetSelectedClient, payload: selected });
      return selected;
    },
    [clientId, user, clients],
    "client",
  );

  useEffect(() => {
    if (!user?.isAdvisor || clientId) return;
    navigate("/advisor/dashboard/", { replace: true, state: params["*"] });
  }, [clientId, navigate, user?.isAdvisor]);

  return (
    <>
      <Container style={{ paddingTop: 110 }}>
        <SentryRoutes>
          {!applicationId && <Route path={Forms.Funds} element={<Funds />} />}
          <Route path={applicationId ? "/" : "/:fundId"} element={<AppLayout />}>
            <Route path={Forms.Begin} element={<KeyInformation />} />
            <Route path={Forms.KeyInformation} element={<KeyInformation />} />
            <Route
              path={Forms.IdVerification}
              element={
                portal.aml?.enabled ? <IdVerification /> : <Navigate to={`../${Forms.InvestorDetails}`} replace />
              }
            />
            <Route path={Forms.InvestorDetails} element={<InvestorDetails />} />
            <Route path={Forms.Objectives} element={<Objectives />} />
            <Route path={Forms.Finances} element={<Finances />} />
            <Route path={Forms.Experience} element={<Experience />} />
            <Route path={Forms.Assessment} element={<Assessment />} />
            <Route path={Forms.Investment} element={<InvestmentDetails />} />
            {user?.advisor && <Route path={Forms.Fee} element={<Fee />} />}
            <Route path={Forms.Agreement} element={<SignAgreement />} />
            <Route path={Forms.Complete} element={<Complete />} />

            <Route index element={<Navigate to={`../${Forms.KeyInformation}`} replace />} />
          </Route>
        </SentryRoutes>
      </Container>
    </>
  );
};

export default Application;
