import { usePreloadedState } from "@wearenova/use-sce";
import { keyBy, merge } from "lodash";
import React, { Reducer, useContext, useMemo, useReducer } from "react";
import { AdminApplication } from "server/services/application";
import { AdminCompany } from "server/services/company";
import { LeanFund } from "server/services/fund";
import { LeanPortfolio } from "server/services/portfolio";
import { LeanRole } from "server/services/role";
import { AdminHolding, AdminTransaction } from "server/services/transaction";
import { AdminUser } from "server/services/user";
import { PartialDeep } from "type-fest";
import { Action, ActionMap, ProviderValue } from "./types";

export interface UnformattedState {
  users: AdminUser[];
  roles: LeanRole[];
  companies: AdminCompany[];
  transactions: AdminTransaction[];
  holdings: AdminHolding[];
  applications: AdminApplication[];
  portfolios: LeanPortfolio[];
  funds: LeanFund[];
}

type State = {
  [Key in keyof UnformattedState]: Record<string, UnformattedState[Key][number]>;
};

export enum AdminActions {
  SetAll = "SetAll",

  SetUsers = "SetUsers",
  UpdateUser = "UpdateUser",
  RemoveUser = "RemoveUser",

  SetRoles = "SetRoles",
  UpdateRole = "UpdateRole",
  RemoveRole = "RemoveRole",

  SetCompanies = "SetCompanies",
  UpdateCompany = "UpdateCompany",
  RemoveCompany = "RemoveCompany",

  SetHoldings = "SetHoldings",
  UpdateHoldings = "UpdateHolding",
  RemoveHoldings = "RemoveHolding",

  SetApps = "SetApps",
  UpdateApp = "UpdateApp",
  RemoveApp = "RemoveApp",

  SetPortfolios = "SetPortfolios",
  UpdatePortfolio = "UpdatePortfolio",
  RemovePortfolio = "RemovePortfolio",

  SetFunds = "SetFunds",
  UpdateFund = "UpdateFund",
  RemoveFund = "RemoveFund",

  SetTransactions = "SetTransactions",
  UpdateTransaction = "UpdateTransaction",
  BulkUpdateTransactions = "BulkUpdateTransactions",
  RemoveTransaction = "RemoveTransaction",
}

interface ActionPayloadMap {
  [AdminActions.SetUsers]: AdminUser[];
  [AdminActions.UpdateUser]: PartialDeep<AdminUser> | AdminUser[];
  [AdminActions.RemoveUser]: string;

  [AdminActions.SetRoles]: LeanRole[];
  [AdminActions.UpdateRole]: PartialDeep<LeanRole> | LeanRole[];
  [AdminActions.RemoveRole]: string;

  [AdminActions.SetCompanies]: AdminCompany[];
  [AdminActions.UpdateCompany]: PartialDeep<AdminCompany> | AdminCompany[];
  [AdminActions.RemoveCompany]: string;

  [AdminActions.SetHoldings]: AdminHolding[];
  [AdminActions.UpdateHoldings]: PartialDeep<AdminHolding> | AdminHolding[];
  [AdminActions.RemoveHoldings]: string;

  [AdminActions.SetApps]: AdminApplication[];
  [AdminActions.UpdateApp]: PartialDeep<AdminApplication> | AdminApplication[];
  [AdminActions.RemoveApp]: string;

  [AdminActions.SetPortfolios]: LeanPortfolio[];
  [AdminActions.UpdatePortfolio]: PartialDeep<LeanPortfolio> | LeanPortfolio[];
  [AdminActions.RemovePortfolio]: string;

  [AdminActions.SetFunds]: LeanFund[];
  [AdminActions.UpdateFund]: PartialDeep<LeanFund> | LeanFund[];
  [AdminActions.RemoveFund]: string;

  [AdminActions.SetTransactions]: AdminTransaction[];
  [AdminActions.UpdateTransaction]: PartialDeep<AdminTransaction> | AdminTransaction[];
  [AdminActions.RemoveTransaction]: string;
}

const DEFAULT_VALUE: State = {
  users: {},
  roles: {},
  companies: {},
  transactions: {},
  holdings: {},
  applications: {},
  portfolios: {},
  funds: {},
};

const AdminContext = React.createContext<ProviderValue<State, ActionPayloadMap>>({
  ...DEFAULT_VALUE,
  dispatch: () => null,
});
AdminContext.displayName = "AdminContext";

type AdminActionMapType = ActionMap<State, ActionPayloadMap>;

const getSet =
  <T extends AdminActionMapType[`Set${string}` & keyof AdminActionMapType]>(key: keyof State) =>
  (state: State, action: Parameters<T>[1]) => ({ ...state, [key]: keyBy(action.payload, "_id") });

const getUpdate =
  <T extends AdminActionMapType[`Update${string}` & keyof AdminActionMapType]>(key: keyof State) =>
  (state: State, action: Parameters<T>[1]) => ({
    ...state,
    [key]: {
      ...state[key],
      ...(Array.isArray(action.payload)
        ? keyBy(action.payload, "_id")
        : { [action.payload._id]: merge({}, state[key][action.payload._id] ?? {}, action.payload) }),
    },
  });

const getRemove =
  <T extends AdminActionMapType[`Remove${string}` & keyof AdminActionMapType]>(key: keyof State) =>
  (state: State, action: Parameters<T>[1]) => {
    delete state[key][action.payload];
    return { ...state, [key]: { ...state[key] } };
  };

const ACTION_MAP: AdminActionMapType = {
  [AdminActions.SetUsers]: getSet("users"),
  [AdminActions.UpdateUser]: getUpdate("users"),
  [AdminActions.RemoveUser]: getRemove("users"),

  [AdminActions.SetRoles]: getSet("roles"),
  [AdminActions.UpdateRole]: getUpdate("roles"),
  [AdminActions.RemoveRole]: getRemove("roles"),

  [AdminActions.SetCompanies]: getSet("companies"),
  [AdminActions.UpdateCompany]: getUpdate("companies"),
  [AdminActions.RemoveCompany]: getRemove("companies"),

  [AdminActions.SetHoldings]: getSet("holdings"),
  [AdminActions.UpdateHoldings]: getUpdate("holdings"),
  [AdminActions.RemoveHoldings]: getRemove("holdings"),

  [AdminActions.SetApps]: getSet("applications"),
  [AdminActions.UpdateApp]: getUpdate("applications"),
  [AdminActions.RemoveApp]: getRemove("applications"),

  [AdminActions.SetPortfolios]: getSet("portfolios"),
  [AdminActions.UpdatePortfolio]: getUpdate("portfolios"),
  [AdminActions.RemovePortfolio]: getRemove("portfolios"),

  [AdminActions.SetFunds]: getSet("funds"),
  [AdminActions.UpdateFund]: getUpdate("funds"),
  [AdminActions.RemoveFund]: getRemove("funds"),

  [AdminActions.SetTransactions]: getSet("transactions"),
  [AdminActions.UpdateTransaction]: getUpdate("transactions"),
  [AdminActions.RemoveTransaction]: getRemove("transactions"),
};

const AdminReducer: Reducer<State, Action<ActionPayloadMap, keyof ActionPayloadMap>> = (state, action) => {
  return ACTION_MAP[action.type]?.(state, action as any) ?? state;
};

export const AdminProvider: React.FC = (props) => {
  const preloaded = usePreloadedState<Partial<UnformattedState>>();
  const [state, dispatch] = useReducer(AdminReducer, { ...DEFAULT_VALUE, roles: keyBy(preloaded.roles, "_id") });

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <AdminContext.Provider value={value}>{props.children}</AdminContext.Provider>;
};

const useAdminContext = () => useContext(AdminContext);

export default useAdminContext;
