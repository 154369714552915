import { Divider as MUIDivider, DividerProps as MUIDividerProps } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";

export interface DividerProps extends MUIDividerProps {
  color?: "primary" | "secondary" | "default";
  size?: "small" | "medium" | "large";
  gutterBottom?: boolean;
  bottomMargin?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles({ name: "Divider" })((theme) => ({
  gutterBottom: {
    marginBottom: "0.35em",
  },
  bottomMargin: {
    marginBottom: theme.spacing(1.5),
  },
  small: {
    borderBottomWidth: theme.spacing(0.125),
  },
  medium: {
    borderBottomWidth: theme.spacing(0.375),
  },
  large: {
    borderBottomWidth: theme.spacing(0.75),
  },
  fullWidth: {
    width: "100%",
  },
  default: {},
  primary: {
    borderColor: theme.palette.primary.main,
  },
  secondary: {
    borderColor: theme.palette.secondary.main,
  },
}));

const Divider: React.FC<DividerProps> = ({
  size = "small",
  color = "default",
  gutterBottom = false,
  bottomMargin = false,
  fullWidth = false,
  className,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <MUIDivider
      className={clsx(classes[size], classes[color], className, {
        [classes.gutterBottom]: gutterBottom,
        [classes.bottomMargin]: bottomMargin,
        [classes.fullWidth]: fullWidth,
      })}
      {...props}
    />
  );
};

export default Divider;
