import { Axios, AxiosError } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { AuthContextUser, LeanUser, UserPersonalDetails } from "server/services/user";

export const submitForgotPassword = async (email: string) => {
  try {
    await ipAxios.post("/public/auth/forgot-password", { email });
    return true;
  } catch (error) {
    Logging.error(error);
    return false;
  }
};

export const setPassword = async (password: string) => {
  try {
    const res = await ipAxios.post<AuthContextUser>("/public/auth/set-password", {
      password,
    });
    return { user: res.data, redirectTo: res.headers["x-redirect-to"] as string | undefined };
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const submitLogin = async (email: string, password: string) => {
  try {
    const res = await ipAxios.post("/public/auth/login", { email, password });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const submitTwoFA = async (oneTimePasscode: string) => {
  try {
    const res = await ipAxios.post<AuthContextUser>("/public/auth/authenticate", { oneTimePasscode });
    return { redirectTo: res.headers["x-redirect-to"] as string | undefined, data: res.data };
  } catch (error) {
    return null;
  }
};

export const submitBackupCodes = async (backupCode: string) => {
  try {
    const res = await ipAxios.post<AuthContextUser>("/public/auth/backup", { backupCode });
    return { redirectTo: res.headers["x-redirect-to"] as string | undefined, data: res.data };
  } catch (error) {
    return null;
  }
};

export const getBackupCodes = async () => {
  try {
    const res = await ipAxios.get<AuthContextUser>("/public/auth/backup");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const submitRegistration = async (
  values: Pick<LeanUser, "forenames" | "surname" | "email" | "password" | "type"> & {
    personalDetails: Pick<UserPersonalDetails, "contactNumber">;
  },
) => {
  try {
    const res = await ipAxios.post("/public/auth/register", values);
    return res.data;
  } catch (error) {
    const axiosError = error as AxiosError;
    Logging.error(
      error,
      axiosError.response?.status !== 409 ? "" : "Oops. This email address is already registered. Please login.",
      { duration: 10 },
    );
    return null;
  }
};

export const getAuthContext = async (axios: Axios) => {
  try {
    const res = await (axios || ipAxios).get<AuthContextUser | null>("/public/auth/context");
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const logout = async () => {
  try {
    await ipAxios.get("/public/auth/logout");
    return true;
  } catch (error) {
    Logging.error(error);
    return false;
  }
};
