import { supportedValuesOf } from "@formatjs/intl-enumerator";
import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { numberFormatter } from "@wearenova/mui-data-table";
import { createTransaction } from "client/api/admin/transactions";
import { deleteTransaction, updateTransaction } from "client/api/admin/transactions/transaction";
import { SelectOption } from "client/components/Fields/SelectField";
import useAdminContext, { AdminActions } from "client/context/admin";
import FormGen, { ErrorsList, FormGenConfig, FormGenSubmitHandler } from "form-gen";
import _ from "lodash";
import { ObjectIdLike } from "mongoose";
import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { AdminTransaction, LeanTransaction } from "server/services/transaction";
import {
  BuySell,
  TransactionDealStage,
  TransactionStatus,
  TransactionStatuses,
  TransactionType,
} from "server/services/transaction/consts";
import Yup from "shared/utils/Yup";
import CRUDButtons from "../components/CRUDButtons";
import useAppOptions, { AppOption } from "../hooks/useAppOptions";
import useCompanyOptions, { CompanyOption } from "../hooks/useCompanyOptions";
import useFundOptions, { FundOption } from "../hooks/useFundOptions";
import usePortfolioOptions from "../hooks/usePortfolioOptions";

export interface TransactionFormType extends LeanTransaction {
  fund: ObjectIdLike;
  company: ObjectIdLike;
  portfolio: ObjectIdLike;
  shareClass: ObjectIdLike;
  disableEmails?: boolean;
}

interface TransactionFormProps extends DialogProps {
  transaction?: AdminTransaction | null;
  onClose(): void;
}

interface ShareClassOption extends Exclude<SelectOption, string> {
  sharePrice?: number;
  nominalValue?: number;
}

export const getTransactionConfig = ({
  apps,
  companies: companiesParam,
  funds,
  portfolios,
}: {
  portfolios?: SelectOption[];
  funds?: FundOption[];
  apps?: AppOption[];
  companies?: CompanyOption[];
} = {}): FormGenConfig<TransactionFormType> => {
  const companies = (companiesParam ?? []).filter((c) => c.shareClasses.length);
  const compMap = (companies ?? []).reduce(
    (prev, curr) => ({
      ...prev,
      [curr.value]: curr,
    }),
    {} as Record<string, CompanyOption>,
  );

  return [
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Details",
      fields: [
        {
          type: "select",
          name: "fund",
          label: "Fund",
          options: funds ?? [],
          onChange(_e, value) {
            const selected = value as FundOption | null;
            this.setValues({
              ...this.values,
              fund: selected?.value ?? null,
              portfolio: (selected?.defaultPortfolio as ObjectIdLike | undefined) ?? null,
              nominee: selected?.nominee ?? "",
              investmentManager: selected?.manager ?? "",
              investor: selected?.label ?? "",
            });
            if (!selected) return;
            const comp = compMap?.[this.values.company as string];
            if (comp?.funds.includes(selected.value)) {
              this.setFieldValue("company", null, false);
              this.setFieldValue("deal", null);
            }
          },
          validation: Yup.objectId().nullable().required(),
        },
        {
          type: "select",
          name: "type",
          label: "Type",
          placeholder: "Type",
          options: TransactionType,
          initialValue: TransactionType.Primary,
          validation: Yup.string().nullable().oneOf(Object.values(TransactionType)).required(),
        },
        {
          type: "select",
          name: "dealStage",
          label: "Deal Stage",
          placeholder: "Deal Stage",
          options: TransactionDealStage,
          validation: Yup.string().nullable().oneOf(Object.values(TransactionDealStage)).required(),
        },
        {
          type: "select",
          name: "status",
          label: "Status",
          placeholder: "Status",
          options: TransactionStatus,
          initialValue: TransactionStatus.Created,
          validation: Yup.string().nullable().oneOf(TransactionStatuses).required(),
        },
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "date",
              name: "settledAt",
              label: "Settle Date",
              placeholder: "Settle Date",
              validation: Yup.date()
                .nullable()
                .when("status", {
                  is: TransactionStatus.Finalised,
                  then: (schema) => schema.required(),
                }),
            },
            {
              type: "date",
              name: "tradedAt",
              label: "Trade Date",
              placeholder: "Trade Date",
              validation: Yup.date()
                .nullable()
                .when("status", {
                  is: TransactionStatus.Finalised,
                  then: (schema) => schema.required(),
                }),
            },
          ],
        },
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "select",
              name: "company",
              label: "Company",
              onChange(_e, value) {
                const selected = value as CompanyOption | null;
                if (this.values.company === selected?.value) return;
                this.setFieldValue("company", selected?.value ?? null, false);
                this.setFieldValue("deal", null);
              },
              options: ({ fund }) => {
                if (!companies) return [];
                if (!fund) return companies;
                return companies.filter((c) => c.funds.includes(fund));
              },
              validation: Yup.objectId().nullable().required(),
            },
            {
              type: "text",
              name: "meta.investmentRound",
              label: "Investment Round",
              placeholder: "Investment Round",
              validation: Yup.string().nullable(),
            },
          ],
        },
        {
          type: "select",
          name: "deal",
          label: "Deal",
          validation: Yup.objectId().nullable(),
          condition: (values) => compMap?.[values.company as string]?.deals?.length,
          options: (values) => {
            const comp = compMap?.[values.company as string];
            if (!comp?.deals) return [];
            return comp.deals.map((deal) => ({
              value: deal._id,
              label: `Investment Round ${deal.investmentRound} - ${deal.stage}`,
            }));
          },
        },
        {
          type: "select",
          name: "portfolio",
          label: "Portfolio",
          options: portfolios ?? [],
          validation: Yup.objectId().nullable().required(),
        },
        {
          type: "section",
          title: "Metadata",
          fields: [
            {
              type: "text",
              name: "nominee",
              label: "Nominee",
              placeholder: "Nominee",
              validation: Yup.string().required(),
            },
            {
              type: "text",
              name: "investor",
              label: "Investor",
              placeholder: "Investor",
              validation: Yup.string().required(),
            },
            {
              type: "select",
              name: "investmentManager",
              label: "Investment Manager",
              props: { freeSolo: true },
              options: _.uniq((funds ?? []).map((f) => f.manager).filter<string>((m): m is string => Boolean(m))),
              validation: Yup.string().required(),
            },
            {
              type: "text",
              name: "meta.notes",
              label: "Notes",
              placeholder: "Notes",
              props: { multiline: true, rows: 4 },
              validation: Yup.string().nullable(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      accordion: { enabled: true, defaultExpanded: true },
      title: "Financial Details",
      fields: [
        {
          type: "radio",
          name: "buySell",
          label: "Buy/Sell",
          options: BuySell,
          initialValue: BuySell.Buy,
          validation: Yup.string().nullable().oneOf(Object.values(BuySell)).required(),
        },
        {
          type: "select",
          name: "meta.currency",
          label: "Currency",
          placeholder: "Currency",
          options: supportedValuesOf("currency"),
          initialValue: "GBP",
          get validation() {
            return Yup.string()
              .nullable()
              .oneOf(this.options as string[], "Invalid currency")
              .required();
          },
        },
        {
          type: "select",
          name: "shareClass",
          label: "Share Class",
          placeholder: "Share Class",
          condition: (values) => values.company,
          onChange(_e, value) {
            const selected = value as ShareClassOption | null;
            this.setValues({
              ...this.values,
              shareClass: selected?.value ?? null,
              sharePrice: selected?.sharePrice ?? null,
              nominalValue: selected?.nominalValue ?? null,
            });
          },
          options: (values) => {
            if (!values.company) return [];
            const comp = compMap[values.company as string];
            if (!comp) return [];
            return comp.shareClasses.map<ShareClassOption>((sc) => ({
              label: sc.name,
              value: sc._id,
              sharePrice: sc.fundValues?.[values.fund as string]?.sharePrice ?? sc.sharePrice,
              nominalValue: sc.fundValues?.[values.fund as string]?.nominalValue ?? sc.nominalValue,
            }));
          },
          validation: Yup.objectId().nullable().required(),
        },
        {
          type: "section",
          props: { row: true },
          condition: (values) => values.company,
          fields: [
            {
              type: "number",
              name: "sharePrice",
              label: "Share Price",
              placeholder: "Share Price",
              props: { formatProps: { min: 0, decimalScale: 10, fixedDecimalScale: false } },
              validation: Yup.number().nullable().min(0, "${path} must be at least 0").required(),
            },
            {
              type: "number",
              name: "nominalValue",
              label: "Nominal Value",
              placeholder: "Nominal Value",
              props: { formatProps: { min: 0, decimalScale: 7, fixedDecimalScale: false } },
              validation: Yup.number().nullable().min(0, "${path} must be at least 0").required(),
            },
          ],
        },
        {
          type: "number",
          name: "noShares",
          label: "No. Shares",
          placeholder: "No. Shares",
          condition: (values) => !values.subscriptions?.length,
          props: {
            disableMonetaryFormat: true,
            formatProps: { min: 1, decimalScale: 0 },
            helperText: "Please note that if you are going to add subscriptions, this field is calculated from them.",
          },
          validation: Yup.number()
            .nullable()
            .integer()
            .min(1)
            .when("subscriptions", {
              is: (subscriptions: TransactionFormType["subscriptions"]) => !subscriptions?.length,
              then: (schema) => schema.required(),
            }),
        },
      ],
    },
    {
      type: "section",
      accordion: true,
      title: "SEIS/EIS Details",
      name: "seisDetails",
      condition: (values) =>
        values.dealStage && [TransactionDealStage.SEIS, TransactionDealStage.EIS].includes(values.dealStage),
      fields: [
        {
          type: "text",
          name: "uir",
          label: "Unique Investment Reference",
          placeholder: "UIR",
          validation: Yup.string().nullable(),
        },
        {
          type: "date",
          name: "seis1.sentAt",
          label: "SEIS1 Sent At",
          placeholder: "Sent At",
          validation: Yup.date().nullable(),
        },
        {
          type: "section",
          props: { row: true },
          fields: [
            {
              type: "date",
              name: "seis2.expectedAt",
              label: "SEIS2 Expected At",
              placeholder: "Expected At",
              validation: Yup.date().nullable(),
            },
            {
              type: "date",
              name: "seis2.receivedAt",
              label: "SEIS2 Received At",
              placeholder: "Received At",
              validation: Yup.date().nullable(),
            },
            {
              type: "file",
              name: "seis2.key",
              label: "SEIS2 File/Email",
              placeholder: "File/Email",
              validation: Yup.string().nullable(),
            },
          ],
        },
      ],
    },
    {
      type: "section",
      title: "Subscriptions",
      accordion: true,
      fields: [
        {
          type: "section",
          title: (values) => `Total Shares: ${_.sumBy(values.subscriptions, "noShares")}`,
        },
        {
          type: "array",
          name: "subscriptions",
          label: "Subscriptions",
          initialValue: [],
          validation: Yup.array(),
          fields: [
            {
              type: "select",
              name: "subscriber",
              label: "Subscriber",
              placeholder: "Subscriber",
              options: (values) =>
                !values.fund
                  ? []
                  : _.uniqBy(
                      (apps ?? [])
                        .filter((a) => a.fund._id === values.fund)
                        .map((a) => ({ label: a.investor.fullName, value: a.investor._id })),
                      "value",
                    ),
              onChange(e, value) {
                const selected = value as Exclude<SelectOption, string> | null;
                this.setFieldValue("noShares", null, false);
                this.setFieldValue(e.target.name, selected?.value ?? null, false);
                this.setFieldValue(e.target.name.replace("subscriber", "application"), null);
              },
              validation: Yup.objectId().nullable().required(),
            },
            {
              type: "select",
              name: "application",
              label: "Application",
              placeholder: "Application",
              condition: (values, name) => name && _.get(values, name.replace("application", "subscriber")),
              options: (values, name) =>
                !values.fund
                  ? []
                  : (apps ?? []).filter(
                      (a) => a.investor._id === _.get(values, name.replace("application", "subscriber")),
                    ),
              validation: Yup.objectId().nullable().required(),
            },
            {
              type: "number",
              name: "noShares",
              label: "No. Shares",
              placeholder: "No. Shares",
              props: { disableMonetaryFormat: true, formatProps: { min: 1, decimalScale: 0 } },
              validation: Yup.number().nullable().positive().required(),
            },
            {
              type: "section",
              title: (values, name) => {
                if (!name || !values.sharePrice) return null;
                const noShares = _.get(values, name, {}).noShares;
                if (!noShares) return null;
                return `Amount: ${numberFormatter(noShares * values.sharePrice, {
                  currency: values.meta?.currency || true,
                })}`;
              },
            },
            {
              type: "section",
              condition: (values) =>
                values.dealStage && [TransactionDealStage.SEIS, TransactionDealStage.EIS].includes(values.dealStage),
              fields: [
                {
                  type: "date",
                  name: "seis3.generatedAt",
                  label: "SEIS3 Generated At",
                  placeholder: "Generated At",
                  validation: Yup.date().nullable(),
                },
                {
                  type: "date",
                  name: "seis3.signedAt",
                  label: "SEIS3 Signed At",
                  placeholder: "Signed At",
                  validation: Yup.date().nullable(),
                },
                {
                  type: "file",
                  name: "seis3.key",
                  label: "SEIS3 File",
                  placeholder: "File",
                  props: {
                    owner: (values, name) => _.get(values, name.replace("seis3.key", "subscriber")) ?? null,
                    options: { accept: "application/pdf" },
                  },
                  validation: Yup.string().nullable(),
                },
              ],
            },
          ],
        },
        {
          type: "section",
          title: (values) => `Total Shares: ${_.sumBy(values.subscriptions, "noShares")}`,
        },
      ],
    },
    {
      type: "radio",
      name: "disableEmails",
      label: "Would you like to disable all emails to subscribers?",
      initialValue: true,
      validation: Yup.boolean().required(),
    },
  ];
};

const Form: React.FC<TransactionFormProps> = ({ transaction, onClose, ...props }) => {
  const { transactionId } = useParams<{ transactionId: string }>();
  const { dispatch, transactions } = useAdminContext();
  const portfolioOptions = usePortfolioOptions();
  const fundOptions = useFundOptions();
  const appOptions = useAppOptions();
  const companyOptions = useCompanyOptions();

  const adminTransaction = useMemo(
    () => transaction || transactions[transactionId!],
    [transactionId, transaction, transactions],
  );

  const config = useMemo(
    () =>
      getTransactionConfig({
        portfolios: portfolioOptions,
        funds: fundOptions,
        apps: appOptions,
        companies: companyOptions,
      }),
    [appOptions, companyOptions, fundOptions, portfolioOptions],
  );

  const handleSubmit = useCallback<FormGenSubmitHandler<TransactionFormType>>(
    async (values) => {
      if (!values.deal) values.deal = null;
      if (values.subscriptions?.length) values.noShares = null;
      const res = !adminTransaction
        ? await createTransaction(values)
        : await updateTransaction(adminTransaction._id, values);
      if (!res) return;
      dispatch({ type: AdminActions.UpdateTransaction, payload: res });
      onClose();
    },
    [adminTransaction, dispatch, onClose],
  );

  const handleDelete = useCallback(async () => {
    if (!adminTransaction) return;
    const transactionId = await deleteTransaction(adminTransaction._id);
    if (!transactionId) return;
    dispatch({ type: AdminActions.RemoveTransaction, payload: transactionId });
    onClose();
  }, [adminTransaction, dispatch, onClose]);

  const initialValues = useMemo<TransactionFormType | undefined>(() => {
    if (!adminTransaction) return;
    return {
      ...adminTransaction,
      fund: adminTransaction.fund._id,
      company: adminTransaction.company._id,
      portfolio: adminTransaction.portfolio._id,
      shareClass: adminTransaction.shareClass._id,
    };
  }, [adminTransaction]);

  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <DialogTitle>{!adminTransaction ? "Create" : "Edit"} Share Transaction</DialogTitle>
      <DialogContent>
        <FormGen
          config={config}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          enableReinitialize
          castRequiredSchema
        >
          <ErrorsList />
          <CRUDButtons onCancel={onClose} onDelete={handleDelete} />
        </FormGen>
      </DialogContent>
    </Dialog>
  );
};
export default Form;
