import { useEffect } from "react";

let currentTitle = "";

const useTitle = (title: string) => {
  currentTitle = title;
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};

export const getTitle = () => currentTitle;

export default useTitle;
