import { Box, Container, Typography } from "@mui/material";
import Markdown from "client/components/Markdown";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import AccordionItem from "./AccordionItem";

const useStyles = makeStyles({ name: "FAQAccordion" })((theme) => ({
  accordionContainer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  header: {
    paddingBottom: "20px",
  },
}));

interface Props {
  items: { title: string; content: string }[];
}

const FAQs: FC<Props> = ({ items }) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={{ borderBottom: "2px solid", borderBottomColor: "secondary.contrastText" }}
      className={classes.accordionContainer}
    >
      <Container>
        <div className={classes.header}>
          <Typography variant="h2" color="inherit">
            FAQs
          </Typography>
        </div>

        {/* render the accordion items */}
        {items?.length &&
          items.map((item) => {
            return (
              <AccordionItem key={item.title} title={item.title}>
                <Markdown>{item.content}</Markdown>
              </AccordionItem>
            );
          })}
      </Container>
    </Box>
  );
};

export default FAQs;
