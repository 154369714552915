import useSCEffect, { usePreloadedState } from "@wearenova/use-sce";
import { AxiosInstance } from "axios";
import { getClients } from "client/api/advisor/clients";
import _ from "lodash";
import React, { Reducer, useContext, useEffect, useMemo, useReducer } from "react";
import { AdvisorState, AdvisorStateClient } from "server/services/user";
import useAuthContext from "./auth";
import { Action, ActionMap, ProviderValue } from "./types";
import { DEFAULT_USER_DATA } from "./user";

interface State extends AdvisorState {}

export const DEFAULT_ADVISOR_DATA: Omit<ProviderValue<State, ActionPayloadMap>, "dispatch"> = {
  clients: {},
  client: null,
};

export enum AdvisorActions {
  SetData = "SET_DATA",
  ClearData = "CLEAR_DATA",

  SetSelectedClient = "SET_SELECTED_CLIENT",

  SetClientDetails = "SET_CLIENT_DETAILS",

  SetClientBalances = "SET_CLIENT_BALANCES",
  SetClientHoldings = "SET_CLIENT_HOLDINGS",
  SetClientHistoricValues = "SET_CLIENT_HISTORIC_VALUES",

  SetClientStatement = "SET_CLIENT_STATEMENT",

  SetClientApplications = "SET_CLIENT_APPLICATIONS",

  SetClients = "SET_CLIENTS",
  SetClient = "SET_CLIENT",
}

interface ActionPayloadMap {
  [AdvisorActions.SetData]: Omit<AdvisorState, "clients">;
  [AdvisorActions.ClearData]: undefined;
  [AdvisorActions.SetSelectedClient]: AdvisorStateClient | null;
  [AdvisorActions.SetClientDetails]: AdvisorStateClient["details"];
  [AdvisorActions.SetClientBalances]: AdvisorStateClient["balances"];
  [AdvisorActions.SetClientHoldings]: AdvisorStateClient["holdings"];
  [AdvisorActions.SetClientHistoricValues]: AdvisorStateClient["historicValues"];
  [AdvisorActions.SetClientStatement]: AdvisorStateClient["statement"];
  [AdvisorActions.SetClientApplications]: AdvisorStateClient["applications"];
  [AdvisorActions.SetClients]: AdvisorStateClient[];
  [AdvisorActions.SetClient]: AdvisorStateClient;
}

interface AdvisorAction<K extends keyof ActionPayloadMap> extends Action<ActionPayloadMap, K> {
  clientId?: string;
}

const AdvisorContext = React.createContext<ProviderValue<State, ActionPayloadMap, { clientId?: string }>>({
  ...DEFAULT_ADVISOR_DATA,
  dispatch: () => null,
});
AdvisorContext.displayName = "AdvisorContext";

const { advisor, ...DEFAULT_CLIENT_DATA } = {
  ...DEFAULT_USER_DATA,
  userId: "",
  details: {},
  email: "",
  status: "",
  type: "",
  fee: "",
};

const updateState = <T extends keyof AdvisorStateClient>(
  state: AdvisorState,
  key: T,
  payload: AdvisorStateClient[T],
  clientId: string,
) => {
  return {
    ...state,
    ...(!clientId
      ? { [key]: payload }
      : {
          clients: {
            ...state.clients,
            [clientId]: { ...DEFAULT_CLIENT_DATA, ...state.clients[clientId], [key]: payload },
          },
        }),
  };
};

const ACTION_MAP: ActionMap<State, ActionPayloadMap, { clientId?: string }> = {
  [AdvisorActions.SetData]: (state, action) => ({ ...state, ...action.payload }),
  [AdvisorActions.ClearData]: () => ({ ...DEFAULT_ADVISOR_DATA }),

  [AdvisorActions.SetSelectedClient]: (state, action) => ({ ...state, client: action.payload || null }),

  [AdvisorActions.SetClientDetails]: (state, action) => ({ ...state, details: action.payload }),

  [AdvisorActions.SetClientBalances]: (state, action) =>
    updateState(state, "balances", action.payload, action.clientId!),
  [AdvisorActions.SetClientHoldings]: (state, action) =>
    updateState(state, "holdings", action.payload, action.clientId!),
  [AdvisorActions.SetClientHistoricValues]: (state, action) =>
    updateState(state, "historicValues", action.payload, action.clientId!),

  [AdvisorActions.SetClientStatement]: (state, action) =>
    updateState(state, "statement", action.payload, action.clientId!),

  [AdvisorActions.SetClientApplications]: (state, action) =>
    updateState(state, "applications", action.payload, action.clientId!),

  [AdvisorActions.SetClients]: (state, action) => ({
    ...state,
    clients: action.payload.reduce(
      (clients, client) => ({
        ...clients,
        [client.userId]: {
          ...DEFAULT_CLIENT_DATA,
          ...state.clients[client.userId],
          ...client,
        },
      }),
      { ...state.clients },
    ),
  }),
  [AdvisorActions.SetClient]: (state, action) => {
    const clientId = action.clientId || action.payload.userId;
    return {
      ...state,
      clients: {
        ...state.clients,
        [clientId]: {
          ...DEFAULT_CLIENT_DATA,
          ...state.clients[clientId],
          ...action.payload,
        },
      },
    };
  },
};

const reducer: Reducer<State, AdvisorAction<keyof ActionPayloadMap>> = (state, action) => {
  if (!ACTION_MAP[action.type]) return state;
  return ACTION_MAP[action.type](state, action as any);
};

export const AdvisorProvider: React.FC = (props) => {
  const { user } = useAuthContext();
  const { clients, client } =
    usePreloadedState<{ clients?: Record<string, AdvisorStateClient>; client?: AdvisorStateClient | null }>();
  const [state, dispatch] = useReducer(reducer, {
    ...DEFAULT_ADVISOR_DATA,
    clients: (clients && _.keyBy(clients, "userId")) || DEFAULT_ADVISOR_DATA.clients,
    client: client || DEFAULT_ADVISOR_DATA.client,
  });

  useEffect(() => {
    if (!user) dispatch({ type: AdvisorActions.ClearData, payload: undefined });
  }, [user]);

  useSCEffect(
    async (axios?: AxiosInstance) => {
      if (!user?.isAdvisor) return;
      const res = await getClients.call(axios);
      dispatch({ type: AdvisorActions.SetClients, payload: res });
      return res;
    },
    [user],
    "clients",
  );

  const value = useMemo(() => ({ ...state, dispatch }), [state]);

  return <AdvisorContext.Provider value={value}>{props.children}</AdvisorContext.Provider>;
};

const useAdvisorContext = () => {
  return useContext(AdvisorContext);
};

export default useAdvisorContext;
