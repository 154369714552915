import { Axios } from "axios";
import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { LeanApplication } from "server/services/application";
import { LeanAssessment } from "server/services/assessment";

export async function getApplicationById(this: Axios | undefined, applicationId: string) {
  try {
    const res = await (this || ipAxios).get<LeanApplication>(`/applications/${applicationId}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export const patchApplicationData = async (
  applicationId: string,
  values: Partial<NonNullable<LeanApplication["data"]>>,
) => {
  try {
    const res = await ipAxios.patch<LeanApplication>(`/applications/${applicationId}`, values);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const acceptAdvisorFeeData = async (appId: string) => {
  try {
    const res = await ipAxios.put<LeanApplication>(`/applications/${appId}/fee`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getSignatureURL = async (appId: string, signatureId?: string) => {
  try {
    const res = await ipAxios.get<{ url: string }>(`/applications/${appId}/signature`, { params: { signatureId } });
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getIdVerificationURL = async (applicationId: string) => {
  try {
    const res = await ipAxios.get<{ url: string }>(`/applications/${applicationId}/aml/url`);
    return res.data;
  } catch (error) {
    Logging.error(error, "Failed initialising identity check. Please try again later.");
    return null;
  }
};

export const setAMLFlowCompleted = async (applicationId: string) => {
  try {
    const res = await ipAxios.put<undefined>(`applications/${applicationId}/aml/complete`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const getExtractedAMLDetails = async (applicationId: string) => {
  try {
    const res = await ipAxios.get<undefined | LeanApplication>(`/applications/${applicationId}/aml/extracted-details`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export async function getAssessmentById(assessmentId: string) {
  try {
    const res = await ipAxios.get<LeanAssessment>(`/assessments/${assessmentId}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export async function setUserAssessmentAnswer(assessmentId: string, question: number, selectedOption: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(
      `/assessments/${assessmentId}/attempts/current/questions/${question}/answer`,
      { selectedOption },
    );
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export async function submitUserAssessment(assessmentId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/assessments/${assessmentId}/submit`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}

export async function completeUserAssessment(assessmentId: string) {
  try {
    const res = await ipAxios.put<LeanAssessment>(`/assessments/${assessmentId}/continue`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
}
