import { Container, FormGroup, Link, Typography } from "@mui/material";
import Divider from "client/components/Divider";
import FormButtons from "client/components/FormButtons";
import useFundsContext from "client/context/funds";
import useToggle from "client/hooks/useToggle";
import useAppFormRedirect from "client/views/Application/hooks/useAppRedirect";
import FormGen, { ErrorsList, FormGenConfig, SectionConfig } from "form-gen";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Objectives as ObjectivesType } from "server/services/application";
import { ThreeToFiveYears } from "server/services/application/consts";
import { LeanFund } from "server/services/fund";
import { FundType } from "server/services/fund/consts";
import Yup from "shared/utils/Yup";
import WhyQuestions from "./components/WhyQuestions";
import useApplication from "./hooks/useApplication";
import useAppStyles from "./hooks/useAppStyles";
import { Forms } from "./utils";

const ThreeToFiveYearsOptions = Object.values(ThreeToFiveYears);

const getThreeToFiveYearsWarning = (
  path: "whenNeedIncome" | "whenNeedAccess",
  isAdvisor?: boolean,
): SectionConfig<ObjectivesType> => ({
  type: "section",
  title: isAdvisor
    ? "Please be aware that if your client requires access to their capital within five years from the date of subscription, investments in early-stage unquoted equities are unlikely to be appropriate for them."
    : "Please be aware that if you require access to your capital within five years from the date of subscription, investments in early-stage unquoted equities are unlikely to be appropriate for you. Please contact your IFA.",
  condition: (values) => {
    const value = values[path];
    return value && [ThreeToFiveYears.WithinThree, ThreeToFiveYears.ThreeToFive].includes(value);
  },
});

export const getLabel = (isAdvisor: boolean | undefined, advisorLabel: string, investorLabel: string) =>
  isAdvisor ? advisorLabel : investorLabel;

export const getObjectivesConfig = ({
  fund,
  isAdvisor,
}: {
  fund: LeanFund;
  isAdvisor?: boolean;
}): FormGenConfig<ObjectivesType> => {
  const isSEIS = fund.type.includes(FundType.SEIS);
  const isEIS = fund.type.includes(FundType.EIS);
  return [
    {
      type: "checkbox",
      name: "understandsRisk",
      label: getLabel(
        isAdvisor,
        "Please tick this to confirm that your client understands the high risk investment objectives of the service (including the risk factors detailed in the Information Memorandum and Key Information Document) and that these are consistent with your client's personal financial objectives.",
        "Please tick this to confirm that you understand the high risk investment objectives of the service (including the risk factors detailed in the Information Memorandum and Key Information Document) and that these are consistent with your personal financial objectives.",
      ),
      validation: Yup.boolean()
        .oneOf(
          [true],
          "Please check to agree that you understand the risk. If you are not comfortable with this risk, you should not proceed with this investment.",
        )
        .required(
          "Please check to agree that you understand the risk. If you are not comfortable with this risk, you should not proceed with this investment.",
        ),
    },
    {
      type: "radio",
      name: "notNeedCapitalAccess",
      label: getLabel(
        isAdvisor,
        `By answering yes below, you confirm that your client will not need income from or access to the capital invested during the anticipated holding period of ${fund.applications.holdingPeriod} years from the date that the investment is made.`,
        `By answering yes below, you confirm that you will not need income from or access to the capital invested during the anticipated holding period of ${fund.applications.holdingPeriod} years from the date that the investment is made.`,
      ),
      validation: Yup.boolean().nullable().required("Please select either Yes or No"),
    },
    {
      type: "radio",
      name: "relyingOnInvestment",
      label: getLabel(
        isAdvisor,
        "Is your client relying on this investment to secure a certain income?",
        "Are you relying on this investment to secure a certain income?",
      ),
      validation: Yup.boolean().nullable().required("Please select either Yes or No"),
    },
    {
      type: "radio",
      name: "willingToLoseInvestment",
      label: getLabel(
        isAdvisor,
        "Investing may expose your client to significant capital losses, which may have a materially detrimental effect on your client's standard of living. Is your client able to lose all of your investment and absorb the impact this would have on their lifestyle?",
        "Investing may expose you to significant capital losses, which may have a materially detrimental effect on your standard of living. Are you able to lose all of your investment and absorb the impact this would have on your lifestyle?",
      ),
      options: [
        {
          label: getLabel(
            isAdvisor,
            "Yes, I confirm that my client is able to lose 100% of their investment in this fund.",
            "Yes, I confirm that I am able to lose 100% of my investment in this fund.",
          ),
          value: true,
        },
        { label: "No", value: false },
      ],
      validation: Yup.boolean().nullable().required("Please select either Yes or No"),
    },
    {
      type: "section",
      key: "capitalCommitments",
      fields: [
        {
          type: "radio",
          name: "capitalCommitments.present",
          label: getLabel(
            isAdvisor,
            `Does your client have any significant capital commitments which cannot be funded from your annual disposable income or liquid savings within the next ${fund.applications.holdingPeriod} years? If you answered 'yes' to this question, please confirm how this commitment will be funded below.`,
            `Do you have any significant capital commitments which cannot be funded from your annual disposable income or liquid savings within the next ${fund.applications.holdingPeriod} years? If you answered 'yes' to this question, please confirm how this commitment will be funded below.`,
          ),
          validation: Yup.boolean().nullable().required("Please select either Yes or No"),
        },
        {
          type: "text",
          name: "capitalCommitments.details",
          label: getLabel(
            isAdvisor,
            "How will your client fund this commitment?",
            "How will you fund this commitment?",
          ),
          placeholder: isAdvisor
            ? "Information about how your client will fund this commitment"
            : "Information about how you will fund this commitment",
          condition: (values) => Boolean(values.capitalCommitments?.present),
          validation: Yup.string().when("present", {
            is: true,
            then: Yup.string().required("Please provide details"),
          }),
        },
      ],
    },
    {
      type: "radio",
      name: "whenNeedIncome",
      label: getLabel(
        isAdvisor,
        "To meet your client's investment objectives, when will they need to receive returns from their investment in early stage, unquoted companies?",
        "To meet your investment objectives, when will you need to receive returns from your investment in early stage, unquoted companies?",
      ),
      options: ThreeToFiveYearsOptions,
      validation: Yup.string()
        .nullable()
        .oneOf(ThreeToFiveYearsOptions, "Please select one of the available options")
        .required("Please select one of the available options"),
    },
    getThreeToFiveYearsWarning("whenNeedIncome", isAdvisor),
    {
      type: "radio",
      name: "whenNeedAccess",
      label: getLabel(
        isAdvisor,
        "To meet your client's investment objectives, when will they need to have access to their capital invested in early stage, unquoted companies?",
        "To meet your investment objectives, when will you need to have access to your capital invested in early stage, unquoted companies?",
      ),
      options: ThreeToFiveYearsOptions,
      validation: Yup.string()
        .nullable()
        .oneOf(ThreeToFiveYearsOptions, "Please select one of the available options")
        .required("Please select one of the available options"),
    },
    getThreeToFiveYearsWarning("whenNeedAccess", isAdvisor),
    ...(fund.type.every((type) => type !== FundType.SEIS && type !== FundType.EIS)
      ? []
      : ([
          { type: "divider" },
          {
            type: "section",
            title: "Specific questions relating to SEIS and/or EIS taxation reliefs",
            fields: [
              {
                type: "section",
                title: getLabel(
                  isAdvisor,
                  "Please note the service may not be an appropriate investment if your client cannot take advantage of some of the SEIS and/or EIS tax reliefs (for each question, tick one box as appropriate).",
                  "Please note the service may not be an appropriate investment if you cannot take advantage of some of the SEIS and/or EIS tax reliefs (for each question, tick one box as appropriate).",
                ),
                titleProps: { variant: "body2", strong: true },
              },
            ],
          },
          {
            type: "radio",
            name: "receivedTaxAdvice",
            label: getLabel(
              isAdvisor,
              "Has your client received any taxation advice relating to investment in this fund?",
              "Have you received any taxation advice relating to investment in this fund?",
            ),
            validation: Yup.boolean().nullable().required("Please select either Yes or No"),
          },
          {
            type: "radio",
            name: "useSEISRelief",
            get label() {
              return (
                <>
                  {this.stringLabel} to take advantage of{" "}
                  {`${isSEIS && isEIS ? "SEIS and/or EIS" : fund.type.join(" and/or ")}`} taxation reliefs if available?
                </>
              );
            },
            stringLabel: getLabel(isAdvisor, "My client wishes", "I wish"),
            validation: Yup.boolean().nullable().required("Please select either Yes or No"),
          },
          {
            type: "section",
            condition: (values) => Boolean(values.useSEISRelief),
            fields: [
              {
                type: "radio",
                name: "claimIncomeTaxRelief",
                label: getLabel(
                  isAdvisor,
                  "Is your client seeking to claim full income tax relief? (They should consider whether you have sufficient income tax liability.)",
                  "Are you seeking to claim full income tax relief? (You should consider whether you have sufficient income tax liability.)",
                ),
                validation: Yup.boolean()
                  .nullable()
                  .when("useSEISRelief", {
                    is: true,
                    then: (schema) => schema.required("Please select either Yes or No"),
                  }),
              },
              {
                type: "radio",
                name: "carryBack.present",
                label: getLabel(
                  isAdvisor,
                  "Is your client seeking to claim any tax relief relating to a previous tax year?",
                  "Are you seeking to claim any tax relief relating to a previous tax year?",
                ),
                validation: Yup.boolean().nullable(),
              },
              {
                type: "section",
                title: getLabel(
                  isAdvisor,
                  "Please confirm (by ticking the below confirmation box) that your client understand that investments into investee companies may not occur in the current tax year and therefore there is no guarantee that they will be able to claim any SEIS and/or EIS and capital gains tax reliefs relating to a prior tax year.",
                  "Please confirm (by ticking the below confirmation box) that you understand that investments into investee companies may not occur in the current tax year and therefore there is no guarantee that you will be able to claim any SEIS and/or EIS and capital gains tax reliefs relating to a prior tax year.",
                ),
                fields: [
                  {
                    type: "checkbox",
                    name: "carryBack.mayNotOccurInTaxYear",
                    label: getLabel(
                      isAdvisor,
                      "Please tick this box to confirm that your client understands that there is no guarantee that carry back relief will be available.",
                      "Please tick this box to confirm that you understand that there is no guarantee that carry back relief will be available.",
                    ),
                    validation: Yup.boolean().nullable(),
                  },
                ],
              },
            ],
          },
          {
            type: "validation",
            validation: Yup.object({
              carryBack: Yup.object({
                present: Yup.boolean().nullable().notRequired(),
                mayNotOccurInTaxYear: Yup.boolean().nullable().notRequired(),
              }).when("useSEISRelief", {
                is: true,
                then: Yup.object({
                  present: Yup.boolean().nullable().required("Please select either Yes or No"),
                  mayNotOccurInTaxYear: Yup.boolean()
                    .typeError("Please tick this box")
                    .oneOf([true], "Please tick this box")
                    .required("Please tick this box"),
                }),
              }),
            }),
          },
        ] as FormGenConfig<ObjectivesType>)),
    ...(!isSEIS
      ? []
      : ([
          {
            type: "section",
            key: "cgtExemptions",
            fields: [
              {
                type: "radio",
                name: "cgtExemption.present",
                label: getLabel(
                  isAdvisor,
                  "For SEIS purposes, is your client seeking to take advantage of the 50% CGT exemption for chargeable gains reinvested (up to the maximum subscribed)?",
                  "For SEIS purposes, are you seeking to take advantage of the 50% CGT exemption for chargeable gains reinvested (up to the maximum subscribed)?",
                ),
                validation: Yup.boolean().nullable().required("Please select either Yes or No"),
              },
              {
                type: "text",
                name: "cgtExemption.details",
                label: "Please provide more information about the gains, including dates & amounts",
                placeholder: "Information about the gains",
                condition: (values) => Boolean(values.cgtExemption?.present),
                validation: Yup.string().when("present", {
                  is: true,
                  then: Yup.string().required("Please provide details"),
                }),
              },
            ],
          },
        ] as FormGenConfig<ObjectivesType>)),
    ...(!isEIS
      ? []
      : ([
          {
            type: "section",
            fields: [
              {
                type: "radio",
                name: "deferCapitalGains.present",
                label: getLabel(
                  isAdvisor,
                  "For EIS purposes, is your client seeking to defer a capital gain?",
                  "For EIS purposes, are you seeking to defer a capital gain?",
                ),
                validation: Yup.boolean().nullable().required("Please select either Yes or No"),
              },
              {
                type: "text",
                name: "deferCapitalGains.details",
                label: "Please provide more information about the gains, including dates & amounts",
                placeholder: "Information about the gains",
                condition: (values) => Boolean(values.deferCapitalGains?.present),
                validation: Yup.string().when("present", {
                  is: true,
                  then: Yup.string().required("Please provide details"),
                }),
              },
            ],
          },
        ] as FormGenConfig<ObjectivesType>)),
  ];
};

const Objectives: React.FC = () => {
  useAppFormRedirect();
  const { classes } = useAppStyles();
  const navigate = useNavigate();
  const { selected: selectedFund } = useFundsContext();
  const { application, handleAppDataPatch, isAdvisor } = useApplication();
  const [dialogOpen, toggleDialog] = useToggle(false);
  const sectionText = isAdvisor ? "Section 2 of 4" : "Section 2 of 5";

  const config = useMemo(
    () => selectedFund && getObjectivesConfig({ fund: selectedFund, isAdvisor }),
    [isAdvisor, selectedFund],
  );

  const goBack = useCallback(() => navigate(`../${Forms.InvestorDetails}`), [navigate]);

  const handleSubmit = useCallback(
    async (values: ObjectivesType) => {
      const app = await handleAppDataPatch({ objectives: values });
      if (app) navigate(`../${Forms.Finances}`);
    },
    [handleAppDataPatch, navigate],
  );

  return (
    <>
      <Container maxWidth="md">
        <FormGroup>
          <Typography variant="h4">
            <strong>{sectionText}:</strong>
          </Typography>
          <Typography variant="h4" paragraph>
            Investment objectives
          </Typography>
          <Typography paragraph>
            The investment is designed to be held for the medium to long term, because investments in SEIS/EIS companies
            have to be held for at least three years in order to benefit from the SEIS and/or EIS and capital gains tax
            reliefs in relation to those investee companies. Please note that investments in unquoted companies are
            higher risk than shares quoted on the main market of the London Stock Exchange. The service is designed to
            provide investors with access to a portfolio of investments in small unquoted companies which qualify for
            SEIS and/or EIS tax benefits and have the potential for capital appreciation.
          </Typography>
          <Typography variant="body2" paragraph>
            <Link onClick={toggleDialog.on}>Why do we ask all the questions below?</Link> (It will only take 2 min to
            read)
          </Typography>
        </FormGroup>
      </Container>
      <Divider />
      {config && (
        <Container maxWidth="md">
          <FormGen
            config={config}
            initialValues={application?.data.objectives}
            classes={{ formGroup: classes.formGroup }}
            validateOnMount
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ errors, submitCount }) => (
              <>
                <Container maxWidth="md">
                  {submitCount > 0 && Object.keys(errors).length !== 0 && errors.constructor === Object && (
                    <FormGroup>
                      <Typography variant="body2" color="error" paragraph>
                        Please complete the following fields:
                      </Typography>
                      <ErrorsList />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormButtons primaryProps={{ label: "Next" }} secondaryProps={{ label: "Back", onClick: goBack }} />
                  </FormGroup>
                </Container>
              </>
            )}
          </FormGen>
        </Container>
      )}
      <WhyQuestions open={dialogOpen} onClose={toggleDialog.off} />
    </>
  );
};

export default Objectives;
