export enum AssessmentStatus {
  PENDING = "pending", // show the assessment to the user
  COOLDOWN_SUCCESS = "cooldown-success", //this will be shown when user has completed the assessment successfully in less than 3 attempts
  COOLDOWN_FAILED = "cooldown-failed", // this will be shown after each failed attempt exluding the first failed attempt
  COMPLETED_SUCCESSFULLY = "completed-successfully", //user has completed the assessment, user has completed the success cooldown period
  CONTINUE_TO_THE_APPLICATION = "continue-to-the-application", //user has completedd the success cooldown period and has selected continue to the application
}

export const QUESTIONS = [
  //set 1
  [
    {
      question: "What happens to the majority of small, unquoted companies?",
      options: {
        a: "They Fail",
        b: "They are likely to list on a stock exchange",
        c: "They are likely to be sold to a larger company",
        d: "They generate good returns for investors",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason:
          "The majority of small, unquoted companies may fail. There is no  single outcome that applies to all small, unquoted companies, and the success or failure of these companies can  depend on a variety of factors such as their business model, management team, market demand, and competition.",
      },
    },

    {
      question: "What will happen if one or more of the Investee Companies fails? ",
      options: {
        a: "I will be compensated by a third party",
        b: "I will receive compensation from the Financial Services Compensation Scheme (FSCS)",
        c: "I may lose some or all of the money I invest",
        d: "I will be able to sell my shares at par",
      },
      answer: {
        correctOption: "c",
        selectedOption: null,
        reason:
          "If one or more of the investee companies fails, you may lose some or  all of the money you invested. There is no guarantee that you will be compensated by a third party or the Financial  Services Compensation Scheme (FSCS) in the event that an investee company fails.",
      },
    },
    {
      question:
        "When will I be able to get back my initial investment if it has been invested by the Investment Manager?",
      options: {
        a: "On-demand at any time",
        b: "It may not be possible to liquidate my investment quickly or at all",
        c: "The company will be listed so I can sell my shares whenever I choose",
        d: "The investment manager will buy my shares and provide me with liquidity",
      },
      answer: {
        correctOption: "b",
        selectedOption: null,
        reason:
          "It may not be possible to liquidate your investment in an unquoted  company quickly or at all. Unquoted companies do not have a public market where shares can be traded, so it may be  difficult to find buyers for your shares. Additionally, the investment manager may have terms and conditions in place  that restrict your ability to sell your shares or require you to give notice before selling.",
      },
    },
    {
      question:
        "What will happen to the level of my shareholding in an investee company if it issues more shares in the  future after I invest?",
      options: {
        a: "My proportion of the total shares in the company will increase",
        b: "My proportion of the total shares in the company will remain the same",
        c: "My proportion of the total shares in the company will decrease",
        d: "The value of the total shares in the company will increase",
      },
      answer: {
        correctOption: "c",
        selectedOption: null,
        reason:
          "If an investee company issues more shares in the future after you  invest, your proportion of the total shares in the company will decrease. This is because the company is diluting the  value of existing shares by issuing additional shares, which increases the total number of shares outstanding and  reduces the relative ownership stake of each shareholder.",
      },
    },
    {
      question: "When do the majority of small, unquoted companies pay dividends?",
      options: {
        a: "Rarely",
        b: "Bi-annually",
        c: "On-demand ",
        d: "Every year",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason:
          "The majority of small, unquoted companies may pay dividends  rarely or not at all. These companies may not have the same level of profitability or cash flow as larger, publicly  traded companies, and they may choose to reinvest their profits into the business rather than pay dividends to  shareholders. Additionally, unquoted companies may have more flexibility in terms of their dividend policies, as they  are not subject to the same level of regulatory oversight as publicly traded companies.",
      },
    },
  ],
  [
    // set 2

    {
      question: "What is the main difference between a common and preferred stock?",
      options: {
        a: "Common stockholders have voting rights, while preferred stockholders do not",
        b: "Preferred stockholders have priority in the event of a company liquidation",
        c: "Preferred stock has a fixed dividend, while common stock dividends can vary",
        d: "Common stockholders have the right to purchase new shares before other investors",
      },
      answer: {
        correctOption: "b",
        selectedOption: null,
        reason:
          "The main difference between common and preferred stock is that  common stockholders have voting rights, while preferred stockholders do not. Common stockholders have the right  to participate in company decision-making through the exercise of their voting rights at shareholder meetings.  Preferred stockholders, on the other hand, do not have voting rights and are typically not involved in the company's  decision-making process. Preferred stock may have other rights and privileges, such as a fixed dividend or priority in  the event of company liquidation, but these rights may vary depending on the specific terms of the preferred stock.",
      },
    },
    {
      question:
        "What is the main consideration when evaluating the potential risks of investing in early-stage companies?",
      options: {
        a: "The company's financial health",
        b: "The company's management team",
        c: "The company's industry and market conditions",
        d: "All of the above",
      },
      answer: {
        correctOption: "d",
        selectedOption: null,
        reason:
          "The main considerations when evaluating the potential risks of  investing in early-stage companies include the company's financial health, management team, industry and market  conditions, and other factors that could impact the company's success or failure. These factors can all contribute to  the level of risk associated with an investment in an early-stage company, and investors should carefully consider  these factors before making an investment decision.",
      },
    },
    {
      question: "What is the main risk of investing in early-stage companies?",
      options: {
        a: "Potential for high volatility",
        b: "Potential for significant losses",
        c: "Potential for illiquidity",
        d: "All of the above",
      },
      answer: {
        correctOption: "b",
        selectedOption: null,
        reason:
          "The main risk of investing in early-stage companies is the potential  for significant losses. These companies are often in the early stages of their development and may not yet have a proven track record of profitability or financial stability. Additionally, early-stage companies may be subject to high  volatility and illiquidity, as there may not be a ready market for buying and selling their shares. As a result, investing  in early-stage companies carries a high degree of risk, and investors may lose some or all of their investments.",
      },
    },
    {
      question:
        "Here is a question with four possible answers about the risk of an EIS or SEIS investment losing its tax relief:",
      options: {
        a: "The company may not meet the eligibility criteria for the scheme",
        b: "The company may not have sufficient qualifying assets",
        c: "The company may not be carrying on a qualifying trade",
        d: "The investor may not meet the eligibility criteria for the scheme",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason:
          "The risk of an EIS or SEIS investment losing its tax relief may occur if  the company does not meet the eligibility criteria for the scheme, does not have sufficient qualifying assets, is not  carrying on a qualifying trade, or if the investor does not meet the eligibility criteria for the scheme. It is important for investors to carefully review the eligibility criteria for these schemes and ensure that both the company and the  investor meet all of the requirements in order to qualify for tax relief. Failure to meet any of the eligibility criteria may  result in the loss of tax relief on the investment.",
      },
    },
    {
      question:
        "What is the main limitation of the FSCS when it comes to compensating investors for losses resulting from  the failure of early-stage investments?",
      options: {
        a: "The FSCS may not cover losses resulting from the failure of early-stage investments",
        b: "The FSCS may not cover all types of early-stage investments",
        c: "The FSCS may not cover the full amount of an investor's losses",
        d: "All of the above",
      },
      answer: {
        correctOption: "d",
        selectedOption: null,
        reason: `The Financial Services Compensation Scheme (FSCS) is a UK government-backed protection scheme that compensates investors if a financial firm goes out of business or is  unable to meet its obligations. However, the FSCS has several limitations when it comes to compensating investors  for losses resulting from the failure of early-stage investments. <br/>First, the FSCS may not cover losses resulting from the failure of early-stage investments. This is because  early-stage investments, such as start-ups and venture capital projects, are generally considered to be higher  risk and may not be covered by the FSCS. 
              Second, the FSCS may not cover all types of early-stage investments. For example, the FSCS may not cover  investments in certain types of businesses or industries, such as those that are considered to be high-risk or highly speculative. 
              Finally, the FSCS may not cover the full amount of an investor's losses. The FSCS has a limit on the amount of  compensation it can pay to investors, and this limit may not be sufficient to fully compensate investors for  their losses. 
<br/>
              Overall, it is important for investors to be aware of the limitations of the FSCS when it comes to compensating for  losses resulting from the failure of early-stage investments. It is always a good idea for investors to carefully consider  the risks involved in any investment and to diversify their portfolio to minimize their exposure to risk. 
              
              `,
      },
    },
  ],
  [
    // third set

    {
      question: "What is the main risk of investing in early-stage companies?",
      options: {
        a: "Limited geographical reach",
        b: "Illiquidity",
        c: "Volatility",
        d: "limited market demand",
      },
      answer: {
        correctOption: "b",
        selectedOption: null,
        reason: `Investing in early-stage companies involves a high level of risk, as  these companies are typically in the process of developing and testing their products or services and may not yet have 
              a proven track record or revenue stream. One of the main risks of investing in early-stage companies is illiquidity,  meaning that the investment is not easily converted to cash. This can make it difficult to sell the investment if needed and can result in significant losses if the company does not perform as expected. 
              `,
      },
    },
    {
      question: "What is the main risk of investing in unquoted companies?",
      options: {
        a: "Lack of transparency",
        b: "Difficulty in valuing the company",
        c: "Limited Market shares",
        d: "Limited legal protection",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason:
          "The main risk of investing in unquoted companies is a lack of  transparency. These companies are not listed on a public stock exchange and therefore may not have the same level  of disclosure and regulatory oversight as publicly traded companies. This can make it difficult for investors to  accurately assess the financial health and prospects of the company, which can increase the risk of investing in  unquoted companies.",
      },
    },
    {
      question: "What is the main risk of investing in companies that have not yet generated profits? ",
      options: {
        a: "They may never become profitable",
        b: "They may not pay dividends",
        c: "They may experience significant price volatility",
        d: "They may be subject to regulatory risks",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason:
          "Investing in companies that have not yet generated profits carries a  high level of risk, as there is no guarantee that the company will ever become profitable. This means that there is a  possibility that the investment will not generate any returns and could result in a total loss of the investment. In  addition to this, companies that have not yet generated profits may not have the financial resources or stability to  pay dividends to shareholders, which can impact the potential returns on the investment.",
      },
    },
    {
      question: "What is the main risk of investing in companies that rely on a single product or service?",
      options: {
        a: "They may have limited growth potential",
        b: "They may be at risk of competition",
        c: "They may be vulnerable to market shifts",
        d: "They may be subject to regulatory risks",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason: `Investing in companies that rely on a single product or service carries  a risk of limited growth potential. If the product or service fails in the market or becomes obsolete, the company may  struggle to generate revenue and may not have other sources of income to fall back on. This can impact the potential  for the company to grow and expand and may result in lower returns on the investment. In addition to this,  
              companies that rely on a single product or service may be more vulnerable to competition and market shifts, which  can also impact the potential for growth and success.`,
      },
    },
    {
      question: "What is most likely to happen if you invest in this fund?",
      options: {
        a: "You will return more than the broader public market on average",
        b: "You will return as much as the public market on average",
        c: "You will neither returns more or less than what you invested on average",
        d: "You will lose all your investment in the fund",
      },
      answer: {
        correctOption: "a",
        selectedOption: null,
        reason: `Investing in early-stage companies carries a high level of risk, as  these companies are typically in the process of developing and testing their products or services and may not yet have  a proven track record or revenue stream. This means that there is a possibility that the investment will not generate  any returns and could result in a total loss of the investment. In addition to this, early-stage companies may not have  the financial resources or stability to pay dividends to shareholders, which can impact the potential returns on the 
              investment. It is important for investors to carefully consider the risks and potential returns of any investment before  making a decision. `,
      },
    },
  ],
];
