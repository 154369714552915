import { ColGroupDefinition, ColumnDefinition } from "@wearenova/mui-data-table";
import React, { ReactNode } from "react";
import { ExportStructure } from "server/services/export";

const getTextContent = (node: ReactNode): string => {
  if (typeof node === "string" || typeof node === "number") return node.toString();
  if (!node || typeof node === "boolean") return "";
  if (Array.isArray(node)) return node.map(getTextContent).join("");
  if (React.isValidElement(node) && node.props.children) return getTextContent(node.props.children);
  return "";
};

const getDataIndex = (col: ColumnDefinition<any> | ColGroupDefinition<any>) => {
  if (col.dataIndex) return col.dataIndex;
  if (typeof col.numerical === "string") return col.numerical;
  if (typeof col.numerical === "object" && typeof col.numerical.path === "string") return col.numerical.path;
};

const getStringTitle = (col: ColumnDefinition<any> | ColGroupDefinition<any>, data: any[]) => {
  const renderedTitle = typeof col.title !== "function" ? col.title : col.title(data);
  return getTextContent(renderedTitle);
};

export const toCSVStructure = <T extends ColumnDefinition<any>>(
  structure: T[],
  data: T extends ColumnDefinition<any, infer U> ? U : never,
) => {
  return structure
    .flatMap((col) => {
      const rootTitle = getStringTitle(col, data);
      if (!col.colGroup) return { key: col.key, title: rootTitle, dataIndex: getDataIndex(col) };
      return col.colGroup.map((cg) => {
        const nestedTitle = getStringTitle(cg, data);
        return {
          key: cg.key,
          title: typeof rootTitle !== "string" || !rootTitle ? nestedTitle : `${rootTitle} - ${nestedTitle}`,
          dataIndex: getDataIndex(cg),
        };
      });
    })
    .filter((c): c is ExportStructure => Boolean(c.title && c.dataIndex));
};
