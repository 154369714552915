import ipAxios from "client/utils/ipAxios";
import Logging from "client/utils/logging";
import { ObjectIdLike } from "mongoose";
import { LeanPortfolio } from "server/services/portfolio";

export const patchPortfolio = async (id: ObjectIdLike, changes: Partial<LeanPortfolio>) => {
  try {
    const res = await ipAxios.patch<LeanPortfolio>(`/admin/portfolios/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const updatePortfolio = async (id: ObjectIdLike, changes: LeanPortfolio) => {
  try {
    const res = await ipAxios.put<LeanPortfolio>(`/admin/portfolios/${id}`, changes);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};

export const deletePortfolio = async (id: ObjectIdLike) => {
  try {
    const res = await ipAxios.delete<string>(`/admin/portfolios/${id}`);
    return res.data;
  } catch (error) {
    Logging.error(error);
    return null;
  }
};
